import { Auth, API } from 'aws-amplify'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import Swal from 'sweetalert2'

import { getAuth } from '../redux/selectors/auth'

const axios = require('axios')
const md5 = require('md5')

export const ppthb888Deposit = (payload) => {
  return API.post('thirdParty', `/ppthb888/payment-request`, {
    body: {
      playerUsername: get(payload, 'playerUsername', null),
      amount: get(payload, 'amount', 100),
      transactionNumber: get(payload, 'transactionNumber', new Date().toISOString()),
      remark: get(payload, 'remark', ''),
    },
  })
    .then((res) => {
      if (res.code === 0) {
        const paymentUrl = get(res, 'detail.results', null)
        console.log('data ======', paymentUrl)
        return paymentUrl
        // dispatch(
        //   setWalletCredit({
        //     wallet: {
        //       credit: 0,
        //     },
        //   })
        // )
      }
      throw new Error('unexpected ppthb888 res.code')
    })
    .catch((err) => {
      console.error(err)
      throw new Error(err.message || 'error at ppthb888 request')
    })
}

// const api = axios.create({
//   baseURL: 'https://www.ppthb888.com',
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//   },
//   validateStatus(status) {
//     return status >= 200 && status <= 400
//   },
// })

// function urlJoin(...args) {
//   return args
//     .join('/')
//     .replace(/[/]+/g, '/')
//     .replace(/^(.+):\//, '$1://')
//     .replace(/^file:/, 'file:/')
//     .replace(/\/(\?|&|#[^!])/g, '$1')
//     .replace(/\?/g, '&')
//     .replace('&', '?')
// }

// TODO

// =========== BACK UP ===========
// const merchantUid = 'e1c5eff61fd141958c74f4e987f5fd60'
// const merchantSecret = '67c2f11548d34ec6a8a45824d010d6e0'
// const notifyUrl = 'https://dev.api.vava.dev/third-party/ppthb888/payment-success'
// const callbackRedirect = '/transactions'
/* export const ppthb888Deposit = async (payload) => {
  // payer name
  // amount
  // transactionID
  // remark
  const playerUsername = payload.playerUsername
  const amount = payload.amount
  const transactionNumber = payload.transactionNumber
  const remark = ''

  const returnUrl = urlJoin(window ? window.location.origin : '', callbackRedirect)
  // console.log(returnUrl)

  const data = {
    amount,
    merchant: merchantUid, // Merchant uid
    paytype: 'promptpay', // Payment type
    outtradeno: transactionNumber, // Merchant order number
    remark,
    notifyurl: notifyUrl,
    returnurl: returnUrl,
    payername: playerUsername, // Payer's name
    returndataformat: 'serverhtml',
  }

  // prepare param string for signing
  const paramString = Object.keys(data)
    .sort() // sort key alphabetically
    .reduce(
      (queryString, key) => (queryString += `&${key}=${encodeURIComponent(data[key])}`), // urlencoding
      ''
    ) // stringify
    .substring(1) // remove first &
    .toLowerCase() // lowercase

  const strToHash = `${paramString}&secret=${merchantSecret}`
  const hashResult = md5(strToHash)

  const queryString = `${paramString}&sign=${hashResult}`

  console.log('>>>: ppthb888Deposit -> queryString', queryString)
  // console.log(`/pay?${queryString}`)
  try {
    // const { status, data } = await api.post('/pay', queryString)
    // check code equal zero - {"code":6,"results":"the outtradeno has exists"}
    // res.status(status).send(data)
    // return {
    //   status,
    //   data,
    // }
  } catch (error) {
    console.error(error)
  }
  // res.status(200).json({ ok: true })
}
*/
