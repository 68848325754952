/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewTest = /* GraphQL */ `
  query GetNewTest($id: ID!) {
    getNewTest(id: $id) {
      id
      email
      number
      time
      round
      expire
    }
  }
`
export const listNewTests = /* GraphQL */ `
  query ListNewTests($filter: TableNewTestFilterInput, $limit: Int, $nextToken: String, $orderBy: LinkOrderByInput) {
    listNewTests(filter: $filter, limit: $limit, nextToken: $nextToken, orderBy: $orderBy) {
      items {
        id
        email
        number
        time
        round
        expire
      }
      nextToken
    }
  }
`
