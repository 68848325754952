// types
export const TOGGLE_CART = 'TOGGLE_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const UPDATE_AMOUNT = 'UPDATE_AMOUNT'
export const UPDATE_RATIO = 'UPDATE_RATIO'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const UPDATE_UNIQUE_CART = 'UPDATE_UNIQUE_CART'
export const REMOVE_LASTEST = 'REMOVE_LASTEST'

// actions
export const toggleCart = ({ lottoNumber, purchaseItem }) => ({
  type: TOGGLE_CART,
  lottoNumber,
  purchaseItem,
})

export const updateAmount = ({ purchaseItem, newAmount }) => ({
  type: UPDATE_AMOUNT,
  purchaseItem,
  newAmount,
})

export const updateRatio = ({ purchaseItem, amount }) => ({
  type: UPDATE_RATIO,
  purchaseItem,
  amount,
})

export const clearCart = () => ({
  type: CLEAR_CART,
})

export const removeFromCart = ({ detail, key, stamp }) => ({
  type: REMOVE_FROM_CART,
  detail,
  key,
  stamp,
})

export const updateUniqueCart = () => ({
  type: UPDATE_UNIQUE_CART,
})

export const removeLastest = () => ({
  type: REMOVE_LASTEST,
})
