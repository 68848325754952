import PropTypes from 'prop-types'
import React from 'react'

import './Box.scss'
import noop from 'lodash/noop'

// import Flare1 from '../Flare/Flare1'
// import Flare2 from '../Flare/Flare2'

const BoxWhite = ({ children, containerClass, onClick }) => {
  return (
    <div className={`boxComponent__containerWhite ${containerClass}`} onClick={onClick}>
      {
        <div className='boxComponent__shineBox'>
          <div className='boxComponent' />
        </div>
      }
      {children}
      {/*{withFlare && <Flare1 />}*/}
      {/*{withFlare && <Flare2 />}*/}
    </div>
  )
}

BoxWhite.defaultProps = {
  withFlare: true,
  // withShine: true,
  onClick: noop,
}

BoxWhite.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.string,
  // withFlare: PropTypes.bool,
  // withShine: PropTypes.bool,
  onClick: PropTypes.func,
}

export default BoxWhite
