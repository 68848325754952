import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FacebookShareButton, LineShareButton } from 'react-share'

import { getFrontendUrl } from '../../config/urls'
import { AFF_PAGE } from '../../utils/lottoList'
import { useSelector } from 'react-redux'
import { getLang } from '../../redux/selectors/lang'

const FacebookIcon = () => {
  return (
    <svg viewBox='0 0 64 64' width='32' height='32' className='social-icon social-icon--facebook '>
      <g>
        <circle cx='32' cy='32' r='31' fill='#3b5998'></circle>
      </g>
      <g>
        <path
          d='M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z'
          fill='white'
        ></path>
      </g>
    </svg>
  )
}

const LineIcon = () => {
  return (
    <svg viewBox='0 0 64 64' width='32' height='32' className='social-icon social-icon--line '>
      <g>
        <circle cx='32' cy='32' r='31' fill='#00b800'></circle>
      </g>
      <g>
        <path
          d='M52.62 30.138c0 3.693-1.432 7.019-4.42 10.296h.001c-4.326 4.979-14 11.044-16.201 11.972-2.2.927-1.876-.591-1.786-1.112l.294-1.765c.069-.527.142-1.343-.066-1.865-.232-.574-1.146-.872-1.817-1.016-9.909-1.31-17.245-8.238-17.245-16.51 0-9.226 9.251-16.733 20.62-16.733 11.37 0 20.62 7.507 20.62 16.733zM27.81 25.68h-1.446a.402.402 0 0 0-.402.401v8.985c0 .221.18.4.402.4h1.446a.401.401 0 0 0 .402-.4v-8.985a.402.402 0 0 0-.402-.401zm9.956 0H36.32a.402.402 0 0 0-.402.401v5.338L31.8 25.858a.39.39 0 0 0-.031-.04l-.002-.003-.024-.025-.008-.007a.313.313 0 0 0-.032-.026.255.255 0 0 1-.021-.014l-.012-.007-.021-.012-.013-.006-.023-.01-.013-.005-.024-.008-.014-.003-.023-.005-.017-.002-.021-.003-.021-.002h-1.46a.402.402 0 0 0-.402.401v8.985c0 .221.18.4.402.4h1.446a.401.401 0 0 0 .402-.4v-5.337l4.123 5.568c.028.04.063.072.101.099l.004.003a.236.236 0 0 0 .025.015l.012.006.019.01a.154.154 0 0 1 .019.008l.012.004.028.01.005.001a.442.442 0 0 0 .104.013h1.446a.4.4 0 0 0 .401-.4v-8.985a.402.402 0 0 0-.401-.401zm-13.442 7.537h-3.93v-7.136a.401.401 0 0 0-.401-.401h-1.447a.4.4 0 0 0-.401.401v8.984a.392.392 0 0 0 .123.29c.072.068.17.111.278.111h5.778a.4.4 0 0 0 .401-.401v-1.447a.401.401 0 0 0-.401-.401zm21.429-5.287c.222 0 .401-.18.401-.402v-1.446a.401.401 0 0 0-.401-.402h-5.778a.398.398 0 0 0-.279.113l-.005.004-.006.008a.397.397 0 0 0-.111.276v8.984c0 .108.043.206.112.278l.005.006a.401.401 0 0 0 .284.117h5.778a.4.4 0 0 0 .401-.401v-1.447a.401.401 0 0 0-.401-.401h-3.93v-1.519h3.93c.222 0 .401-.18.401-.402V29.85a.401.401 0 0 0-.401-.402h-3.93V27.93h3.93z'
          fill='white'
        ></path>
      </g>
    </svg>
  )
}

const CloseIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' width='24' height='24' viewBox='0 0 24 24'>
      <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z' />
    </svg>
  )
}

export default function ShareButton({ url = '' }) {
  if (/^http/.test(url)) {
    // Need to trim url, and modify before proceeding, as the url is the full URL link, not just the path name
    url = url.replace(/^https?:\/\/.+?\//g, getFrontendUrl() + '/')
  }

  const [isCollapse, setIsCollapse] = useState(true)
  const lang = useSelector(getLang)
  const language = lang.lang

  return (
    <div
      className={`py-1 px-4 home-share-sheet-btn text-xs ${!isCollapse ? 'active' : ''}`}
      onClick={() => setIsCollapse(!isCollapse)}
    >
      {isCollapse ? (
        <span>{language === 'MM' ? 'သင့်မိတ်ဆက်လင့်ခ်ကိုသူငယ်ချင်မျှဝေသည်' : AFF_PAGE['သင့်မိတ်ဆက်လင့်ခ်ကိုသူငယ်ချင်မျှဝေသည်']}</span>
      ) : (
        <div className='home-share-sheet'>
          <FacebookShareButton url={url}>
            <FacebookIcon />
          </FacebookShareButton>
          <LineShareButton url={url}>
            <LineIcon />
          </LineShareButton>
          <button onClick={() => setIsCollapse(!isCollapse)}>
            <CloseIcon />
          </button>
        </div>
      )}
    </div>
  )
}

ShareButton.propTypes = {
  url: PropTypes.string,
}
