/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNewTest = /* GraphQL */ `
  mutation CreateNewTest($input: CreateNewTestInput!) {
    createNewTest(input: $input) {
      id
      email
      number
      time
      round
      expire
    }
  }
`
export const updateNewTest = /* GraphQL */ `
  mutation UpdateNewTest($input: UpdateNewTestInput!) {
    updateNewTest(input: $input) {
      id
      email
      number
      time
      round
      expire
    }
  }
`
export const deleteNewTest = /* GraphQL */ `
  mutation DeleteNewTest($input: DeleteNewTestInput!) {
    deleteNewTest(input: $input) {
      id
      email
      number
      time
      round
      expire
    }
  }
`
