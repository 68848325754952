import React, { useState } from 'react'
import _ from 'lodash'

import SortArrows from '../../components/SortArrows'
import Button from '../../components/Button/Button'
import Box from '../../components/Box/Box'
import './CreateLotto.scss'

const CreateLotto = () => {
  const [data] = useState([])

  const sortColumn = () => {}
  const goNext = () => {}
  const goBack = () => {}
  const findLotto = () => {}
  const handleCreateLottoButtonClick = () => {}

  return (
    <section className='createLotto'>
      <div className='createLotto__topButtonWrapper'>
        <Button
          text='จัดการเลขชุด'
          buttonBackground='rgba(0,0,0,0.5)'
          wrapperClassName='createLotto__titleWrapper'
          buttonClassName='createLotto__titleButton'
          shouldNotShowCursor
        />
        <Button
          text='+ สร้างเลขชุด'
          wrapperClassName='createLotto__createButtonWrapper'
          buttonClassName='createLotto__createButton'
          onButtonClick={handleCreateLottoButtonClick}
        />
      </div>
      <Box containerClass='listing'>
        <div className='listing__wrapper'>
          <div className='listing__inner'>
            <select name='list'>
              {_.range(10, 110, 10).map((num) => {
                return (
                  <option key={num} value={num}>
                    {num}
                  </option>
                )
              })}
            </select>
            <p>แสดงรายการ</p>
          </div>
          <input onChange={findLotto} type='text' name='search' id='search' placeholder='ค้นหา' />
        </div>
        <hr />
        <table>
          <thead>
            <tr>
              <th colSpan='3'>ชื่อเลขชุด</th>
              <th onClick={sortColumn} className='listing__sort'>
                วัน-เวลาที่สร้าง
                <SortArrows />
              </th>
              <th onClick={sortColumn} className='listing__sort'>
                จัดการ
                <SortArrows />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              <td>data</td>
            ) : (
              <tr>
                <td>No data available in table</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='summary'>
          <span className='summary__number'>0-0</span>
          <p>จาก 0 รายการ</p>
        </div>
        <div className='pageButton'>
          <button onClick={goNext}>&lt;</button>
          <button onClick={goBack}>&gt;</button>
        </div>
      </Box>
    </section>
  )
}

export default CreateLotto
