import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Helmet } from 'react-helmet'
import _ from 'lodash'

import Hotpick from '../Home/Hotpick'

import './Hotpick.scss'

export default function Fastbuy({ match }) {
  const { hotpickId } = match.params
  let history = useHistory()
  const [hotpick, setHotpick] = useState(null)

  useEffect(() => {
    API.get('hotpick', '/hotpicks/' + hotpickId)
      .then((res) => {
        console.log('get hotpicks >> ', res)
        const response = _.get(res, 'detail', [])

        let queryHotpick = {}
        queryHotpick._id = response._id
        queryHotpick.lottoNumber = response.lottoNumber
        queryHotpick.title = response.title
        queryHotpick.desc = response.desc
        queryHotpick.dateStr = response.dateStr
        queryHotpick.result = {}
        queryHotpick.result.threeAll = response.result.threeAll
        queryHotpick.result.twoAll = response.result.twoAll
        queryHotpick.result.nineteenStraight = response.result.nineteenStraight
        queryHotpick.result.runAll = response.result.nineteenStraight
        queryHotpick.hotpickImageURL = response.hotpickImageURL
        console.log(queryHotpick, 'queryHotpick')
        setHotpick(queryHotpick)
      })
      .catch((err) => {
        console.error(err, 'error')
        console.log(hotpick)
        history.push('/')
      })
    return () => {}
  }, [])
  return (
    <>
      {hotpick !== null ? (
        <Helmet>
          <meta property='og:title' content={'PAATOOK | ' + hotpick.title} />
          <meta property='og:description' content={hotpick.desc} />
          <meta property='og:image' content={hotpick.hotpickImageURL} />
        </Helmet>
      ) : null}
      <div className='hotpick-screen page'>
        {hotpick !== null ? <Hotpick data={hotpick} /> : <div>กรุณารอสักครู่ครับ...</div>}
      </div>
    </>
  )
}
