import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import get from 'lodash/get'
import _get from 'lodash/get'
// import sumBy from 'lodash/sumBy'
import _sortBy from 'lodash/sortBy'
import _reverse from 'lodash/reverse'
import moment from 'moment'
import numeral from 'numeral'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import { getLang } from '../../redux/selectors/lang'
import { AFF_PAGE } from '../../utils/lottoList'

import './Affiliate.scss'
import '../Withdrawal/Withdrawal.scss'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'

import ShareButton from '../../components/ShareButton/ShareButton'
import Box from '../../components/Box/Box'
import BoxWhite from '../../components/Box/BoxWhite'
import SortArrows from '../../components/SortArrows'
import { getAuth } from '../../redux/selectors/auth'
import { colorCode } from '../../utils/color'

const Affiliate = () => {
  const [affiliates, setAffiliates] = useState([])
  const [startDate, setStartDate] = useState(moment().add(-7, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [startDateFocus, setStartDateFocus] = useState(false)
  const [endDateFocus, setEndDateFocus] = useState(false)
  const [sortAsc, setSortAsc] = useState(false)
  const [tableColumn] = useState([
    { name: 'childUsername', label: 'User' },
    { name: 'date', label: 'Date' },
    { name: 'betting', label: 'Bet' },
    { name: 'amount', label: 'Amount' },
  ])
  const [isLoading, setIsLoading] = useState(false)
  const auth = useSelector(getAuth)
  const [affiliateWallet, setAffiliateWallet] = useState(0)
  const [transfer, setTransfer] = useState('')
  const [isTransfer, setIsTransfer] = useState(false)
  const history = useHistory()

  const lang = useSelector(getLang)
  const language = lang.lang

  const fetchAffiliateReport = async () => {
    const path = `/affiliate/report?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate
      .clone()
      .add(1, 'days')
      .format('YYYY-MM-DD')}&limit=100`
    setIsLoading(true)
    API.get('affiliate', path)
      .then((res) => {
        console.log(res, 'affiliate Report')
        const allReport = get(res, 'detail', [])
        setAffiliates(allReport)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const fetchAffiliateWallet = async () => {
    return await API.get('playerWallet', `/players/${auth.username}/wallets/1`)
      .then((res) => {
        const credit = _get(res, 'detail.credit', 0)
        console.log(credit, 'credit')
        setAffiliateWallet(credit)
      })
      .catch((err) => {
        console.log('error get Affiliate Wallet ======', err)
        console.error(err)
      })
  }

  // const _onTransfer = (e) => {
  //   const value = e.target.value
  //   if (value === '' || /^\d+(\.\d{0,2})?$/g.test(value)) {
  //     setTransfer(value)
  //   }
  // }


  const _onClickTransfer = async () => {
    // const amountNumber = Number(transfer)
    // Validate
    if (!affiliateWallet || affiliateWallet < 100) {
      Swal.fire({
        icon: 'error',
        title: 'Your transfer amount is below the limit',
        text: 'The minimum is 100 Baht.',
      })
      return
    }

    const walletBalance = await fetchAffiliateWallet()

    if (affiliateWallet > walletBalance) {
      Swal.fire({
        icon: 'error',
        title: 'An error occured',
        text: `Your transfer amount (${numeral(walletBalance).format('0,0.00')} Baht) is Insufficient`,
      })
      return
    }

    setIsTransfer(true)

    API.post('affiliate', '/transfer', {
      body: {
        amount: affiliateWallet,
      },
    })
      .then((res) => {
        console.log(res, 'res')
        const resStatus = _get(res, 'status', null)
        if (resStatus === 'Success') {
          return Swal.fire({
            icon: 'success',
            title: 'Success Transfer',
            text: `You can withdraw money from credit immediately. Or BET more to get more luck`,
          })
        } else {
          throw new Error('An error occured')
        }
      })
      .catch((err) => {
        console.log('error transfer ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'An error occured',
          text: err.message,
        })
      })
      .finally(() => {
        setIsTransfer(false)
        fetchAffiliateWallet()
      })
  }

  useEffect(() => {
    fetchAffiliateReport()
    fetchAffiliateWallet()
  }, [startDate, endDate, affiliateWallet])

  const sortColumn = (columnName) => {
    let sorted = []

    if (columnName === 'createDate') {
      sorted = affiliates.sort((a, b) => {
        return moment(a.createDate).diff(moment(b.createDate))
      })
    } else {
      sorted = _sortBy(affiliates, columnName)
    }

    if (!sortAsc) {
      sorted = _reverse(sorted)
    }
    setSortAsc(!sortAsc)
    setAffiliates(sorted)
  }

  return (
    <section className='affiliate page-top p-4 items-center mx-auto'>
      <Box containerClass='affiliate__pageTitle'>{language === 'MM' ? 'ဒီနေ့၏မန်ဘာဝင်များ' : AFF_PAGE['ဒီနေ့၏မန်ဘာဝင်များ']}</Box>
      <BoxWhite containerClass='withdrawal__box'>
        <span className='deposit__step'>โยกเข้ากระเป๋าหลัก</span>
        <p className='withdrawal__box-bankChoice withdrawal__withIcon-choose'>
          รายได้ปัจจุบัน</p>
        <div className='text-2xl sm:text-4xl' style={{ fontFamily: 'DESG7 Classic', color: colorCode.lottoYellow }}>
          {numeral(affiliateWallet).format('0,0.00') || 0}
        </div>
        {/*<div className='mt-5'>*/}
        {/*  <p>Income to be transferred as credit</p>*/}
        {/*  <input type='number' value={transfer} style={{ height: '200%' }} onChange={_onTransfer} />*/}
        {/*</div>*/}
        <div className='affiliate__share-button mt-3'>
          <button onClick={_onClickTransfer} disabled={isTransfer || affiliateWallet === 0}>
            <span>{language === 'MM' ? 'ဝင်ငွေထုပ်ယူရန်' : AFF_PAGE['ဝင်ငွေထုပ်ယူရန်']}</span>
          </button>
        </div>
      </BoxWhite>

      <Box containerClass='affiliate__pageTitle mt-5 text-xs'>{language === 'MM' ? 'မိတ်ဆက်ထားသောမန်ဘာဝင်များ' : AFF_PAGE['မိတ်ဆက်ထားသောမန်ဘာဝင်များ']}</Box>
      <div className='affiliate__date'>
        <div className='affiliate__date-wrapper'>
          <p>ตั้งแต่วันที่</p>
          <SingleDatePicker
            date={startDate}
            onDateChange={(date) => {
              setStartDate(date)
            }}
            focused={startDateFocus}
            onFocusChange={({ focused }) => setStartDateFocus(focused)}
            id='startDate'
            numberOfMonths={1}
            noBorder={true}
            readOnly
            displayFormat={'DD/MM/YYYY'}
            isOutsideRange={() => false}
          />
        </div>
        <div className='affiliate__date-wrapper'>
          <p>ถึงวันที่</p>
          <SingleDatePicker
            date={endDate}
            onDateChange={(date) => {
              setEndDate(date)
            }}
            focused={endDateFocus}
            onFocusChange={({ focused }) => setEndDateFocus(focused)}
            id='endDate'
            numberOfMonths={1}
            noBorder={true}
            readOnly
            displayFormat={'DD/MM/YYYY'}
            isOutsideRange={() => false}
          />
        </div>
      </div>
      <Box containerClass='affiliate__boxContainer'>
        <table className='affiliate__table'>
          <thead>
            <tr>
              {tableColumn.map((column, i) => (
                <th onClick={() => sortColumn(column.name)} key={`${column.name}-${i}`}>
                  {column.label}
                  <SortArrows />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isLoading && affiliates.length > 0 ? (
              affiliates.map(({ childUsername, date, amount }, index) => {
                const formattedDate = moment(date).format('DD/MM/YYYY')

                return (
                  <tr key={`affiliate-${index}`}>
                    <td style={{fontSize: '9px'}}>{childUsername}</td>
                    <td>{formattedDate}</td>
                    <td>{numeral((amount * 100) / 8).format('0,0.00')}</td>
                    <td>{numeral(amount).format('0,0.00')}</td>
                  </tr>
                )
              })
            ) : isLoading ? (
              <div className='loading-gif'>
                <img className='loading-indicator-gif ' src={require('../../assets//dong-loading/dong-loading.png')} alt='loading' />
              </div>
            ) : (
              <tr>
                <td colSpan='7' className='affiliate__title'>
                  ไม่มีประวัติ
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {!isLoading && affiliates.length > 0 ? (
        <div className='affiliate__boxContainer-money text-green-600'>
          <span>Income Summary</span>
          <div className='affiliate__boxContainer-money-detail'>

            <span>
              Total: <span>{affiliates.map(obj => obj.amount).reduce((accumulator, current) => {
              return accumulator + current;
            }, 0).toFixed(2)}&nbsp;</span>
              {/*<span*/}
              {/*  className={`affiliate__boxContainer-money-detail_total${*/}
              {/*    totalDeposit - totalWithdraw < 0 ? ' negativeValue' : ''*/}
              {/*  }`}*/}
              {/*>*/}
              {/*  {numeral(totalDeposit - totalWithdraw).format('0,0.00')}{' '}*/}
              {/*</span>*/}
               BAHT
            </span>
          </div>
        </div>) : null }
      </Box>
    </section>
  )
}

export default Affiliate
