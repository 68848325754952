import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'

import trashIcon from '../assets/trashIcon.svg'
import { getAuth } from '../../../redux/selectors/auth'
import { clearCart, updateAmount, removeFromCart, updateUniqueCart } from '../../../redux/actions/cart'
import { lottoType, lottoType_TH } from '../../../utils/lottoList'

export default function CheckoutModal(props) {
  const dispatch = useDispatch()

  const auth = useSelector(getAuth)
  const walletCredit = useSelector((state) => state.wallet.credit)
  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])

  const [isAllSamePrice, setIsAllSamePrice] = useState(false)
  const [isPriceChange, setIsPriceChange] = useState(false)
  const [priceArray, setPriceArray] = useState(cartItems)
  const [showDuplicates, setShowDuplicates] = useState(false)
  const [isHighestPossible, setIsHighestPossible] = useState(false)

  const showModal = props.showModal
  const cartCounter = cartItems.length || 0
  const purchaseAmount = Math.round((cartItems.reduce((sum, x) => sum + x.amount, 0) + Number.EPSILON) * 100) / 100
  const setShowModal = props.setShowModal
  const highestPayRate = props.highestPayRate
  const language = props.language

  const forceSetPrice = () => {
    setShowModal((prevState) => ({ ...prevState, isShow: false }))
    setIsAllSamePrice(false)
    const stamp = Math.floor(Math.random() * 1000000)
    const newPriceArray = priceArray.map((obj) => {
      if (obj.isSet) {
        return obj
      } else {
        obj.isSet = true
        obj.stamp = stamp
        dispatch(updateAmount({ purchaseItem: obj }))
        return obj
      }
    })
    setPriceArray(newPriceArray)
    setIsPriceChange(false)
  }

  const handlePriceChangeAll = (event) => {
    setIsAllSamePrice(true)
    let val = event !== 'ALL-IN' ? event.target.value : ''
    // handle non-number type
    if (!/^[0-9]*\.?[0-9]*$/.test(val) && val !== '') {
      return
    }
    const newVal = val.replace(/^0+/, '')
    let amount = _.defaultTo(parseInt(newVal), 0) // parseInt('') > NaN
    if (amount > 5000) {
      amount = 5000
    }
    if (event === 'ALL-IN') {
      setIsHighestPossible(true)
      const currentAmount =
        Math.round((cartItems.reduce((sum, x) => sum + (x.isSet ? x.amount : 0), 0) + Number.EPSILON) * 100) / 100
      amount = Math.floor(((walletCredit - currentAmount) / priceArray.filter((obj) => !obj.isSet).length) * 100) / 100
    }
    const newPriceArray = priceArray.map((obj) => {
      if (obj.isSet) {
        return obj
      } else {
        obj.isSet = false
        obj.amount = amount
        dispatch(updateAmount({ purchaseItem: obj }))
        return obj
      }
    })
    setPriceArray(newPriceArray)
    if (amount > 0) {
      setIsPriceChange(true)
    } else {
      setIsPriceChange(false)
    }
  }

  const handlePriceChange = (event, type, detail, stamp) => {
    setIsAllSamePrice(false)
    const val = event.target.value

    // handle non-number type
    if (!/^[0-9]*\.?[0-9]*$/.test(val) && val !== '') {
      Swal.fire({
        icon: 'error',
        title: 'Please enter numbers only',
      })
      return
    }
    const newVal = val.replace(/^0+/, '')
    let amount = _.defaultTo(parseInt(newVal), 0) // parseInt('') > NaN
    if (amount > 2000) {
      amount = 2000
    }
    const newPriceArray = priceArray.map((obj) => {
      if (obj.type === type && obj.detail === detail && obj.stamp === stamp) {
        obj.amount = amount
        dispatch(updateAmount({ purchaseItem: obj }))
      }
      return obj
    })
    setIsPriceChange(true)
    setPriceArray(newPriceArray)
    // console.log('newPriceArray >>', newPriceArray)
  }

  useEffect(() => {
    if (purchaseAmount >= walletCredit) {
      setIsHighestPossible(true)
    } else {
      setIsHighestPossible(false)
    }
    return () => {}
  }, [purchaseAmount, walletCredit])

  const sortingScore = {
    '2A': 1,
    '2B': 2,
    '2R': 3,
    '3A': 4,
    '3R': 5,
    '3F': 6,
    '3B': 7,
    SA: 8,
    SB: 9,
    '1P': 10,
    '1S': 11,
  }
  // const lottoType = {
  //   // '2A': '2 ตัวบน',
  //   // '2B': '2 ตัวล่าง',
  //   // '3A': '3 ตัวบน',
  //   // '3R': '3 ตัวโต๊ด',
  //   // '3F': '3 ตัวหน้า',
  //   // '3B': '3 ตัวล่าง',
  //   // SA: 'วิ่งบน',
  //   // SB: 'วิ่งล่าง',

  //   '2A': '၂လုံးပေါ်',
  //   '2B': '၂လုံးအောက်',
  //   '3A': '၃ လုံးပေါ်',
  //   '3R': '၃လုံးပြောင်းပြန်',
  //   '3F': 'ရှေ့၃လုံး',
  //   '3B': '၃လုံးအောက်',
  //   SA: 'အပေါ်ပြေး',
  //   SB: 'အောက်ပြေး',
  // }

  const sortedCartItems = _.sortBy(cartItems, (item) => sortingScore[item.type])
  const groupedCartItems = _.groupBy(sortedCartItems, 'type')
  const cartTable = []
  const TrashCanIcon = () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' width='24' height='24' viewBox='0 0 24 24'>
        <path d='M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z' />
      </svg>
    )
  }

  const checkForDuplicated = (value, type) => {
    let lottoGroupByType = _.groupBy(cartItems, 'type')
    return _.filter(lottoGroupByType[type], (item) => item.detail === value).length > 1
  }

  const checkForLimited = (value, type) => {
    // console.log(fetchPayRate[type][value], 'fetchPayRate[type][value]')
    const isLimited =
      cartItems.find((price) => price.type === type && price.detail === value).payRate < highestPayRate[type]
    return isLimited
  }

  const checkForClosed = (value, type) => {
    const isClosed = cartItems.find((price) => price.type === type && price.detail === value).payRate === 0
    return isClosed
  }

  _.forIn(groupedCartItems, (value, key) =>
    cartTable.push(
      <table key={key} className='table-auto'>
        <thead>
          <tr>
            <th className=' py-2' style={{ display: 'none' }}>
              #
            </th>
            <th className=' py-2' style={{ color: '#000000' }}>
              {/* แทงเลข */}
              Number
            </th>
            <th className='py-2' style={{ fontWeight: 'bold', color: '#000000' }}>
              {language === 'MM' ? lottoType[key] : lottoType_TH[key]}
            </th>
            <th className=' py-2' style={{ color: '#000000' }}>
              {/* อัตราคูณ */}x Rate
            </th>
            <th className=' py-2' style={{ color: '#000000' }}>
              {/* เรทถูกรางวัล */}
              Reward
            </th>
            <th className=' py-2'></th>
          </tr>
        </thead>
        <tbody className={`${showDuplicates ? 'show-duplicates' : ''}`}>
          {groupedCartItems[key].map((item, index) => (
            <tr
              className={`buy-lotto__modal-box-cart-counter ${
                checkForDuplicated(item.detail, item.type) ? 'duplicated' : ''
              } ${checkForLimited(item.detail, item.type) ? 'limited' : ''} ${
                checkForClosed(item.detail, item.type) ? 'closed' : ''
              } ${item.isSet ? 'isSet' : ''}`}
              key={`${key}-${item.detail}-${index}`}
            >
              <td className='px-4 py-2' style={{ display: 'none' }}>
                {index + 1}.
              </td>
              <td className='px-4 py-2'>
                <div>{item.detail}</div>
              </td>
              <td className={`px-4 py-2 my-2 ${item.isSet ? 'isSet' : ''}`}>
                <input
                  value={
                    Math.round(
                      (cartItems.find(
                        (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp
                      ).amount +
                        Number.EPSILON) *
                        100
                    ) / 100 || ''
                  }
                  onChange={(event) => handlePriceChange(event, key, item.detail, item.stamp)}
                  type='text'
                  pattern='[0-9]*'
                  min='1'
                  className={`${item.isSet ? 'isSet' : ''}`}
                />
              </td>
              <td className='px-4 py-2'>
                {/*{fetchPayRate[key][item.detail] === undefined*/}
                {/*  ? 'x' + fetchPayRate[key].default*/}
                {/*  : 'x' + fetchPayRate[key][item.detail]}*/}
                {'x' + cartItems.find((price) => price.type === key && price.detail === item.detail).payRate}
              </td>
              <td className='px-4 py-2'>
                <div>
                  {
                    //   parseFloat(
                    //   fetchPayRate[key][item.detail] === undefined
                    //     ? fetchPayRate[key].default
                    //     : fetchPayRate[key][item.detail]
                    // )
                    Math.round(
                      (cartItems.find((price) => price.type === key && price.detail === item.detail).payRate *
                        cartItems.find(
                          (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp
                        ).amount +
                        Number.EPSILON) *
                        100
                    ) / 100
                  }
                </div>
              </td>

              <td
                className='px-4 py-2 btn-bevel'
                onClick={() => dispatch(removeFromCart({ detail: item.detail, key: key, stamp: item.stamp }))}
              >
                <img src={trashIcon} style={{ width: '40px', marginleft: '30px' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  )

  useEffect(() => {
    setPriceArray(cartItems)
    return () => {}
  }, [priceArray])

  return (
    <section className={`buy-lotto__modal ${showModal.isShow && showModal.currentModal === 'ใส่ราคา' ? 'active' : ''}`}>
      <div className={`buy-lotto__modal-box ${showModal.currentModal === 'ใส่ราคา' ? 'active' : ''}`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          {/* ใส่ราคา<span>{cartCounter} รายการ</span> */}
          Pricing<span>{cartCounter} items</span>
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div className='buy-lotto__modal-box-content'>{cartTable}</div>
        <div className='buy-lotto__check-duplicate-btn text-lg border-bottom'>
          <button className='btn-bevel' onClick={() => setShowDuplicates(!showDuplicates)}>
            {/* <span>ดูเลขซ้ำ</span> */}
            <span>{language !== 'MM' ? 'ดูเลขซ้ำ' : 'Highlight duplicate'}</span>
          </button>
          <button className='btn-bevel' onClick={() => dispatch(updateUniqueCart())}>
            {/* <span>ตัดเลขซ้ำ</span> */}
            <span>{language !== 'MM' ? 'ตัดเลขซ้ำ' : 'Clear duplicaite'}</span>
          </button>
        </div>
        <div className='buy-lotto__modal-cart-overview'>
          <div>
            <div className='buy-lotto__modal-box-price'>
              {/* <span>&nbsp;ราคา &quot;เท่ากัน&quot; ทั้งหมด</span> */}
              <span>{language !== 'MM' ? 'ใส่ราคาทั้งหมด' : 'Set same price to all'}</span>

              <input
                className='mx-2 my-2'
                type='text'
                pattern='[0-9]*'
                min='1'
                // placeholder='ใส่ราคา'
                placeholder='price'
                onChange={handlePriceChangeAll}
                value={
                  isAllSamePrice && _.find(priceArray, { isSet: false })
                    ? _.find(priceArray, { isSet: false }).amount
                    : ''
                }
              />
              {!isHighestPossible ? (
                <button className={isPriceChange ? '' : 'disabled'} onClick={() => forceSetPrice()}>
                  {/* แทงต่อ */}
                  {language !== 'MM' ? 'แทงต่อ' : 'select more'}
                </button>
              ) : (
                <button className='all-in' onClick={() => handlePriceChangeAll('ALL-IN')}>
                  {/* หมดหน้าตัก */}
                  {language !== 'MM' ? 'หมดหน้าตัก' : 'all-in'}
                </button>
              )}
            </div>
          </div>
          <div>
            <div className='px-4 py-2'>
              {/* <div>ยอดคงเหลือ</div> */}
              <div>{language !== 'MM' ? 'ยอดคงเหลือ' : 'Remaining credit'}</div>
              <div className='text-xl'>
                {/* {!auth.username ? 'กรุณาเข้าสู่ระบบ' : numeral(walletCredit).format('0,0.00')} */}
                {!auth.username ? 'Please log in' : numeral(walletCredit).format('0,0.00')}
              </div>
            </div>
            <div className='px-4 py-2'>
              {/* <div>รวมยอดแทง</div> */}
              <div>{language !== 'MM' ? 'รวมยอดแทง' : 'Total amount'}</div>
              <div className='text-xl'>
                {numeral(Math.round((purchaseAmount + Number.EPSILON) * 100) / 100).format('0,0.00')}
              </div>
            </div>
          </div>
        </div>
        <div className='buy-lotto__check-duplicate-btn text-xl'>
          <button
            className='btn-bevel'
            onClick={() => {
              dispatch(clearCart())
              setPriceArray(_.get(cartStore, 'items', []))
              setShowModal((prevState) => ({ ...prevState, isShow: false }))
            }}
          >
            {/* <span>ยกเลิกทั้งหมด</span> */}
            <span>{language !== 'MM' ? 'ยกเลิกทั้งหมด' : 'Cancel All'}</span>
          </button>
          <button
            className={`${!auth.username || cartCounter == 0 ? 'disabled' : ''} btn-bevel`}
            onClick={() => setShowModal({ isShow: true, currentModal: 'confirm' })}
          >
            {/* <span>{!auth.username ? 'กรุณาเข้าสู่ระบบ' : 'ต่อไป'}</span> */}
            <span>{!auth.username ? 'Plesae log in' : `${language !== 'MM' ? 'ต่อไป' : 'Next'}`}</span>
          </button>
        </div>
      </div>
    </section>
  )
}

CheckoutModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  highestPayRate: PropTypes.object,
}
