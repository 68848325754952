import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => (
  <div style={{ textAlign: 'center', color: 'white', fontSize: '24px' }}>
    <h2>ขออภัยครับ หน้านี้ไม่มีแล้วครับ</h2>
    <Link to='/'>กลับสู่หน้าแรกกันครับผม</Link>
  </div>
)

export default NotFoundPage
