import React, { useState, useEffect } from 'react'
import { Auth, API } from 'aws-amplify'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import _get from 'lodash/get'
import _map from 'lodash/map'
import numeral from 'numeral'
import Modal from 'react-modal'
import Swal from 'sweetalert2'
// import { faHandMiddleFinger } from '@fortawesome/free-solid-svg-icons'
// import AlignItems from 'autoprefixer/lib/hacks/align-items'

import { getCognitoProvider } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
import { getLang } from '../../redux/selectors/lang'
import { setLang } from '../../redux/actions/lang'
// import { getReceipts } from '../../redux/selectors/receipts'
import { getWallet } from '../../redux/selectors/wallet'
import { setWalletCredit } from '../../redux/actions/wallet'
import DropdownNotification from './DropdownNotification'
import DepositModalV2 from './DepositModalV2'
import Box from '../Box/Box'
import Button from '../Button/Button'
import useComponentVisible from './useComponentVisible'
import { composePhoneNumber } from '../../utils/phoneNumber'
import dollarIconPNG from './assets/dollar-user-icon.png'
import plusIconPNG from './assets/plus-icon.png'
import userIconPNG from './assets/user-icon.png'
import bankIconPNG from './assets/bank-icon.png'
import walletIconPNG from './assets/wallet-icon.png'
import historyIconPNG from './assets/history-icon.png'
import configIconPNG from './assets/config-icon.png'
import questionIconPNG from './assets/question-icon.png'
import chatIconPNG from './assets/chat-icon.png'
import inviteFriendIconPNG from './assets/invite-friend.png'
import affiliateIconPNG from './assets/affiliate-icon.png'
import resultHistoryIcon from './assets/result-history-icon.png'
import { mapBankCodeToBankName } from '../../utils/mapBankCodeToBankName'
import { BANK_PAGE } from '../../utils/lottoList'

import './Header.scss'
import Input from '../Form/Input'

//default profile image

function Header() {
  const auth = useSelector(getAuth)
  const lang = useSelector(getLang)
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true)
  const location = useLocation()
  const history = useHistory()

  const language = lang.lang

  // const receipts = useSelector(getReceipts)
  const walletAmount = _.get(useSelector(getWallet), 'credit', 0)
  const dispatch = useDispatch()
  // const [walletAmount, setWalletAmount] = useState(0)
  const [gameCredit, setGameCredit] = useState(0)
  // const [tempCredit, setTempCredit] = useState(0)
  // const [cooldown, setCooldown] = useState(0)
  // const [isDeposit, setIsDeposit] = useState(false)
  // const [isShowGameCredit, setIsShowGameCredit] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isProfileNavShow, setIsProfileNavShow] = useState(false)
  const [isNotificationShow, setIsNotificationShow] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  const [modal, setModal] = useState('')
  const [contactModal, setContactModal] = useState(false)
  const [unreadNoti, setUnreadNoti] = useState(0)
  const [isCollapse, setIsCollapse] = useState(true)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  // const [isPressLogin, setIsPressLogin] = useState(false)
  // const [isSuccessLogin, setIsSuccessLogin] = useState(false)
  // const [isSuccessRegister, setIsSuccessRegister] = useState(false)

  const [OTPValue, setOTPValue] = useState('')
  const [OTPNumber, setOTPNumber] = useState('')
  const [isRequestOTP, setIsRequestOTP] = useState(false)

  // const inputUsername = useRef(null)
  // const inputPassword = useRef(null)

  // const inputRegisUsername = useRef(null)
  // const inputRegisPassword = useRef(null)
  // const inputRegisConfirm = useRef(null)

  const cognitoProvider = { provider: getCognitoProvider() }

  const [confirmPassword, setConfirmPassword] = useState(false)
  const [newUser, setNewUser] = useState({
    username: '',
    password: 'a12345678',
    userConfirmPassword: 'a12345678',
    newPassword: 'a12345678',
    accountName: '',
    accountNumber: '',
    bankCode: '',
  })

  const [isTenSecond, setIsTenSecond] = useState(false)
  // const [isOldCustomer, setIsOldCustomer] = useState(false)

  //YK
  const [currentYeekee, setCurrentYeekee] = useState(1)
  // const currentYeekee = Math.floor((parseInt(time.split(' ')[0]) * 60 + parseInt(time.split(' ')[1]) - 1) / 5)
  const [goldNumber, setGoldNumber] = useState({ one: false, two: false, three: false, four: false, five: false })
  // const [stopPoint, setStopPoint] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [rewardDigit, setRewardDigit] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [counter, setCounter] = useState({
    one: ~~(Math.random() * 10),
    two: ~~(Math.random() * 10),
    three: ~~(Math.random() * 10),
    four: ~~(Math.random() * 10),
    five: ~~(Math.random() * 10),
    six: -10,
  })
  const [counter2, setCounter2] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [timing, setTiming] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [trigger, setTrigger] = useState(false)
  const [countdown, setCountdown] = useState(0)
  const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' })
  const [fadePropTwo, setFadePropTwo] = useState({ fade: 'fade-in-2' })
  const [fadePropThree, setFadePropThree] = useState({ fade: 'fade-in-3' })
  const [fadePropFour, setFadePropFour] = useState({ fade: 'fade-in-4' })
  const [fadePropFive, setFadePropFive] = useState({ fade: 'fade-in-5' })
  const [isClosed, setIsClosed] = useState(false)
  const [isAnotherRound, setIsAnotherRound] = useState(0)

  //YK Function

  useEffect(() => {
    dispatch(setLang('TH'))
    const timer = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, one: prevCount.one + 1 })) // <-- Change this line!
    }, 523)
    const timer2 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, two: prevCount.two + 1 })) // <-- Change this line!
    }, 727)
    const timer3 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, three: prevCount.three + 1 })) // <-- Change this line!
    }, 419)
    const timer4 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, four: prevCount.four + 1 })) // <-- Change this line!
    }, 953)
    const timer5 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, five: prevCount.five + 1 })) // <-- Change this line!
    }, 307)
    const timer6 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, six: prevCount.six + 1 })) // <-- Change this line!
    }, 1000)
    return () => {
      clearInterval(timer)
      clearInterval(timer2)
      clearInterval(timer3)
      clearInterval(timer4)
      clearInterval(timer5)
      clearInterval(timer6)
    }
  }, []) // Pass in empty array to run effect only once!

  useEffect(() => {
    if (!goldNumber.one) {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({
          fade: 'fade-out',
        })
      } else {
        setFadeProp({
          fade: 'fade-in',
        })
      }
    } else {
      setFadeProp({ fade: '' })
    }
  }, [counter2.one])

  useEffect(() => {
    if (!goldNumber.two) {
      if (fadePropTwo.fade === 'fade-in-2') {
        setFadePropTwo({
          fade: 'fade-out-2',
        })
      } else {
        setFadePropTwo({
          fade: 'fade-in-2',
        })
      }
    } else {
      setFadePropTwo({ fade: '' })
    }
  }, [counter2.two])

  useEffect(() => {
    if (!goldNumber.three) {
      if (fadePropThree.fade === 'fade-in-3') {
        setFadePropThree({
          fade: 'fade-out-3',
        })
      } else {
        setFadePropThree({
          fade: 'fade-in-3',
        })
      }
    } else {
      setFadePropThree({ fade: '' })
    }
  }, [counter2.three])
  //
  useEffect(() => {
    if (!goldNumber.four) {
      if (fadePropFour.fade === 'fade-in-4') {
        setFadePropFour({
          fade: 'fade-out-4',
        })
      } else {
        setFadePropFour({
          fade: 'fade-in-4',
        })
      }
    } else {
      setFadePropFour({ fade: '' })
    }
  }, [counter2.four])

  useEffect(() => {
    if (!goldNumber.five) {
      if (fadePropFive.fade === 'fade-in-5') {
        setFadePropFive({
          fade: 'fade-out-5',
        })
      } else {
        setFadePropFive({
          fade: 'fade-in-5',
        })
      }
    } else {
      setFadePropFive({ fade: '' })
    }
  }, [counter2.five])

  useEffect(() => {
    if (!_.isNull(auth.username) && isTenSecond) {
      const timer = setInterval(() => {
        getWalletCredit()
        // getGameCredit()
        console.log('get wallet every minute')
      }, 60000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [isTenSecond])

  useEffect(() => {
    if (isComponentVisible === false) {
      toggleProfileNav()
    }
    setIsComponentVisible(true)
  }, [isComponentVisible])

  // const depositToGame = async () => {
  //   // setCooldown(5)
  //   // setTimeout(() => {
  //   //   setCooldown(0)
  //   // }, 5000)
  //   if (auth.username) {
  //     await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
  //       .then((res) => {
  //         console.log(res, 'deposit to game adfasdfadsfda')
  //         const credit = _.get(res, 'detail.credit', 0)
  //         const adjustAmount = -Math.floor(credit)
  //         if (adjustAmount < 0) {
  //           const depositData = { adjustAmount: adjustAmount, note: -adjustAmount, adjustBy: auth.username }
  //           API.post('playerWallet', `/players/${auth.username}/game/transfer/deposit`, { body: depositData })
  //             .then((res) => {
  //               if (res.code === 0) {
  //                 console.log(res, 'deposit to game 2 asldkfjlas;kdf')
  //               }
  //             })
  //             .catch((error) => {
  //               console.error('error deposit to game>>>', error)
  //               // Swal.fire({
  //               //   icon: 'error',
  //               //   title: 'เกิดข้อผิดพลาด',
  //               //   text: error.message || '',
  //               // })
  //             })
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('error deposit to game>>>', error)
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'เกิดข้อผิดพลาด',
  //           text: error.message || '',
  //         })
  //       })
  //   }
  // }

  useEffect(() => {
    console.log(location, 'location')
    if (!_.isNull(auth.username)) {
      console.log('get wallet')
      getWalletCredit()
      // getGameCredit()
      // if (location.pathname === '/games') {
      //   depositToGame()
      // }
      // const myInterval = setInterval(() => getWalletCredit(), 5000)
      // return () => clearInterval(myInterval)
      const secondNow = moment().second()
      const timeOutSecond = secondNow < 10 ? 10 - secondNow : 70 - secondNow
      setTimeout(() => {
        getWalletCredit()
        // getGameCredit()
        console.log('set timeout second', timeOutSecond)
        setIsTenSecond(true)
      }, timeOutSecond * 1000)
    }
  }, [auth.username, location])

  useEffect(
    () => {
      const date = moment(new Date()).format('DD/MM/YY')
      const time = moment(new Date()).format('HH mm ss')
      // console.log(time, 'time')
      const current = Math.floor((parseInt(time.split(' ')[0]) * 60 + parseInt(time.split(' ')[1]) - 1) / 5)
      // console.log(current, 'current')
      const next = current + 1
      const name = 'ยี่กี ' + next
      setCurrentYeekee(current <= 0 ? 1 : current)
      // setMobileYeekeeNumber(current <= 0 ? 1 : current)
      let countdownSecond = 1000
      const nextYeekee = (current + 1) * 5
      // const nextHours = Math.floor(nextYeekee / 60)
      // const nextMinutes = nextYeekee - nextHours * 60
      // console.log(nextYeekee, 'nextYeekee')
      // console.log(nextHours, 'nextHours')
      // console.log(nextMinutes, 'nextMinutes')
      console.log(name, 'name')
      if (isAnotherRound === 0) {
        const currentSecond =
          parseInt(time.split(' ')[0]) * 3600 + parseInt(time.split(' ')[1]) * 60 + parseInt(time.split(' ')[2])
        const nextSecond = nextYeekee * 60 + 20
        // console.log(currentSecond, 'currentSecond')
        // console.log(nextSecond, 'nextSecond')
        countdownSecond = nextSecond - currentSecond
        setCountdown(countdownSecond)

        if (countdownSecond <= 0) {
          setIsClosed(true)
          API.get('lotto', `/results?lottoName=${name}&drawDateStr=${date}`)
            .then((res) => {
              // console.log(res.detail, 'res result')
              if (res.code === 0) {
                const resultLotto = res.detail[0].results
                const firstVal = parseInt(resultLotto['3A'].split('')[0])
                const secondVal = parseInt(resultLotto['2A'].split('')[0])
                const thirdVal = parseInt(resultLotto['2A'].split('')[1])
                const fourthVal = parseInt(resultLotto['2B'].split('')[0])
                const fifthVal = parseInt(resultLotto['2B'].split('')[1])
                setRewardDigit((prevState) => ({
                  ...prevState,
                  one: firstVal,
                  two: secondVal,
                  three: thirdVal,
                  four: fourthVal,
                  five: fifthVal,
                }))
              }
            })
            .catch((err) => console.error(err))
          setCountdown(0)
          setCounter((prevState) => ({ ...prevState, six: 0 }))
          return
        }
      } else {
        countdownSecond = 260
      }
      // // console.log(countdownSecond, 'countdownSecound')
      // // console.log(countdown, 'countdown')
      console.log(countdownSecond, 'countdownSecond')
      const timer1 = setTimeout(() => {
        API.get('lotto', `/results?lottoName=${name}&drawDateStr=${date}`)
          .then((res) => {
            // console.log(res.detail, 'res result')
            if (res.code === 0) {
              setIsClosed(true)
              const resultLotto = res.detail[0].results
              const firstVal = parseInt(resultLotto['3A'].split('')[0])
              const secondVal = parseInt(resultLotto['2A'].split('')[0])
              const thirdVal = parseInt(resultLotto['2A'].split('')[1])
              const fourthVal = parseInt(resultLotto['2B'].split('')[0])
              const fifthVal = parseInt(resultLotto['2B'].split('')[1])
              setRewardDigit((prevState) => ({
                ...prevState,
                one: firstVal,
                two: secondVal,
                three: thirdVal,
                four: fourthVal,
                five: fifthVal,
              }))
              setTimeout(() => {
                setGoldNumber((prevState) => ({
                  ...prevState,
                  one: true,
                  two: true,
                  three: true,
                  four: true,
                  five: true,
                }))
                setGoldNumber((prevState) => ({
                  ...prevState,
                  one: false,
                  two: false,
                  three: false,
                  four: false,
                  five: false,
                }))
                console.log(countdown, 'first')
                setIsAnotherRound((prevState) => prevState + 1)
                setCountdown(260)
                setIsClosed(false)
                setTrigger(false)
                setCounter((prevState) => ({ ...prevState, six: -10 }))
              }, 40000)
            }
          })
          .catch((err) => console.error(err))
      }, countdownSecond * 1000)
      // console.log(time, 'new time')
      return () => {
        clearTimeout(timer1)
      }
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [isAnotherRound]
  )

  useEffect(() => {
    if (typeof rewardDigit.one == 'number' && typeof counter.one == 'number') {
      // console.log(counter, 'counter user')
      setCounter2((prevCount) => ({
        ...prevCount,
        one: counter.one,
        two: counter.two,
        three: counter.three,
        four: counter.four,
        five: counter.five,
      }))
      setTrigger(true)
      const timer7 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, one: true }))
      }, 10000)
      const timer8 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, two: true }))
      }, 11000)
      const timer9 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, three: true }))
      }, 12000)
      const timer10 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, four: true }))
      }, 13000)
      const timer11 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, five: true }))
      }, 14000)
      return () => {
        clearTimeout(timer7)
        clearTimeout(timer8)
        clearTimeout(timer9)
        clearTimeout(timer10)
        clearTimeout(timer11)
      }
    }
  }, [rewardDigit])

  useEffect(() => {
    if (trigger) {
      const timing1 = rewardDigit.one - (counter2.one % 10) + 20
      const timing2 = rewardDigit.two - (counter2.two % 10) + 20
      const timing3 = rewardDigit.three - (counter2.three % 10) + 20
      const timing4 = rewardDigit.four - (counter2.four % 10) + 20
      const timing5 = rewardDigit.five - (counter2.five % 10) + 20
      setTiming({
        one: 10000 / timing1,
        two: 11000 / timing2,
        three: 12000 / timing3,
        four: 13000 / timing4,
        five: 14000 / timing5,
      })
      const timer12 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, one: prevCount.one + 1 })) // <-- Change this line!
      }, 10000 / timing1)
      const timer13 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, two: prevCount.two + 1 })) // <-- Change this line!
      }, 11000 / timing2)
      const timer14 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, three: prevCount.three + 1 })) // <-- Change this line!
      }, 12000 / timing3)
      const timer15 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, four: prevCount.four + 1 })) // <-- Change this line!
      }, 13000 / timing4)
      const timer16 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, five: prevCount.five + 1 })) // <-- Change this line!
      }, 14000 / timing5)
      return () => {
        clearTimeout(timer12)
        clearTimeout(timer13)
        clearTimeout(timer14)
        clearTimeout(timer15)
        clearTimeout(timer16)
      }
    }
  }, [trigger])

  const navigateToYeekee = () => {
    if (auth.username && currentYeekee + 1 !== 288 && !isClosed) {
      const date = moment(new Date()).format('DD/MM/YY')
      const name = 'ยี่กี่ ' + (currentYeekee + 1)
      console.log(date, name, 'datae')
      API.get('lotto', `/lottos?status=open&lottoGroup=หวยยี่กี&limit=1`)
        .then((res) => {
          if (res.code === 0 && res.detail[0].status === 'open') {
            const yeekeeNumber = res.detail[0].lottoNumber
            history.replace(`/lottos/${yeekeeNumber}`)
          }
        })
        .catch((err) => console.error(err))
    }
  }

  // 1.0-20 classic
  // 2.50  classic
  // 3.100 classic
  // 4.500 classic
  // 5.1000 classic
  // 6.3000 classic
  // 7.5000 classic
  // 8.10000 siver
  // 9.50000 gold
  // 10.100,000 platinum

  const profileLevel =
    walletAmount > 99999
      ? 12
      : walletAmount > 49999
      ? 11
      : walletAmount > 29999
      ? 10
      : walletAmount > 9999
      ? 9
      : walletAmount > 4999
      ? 8
      : walletAmount > 2999
      ? 7
      : walletAmount > 1999
      ? 6
      : walletAmount > 999
      ? 5
      : walletAmount > 499
      ? 4
      : walletAmount > 99
      ? 3
      : walletAmount > 49
      ? 2
      : 1
  const profileImg = require(`./assets/profile-lv/${profileLevel}.png`)

  // const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  // const [confirmPassword, setConfirmPassword] = useState(false)

  //  Header.js is DONG
  //  Header_2.js  Obsolete

  let isCreatedWallet = false

  const validateAll = () => {
    console.log(newUser, 'newUser')
    if (!newUser.username || !newUser.accountName || !newUser.accountNumber || !newUser.bankCode) {
      return false
    }

    // if (newUser.password !== newUser.userConfirmPassword) {
    //   return false
    // }

    return true
  }

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    // if (inputName === 'username') {
    //   const newValue = value.replace(/[^0-9]/g, '')
    //   setNewUser({ ...newUser, [inputName]: newValue })
    //   return
    // }

    setNewUser({ ...newUser, [inputName]: value })
  }

  const _handlePhoneNumber = () => {
    if (newUser.username.length !== 10) {
      Swal.fire({
        icon: 'error',
        title: 'Please enter a valid 10-digit mobile phone number.',
      })
      return
    }
    if (newUser.password.length < 8) {
      Swal.fire({
        icon: 'error',
        // title: 'รหัสผ่านของท่านมีความยาวไม่ถึง 8 ตัวอักษรครับ',
        title: 'Password shall be more than 8 characters',
      })
      return
    }
    setConfirmPassword(true)
  }

  const handleSignUp = async () => {
    const isValidForm = validateAll()
    if (!isValidForm) {
      return Swal.fire({
        icon: 'error',
        // title: 'เกิดข้อผิดพลาด',
        // text: 'รหัสผ่านและยืนยันรหัสผ่านของท่านไม่ตรงกันครับ',
        title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
        text: language !== 'TH' ? 'ပါမှန်ရိုက်ပါ' : 'กรุณากรอกข้อมูลให้ครบครับ',
      })
    } else {
      try {
        const newUsername = composePhoneNumber('+66', newUser.username)
        const newPassword = 'a12345678'
        const bankData = {
          accountName: newUser.accountName,
          accountNumber: newUser.accountNumber,
          bankCode: newUser.bankCode,
        }
        // const newUsername = newUser.username.split('@')[0]
        await Auth.signUp({
          username: newUsername,
          password: newPassword,
          attributes: {
            name: newUsername,
            phone_number: newUsername,
          },
        }).then((res) => {
          console.log(res, 'res user signUp')
        })
        setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
        handleLoginAndAddBank(bankData)
      } catch (e) {
        console.error(e)
        if (e.message === 'User already exists') {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            // text: 'บัญชีนี้ซ้ำกับบัญชีที่มีอยู่ในระบบครับ',
            title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
            text: language !== 'TH' ? 'ဤဖုန်းနံပါတ်ကျအသုံးဝင်ပြီ' : 'เบอร์นี้เคยมีผู้สมัครไปแล้วครับผม',
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
            text: e.message || '',
          })
        }
      }
    }
  }

  const handleLoginAndAddBank = async (bankInfo) => {
    // e.preventDefault()
    // console.log(window.location.hash)
    try {
      // const newUsername = newUser.username
      const newUsername = composePhoneNumber('+66', newUser.username)
      const newPassword = 'a12345678'
      await Auth.signIn(newUsername, newPassword).then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
          console.log(requiredAttributes)
          Auth.completeNewPassword(
            user, // the Cognito User Object
            'a12345678' // the new password
          )
            .then((res) => {
              // at this time the user is logged in if no MFA required
              console.log(res, 'res in handleLoginAndAddBank')
            })
            .catch((e) => {
              console.log(e)
            })
        }
        console.log(user, 'user signIn')
        const username = user.username
        setIsLoading(true)
        const payload = {
          accountName: bankInfo.accountName,
          bankCode: bankInfo.bankCode,
          bankName: mapBankCodeToBankName[bankInfo.bankCode],
          accountNumber: bankInfo.accountNumber,
        }
        API.post('playerBankAccount', `/players/${username}/request`, {
          body: payload,
        })
          .then((res) => {
            const resBankAccount = _get(res, 'detail', null)
            if (resBankAccount) {
              setIsLoading(false)
              return Swal.fire({
                icon: 'success',
                // title: 'เพิ่มบัญชีธนาคารสำเร็จ',
                // text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
                title:
                  language !== 'TH' ? 'သင့်အကောင့်သည်အျင်အားလုပ်ဆောင်ပြီးနောက်တစ်ဆင်ရေ' : 'สร้างบัญชีเรียบร้อยแล้วครับ',
                // text: language !== 'TH' ? 'သင်၏စကားဝှက်ကို a12345678' : 'รหัสผ่านของท่านคือ a12345678',
              })
            } else {
              // throw new Error('ระบบผิดพลาด ไม่สามารถเพิ่มบัญชีธนาคารได้ กรุณาติดต่อเจ้าหน้าที่')
              throw new Error('System error can not add your bank account. Please contact admin.')
            }
          })
          .catch((err) => {
            setIsLoading(false)
            console.log('error add bank account ======')
            console.error(err)
            Swal.fire({
              icon: 'error',
              // title: 'เกิดข้อผิดพลาด',
              title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
              text: err.message || '',
            })
          })
          .finally(() => {
            setIsLoading(false)
            // history.replace('/callback')
          })
      })
      // console.log(testUser, 'testUserrrrr Home Container')
    } catch (err) {
      console.log(err, 'e message')
      if (err.message === 'Incorrect username or password.') {
        Swal.fire({
          icon: 'error',
          // title: 'เกิดข้อผิดพลาด',
          // text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          title: 'Error',
          text: 'Telephone number or password was incorrect',
        })
      } else {
        Swal.fire({
          icon: 'error',
          // title: 'เกิดข้อผิดพลาด',
          title: 'Error',

          text: err.message || '',
        })
      }
    }
  }

  const handleLogin = async () => {
    // e.preventDefault()
    // console.log(window.location.hash)
    try {
      // const newUsername = newUser.username
      const newUsername = composePhoneNumber('+66', newUser.username)
      await Auth.signIn(newUsername, 'a12345678').then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
          console.log(requiredAttributes)
          const newPassword = 'a12345678'
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
          )
            .then((res) => {
              // at this time the user is logged in if no MFA required
              console.log(res)
            })
            .catch((e) => {
              console.log(e)
            })
        }
        console.log(user, 'user signIn')
      })
      // console.log(testUser, 'testUserrrrr Home Container')
      history.replace('/callback')
    } catch (err) {
      console.log(err, 'e message')
      if (err.message === 'Incorrect username or password.') {
        Swal.fire({
          icon: 'error',
          // title: 'เกิดข้อผิดพลาด',
          // text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
          text:
            language !== 'TH'
              ? 'ဖုန်းနံပါတ်သိမ်းမှတ်သေးပြီ သို့မှာ စကားဝှက်မှန်တီးပြီ'
              : 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
        })
      } else {
        Swal.fire({
          icon: 'error',
          // title: 'เกิดข้อผิดพลาด',
          title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',

          text: err.message || '',
        })
      }
    }
  }
  // console.log(location, 'location')
  const handleOnClick = () => history.push('/deposit')

  const setFocus = () => {
    const page = document.getElementsByClassName('app-wrapper')[0]
    if (!isFocus && page) {
      setIsFocus(true)
      page.classList.add('blur-me')
      document.body.classList.add('block-scroll')
    } else {
      setIsFocus(false)
      page.classList.remove('blur-me')
      document.body.classList.remove('block-scroll')
    }
  }

  const toggleProfileNav = () => {
    setIsProfileNavShow(!isProfileNavShow)
    setFocus()
  }

  const toggleNotification = () => {
    setIsNotificationShow(!isNotificationShow)
    setFocus()
  }

  // const getGameCredit = () => {
  //   if (auth.username) {
  //     console.log('get game credit')
  //     API.get('playerWallet', `/players/${auth.username}/game/credit`)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           const remainingGameCredit = res.detail.balance
  //           // setIsShowGameCredit(true)
  //           setGameCredit(remainingGameCredit)
  //           if (location.pathname !== '/games' && remainingGameCredit > 0) {
  //             API.post('playerWallet', `/players/${auth.username}/game/transfer/withdraw`)
  //               .then((res) => {
  //                 console.log(res, 'res withdraw from game')
  //                 if (res.code === 0) {
  //                   //necessery
  //                   console.log(res, 'withdraw game credit')
  //                   // setGameCredit(0)
  //                   // setTempCredit((prevState) => prevState + remainingGameCredit)
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.error('error withdraw from game>>>', error)
  //                 // Swal.fire({
  //                 //   icon: 'error',
  //                 //   title: 'เกิดข้อผิดพลาด',
  //                 //   text: error.message || '',
  //                 // })
  //               })
  //           }
  //         } else {
  //           const loginData = {
  //             username: auth.username,
  //             accountName: auth.username,
  //             productCode: 'PragmaticPlay',
  //             gameCode: 'vs:vswayswest',
  //             language: 'en',
  //             ip: '1.1.1.1',
  //           }
  //           API.post('lotto', '/games/login', { body: loginData })
  //             .then((res) => {
  //               console.log(res, 'res')
  //               if (res.code === 0) {
  //                 console.log(res.detail, 'login game')
  //               }
  //             })
  //             .catch((err) => {
  //               console.log('error fetching game ======')
  //               console.error(err)
  //             })
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('error ======', err)
  //         console.error(err)
  //         const loginData = {
  //           username: auth.username,
  //           accountName: auth.username,
  //           productCode: 'PragmaticPlay',
  //           gameCode: 'vs:vswayswest',
  //           language: 'en',
  //           ip: '1.1.1.1',
  //         }
  //         API.post('lotto', '/games/login', { body: loginData })
  //           .then((res) => {
  //             console.log(res, 'res')
  //             if (res.code === 0) {
  //               console.log(res.detail, 'login game')
  //             }
  //           })
  //           .catch((err) => {
  //             console.log('error fetching game ======')
  //             console.error(err)
  //           })
  //       })
  //   }
  // }

  // const withdrawFromGame = () => {
  //   // setCooldown(5)
  //   // setTimeout(() => {
  //   //   setCooldown(0)
  //   // }, 5000)
  //   if (auth.username) {
  //     // getGameCredit()
  //     API.get('playerWallet', `/players/${auth.username}/game/credit`)
  //       .then((res) => {
  //         if (res.code === 0) {
  //           const remainingGameCredit = res.detail.balance
  //           if (remainingGameCredit > 0) {
  //             API.post('playerWallet', `/players/${auth.username}/game/transfer/withdraw`)
  //               .then((res) => {
  //                 console.log(res, 'res withdraw from game')
  //                 if (res.code === 0) {
  //                   //not necessery
  //                   setTempCredit((prevState) => prevState + remainingGameCredit)
  //                   getGameCredit()
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.error('error withdraw from game>>>', error)
  //                 Swal.fire({
  //                   icon: 'error',
  //                   title: 'เกิดข้อผิดพลาด',
  //                   text: error.message || '',
  //                 })
  //               })
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('error ======', err)
  //         console.error(err)
  //       })
  //   }
  // }

  const getWalletCredit = () => {
    if (auth.username) {
      // getGameWallet
      // getGameCredit()

      API.get('playerWallet', `/players/${auth.username}/wallets/0`)
        .then((res) => {
          // console.log(res, 'res credit')
          const credit = _.get(res, 'detail.credit', 0)
          if (credit === 'No credit exists' && !isCreatedWallet) {
            dispatch(
              setWalletCredit({
                wallet: {
                  credit: 0,
                },
              })
            )
            setIsLoaded(true)
          } else {
            dispatch(
              setWalletCredit({
                wallet: {
                  credit,
                },
              })
            )
            // setIsDeposit(false)
            setIsLoaded(true)
          }
        })
        .catch((err) => {
          console.log('error ======', err)
          console.error(err)
          // setIsDeposit(true)
          setIsLoaded(true)
        })
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '0',
      right: '0',
      bottom: 'auto',
      transform: 'translateY(-50%)',
      maxWidth: '550px',
      margin: '0 auto',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      overflow: 'inherit',
    },
  }

  const _getNumberUnreadNotifications = (unreadNoti) => {
    setUnreadNoti(unreadNoti)
  }

  const handleLoginFacebook = async () => {
    await Auth.federatedSignIn({ provider: 'Facebook' })
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLoginLine = async () => {
    await Auth.federatedSignIn(cognitoProvider)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLogout = () => {
    Swal.fire({
      // title: 'ออกจากระบบ',
      // text: 'ท่านต้องการออกจากระบบใช่หรือไม่ ?',
      title: 'Log out',
      text: 'Do you want to log out ?',
      showCancelButton: true,
      confirmButtonColor: '#C3B486',
      cancelButtonColor: '#C0C0C0',
      // confirmButtonText: 'ออกจากระบบ',
      // cancelButtonText: 'ยกเลิก',
      confirmButtonText: 'Log out',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        history.push('/logout')
      }
    })
  }

  const _onChangeCode = (e) => {
    const value = e.target.value
    const newValue = value.replace(/[^0-9]/g, '')
    if (newValue.length > 6) {
      return
    }
    setOTPValue(newValue)
  }

  const verifyUser = async (user) => {
    console.log(user, OTPValue, newUser.newPassword)
    const newUsername = composePhoneNumber('+66', user)
    await Auth.forgotPasswordSubmit(newUsername, OTPValue, newUser.newPassword)
      .then((data) => {
        Auth.signIn(newUsername, newUser.newPassword).then((user) => {
          console.log(user, 'user signIn')
          // modalLoginSuccess()
          Auth.currentAuthenticatedUser().then((user) => {
            dispatch(setAuth({ user }))
          })
          setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
          setIsCollapse(true)
          history.replace('/callback')
        })
      })
      .catch((err) =>
        Swal.fire({
          icon: 'error',
          // title: 'ท่านกรอกรหัส OTP ไม่ถูกต้องครับ',
          text: err.message,
        })
      )
  }

  const requestOTP = async () => {
    try {
      const newUsername = composePhoneNumber('+66', newUser.username)
      Auth.forgotPassword(newUsername)
        .then((data) => {
          console.log(data, 'forget-password')
          setIsRequestOTP(true)
        })
        .catch((err) =>
          Swal.fire({
            icon: 'error',
            text: err.message || '',
          })
        )
    } catch (error) {
      console.error('error request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        text: error.message || '',
      })
    }
  }

  const handleOTP = async () => {
    try {
      // const user = await Auth.currentAuthenticatedUser()
      // console.log(user, 'verify OTP')
      // console.log(user, 'user')
      await verifyUser(newUser.username)
    } catch (error) {
      console.error('error request OTP>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  return (
    <>
      <div
        className={`overlay ${isNotificationShow ? 'active' : ''}`}
        onClick={(e) => {
          toggleNotification(e)
        }}
      ></div>
      <DepositModalV2 isShow={modal === 'deposit'} onExit={() => setModal('')} />
      <div className='header-wrapper'>
        <div className='header container mx-auto'>
          <div className='__header-left'>
            <Link to='/'>
              <img className='__header-brand' src={require('../../assets/powerlott-logo.png')} alt='Powerlott' />
            </Link>
          </div>
          <div className='__header-right'>
            {/* {language === 'TH' ? (
              <div className='header-contact-set' onClick={() => dispatch(setLang('MM'))}>
                <span className='hide-mobile'>ไทย (TH)</span>
                <img
                  className='header-headset'
                  src={require('../../assets/lotto-flags/thailand-flag.png')}
                  alt='Thai language'
                />
              </div>
            ) : (
              <div className='header-contact-set' onClick={() => dispatch(setLang('TH'))}>
                <span className='hide-mobile'>မြန်မာစာ (MY)</span>
                <img
                  className='header-headset'
                  src={require('../../assets/lotto-flags/myanmar-flag.png')}
                  alt='Burmese language'
                />
              </div>
            )} */}
            <div className='header-contact-set' onClick={() => setContactModal(!contactModal)}>
              <span className='hide-mobile'>ติดต่อเรา</span>
              <img className='header-headset' src={require('./assets/dong-contact-us.svg')} alt='contact' />
            </div>
            {!_.isNull(auth.username) || (!_.isNull(auth.username) && isLoaded) ? (
              <>
                <button className='notification-btn text-xl'>
                  <img src={require('./assets/notification.png')} alt='notification' onClick={toggleNotification} />
                  <span className={`number-unread-notification ${unreadNoti !== 0 ? 'active' : ''}`}>
                    {unreadNoti !== 0 ? unreadNoti : ''}
                  </span>
                  <DropdownNotification
                    activeClassname={`${isNotificationShow ? 'active' : ''}`}
                    getNumberUnreadNotifications={_getNumberUnreadNotifications}
                  />
                </button>
                <button
                  className='player-wallet font-bold py-1 px-2'
                  onClick={() => handleOnClick()}
                  style={{ color: '#D70C10' }}
                >
                  <span className='px-1 hide-mobile'>
                    {/* I don't know why I need to tick aws.cognito.signin.user.admin on AWS Cognito Console
                   Otherwise there will not provide `attributes` field  */}
                    {auth?.attributes?.name}
                  </span>
                  <span className='px-2'>{Math.floor(walletAmount + gameCredit)}</span>
                  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z' />
                  </svg>
                </button>
              </>
            ) : null}

            {/* {_.isNull(auth.username) ?  */}
            {_.isNull(auth.username) ? (
              <button
                className={`px-4 py-2 btn-bevel green ${!isCollapse ? 'active' : ''}`}
                style={{
                  color: 'red',
                  background: '#EEC87A',
                  borderRadius: '24px',
                  borderColor: 'white',
                  borderStyle: 'solid',
                  borderWidth: '3px',
                }}
                // className={`btn-bevel text-xl py-1 px-5 font-bold header-login-btn ${!isCollapse ? 'active' : ''}`}
                onClick={() => setIsCollapse(!isCollapse)}
              >
                {/* onClick={() => setIsCollapse(!isCollapse) */}
                <span>{language !== 'TH' ? 'လော့ဂ်အင်ဝင်ရန်' : 'เข้าสู่ระบบ'}</span>
              </button>
            ) : !isProfileNavShow ? (
              <button className='login-menu' onClick={() => toggleProfileNav()}>
                <img src={profileImg} alt='' />
                <span>
                  <svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' width='24' height='24' viewBox='0 0 24 24'>
                    <path d='M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z' />
                  </svg>
                </span>
              </button>
            ) : (
              <div ref={ref}>
                <button className='login-menu' onClick={() => toggleProfileNav()}>
                  <img src={profileImg} alt='' />
                </button>
                <div className='profile-menu'>
                  {/* <div className='profile-menu-close-btn' onClick={() => toggleProfileNav()}>
                    <svg
                      width='24'
                      height='24'
                      xmlns='http://www.w3.org/2000/svg'
                      fillRule='evenodd'
                      clipRule='evenodd'
                    >
                      <path d='M7 5h17v16h-17l-7-7.972 7-8.028zm7 6.586l-2.586-2.586-1.414 1.414 2.586 2.586-2.586 2.586 1.414 1.414 2.586-2.586 2.586 2.586 1.414-1.414-2.586-2.586 2.586-2.586-1.414-1.414-2.586 2.586z' />
                    </svg>
                  </div> */}
                  <div className='profile-details-wrapper btn-bevel'>
                    <div className='profile-image'>
                      <img src={profileImg} alt='profile-image' />
                    </div>
                    <div className='profile-details'>
                      <div>{auth?.attributes?.name}</div>
                      <div className='profile-wallet'>
                        {/* <CashIcon /> */}
                        {/* <img src={dollarIconPNG} style={{ width: '24px', height: '24px' }} /> */}
                        <div className='__wallet-amount'>
                          <img src={dollarIconPNG} style={{ width: '30px', height: '30px' }} />
                          {numeral(Math.floor(walletAmount * 100) / 100).format('0,0.00')}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='profile-nav'>
                    <Link to='/profile' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <ProfileSettingsICon /> */}
                      <img src={userIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* บัญชีผู้ใช้งาน */}
                      {language !== 'TH' ? 'မိမိရဲ့ပရိုဖိုင်' : 'บัญชีผู้ใช้งาน'}
                    </Link>
                    <Link to='/add-bank-account' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <BankAccountIcon />  */}
                      <img src={bankIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* บัญชีธนาคาร */}
                      {language !== 'TH' ? 'ဘဏ်အကောင့်' : 'บัญชีธนาคาร'}
                    </Link>
                    <Link to='/deposit' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <DepositIcon /> */}
                      <img src={walletIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* กระเป๋าเงิน (ฝาก/ถอน) */}
                      {language !== 'TH' ? 'ပိုက်ဆံအိပ် ငွေသွင်း-ငွေထုတ်' : 'กระเป๋าเงิน (ฝาก/ถอน)'}
                    </Link>
                    <Link to='/invite-friend' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <DepositIcon /> */}
                      <img src={inviteFriendIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* กระเป๋าเงิน (ฝาก/ถอน) */}
                      {language !== 'TH' ? 'မိတ်ဆက်စနစ်' : 'ชวนเพื่อน'}
                    </Link>
                    <Link to='/affiliate' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <DepositIcon /> */}
                      <img src={affiliateIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* กระเป๋าเงิน (ฝาก/ถอน) */}
                      {language !== 'TH' ? 'မိတ်ဆက်ဝင်ငွေ' : 'รายได้ชวนเพื่อน'}
                    </Link>
                    <Link to='/transaction-list' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <TransactionIcon /> */}
                      <img src={historyIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* ประวัติการฝากถอนเงิน */}
                      {language !== 'TH' ? 'ငွေသွင်း -ငွေထုတ် စာရင်း' : 'ประวัติการฝากถอนเงิน'}
                    </Link>
                    <Link to='/result-history' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <DepositIcon /> */}
                      <img src={resultHistoryIcon} style={{ width: '24px', height: '24px' }} />
                      {/* กระเป๋าเงิน (ฝาก/ถอน) */}
                      {language !== 'TH' ? 'ဆုရလဒ်များ' : 'ตรวจผลรางวัล'}
                    </Link>
                    <Link to='/rules' style={{ gap: '10px' }} onClick={() => toggleProfileNav()}>
                      {/* <TutorialIcon /> */}
                      <img src={configIconPNG} style={{ width: '24px', height: '24px' }} />
                      {/* กฎและกติกา */}
                      {language !== 'TH' ? 'အဆင့်မြင်ချက်' : 'กฎกติกา'}
                    </Link>
                    <Link
                      style={{ gap: '10px' }}
                      to='#'
                      onClick={() => {
                        setContactModal(!contactModal)
                        toggleProfileNav()
                      }}
                    >
                      {/* <HandShakeIcon /> */}
                      <img src={chatIconPNG} style={{ width: '24px', height: '24px' }} />
                      {language !== 'TH' ? 'အက်မင်ဆက်သွယ်ရန်' : 'ติดต่อแอดมิน'}
                    </Link>
                    <Link
                      to='/logout'
                      className='btn-bevel login py-1 px-5 my-3 mx-5 font-bold rounded-full bg-red'
                      onClick={() => {
                        toggleProfileNav()
                      }}
                    >
                      <span>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='#ffffff'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                        >
                          <path d='M16 9v-4l8 7-8 7v-4h-8v-6h8zm-16-7v20h14v-2h-12v-16h12v-2h-14z' />
                        </svg>
                        <div style={{ color: 'white' }}> {language !== 'TH' ? 'စနစ်မှထွက်ရန်' : 'ออกจากระบบ'}</div>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {currentYeekee + 1 >= 288 ? null : (
        <div className='header-wrapper-3'>
          <div className='header-wrapper-2 dongyk' onClick={() => navigateToYeekee()}>
            <div className='yeekee-name'>{currentYeekee + 1 === 288 ? '1' : 'YK ' + (currentYeekee + 1)}</div>
            <div className='counter'>
              <div className={`numbers ${goldNumber.one ? 'gold' : 'white'}`}>
                {/*<div className={`${goldNumber.one ? 'gold' : trigger && !goldNumber.one ? 'red' : ''}`}>*/}
                <div
                  className={!goldNumber.one && trigger ? fadeProp.fade : ''}
                  style={
                    !goldNumber.one && trigger
                      ? {
                          animation: `${fadeProp.fade === 'fade-in' ? 'move-in' : 'move-out'} ${timing.one}ms forwards`,
                        }
                      : goldNumber.one
                      ? {
                          animation: `reward-in ${timing.one * 1.1}ms forwards`,
                        }
                      : null
                  }
                >
                  {/*<div className={`${trigger && !goldNumber.one ? 'red' : ''}`}>*/}
                  {goldNumber.one ? rewardDigit.one : trigger ? counter2.one % 10 : counter.one % 10}
                </div>
              </div>
            </div>
            <div className='counter'>
              <div className={`numbers ${goldNumber.two ? 'gold' : 'white'}`}>
                <div
                  className={!goldNumber.two && trigger ? fadePropTwo.fade : ''}
                  style={
                    !goldNumber.two && trigger
                      ? {
                          animation: `${fadePropTwo.fade === 'fade-in-2' ? 'move-in' : 'move-out'} ${
                            timing.two
                          }ms forwards`,
                        }
                      : goldNumber.two
                      ? {
                          animation: `reward-in ${timing.two * 1.2}ms forwards`,
                        }
                      : null
                  }
                >
                  {/*<div className={`${trigger && !goldNumber.two ? 'red' : ''}`}>*/}
                  {goldNumber.two ? rewardDigit.two : trigger ? counter2.two % 10 : counter.two % 10}
                </div>
              </div>
            </div>
            <div className='counter'>
              <div className={`numbers ${goldNumber.three ? 'gold' : 'white'}`}>
                <div
                  className={!goldNumber.three && trigger ? fadePropThree.fade : ''}
                  style={
                    !goldNumber.three && trigger
                      ? {
                          animation: `${fadePropThree.fade === 'fade-in-3' ? 'move-in' : 'move-out'} ${
                            timing.three
                          }ms forwards`,
                        }
                      : goldNumber.three
                      ? {
                          animation: `reward-in ${timing.three * 1.3}ms forwards`,
                        }
                      : null
                  }
                >
                  {/*<div className={`${trigger && !goldNumber.three ? 'red' : ''}`}>*/}
                  {goldNumber.three ? rewardDigit.three : trigger ? counter2.three % 10 : counter.three % 10}
                </div>
              </div>
            </div>
            <div className='counter ml-1'>
              <div className={`numbers ${goldNumber.four ? 'blue' : 'white'}`}>
                <div
                  className={!goldNumber.four && trigger ? fadePropFour.fade : ''}
                  style={
                    !goldNumber.four && trigger
                      ? {
                          animation: `${fadePropFour.fade === 'fade-in-4' ? 'move-in' : 'move-out'} ${
                            timing.four
                          }ms forwards`,
                        }
                      : goldNumber.four
                      ? {
                          animation: `reward-in ${timing.four * 1.4}ms forwards`,
                        }
                      : null
                  }
                >
                  {/*<div className={`${trigger && !goldNumber.four ? 'red' : ''}`}>*/}
                  {goldNumber.four ? rewardDigit.four : trigger ? counter2.four % 10 : counter.four % 10}
                </div>
              </div>
            </div>
            <div className='counter'>
              <div className={`numbers ${goldNumber.five ? 'blue' : 'white'}`}>
                <div
                  className={!goldNumber.five && trigger ? fadePropFive.fade : ''}
                  style={
                    !goldNumber.five && trigger
                      ? {
                          animation: `${fadePropFive.fade === 'fade-in-5' ? 'move-in' : 'move-out'} ${
                            timing.five
                          }ms forwards`,
                        }
                      : goldNumber.five
                      ? {
                          animation: `reward-in ${timing.five * 1.5}ms forwards`,
                        }
                      : null
                  }
                >
                  {/*<div className={`${trigger && !goldNumber.five ? 'red' : ''}`}>*/}
                  {goldNumber.five ? rewardDigit.five : trigger ? counter2.five % 10 : counter.five % 10}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* register modal */}
      <Modal
        isOpen={showModal.currentModal === 'register'}
        onRequestClose={() => {
          setShowModal((prevState) => ({ ...prevState, isShow: false }))
        }}
        style={customStyles}
        contentLabel='Register Modal'
      >
        <Box withShine={false} withFlare={false} containerClass='contactModal__box-wrapper'>
          <div className='contactModal__box-wrapper reg-header'>
            {' '}
            {language !== 'TH' ? 'အကောင့်အသီးစား' : 'สมัครแบบรวดเร็ว'}
          </div>
          <div className='loginModal__field-top loginModal__flex_text mb-4'>
            <div className='loginModal__register-register loginModal__register-color_dong-red'>
              {language !== 'TH' ? 'ဖုန်းနံပါတ်' : 'เบอร์โทร'}
            </div>
            {/*<input className='loginModal__field' id={1} type='text' placeholder={'เบอร์มือถือ เช่น 0812344567'} />*/}
            <input
              className='loginModal__field'
              placeholder={language !== 'TH' ? 'ဥပမာ 0891234567' : 'เช่น 0891234567'}
              onChange={(e) => _onChangeForm(e, 'username')}
              type='text'
              id='current-username'
              value={newUser.username}
            />
            {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
          </div>

          <hr />
          {/* <div style={{ margin: '20px' }}>
            {/* <p>*กรุณากรอกเบอร์โทรศัพท์มือถือจริงของท่านเพื่อใช้ใน การรับ OTP กรณีที่ลืมรหัสผ่าน</p> */}
          {/* <p style={{ textAlign: 'center', fontSize: '12px', color: '#888888' }}>
            {language !== 'TH' ? 'Please input your telephone number.' : 'กรุณาใส่เบอร์โทรที่ท่านใช้'} <br/>
            {language !== 'TH' ? 'The OTP verification will be applied if you want to change password.' : 'สำหรับการขอ OTP หากท่านต้องการเปลี่ยนรหัสผ่าน'}
            </p>
          </div> */}

          <div className='loginModal__field-top loginModal__flex_text'>
            {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
            <div className='bankAndLogo'>
              <select
                name='bank'
                id='bank-selection'
                value={newUser.bankCode || ''}
                onChange={(e) => _onChangeForm(e, 'bankCode')}
              >
                {/* <option value=''>กรุณาเลือกธนาคาร</option> */}
                <option value=''>
                  {' '}
                  {language === 'MM' ? 'ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။' : BANK_PAGE['ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။']}
                </option>
                {_map(mapBankCodeToBankName, (bankName, bankCode) => {
                  return <option value={bankCode}>{language === 'MM' ? BANK_PAGE[bankName] : bankName}</option>
                })}
              </select>
              {newUser.bankCode && newUser.bankCode !== '' ? (
                <img src={require(`../../assets/bank-logo/${newUser.bankCode}.png`)} alt='bank-user-logo' />
              ) : null}
            </div>
            {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
          </div>

          <div className='loginModal__field-top loginModal__flex_text'>
            <div className='loginModal__register-register loginModal__register-color_dong-red'>
              {language !== 'TH' ? 'အကောင့်နာမည်' : 'ชื่อบัญชี'}
            </div>
            {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
            <input
              className='loginModal__field'
              onChange={(e) => _onChangeForm(e, 'accountName')}
              // placeholder='รหัสผ่านความยาวอย่างน้อย 8 ตัวอักษร'
              placeholder={language !== 'TH' ? 'account name' : 'ชื่อบัญชีของท่าน'}
              type='text'
              id='account-name'
              // autoComplete='current-password'
              value={newUser.accountName}
            />
            {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
          </div>

          <div className='loginModal__field-top loginModal__flex_text'>
            <div className='loginModal__register-register loginModal__register-color_dong-red'>
              {language !== 'TH' ? 'အကောင့်နံပါတ်' : 'เลขบัญชี'}
            </div>
            {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
            <input
              className='loginModal__field'
              onChange={(e) => _onChangeForm(e, 'accountNumber')}
              // placeholder='รหัสผ่านความยาวอย่างน้อย 8 ตัวอักษร'
              placeholder={language !== 'TH' ? 'နံပါတ်သားသိမ်းနိုင်ပါ' : 'ระบุเฉพาะตัวเลข'}
              type='number'
              id='account-number'
              // autoComplete='current-password'
              value={newUser.accountNumber}
            />
            {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
          </div>

          <div className='loginModal__flex_button'>
            <Button
              onButtonClick={() => {
                setIsCollapse(true)
                setShowModal({ isShow: false, currentModal: '' })
              }}
              wrapperClassName='loginModal__close-wrapper'
              buttonClassName='loginModal__close-button'
              text={language !== 'TH' ? 'ပယ်ဖျက်' : 'ยกเลิก'}
              style={{ color: 'black' }}
            />

            <Button
              onButtonClick={handleSignUp}
              wrapperClassName='loginModal__close-wrapper'
              buttonClassName={`loginModal__login-button ${
                newUser.username.length < 10 ||
                newUser.bankCode === '' ||
                newUser.accountName === '' ||
                newUser.accountNumber === ''
                  ? 'disabled'
                  : ''
              }`}
              text={language !== 'TH' ? 'စာရင်းဖွင့်' : 'สมัคร'}
              style={{ color: 'white' }}
            />
          </div>
        </Box>
      </Modal>

      <Modal
        isOpen={contactModal}
        onRequestClose={() => {
          setContactModal(false)
        }}
        style={customStyles}
        contentLabel='Contact Modal'
      >
        <Box withShine={false} withFlare={false} containerClass='contactModal__box-wrapper'>
          <Button
            wrapperClassName='contactModal__title-wrapper'
            buttonClassName='contactModal__title-button'
            text={language !== 'TH' ? 'ဆက်သွယ်ရန်' : 'ติดต่อเรา'}
            buttonBackground='#94020D'
            shouldNotShowCursor
          />

          <div className='contactModal__text-wrapper'>
            <div className='contactModal__bottom-wrapper'>
              <div className='contactModal__contact contactModal__contact-line'>
                <a href='https://lin.ee/eYeLCcD'>
                  <img style={{ width: '50px', height: '50px' }} src={require('./assets/line-icon.png')} />
                </a>
                {/* <p>Contact</p> */}
              </div>
              {/*<div className='contactModal__contact contactModal__contact-call'>*/}
              {/*  <p>ติดต่อผ่านโทรศัพท์</p>*/}
              {/*  <a href='tel:0626609051'>*/}
              {/*    <img src={require('./assets/phone-icon.png')} />*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
          <hr className='contactModal__divider' />
          <Button
            onButtonClick={() => setContactModal(false)}
            wrapperClassName='contactModal__close-wrapper'
            buttonClassName='contactModal__close-button'
            text={language !== 'TH' ? 'ပိတ်' : 'ปิด'}
            buttonBackground='#94020D'
            style={{ color: 'white' }}
          />
        </Box>
      </Modal>

      <Modal
        isOpen={!isCollapse}
        onRequestClose={() => {
          setIsCollapse(true)
        }}
        style={customStyles}
        contentLabel='Login Modal'
      >
        <Box withShine={false} withFlare={false} containerClass='loginModal__box-wrapper'>
          <div className='loginModal__box-wrapper reg-header'>
            {language !== 'TH' ? 'လော့ဂ်အင်ဝင်ရန်' : 'เข้าสู่ระบบ'}
          </div>

          {showModal.currentModal !== 'forget' ? (
            <div className='loginModal loginModal__flex_text mt-2'>
              <div className='loginModal__register-register_text loginModal__register-color_black'>
                {/* หากยังไม่บัญชีกรุณา */}
                {language !== 'TH' ? 'ဖုန်းနှင့်စာရင်းဖွင့်ပြီ -->' : 'สมัครด้วยเบอร์โทร -->'}
              </div>
              <div
                className='loginModal__register-register_text loginModal__register-color_blue loginModal__cursor'
                onClick={() => {
                  setIsCollapse(true)
                  setShowModal({ isShow: true, currentModal: 'register' })
                }}
                style={{ fontWeight: 'Bold', fontSize: '25px' }}
              >
                {language !== 'TH' ? 'စာရင်းဖွင့်' : 'สมัคร'}
              </div>
              {/* isCollapse, setIsCollapse */}
            </div>
          ) : null}

          <div>
            <hr className='loginModal__divider' data-content='divider' />
          </div>
          {showModal.currentModal === 'forget' ? (
            <div className='form__setWrapper mt-4 mb-4 px-4'>
              <div className='form__item px-2 py-2'>
                <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                  {language !== 'TH' ? 'ဖုန်းနံပါတ်' : 'เบอร์โทร'}
                </label>
                <Input
                  containerClassName='input'
                  type='text'
                  id='current-username'
                  value={auth.username ? auth.username : newUser.username}
                  disable
                />
              </div>
              {isRequestOTP ? (
                <div className={`form__item px-2 py-2 ${showModal.currentModal === 'forget' ? '' : 'hidden'}`}>
                  <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                    {language !== 'TH' ? 'စကားဝှက်ျပန္ပီကုဒ္' : 'รหัส OTP'}
                  </label>
                  <Input
                    containerClassName='input'
                    onChange={(e) => _onChangeCode(e)}
                    type='number'
                    id='otp'
                    value={OTPValue}
                    min='1'
                    step='1'
                  />
                </div>
              ) : null}
              {isRequestOTP ? (
                <div className='loginModal__field-top loginModal__flex_text'>
                  <div className='loginModal__register-register loginModal__register-color_dong-red'>
                    {language !== 'TH' ? 'New Password' : 'รหัสผ่านใหม่'}
                  </div>
                  <input
                    onChange={(e) => _onChangeForm(e, 'newPassword')}
                    className='loginModal__field'
                    type='text'
                    id='login-password'
                    autoComplete='login-password'
                    value={newUser.newPassword}
                    // placeholder={'กรอกรหัสผ่านของท่าน'}
                    placeholder={language !== 'TH' ? 'အနည်းအရက်နံပါတ်ဆိုတဲ့အစား' : 'อย่างน้อย 8 หลัก'}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <form className='form__wrapper' onSubmit={handleLogin}>
              <div className='loginModal__field-top loginModal__flex_text'>
                <div className='loginModal__register-register loginModal__register-color_dong-red'>
                  {language !== 'TH' ? 'ဖုန်းနံပါတ်' : 'เบอร์โทร'}
                </div>
                <input
                  className='loginModal__field'
                  onChange={(e) => _onChangeForm(e, 'username')}
                  placeholder={language !== 'TH' ? 'ဖုန်းနံပါတ်ထည့်ပါ' : 'กรอกเบอร์โทรของท่าน'}
                  type='text'
                  id='login-username'
                  value={newUser.username}
                />
                {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
              </div>
              {/* <div className='loginModal__field-top loginModal__flex_text'>
                <div className='loginModal__register-register loginModal__register-color_dong-red'>
                  {language !== 'TH' ? 'စကားဝှက်' : 'รหัสผ่าน'}
                </div>
                <input
                  className='loginModal__field'
                  type='password'
                  id='login-password'
                  autoComplete='login-password'
                  value={newUser.password}
                  onChange={(e) => _onChangeForm(e, 'password')}
                  placeholder={language !== 'TH' ? 'စကားဝှက်ထည့်ပါ' : 'กรุณากรอกรหัสผ่าน'}
                />
              </div> */}
              {/* <Button
                wrapperClassName='loginModal__forget-wrapper'
                buttonClassName={`loginModal__forget-button ${
                  newUser.username.length !== 10 ? 'disabled forgetPassword' : 'forgetPassword'
                }`}
                text={language !== 'TH' ? 'စကားဝှက်ပြောင်းပါ' : 'เปลี่ยนรหัสผ่าน'}
                onClick={() => setShowModal((prevState) => ({ ...prevState, currentModal: 'forget' }))}
              /> */}
            </form>
          )}

          {/* forget password here */}
          {/*<div className='loginModal__forget'>ลืมรหัสผ่าน</div>*/}

          {/* show error login status here */}
          {/*<div className='loginModal__field-top loginModal__flex_text'>*/}
          {/*  {isPressLogin ? (*/}
          {/*    !isSuccessLogin ? (*/}
          {/*      <div className='loginModal__register-register_text loginModal__register-color_red'>*/}
          {/*        *ชื่อหรือรหัสผ่านผิดพลาด*/}
          {/*      </div>*/}
          {/*    ) : (*/}
          {/*      <div className='loginModal__register-register_text loginModal__register-color_red'>*/}
          {/*        เข้าสู่ระบบสำเร็จ !!!*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  ) : (*/}
          {/*    // /!* default state *!/*/}
          {/*    <div className='loginModal__register-register_text loginModal__register-color_red'></div>*/}
          {/*  )}*/}
          {/*</div>*/}

          <div className='loginModal__flex_button'>
            <Button
              onButtonClick={() => {
                setIsCollapse(true)
                setShowModal({ isShow: false, currentModal: '' })
              }}
              wrapperClassName='loginModal__close-wrapper'
              buttonClassName='loginModal__close-button'
              text={language !== 'TH' ? 'နောက်ပြန်' : 'กลับ'}
              // buttonBackground='#d26b70'
              style={{ color: 'black' }}
            />
            {showModal.currentModal === 'forget' && !isRequestOTP ? (
              <Button
                wrapperClassName='loginModal__close-wrapper w-25 h-16'
                buttonClassName={`${isRequestOTP || OTPValue.length > 0 ? 'disabled' : ''} loginModal__login-button `}
                text={language !== 'TH' ? 'Request OTP' : 'ขอ OTP'}
                onClick={() => requestOTP()}
              />
            ) : showModal.currentModal === 'forget' && isRequestOTP ? (
              <Button
                wrapperClassName='loginModal__close-wrapper'
                buttonClassName={`${
                  OTPValue.length < 6 || newUser.newPassword.length < 8 ? 'disabled' : ''
                } loginModal__login-button`}
                onClick={handleOTP}
                text={language !== 'TH' ? 'အတည်ပြု' : 'ยืนยัน'}
              />
            ) : (
              <Button
                onButtonClick={() => setIsCollapse(true)}
                onClick={handleLogin}
                type='submit'
                wrapperClassName='loginModal__close-wrapper'
                buttonClassName={`loginModal__login-button ${
                  newUser.username.length !== 10 || newUser.password.length < 8 ? 'disabled' : ''
                }`}
                text={language !== 'TH' ? 'ဝင်ရောက်' : 'เข้าระบบ'}
                // buttonBackground='#d26b70'
                style={{ color: 'white' }}
              />
            )}
          </div>

          <div>
            <hr className='loginModal__divider' data-content='divider' />
          </div>
          {showModal.currentModal !== 'forget' ? (
            <div className='loginModal__text-wrapper mt-6'>
              <div className='loginModal__bottom-wrapper'>
                {/* <div
                  className='loginModal__contact loginModal__contact-facebook '
                  style={{ padding: '5px', marginBottom: '10px' }}
                >
                  <a onClick={handleLoginFacebook} style={{ display: 'flex', gap: '10px' }}>
                    <img style={{ width: '30px', height: '30px' }} src={require('./assets/facebook-icon.png')} />
                    <p style={{ color: '#000000' }}>{language !== 'TH' ? 'Log in with' : 'เข้าสู่ระบบด้วย'}</p>
                    <p style={{ color: '#4267B2' }}>FB</p>
                  </a>
                </div> */}
                <div className='loginModal__contact loginModal__contact-line ' style={{ padding: '5px' }}>
                  <a onClick={handleLoginLine} style={{ display: 'flex', gap: '10px' }}>
                    <img style={{ width: '30px', height: '30px' }} src={require('./assets/line-icon.png')} />
                    <p style={{ color: '#000000' }}>{language !== 'TH' ? 'လော့ဂ်အင်ဝင်ရန်' : 'เข้าสู่ระบบด้วย'}</p>
                    <p style={{ color: '#00B900' }}>LINE</p>
                  </a>
                </div>
              </div>
            </div>
          ) : null}
        </Box>
      </Modal>
    </>
  )
}

export default Header
