import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import { removeFromCart } from '../../../redux/actions/cart'
import { lottoType, lottoType_TH } from '../../../utils/lottoList'
import { getLang } from '../../../redux/selectors/lang'

export default function CartDisplay() {
  const dispatch = useDispatch()
  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])
  const lang = useSelector(getLang)
  const language = lang.lang

  const sortingScore = {
    '2A': 1,
    '2B': 2,
    '2R': 3,
    '3A': 4,
    '3R': 5,
    '3F': 6,
    '3B': 7,
    SA: 8,
    SB: 9,
    '1P': 10,
    '1S': 11,
  }
  // const lottoType = {
  //   // '2A': '2 ตัวบน',
  //   // '2B': '2 ตัวล่าง',
  //   // '3A': '3 ตัวบน',
  //   // '3R': '3 ตัวโต๊ด',
  //   // '3F': '3 ตัวหน้า',
  //   // '3B': '3 ตัวล่าง',
  //   // SA: 'วิ่งบน',
  //   // SB: 'วิ่งล่าง',

  //   '2A': '၂လုံးပေါ်',
  //   '2B': '၂လုံးအောက်',
  //   '3A': '၃ လုံးပေါ်',
  //   '3R': '၃လုံးပြောင်းပြန်',
  //   '3F': 'ရှေ့၃လုံး',
  //   '3B': '၃လုံးအောက်',
  //   SA: 'အပေါ်ပြေး',
  //   SB: 'အောက်ပြေး',
  // }

  const sortByNumber = _.sortBy(cartItems, (item) => item.detail)
  const sortedCartItems = _.sortBy(sortByNumber, (item) => sortingScore[item.type])
  const groupedCartItems = _.groupBy(sortedCartItems, 'type')
  const cart = []

  _.forIn(groupedCartItems, (value, key) =>
    cart.push(
      <div key={key}>
        <div className='font-bold text-white text-center' style={{ fontSize: '13px', color: 'black' }}>
          {language === 'MM' ? lottoType[key] : lottoType_TH[key]}
        </div>
        {groupedCartItems[key].map((item, index) => (
          <div
            key={index}
            onClick={() => dispatch(removeFromCart({ detail: item.detail, key: key, stamp: item.stamp }))}
            style={{ fontWeight: 'bold', textAlign: 'center', color: `${item.isSet ? 'grey' : 'red'}` }}
          >
            {item.detail}
          </div>
        ))}
      </div>
    )
  )
  return cart
}
