/* eslint-disable import/order */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Dice from 'react-dice-roll'
import _ from 'lodash'
import { API } from 'aws-amplify'
import Combinatorics from 'js-combinatorics'
import Swal from 'sweetalert2'
import numeral from 'numeral'
import _get from 'lodash/get'
import ModalImage from 'react-modal-image'
// import useSound from 'use-sound'

// import resetSfx from 'https://cdn.freesound.org/previews/107/107546_7037-lq.mp3'
// import autoSfx from 'https://cdn.freesound.org/previews/682/682409_11791687-lq.mp3'
// import bubbleSfx from 'https://cdn.freesound.org/previews/485/485065_3518208-lq.mp3'
// import chipSfx from 'https://cdn.freesound.org/previews/201/201806_3745836-lq.mp3'
// import countdownSfx from 'https://cdn.freesound.org/previews/612/612873_12946258-lq.mp3'
// import doubleSfx from 'https://cdn.freesound.org/previews/532/532861_2587408-lq.mp3'
// import drawSfx from 'https://cdn.freesound.org/previews/607/607902_9789704-lq.mp3'
// import followSfx from 'https://cdn.freesound.org/previews/532/532860_2587408-lq.mp3'
// import loseSfx from 'https://cdn.freesound.org/previews/180/180350_2888453-lq.mp3'
// import diceRollSfx from 'https://cdn.freesound.org/previews/515/515424_11083127-lq.mp3'
// import randomSfx from 'https://cdn.freesound.org/previews/540/540369_12029332-lq.mp3'
// import switchSfx from 'https://cdn.freesound.org/previews/414/414434_5121236-lq.mp3'
// import undoSfx from 'https://cdn.freesound.org/previews/405/405548_6436863-lq.mp3'
// import winSfx from 'https://cdn.freesound.org/previews/172/172402_1648170-lq.mp3'

import { getLang } from '../../redux/selectors/lang'
// eslint-disable-next-line import/order
import History from './angalongModal/History'

// import useQuadBounce from './useQuadBounce.js'
// import { motion } from 'framer-motion'

// CHIP
import {
  fiveChip,
  tenChip,
  twentyChip,
  fiftyChip,
  hundredChip,
  fiveHundredChip,
  anyChip,
} from '../../utils/angalong_util.js'

// DICE
import {
  angelDice,
  dongDice,
  elephantDice,
  horseDice,
  nagaDice,
  tigerDice,
  anyDice,
  angelDong,
  angelNaga,
  nagaTiger,
  nagaDong,
  elephantTiger,
  elephantHorse,
  elephantDong,
  tigerHorse,
} from '../../utils/angalong_util.js'

// CARD
import {
  nagaCard,
  elephantCard,
  horseCard,
  tigerCard,
  angelCard,
  dongCard,
  nagaCardActive,
  elephantCardActive,
  horseCardActive,
  tigerCardActive,
  angelCardActive,
  dongCardActive,
} from '../../utils/angalong_util.js'
// import boxDice from '../../assets/angalong/element/box.png'
// import robe from '../../assets/angalong/element/robe.png'
import rulebook_img from '../../assets/angalong/element/rule-book.png'
import story_thumbnail from '../../assets/angalong/element/story_thumbnail.png'
import story_th from '../../assets/angalong/element/story_th.png'
import story_mm from '../../assets/angalong/element/story_mm.png'
import rule_th from '../../assets/angalong/element/rule_th.png'
// eslint-disable-next-line import/order
import rule_mm from '../../assets/angalong/element/rule_mm.png'

import './Angalong.scss'
// import homeIconActive from '../../components/navigator/assets/home-active.png'
// import homeIcon from '../../components/navigator/assets/dong-home.png'
// import { clearCart, updateAmount } from '../../redux/actions/cart'
// import { getWallet } from '../../redux/selectors/wallet'
import { setWalletCredit } from '../../redux/actions/wallet'
import { getAuth } from '../../redux/selectors/auth'
// import { setReceipt } from '../../redux/actions/receipts'

// import AliceCarousel from 'react-alice-carousel'

// import ReceiptModal from '../Lottos/components/ReceiptModal'

const playReset = new Audio('https://cdn.freesound.org/previews/107/107546_7037-lq.mp3')
const playAuto = new Audio('https://cdn.freesound.org/previews/682/682409_11791687-lq.mp3')
const playBubble = new Audio('https://cdn.freesound.org/previews/485/485065_3518208-lq.mp3')
const playChip = new Audio('https://cdn.freesound.org/previews/201/201806_3745836-lq.mp3')
const playCountdown = new Audio('https://cdn.freesound.org/previews/612/612873_12946258-lq.mp3')
const playDouble = new Audio('https://cdn.freesound.org/previews/532/532861_2587408-lq.mp3')
const playDraw = new Audio('https://cdn.freesound.org/previews/607/607902_9789704-lq.mp3')
const playFollow = new Audio('https://cdn.freesound.org/previews/532/532860_2587408-lq.mp3')
const playLose = new Audio('https://cdn.freesound.org/previews/180/180350_2888453-lq.mp3')
const playDiceRoll = new Audio('https://cdn.freesound.org/previews/515/515424_11083127-lq.mp3')
const playRandom = new Audio('https://cdn.freesound.org/previews/540/540369_12029332-lq.mp3')
const playSwitch = new Audio('https://cdn.freesound.org/previews/414/414434_5121236-lq.mp3')
const playUndo = new Audio('https://cdn.freesound.org/previews/405/405548_6436863-lq.mp3')
const playWin = new Audio('https://cdn.freesound.org/previews/346/346814_321967-lq.mp3')

playReset.muted = false
playAuto.muted = false
playBubble.muted = false
playChip.muted = false
playCountdown.muted = false
playDouble.muted = false
playDraw.muted = false
playFollow.muted = false
playLose.muted = false
playDiceRoll.muted = false
playRandom.muted = false
playSwitch.muted = false
playUndo.muted = false
playWin.muted = false

const Angalong = () => {
  const [currentChip, setCurrentChip] = useState(20)
  // const [activeCard, setActiveCard] = useState([1, 1, 1])
  const [rewardCard, setRewardCard] = useState([0, 0, 0])
  const [previousBuy, setPreviousBuy] = useState([])
  const [isUndo, setIsUndo] = useState(false)
  const [isBuy, setIsBuy] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [tempReceiptNumber, setTempReceiptNumber] = useState(0)
  const [buyHistory, setBuyHistory] = useState([])
  const [buyOne, setBuyOne] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })

  const [buyPair, setBuyPair] = useState({
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
    23: 0,
    24: 0,
    25: 0,
    26: 0,
    35: 0,
    36: 0,
    43: 0,
    45: 0,
    46: 0,
    56: 0,
  })
  const [lastBuyOne, setLastBuyOne] = useState({})
  const [lastBuyPair, setLastBuyPair] = useState({})
  const [second, setSecond] = useState(0)
  const [isAuto, setIsAuto] = useState(false)
  const [isMultiply, setIsMultiply] = useState(false)
  const [createReceipt, setCreateReceipt] = useState({})
  const [buyAngalong, setBuyAngalong] = useState({})
  const [resultAngalong, setResultAngalong] = useState({})
  const [walletValidate, setWalletValidate] = useState(null)
  const [rewardHistory, setRewardHistory] = useState([])
  const [isFetchHistory, setIsFetchHistory] = useState(false)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })

  const lang = useSelector(getLang)

  const allPhoto = [
    fiveChip, //0
    tenChip,
    twentyChip,
    fiftyChip,
    hundredChip,
    fiveHundredChip,
    anyChip, //6
    nagaCard, //7
    elephantCard, //8
    horseCard, //9
    tigerCard, //10
    angelCard, //11
    dongCard, //12
    nagaCardActive, //13
    elephantCardActive, //14
    horseCardActive, //15
    tigerCardActive, //16
    angelCardActive, //17
    dongCardActive, //18
    angelDice,
    dongDice,
    elephantDice,
    horseDice,
    nagaDice,
    tigerDice,
    anyDice,
    angelDong, //26
    angelNaga, //27
    nagaTiger, //28
    nagaDong, //29
    elephantTiger, //30
    elephantHorse, //31
    elephantDong, //32
    tigerHorse, //33
  ]

  const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0)
  const sumBuyOne = sumValues(buyOne)
  const sumBuyPair = sumValues(buyPair)
  const purchaseAmount = sumBuyOne + sumBuyPair

  let history = useHistory()
  const dispatch = useDispatch()
  const walletCredit = useSelector((state) => _.get(state, 'wallet.credit', null))
  const auth = useSelector(getAuth)

  const drawDateStr = moment(new Date()).toISOString().slice(2, 10).split('-').reverse().join('/')

  const disableKeyBoard = () => {
    document.onkeydown = () => {
      console.log('test')
      return false
    }
  }

  disableKeyBoard()

  // window.addEventListener('keypress', (event) => {
  //   const press = event.key
  //   console.log(press, 'press')
  //   if (press === 'q') {
  //     playCountdown.play()
  //   }
  //   if (press === 'w') {
  //     playDiceRoll.play()
  //   }
  //   if (press === 'y') {
  //     playWin.play()
  //   }
  //   if (press === 'r') {
  //     playDraw.play()
  //   }
  //   if (press === 't') {
  //     playLose.play()
  //   }
  // })

  const countdownButton = () => {
    playCountdown.play()
  }

  const diceRollButton = () => {
    playDiceRoll.play()
  }

  const winButton = () => {
    playWin.play()
  }

  const drawButton = () => {
    playDraw.play()
  }

  const loseButton = () => {
    playLose.play()
  }

  const activeOne = {
    1: _.countBy(rewardCard)['1'] > 0 ? _.countBy(rewardCard)['1'] + 1 : 0,
    2: _.countBy(rewardCard)['2'] > 0 ? _.countBy(rewardCard)['2'] + 1 : 0,
    3: _.countBy(rewardCard)['3'] > 0 ? _.countBy(rewardCard)['3'] + 1 : 0,
    4: _.countBy(rewardCard)['4'] > 0 ? _.countBy(rewardCard)['4'] + 1 : 0,
    5: _.countBy(rewardCard)['5'] > 0 ? _.countBy(rewardCard)['5'] + 1 : 0,
    6: _.countBy(rewardCard)['6'] > 0 ? _.countBy(rewardCard)['6'] + 1 : 0,
  }
  const activePair = {
    12: _.includes(rewardCard, 1) && _.includes(rewardCard, 2),
    13: _.includes(rewardCard, 1) && _.includes(rewardCard, 3),
    14: _.includes(rewardCard, 1) && _.includes(rewardCard, 4),
    15: _.includes(rewardCard, 1) && _.includes(rewardCard, 5),
    16: _.includes(rewardCard, 1) && _.includes(rewardCard, 6),
    23: _.includes(rewardCard, 2) && _.includes(rewardCard, 3),
    24: _.includes(rewardCard, 2) && _.includes(rewardCard, 4),
    25: _.includes(rewardCard, 2) && _.includes(rewardCard, 5),
    26: _.includes(rewardCard, 2) && _.includes(rewardCard, 6),
    35: _.includes(rewardCard, 3) && _.includes(rewardCard, 5),
    36: _.includes(rewardCard, 3) && _.includes(rewardCard, 6),
    43: _.includes(rewardCard, 4) && _.includes(rewardCard, 3),
    45: _.includes(rewardCard, 4) && _.includes(rewardCard, 5),
    46: _.includes(rewardCard, 4) && _.includes(rewardCard, 6),
    56: _.includes(rewardCard, 5) && _.includes(rewardCard, 6),
  }

  // section 1 for cube to ROLL
  // _----------------------

  // const cube = document.getElementById('cube')
  // const cube2 = document.getElementById('cube2')
  // const cube3 = document.getElementById('cube3')
  // const minY = 16
  // const maxY = 24
  // const minX = 32
  // const maxX = 48

  // const onClickCube = () => {
  //   const xRand = getRandom(max, min)
  //   const yRand = getRandom(max, min)
  //
  //   cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
  //   cube.style.transform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
  // }

  // function getRandom(max, min) {
  //   return (Math.floor(Math.random() * (max - min)) + min) * 90
  // }
  //
  // Array.prototype.random = function () {
  //   return this[Math.floor(Math.random() * this.length)]
  // }

  // const walletAmount = _.get(useSelector(getWallet), 'credit', 0)

  // section 1 for cube to ROLL
  // _----------------------

  const removeDups = (array) => {
    let unique = {}
    array.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true
      }
    })
    return Object.keys(unique)
  }

  // const onlyUnique = (value, index, array) => {
  //   return array.indexOf(value) === index
  // }

  const handleBuyAngalong = (type, amount) => {
    setIsUndo(false)
    setIsBuy(true)

    if (type < 10) {
      setBuyOne((prevState) => ({
        ...prevState,
        [type]: prevState[type] + amount >= 500 ? 500 : prevState[type] + amount,
      }))
    } else {
      setBuyPair((prevState) => ({
        ...prevState,
        [type]: prevState[type] + amount >= 500 ? 500 : prevState[type] + amount,
      }))
    }

    // const newPriceArray = priceArray.map((obj) => {
    //   if (obj.type === type && obj.detail === detail && obj.stamp === stamp) {
    //     obj.amount = amount
    //     dispatch(updateAmount({ purchaseItem: obj }))
    //   }
    //   return obj
    // })
    // setIsPriceChange(true)
    // setPriceArray(newPriceArray)
    // console.log('newPriceArray >>', newPriceArray)
  }

  // const y = useQuadBounce()

  const possibleChip = [0, 5, 10, 20, 50, 100, 500]

  const handleChip = (buy, type) => {
    // setIsAllSamePrice(false)

    if (buy === 'one') {
      if (buyOne[type] === 5) {
        return fiveChip
      }
      if (buyOne[type] === 10) {
        return tenChip
      }
      if (buyOne[type] === 20) {
        return twentyChip
      }
      if (buyOne[type] === 50) {
        return fiftyChip
      }
      if (buyOne[type] === 100) {
        return hundredChip
      }
      if (buyOne[type] === 500) {
        return fiveHundredChip
      }
      return anyChip
    }

    if (buy === 'pair') {
      if (buyPair[type] === 5) {
        return fiveChip
      }
      if (buyPair[type] === 10) {
        return tenChip
      }
      if (buyPair[type] === 20) {
        return twentyChip
      }
      if (buyPair[type] === 50) {
        return fiftyChip
      }
      if (buyPair[type] === 100) {
        return hundredChip
      }
      if (buyPair[type] === 500) {
        return fiveHundredChip
      }
      return anyChip
    }

    if (buy === 'current') {
      if (currentChip === 5) {
        return fiveChip
      }
      if (currentChip === 10) {
        return tenChip
      }
      if (currentChip === 20) {
        return twentyChip
      }
      if (currentChip === 50) {
        return fiftyChip
      }
      if (currentChip === 100) {
        return hundredChip
      }
      if (currentChip === 500) {
        return fiveHundredChip
      }
      return null
    }
  }

  const resetButton = () => {
    // playReset.muted = false
    playReset.play()
    // console.log(resetNow, 'heloww')
    // if (resetNow !== undefined) {
    //   resetNow.then(_ => {
    //     console.log(_, 'a;skdjfal;ksdfjkl;')
    //     // Autoplay started!
    //   }).catch(error => {
    //     console.log(error, 'errrrororora;skdjfal;ksdfjkl;')
    //     // Autoplay was prevented.
    //     // Show a "Play" button so that user can start playback.
    //   });
    // }
    setIsBuy(false)
    setIsUndo(true)
    setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
    setBuyPair({
      12: 0,
      13: 0,
      14: 0,
      15: 0,
      16: 0,
      23: 0,
      24: 0,
      25: 0,
      26: 0,
      35: 0,
      36: 0,
      43: 0,
      45: 0,
      46: 0,
      56: 0,
    })
    setBuyHistory([
      { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
      {
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        35: 0,
        36: 0,
        43: 0,
        45: 0,
        46: 0,
        56: 0,
      },
    ])
    setPreviousBuy([])
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const undoButton = () => {
    if (buyHistory.length > 2) {
      // playUndo.muted = false
      playUndo.play()
      setIsUndo(true)
      if (isMultiply) {
        // const removeBuy = buyHistory.splice(-1)
        // console.log('remove last buyOne')
        const lastPairBuy = previousBuy.findLast((element) => Object.keys(element).length === 15)
        // console.log(lastBuy, 'newObj')
        setBuyPair(lastPairBuy)
        setBuyHistory((prevState) => prevState.slice(0, -1))
        setPreviousBuy((prevState) => prevState.slice(0, -1))

        const lastOneBuy = previousBuy.findLast((element) => Object.keys(element).length === 6)
        // console.log(lastBuy, 'newObj')
        setBuyOne(lastOneBuy)
        setBuyHistory((prevState) => prevState.slice(0, -1))
        setPreviousBuy((prevState) => prevState.slice(0, -1))
      } else {
        const lastTypeBuy = Object.keys(buyHistory[buyHistory.length - 1]).length
        // console.log(lastTypeBuy, 'lastTysdfgsdfgsdfgsdfgpeBuy')
        if (lastTypeBuy === 6) {
          // const removeBuy = buyHistory.splice(-1)
          // console.log('remove last buyOne')
          const lastBuy = previousBuy.findLast((element) => Object.keys(element).length === 6)
          // console.log(lastBuy, 'newObj')
          setBuyOne(lastBuy)
          setBuyHistory((prevState) => prevState.slice(0, -1))
          setPreviousBuy((prevState) => prevState.slice(0, -1))
        }
        if (lastTypeBuy === 15) {
          const lastBuy = previousBuy.findLast((element) => Object.keys(element).length === 15)
          // console.log(lastBuy, 'newObj')
          setBuyPair(lastBuy)
          setBuyHistory((prevState) => prevState.slice(0, -1))
          setPreviousBuy((prevState) => prevState.slice(0, -1))
        }
      }
    }
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const multiplyButton = () => {
    // playDouble.muted = false
    playDouble.play()
    setIsUndo(false)
    setIsBuy(true)
    setIsMultiply(true)
    const buyOneType = [1, 2, 3, 4, 5, 6]
    const buyPairType = [12, 13, 14, 15, 16, 23, 24, 25, 26, 35, 36, 43, 45, 46, 56]
    for (let i = 0; i < buyOneType.length; i++) {
      setBuyOne((prevState) => ({
        ...prevState,
        [buyOneType[i]]: prevState[buyOneType[i]] * 2 >= 500 ? 500 : prevState[buyOneType[i]] * 2,
      }))
    }
    for (let j = 0; j < buyPairType.length; j++) {
      setBuyPair((prevState) => ({
        ...prevState,
        [buyPairType[j]]: prevState[buyPairType[j]] * 2 >= 500 ? 500 : prevState[buyPairType[j]] * 2,
      }))
    }
  }

  const luckyButton = () => {
    // playRandom.muted = false
    playRandom.play()
    setIsUndo(false)
    setIsBuy(true)
    const rollDice = () => {
      return 1 + Math.floor(Math.random() * 4)
    }
    const buyOneType = [1, 2, 3, 4, 5, 6]
    const buyPairType = [12, 13, 14, 15, 16, 23, 24, 25, 26, 35, 36, 43, 45, 46, 56]

    if (rollDice() < 4) {
      const random = Math.floor(Math.random() * buyOneType.length)
      setBuyOne((prevState) => ({
        ...prevState,
        [buyOneType[random]]: prevState[buyOneType[random]] + currentChip,
      }))
    } else {
      const random = Math.floor(Math.random() * buyPairType.length)
      setBuyPair((prevState) => ({
        ...prevState,
        [buyPairType[random]]: prevState[buyPairType[random]] + currentChip,
      }))
    }
  }

  const followButton = () => {
    // playFollow.muted = false
    playFollow.play()
    setIsUndo(true)
    setIsBuy(true)
    if (!_.isEmpty(lastBuyOne)) {
      console.log(lastBuyOne, 'lastBuyOne')
      const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
      setBuyOne(localBuyOne)
    }
    if (!_.isEmpty(lastBuyPair)) {
      console.log(lastBuyPair, 'lastBuyPair')
      const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
      setBuyPair(localBuyPair)
    }
    // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
  }

  const autoPlayButton = () => {
    // playAuto.muted = false
    playAuto.play()
    if (second >= 15 && second <= 40) {
      if (purchaseAmount <= 0) {
        setIsUndo(true)
        setIsBuy(true)
        if (!_.isEmpty(lastBuyOne)) {
          console.log(lastBuyOne, 'lastBuyOne')
          const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
          if (buyOne !== localBuyOne) {
            setBuyOne(localBuyOne)
          }
        }
        if (!_.isEmpty(lastBuyPair)) {
          console.log(lastBuyPair, 'lastBuyPair')
          const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
          if (buyPair !== localBuyPair) {
            setBuyPair(localBuyPair)
          }
        }
      }
    }
    setIsAuto(!isAuto)
  }

  // console.log(buyOne, buyPair, 'asdfasdfasdfasdfasdfasdf')

  // const bounceTransition = {
  //   y: {
  //     duration: 0.4,
  //     yoyo: Infinity,
  //     ease: 'easeOut',
  //   },
  // }

  useEffect(() => {
    if (!isUndo) {
      // playChip.muted = false
      playChip.play()
      setPreviousBuy(buyHistory)
      setBuyHistory((prevState) => [...prevState, buyOne])
    }
  }, [buyOne])

  useEffect(() => {
    if (!isUndo) {
      // playChip.muted = false
      playChip.play()
      setPreviousBuy(buyHistory)
      setBuyHistory((prevState) => [...prevState, buyPair])
    }
  }, [buyPair])

  // console.log(buyHistory, 'buyHistory')
  // console.log(previousBuy, 'previousBuy')

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond(moment().second()) // <-- Change this line!
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  // console.log(second, 'second')

  // console.log(lastBuyOne, lastBuyPair, 'lasdfalsdkfjal;ksdjfas')

  const permute = function (number) {
    console.log(number, 'asdf;lkjadls;kfjakl;sdfjlks;ad')
    // const arr = []
    const refactornumber = number.replace(/,/g, '').toString()
    const result = Combinatorics.permutation(refactornumber.split('')).toArray()
    const newArray = [
      result[0].join(',').split(',').join(''),
      result[1].join(',').split(',').join(''),
      result[2].join(',').split(',').join(''),
      result[3].join(',').split(',').join(''),
      result[4].join(',').split(',').join(''),
      result[5].join(',').split(',').join(''),
    ]
    const unique = removeDups(newArray)
    console.log(unique, 'resultklasdjflasdfjlas;df')
    // let allChecked = false
    // console.log(singleType, 'singleType')
    // console.log(number, 'number')
    // console.log(refactornumber, 'refactorNumber')
    // console.log(result, 'result')
    // console.log(unique, 'unique')
    return unique
    // for (let i = 0; i < unique.length; i++) {
    //   return unique[i].replace(/,/g, '').toString()
    //   // if (!isAlreadyExists(newNumber, singleType)) {
    //   //   return newNumber
    //   // } else {
    //   //   allChecked = true
    //   // }
    // }
    // if (allChecked) {
    //   for (let i = 0; i < unique.length; i++) {
    //     let newNumber = unique[i].replace(/,/g, '').toString()
    //     return newNumber
    //   }
    // }
  }

  useEffect(() => {
    // // if (second === 1) {
    //   // const shuffleArray = (array) => {
    //   //   console.log(array, 'array')
    //   //   for (let i = array.length - 1; i > 0; i--) {
    //   //     const j = Math.floor(Math.random() * (i + 1));
    //   //     const temp = array[i];
    //   //     array[i] = array[j];
    //   //     array[j] = temp;
    //   //   }
    //   // }
    //   // console.log('roll dice')
    //   // // enableKeyBoard()
    //   // const newArray = ['a', 'b', 'c']
    //   // // const shuffleDice = shuffleArray(newArray)
    //   // // console.log(shuffleDice)
    //   // window.addEventListener('keypress', (event) => {
    //   //   console.log('You have pressed key: ', event.key)
    //   // })
    //   // window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[0] }))
    //   // setTimeout(() => {
    //   //   window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[1] }))
    //   // }, 100)
    //   // setTimeout(() => {
    //   //   window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[2] }))
    //   // }, 200)
    // }
    if (second === 5) {
      // playDiceRoll.muted = false
      // playDiceRoll.play()
      diceRollButton()
      setResultAngalong({})
      if (purchaseAmount > 0) {
        setIsUndo(true)
        setIsBuy(true)
        localStorage.setItem('lastBuyOne', JSON.stringify(buyOne))
        localStorage.setItem('lastBuyPair', JSON.stringify(buyPair))
        setLastBuyOne(buyOne)
        setLastBuyPair(buyPair)
      }

      // section 3 for cube to ROLL
      // ----------------------

      // const xRand = getRandom(maxX, minX)
      // const yRand = getRandom(maxY, minY)

      // const xRand2 = getRandom(maxX, minX)
      // const yRand2 = getRandom(maxY, minY)

      // const xRand3 = getRandom(maxX, minX)
      // const yRand3 = getRandom(maxY, minY)

      // const ranY = ['-', '+'].random()

      // console.log(xRand, yRand, xRand2, yRand2, xRand3, yRand3)

      // // cube.style.webkitTransform = 'rotateX(' + xRand + 'deg) rotateY(' + yRand + 'deg)'
      // cube.style.transform = 'rotateX(-' + xRand + 'deg) rotateY(' + ranY + '' + yRand + 'deg)'
      // cube2.style.transform = 'rotateX(-' + xRand2 + 'deg) rotateY(' + ranY + '' + yRand2 + 'deg)'
      // cube3.style.transform = 'rotateX(-' + xRand3 + 'deg) rotateY(' + ranY + '' + yRand3 + 'deg)'

      // section 3 for cube to ROLL
      // ----------------------
      const newArray = ['a', 'b', 'c']
      // const shuffleDice = shuffleArray(newArray)
      // console.log(shuffleDice)
      window.addEventListener('keypress', (event) => {
        console.log('You have pressed key: ', event.key)
      })
      window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[0] }))
      setTimeout(() => {
        window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[1] }))
      }, 100)
      setTimeout(() => {
        window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[2] }))
      }, 200)
      const fetchAngalong = async () => {
        const minutesNow =
          moment().hour() * 60 + moment().minute() - 1 !== 0 ? moment().hour() * 60 + moment().minute() : 1440
        const angalongName = `Angalong ${minutesNow}`
        console.log(angalongName, 'result Name')
        const URI0 = `/results?lottoGroup=Angalong&lottoName=${angalongName}&drawDateStr=${drawDateStr}`
        const encodedURI0 = encodeURI(URI0)
        const fetchResponse = await API.get('lotto', encodedURI0)
        // console.log(fetchResponse, 'fetchAngalong')
        console.log(fetchResponse.detail[0].lottoName, 'reward lottoName')
        if (fetchResponse.code === 0 && fetchResponse.detail.length > 0) {
          const data = fetchResponse.detail[0]
          const resultLotto = data.results
          const lottoName = data.lottoName.split(' ')[1]
          setResultAngalong(resultLotto)
          const lastResult = { lottoName: lottoName, reward: resultLotto['6S'] }
          console.log(lastResult, 'lastResult')
          console.log(rewardHistory, 'rewardHistory')
          console.log('roll dice asdfasdfasdfasdfasdfasd')
          // enableKeyBoard()
          setTimeout(() => {
            const lastResult = { lottoName: lottoName, reward: resultLotto['6S'] }
            console.log(lastResult, 'lastResult')
            console.log(rewardHistory, 'rewardHistory')
            const firstReward = rewardHistory[0]
            if (firstReward !== lastResult) {
              const newRewardHistory = [lastResult].concat(rewardHistory)
              console.log(newRewardHistory, 'newRewardHistory')
              const newArray = newRewardHistory.slice(0, 7)
              console.log(newArray, 'newArray')
              setRewardHistory(newArray)
            }
          }, 10000)
        }
      }
      fetchAngalong().catch(console.error)
    }
    if (second === 8) {
      const newArray = ['d', 'e', 'f']
      window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[0] }))
      setTimeout(() => {
        window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[1] }))
      }, 100)
      setTimeout(() => {
        window.dispatchEvent(new KeyboardEvent('keypress', { key: newArray[2] }))
      }, 200)
    }
    if (second === 15) {
      setIsUndo(true)
      setBuyOne({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 })
      setBuyPair({
        12: 0,
        13: 0,
        14: 0,
        15: 0,
        16: 0,
        23: 0,
        24: 0,
        25: 0,
        26: 0,
        35: 0,
        36: 0,
        43: 0,
        45: 0,
        46: 0,
        56: 0,
      })
      setBuyHistory([
        { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
        {
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          23: 0,
          24: 0,
          25: 0,
          26: 0,
          35: 0,
          36: 0,
          43: 0,
          45: 0,
          46: 0,
          56: 0,
        },
      ])
      setPreviousBuy([])
      setTimeout(() => {
        setRewardCard(() => [0, 0, 0])
      }, 2000)
    }
    if (isAuto && second === 16) {
      setIsUndo(true)
      if (!_.isEmpty(lastBuyOne)) {
        console.log(lastBuyOne, 'lastBuyOne')
        const localBuyOne = JSON.parse(localStorage.getItem('lastBuyOne'))
        setBuyOne(localBuyOne)
      }
      if (!_.isEmpty(lastBuyPair)) {
        console.log(lastBuyPair, 'lastBuyPair')
        const localBuyPair = JSON.parse(localStorage.getItem('lastBuyPair'))
        setBuyPair(localBuyPair)
      }
      // setBuyHistory((previousArr) => (previousArr.slice(0, -1)))
    }
    // if (second === 30) {
    //   setActiveCard(() => [6, 6, 6])
    // }
    if (second === 30) {
      // playCountdown.muted = false
      countdownButton()
    }
    if (second === 41 && purchaseAmount > 0) {
      if (!auth.username) {
        // Not login, so cannot purchase
        Swal.fire({
          icon: 'error',
          // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
          // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
          title: 'Error',
          text: 'Please log in to proceed',
          confirmButtonColor: '#B6964E',
          // confirmButtonText: 'เข้าสู่ระบบ',
        })
        return
      }
      setWalletValidate(null)
      setCreateReceipt({})
      setBuyAngalong({})
      // const walletBalance = fetchWallet()
      // console.log(walletBalance, purchaseAmount)
      // if (walletBalance >= purchaseAmount) {
      let allTong = []
      let allPair = []
      let allAlone = []
      let allBuyPair = []
      if (sumBuyOne > 0) {
        const allProperty = ['1', '2', '3', '4', '5', '6']
        for (const property in buyOne) {
          if (buyOne[property] > 0) {
            let duplicateAlone = []
            const filtered = allProperty.filter(function (value) {
              return value !== property
            })
            console.log(filtered, 'filtered')
            allTong.push({ [`${property}${property}${property}`]: (buyOne[property] * 4) / 9 })
            for (let i = 0; i < filtered.length; i++) {
              const pair = [parseInt(property), parseInt(property), parseInt(filtered[i])]
              const sortPair = pair.sort()
              const pairString = sortPair.toString().split(',').join('')
              allPair.push({ [pairString]: buyOne[property] / 15 })
              const filteredAlone = filtered.filter(function (value) {
                return value !== parseInt(filtered[i]).toString()
              })
              const alonePair = [parseInt(property), parseInt(filtered[i]), parseInt(filtered[i])]
              const sortAlonePair = alonePair.sort()
              const alonePairString = sortAlonePair.toString().split(',').join('')
              duplicateAlone.push(alonePairString)
              for (let j = 0; j < filteredAlone.length; j++) {
                const alone = [parseInt(property), parseInt(filtered[i]), parseInt(filteredAlone[j])]
                const sortAlone = alone.sort()
                const aloneString = sortAlone.toString().split(',').join('')
                duplicateAlone.push(aloneString)
              }
            }
            const uniqAlone = _.uniq(duplicateAlone)
            for (let k = 0; k < uniqAlone.length; k++) {
              allAlone.push({ [uniqAlone[k]]: (buyOne[property] * 2) / 135 })
            }
          }
        }
        // console.log(allTong, allPair, allAlone)
      }
      if (sumBuyPair > 0) {
        for (const property in buyPair) {
          if (buyPair[property] > 0) {
            const newProperty = ['1', '2', '3', '4', '5', '6']
            const pairArray = property.split('')
            const pairOne = `${newProperty[0]}${pairArray[0]}${pairArray[1]}`
            const pairTwo = `${newProperty[1]}${pairArray[0]}${pairArray[1]}`
            const pairThree = `${newProperty[2]}${pairArray[0]}${pairArray[1]}`
            const pairFour = `${newProperty[3]}${pairArray[0]}${pairArray[1]}`
            const pairFive = `${newProperty[4]}${pairArray[0]}${pairArray[1]}`
            const pairSix = `${newProperty[5]}${pairArray[0]}${pairArray[1]}`
            console.log(pairOne, pairTwo, pairThree, pairFour, pairFive, pairSix)
            const permuteOne = permute(pairOne)
            const permuteTwo = permute(pairTwo)
            const permuteThree = permute(pairThree)
            const permuteFour = permute(pairFour)
            const permuteFive = permute(pairFive)
            const permuteSix = permute(pairSix)
            console.log(permuteOne, permuteTwo, permuteThree, permuteFour, permuteFive, permuteSix)
            console.log('asd;fkjas;kldfjkl;asdjfaskldfjkl;sDJfl;kasd')
            const combine = permuteOne.concat(permuteTwo, permuteThree, permuteFour, permuteFive, permuteSix)
            // const uniqueNew = onlyUnique(combine)
            console.log(combine, 'sadfkjas;dlkfjas;kldfjaskldfjklasdf')
            // const pairOneString = sortPairOne.toString().split(',').join('')
            // const pairTwoString = sortPairTwo.toString().split(',').join('')
            for (let i = 0; i < combine.length; i++) {
              allBuyPair.push({ [combine[i]]: buyPair[property] / 30 })
            }
            // allBuyPair.push({ [pairOneString]: buyPair[property] / 2 }, { [pairTwoString]: buyPair[property] / 2 })
          }
        }
        console.log(allBuyPair, 'all Buy Pair to be continued')
      }
      console.log(allTong, allPair, allAlone)
      const getLottoData = async () => {
        const minutesNow =
          moment().hour() * 60 + moment().minute() + 1 !== 1440 ? moment().hour() * 60 + moment().minute() + 1 : 1
        const angalongName = `Angalong ${minutesNow}`
        console.log(angalongName, 'result Name getLottoData')
        // const minutesNow = moment().hour() * 60 + moment().minute()
        // const angalongName = `Angalong ${minutesNow}`
        // console.log(angalongName, drawDateStr, 'angalongName Name')
        const URI0 = `/lottos?status=open&lottoName=${angalongName}&limit=1&lottoGroup&drawDateStr=${drawDateStr}`
        const encodedURI0 = encodeURI(URI0)
        const response = await API.get('lotto', encodedURI0)
        console.log(response, 'getLottoDataResponce')
        if (response.code === 0) {
          const data = response.detail || null
          if (!data || _.isEmpty(data)) {
            return Swal.fire({
              icon: 'error',
              title: `An error occurred`,
              text: 'Please return to Angalong menu. Or contact the staff',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              cancelButtonColor: '#C0C0C0',
              confirmButtonText: 'To Angalong',
            }).then((result) => {
              if (result.value) {
                history.push('/angalong')
              }
            })
          } else {
            const numArray = [
              100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900,
              2000,
            ]
            // const numArray = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
            // const randomIndex = Math.floor(Math.random() * numArray.length)
            // const randomElement = numArray[randomIndex]
            // console.log(randomElement)
            setTimeout(() => {
              console.log(data[0].lottoName, 'getData LottoName')
              setCreateReceipt({
                allTong: allTong,
                allPair: allPair,
                allAlone: allAlone,
                allBuyPair: allBuyPair,
                lottoData: data[0],
              })
            }, numArray)
          }
        } else {
          return Swal.fire({
            icon: 'error',
            title: `An error occurred`,
            text: 'Please return to Angalong menu. Or contact the staff',
            confirmButtonColor: '#B6964E',
            confirmButtonText: 'To Angalong',
          }).then((result) => {
            console.log(result)
            history.push('/angalong')
          })
        }
      }
      getLottoData().catch(console.error)
    }
    // }
  }, [second])

  useEffect(() => {
    if (!_.isEmpty(createReceipt)) {
      console.log(createReceipt, 'createReceipt')
      const purchaseAngalong = async () => {
        const lottoData = createReceipt.lottoData

        if (_.isEmpty(lottoData)) {
          Swal.fire({
            icon: 'error',
            title: 'An error occurred',
            text: 'Please try again',
          })
          return
        }

        // setIsPurchasing(true) // To disable the "ส่งโพย" button while processing
        console.log(lottoData, 'lottoData')
        let buyValue = {}
        buyValue.one = JSON.stringify(buyOne)
        buyValue.two = JSON.stringify(buyPair)
        const stringBuyValue = JSON.stringify(buyValue)

        const lottoDetail = {
          refId: lottoData.lottoNumber,
          lottoGroup: lottoData.lottoGroup,
          lottoName: lottoData.lottoName,
          drawDateStr: lottoData.drawDateStr,
          remark: stringBuyValue,
        }

        const purchaseAngalongAmount = Math.round((purchaseAmount + Number.EPSILON) * 100) / 100

        // console.log(sumPurchase, 'sumPurchase')
        // console.log(purchaseAmount, 'purchaseAmount2')
        // console.log(purchaseItems, 'purchaseItems2')

        let purchaseItems = []
        const purchaseBuyPair = createReceipt.allBuyPair
        const purchaseAllTong = createReceipt.allTong
        const purchaseAllPair = createReceipt.allPair
        const purchaseAllAlone = createReceipt.allAlone

        if (purchaseBuyPair.length > 0) {
          for (let i = 0; i < purchaseBuyPair.length; i++) {
            purchaseItems.push({
              type: '2A',
              detail: Object.keys(purchaseBuyPair[i])[0],
              amount: Object.values(purchaseBuyPair[i])[0],
              payRate: 180,
            })
          }
        }
        if (purchaseAllTong.length > 0) {
          for (let i = 0; i < purchaseAllTong.length; i++) {
            purchaseItems.push({
              type: '3B',
              detail: Object.keys(purchaseAllTong[i])[0],
              amount: Object.values(purchaseAllTong[i])[0],
              payRate: 9,
            })
          }
          for (let j = 0; j < purchaseAllPair.length; j++) {
            purchaseItems.push({
              type: '3A',
              detail: Object.keys(purchaseAllPair[j])[0],
              amount: Object.values(purchaseAllPair[j])[0],
              payRate: 45,
            })
          }
          for (let k = 0; k < purchaseAllAlone.length; k++) {
            purchaseItems.push({
              type: '3F',
              detail: Object.keys(purchaseAllAlone[k])[0],
              amount: Object.values(purchaseAllAlone[k])[0],
              payRate: 135,
            })
          }
        }
        console.log(purchaseItems, 'purchaseItems')

        const data = {
          playerUsername: auth.username, // This is Hash name, not human friendly name
          playerName: auth.attributes.name,
          receiptType: 'angalong',
          refId: lottoDetail.refId, // Earth See here
          // refId: '0', // Earth See here
          receiptDetail: {
            lottoGroup: lottoDetail.lottoGroup,
            lottoName: lottoDetail.lottoName,
            drawDateStr: lottoDetail.drawDateStr,
            remark: lottoDetail.remark,
          },
          purchaseItems,
          purchaseAngalongAmount,
          timeout: 120,
        }

        if (data.purchaseAmount > 10500) {
          Swal.fire({
            icon: 'error',
            // title: 'กรุณาทำรายการใหม่อีกครั้ง',
            title: 'Please try again',

            // html: 'แทงขั้นสูงสุดต่อครั้ง 100,000.00 </br> แทงขั้นสูงสุดต่อเลข 2,000.00',
            html: 'Max per receipt 10,500 </br> Max per number 500',

            confirmButtonColor: '#B6964E',
            // confirmButtonText: 'ทำรายการใหม่',
            confirmButtonText: 'New Bet',
          })
        } else if (purchaseAngalongAmount > walletCredit) {
          Swal.fire({
            icon: 'error',
            // title: 'เงินในกระเป๋าไม่พอ',
            title: 'Insufficiant fund',

            // confirmButtonText: 'ฝากเงิน',
            confirmButtonText: 'Deposit',
          }).then((result) => {
            if (result.value) {
              history.push('/deposit')
            }
          })
        } else {
          // About to buy angalong !!
          const createBuyAngalong = await API.post('purchase', '/receipts', { body: data })
          if (createBuyAngalong.code === 0) {
            const setReceipt = _.get(createBuyAngalong, 'detail', {})
            console.log(setReceipt, 'setReceipt')
            setBuyAngalong(setReceipt)
          } else {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred',
              text: 'No Receipt Set',
            })
          }
        }
      }
      purchaseAngalong().catch(console.error)
    }
  }, [createReceipt])

  useEffect(() => {
    if (!_.isEmpty(buyAngalong)) {
      console.log(buyAngalong, 'buyAngalong')
      if (!buyAngalong.receiptNumber || !buyAngalong.receiptDetail.lottoName) {
        Swal.fire({
          icon: 'error',
          title: 'An error occurred',
          text: 'No Receipt Detail Set',
        })
      } else {
        const fetchWallet = async () => {
          if (auth.username) {
            const walletFetch = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (walletFetch.code === 0) {
              const credit = _get(walletFetch, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                })
              )
              setWalletValidate(credit)
            } else {
              Swal.fire({
                icon: 'error',
                // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
                // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
                title: 'Error',
                text: 'Please log in to proceed',
                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'เข้าสู่ระบบ',
              })
            }
          }
        }
        fetchWallet().catch(console.error)
      }
    }
  }, [buyAngalong])

  useEffect(() => {
    if (walletValidate > 0) {
      console.log(walletValidate, 'walletValidate')
      console.log(buyAngalong, 'buyAngalong in walletValidate')
      const buyAngalongAmount = buyAngalong.purchaseAmount

      if (walletValidate >= buyAngalongAmount) {
        setIsSuccess(true)
        const confirmReceipt = async () => {
          const receiptNumber = buyAngalong.receiptNumber
          // const lottoName = buyAngalong.receiptDetail.lottoName
          const confirmAngalong = await API.post('purchase', `/receipts/${receiptNumber}/confirm`)
          const path = `/receipts?receiptType=angalong&limit=1`
          API.get('purchase', path).then((res) => {
            const lastReceipt = _.get(res, 'detail', [])[0]
            const receiptStatus = lastReceipt.status
            const tempNumber = lastReceipt.receiptNumber
            setTempReceiptNumber(tempNumber)
            console.log(receiptStatus, lastReceipt.receiptNumber, 'asdf;kjsa;df')
            if (receiptStatus === 'confirm') {
              // Success
              const path = `/receipts?receiptNumber=${tempReceiptNumber}`

              setTimeout(() => {
                API.get('purchase', path).then((res) => {
                  // let sortedReceipts = []
                  const lastReceipt = _.get(res, 'detail', [])[0]
                  const receiptPurchaseAmount = lastReceipt.purchaseAmount
                  const receiptStatus = lastReceipt.status
                  console.log(receiptStatus, lastReceipt.receiptNumber, 'asdf;kjsa;df')
                  if (receiptStatus === 'rewarded' || receiptStatus === 'confirm') {
                    // Success
                    setIsConfirm(true)
                    Swal.fire({
                      // icon: 'error',
                      title: "<h3 style='color:greenyellow'>" + 'Purchase Complete' + '</h3>',
                      text: `Total ${numeral(receiptPurchaseAmount).format('0,0')} Baht`,
                      confirmButtonColor: 'transparent',
                      background: 'transparent',
                      showConfirmButton: false,
                      timer: 1000,
                    })
                  } else {
                    setIsSuccess(false)
                    Swal.fire({
                      icon: 'error',
                      title: 'An error occurred',
                      text: 'Your purchase was unsuccessful',
                    })
                  }
                })
              }, 5000)
            }
          })
          console.log(confirmAngalong)
        }
        const refreshWallet = async () => {
          if (auth.username) {
            const responseWallet = await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
            if (responseWallet.code === 0) {
              const credit = _get(responseWallet, 'detail.credit', 0)
              dispatch(
                setWalletCredit({
                  wallet: {
                    credit,
                  },
                })
              )
            }
          } else {
            // Not login, so cannot purchase
            Swal.fire({
              icon: 'error',
              // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
              // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
              title: 'Error',
              text: 'Please log in to proceed',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              // confirmButtonText: 'เข้าสู่ระบบ',
            })
          }
        }
        confirmReceipt().catch(console.error)
        refreshWallet().catch(console.error)
      } else {
        Swal.fire({
          icon: 'error',
          // title: 'ยอดเงินของคุณไม่เพียงพอ',
          title: 'Insufficient fund',
          // confirmButtonText: 'ฝากเงิน',
          confirmButtonText: 'Deposit',
          //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
          //     '0,0.00'
          //   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
          // }).then((result) => {
          //   if (result.value) {
          //     history.push('/deposit')
          //   }
          text: `Credit (${numeral(walletValidate).format('0,0')} Baht), require amount ${buyAngalongAmount}`,
        }).then((result) => {
          if (result.value) {
            history.push('/deposit')
          }
        })
      }
    }
  }, [walletValidate])

  useEffect(() => {
    if (!_.isEmpty(resultAngalong)) {
      const firstVal = parseInt(resultAngalong['6S'].split('')[0])
      const secondVal = parseInt(resultAngalong['6S'].split('')[1])
      const thirdVal = parseInt(resultAngalong['6S'].split('')[2])
      // setActiveCard(() => [firstVal, secondVal, thirdVal])

      // setRewardCard(() => [firstVal, secondVal, thirdVal])
      setTimeout(() => {
        setRewardCard(() => [firstVal, secondVal, thirdVal])
      }, 6000)

      if (purchaseAmount > 0) {
        // const tempReward = [firstVal, secondVal, thirdVal]
        // let buyOnePrice = 0
        // let buyPairPrice = 0

        // let countBuyOne = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
        // let countBuyPair = {
        //   12: 0,
        //   13: 0,
        //   14: 0,
        //   15: 0,
        //   16: 0,
        //   23: 0,
        //   24: 0,
        //   25: 0,
        //   26: 0,
        //   35: 0,
        //   36: 0,
        //   43: 0,
        //   45: 0,
        //   46: 0,
        //   56: 0,
        // }

        // if (sumBuyOne > 0) {
        //   for (const property in buyOne) {
        //     const countReward = _.countBy(tempReward)[property]
        //     if (countReward > 0) {
        //       countBuyOne[property] = countReward + 1
        //     }
        //   }
        //   for (const property in countBuyOne) {
        //     buyOnePrice += countBuyOne[property] * buyOne[property]
        //   }
        // }

        // if (sumBuyPair > 0) {
        //   for (const property in buyPair) {
        //     const firstNum = parseInt(property.split('')[0])
        //     const secondNum = parseInt(property.split('')[1])
        //     const arrayBuyPair = [firstNum, secondNum]
        //     let allFounded = arrayBuyPair.every((ai) => tempReward.includes(ai))
        //     if (allFounded) {
        //       countBuyPair[property] += 5
        //     }
        //   }
        //   for (const property in countBuyPair) {
        //     if (countBuyPair[property] > 0) {
        //       countBuyPair[property] += 1
        //     }
        //   }
        //   for (const property in buyPair) {
        //     const firstNum = parseInt(property.split('')[0])
        //     const secondNum = parseInt(property.split('')[1])
        //     const arrayBuyPair = [firstNum, secondNum]
        //     let allFounded = arrayBuyPair.every((ai) => tempReward.includes(ai))
        //     if (allFounded) {
        //       buyPairPrice += countBuyPair[property] * buyPair[property]
        //     }
        //   }
        // }
        if (tempReceiptNumber > 0) {
          const path = `/receipts?receiptNumber=${tempReceiptNumber}`
          API.get('purchase', path)
            .then((res) => {
              // let sortedReceipts = []
              const lastReceipt = _.get(res, 'detail', [])[0]
              const lastPrizeAmount = lastReceipt.prizeAmount
              const rewardPrize = lastPrizeAmount - purchaseAmount
              if (isSuccess && isConfirm) {
                if (rewardPrize < 0) {
                  setTimeout(() => {
                    // playLose.muted = false
                    loseButton()
                    Swal.fire({
                      // icon: 'error',
                      title: "<h3 style='color:red'>" + 'You Lose' + '</h3>',
                      text: -rewardPrize + ' Baht',
                      confirmButtonColor: 'transparent',
                      background: 'transparent',
                      showConfirmButton: false,
                      timer: 5000,
                    })
                    // window.dispatchEvent(new KeyboardEvent('keypress', { key: 't' }))
                    // playLose.play()
                  }, 4000)
                }
                if (rewardPrize > 0) {
                  setTimeout(() => {
                    // playWin.muted = false
                    winButton()
                    Swal.fire({
                      // icon: 'error',
                      title: "<h3 style='color:goldenrod'>" + 'YOU WIN !!' + '</h3>',
                      text: rewardPrize + ' BAHT',
                      confirmButtonColor: 'transparent',
                      background: 'transparent',
                      showConfirmButton: false,
                      timer: 5000,
                    })
                    // playWin.play()
                  }, 4000)
                }
                if (purchaseAmount > 0 && rewardPrize === 0) {
                  setTimeout(() => {
                    // playDraw.muted = false
                    drawButton()
                    Swal.fire({
                      // icon: 'error',
                      title: "<h3 style='color:goldenrod'>" + 'DRAW !!' + '</h3>',
                      text: 'Try Again',
                      confirmButtonColor: 'transparent',
                      background: 'transparent',
                      showConfirmButton: false,
                      timer: 5000,
                    })
                    // window.dispatchEvent(new KeyboardEvent('keypress', { key: 'r' }))
                    // playDraw.play()
                  }, 4000)
                }
              }
              setTimeout(() => {
                setIsSuccess(false)
                setIsConfirm(false)
              }, 6500)
            })
            .catch((err) => {
              console.log('error ======', err)
              console.error(err)
            })
        }
      }
    }
  }, [resultAngalong])

  useEffect(() => {
    if (!isFetchHistory) {
      API.get('lotto', `/results?lottoGroup=Angalong&limit=7`)
        .then((res) => {
          const data = res.detail
          const lastSeven = data.map((obj) => {
            const result = {}
            result.lottoName = obj.lottoName.split(' ')[1]
            result.reward = obj.results['6S']
            return result
          })
          console.log(lastSeven, 'data')
          setRewardHistory(lastSeven)
          setIsFetchHistory(true)
        })
        .catch((err) => console.error(err))
    }
  }, [])

  const angalongDice = (cube, place) => {
    // const currentDice = place === 'first' ? activeCard[0] : place === 'second' ? activeCard[1] : activeCard[2]
    // console.log(second, currentDice)
    return (
      // section 2 for cube to ROLL
      // ----------------------

      // size of the rolling dice
      <Dice
        id={cube}
        className={`${place}-dice`}
        size='60'
        faces='123456'
        defaultValue={6}
        cheatValue={6}
        triggers={`${cube === 'cube' ? 'a' : cube === 'cube2' ? 'b' : 'c'}`}
        rollingTime={5000}
      />
    )
  }

  const angalongResult = (cube, place) => {
    const reward = place === 'first' ? 0 : place === 'second' ? 1 : 2
    const tempResult = resultAngalong['6S'] ? resultAngalong['6S'] : '111'
    const resultReward = tempResult.split('')[reward]
    // console.log(resultReward, 'resuljas;ldfasdfasdfas')
    // console.log(second, currentDice)
    return (
      // section 2 for cube to ROLL
      // ----------------------

      // size of the rolling dice
      <Dice
        id={cube}
        className={`${place}-dice`}
        size='60'
        faces='123456'
        defaultValue={6}
        cheatValue={resultReward}
        triggers={`${cube === 'cube' ? 'd' : cube === 'cube2' ? 'e' : 'f'}`}
        rollingTime={2000}
      />
    )
  }

  return (
    <div className='angalong mt-20'>
      <div className='display'>
        {/*<div className='menu'>*/}
        {/*{second >= 50 || second <= 10 ? (*/}
        {/*  <TrafficLight RedOn />*/}
        {/*) : second <= 15 || second >= 45 ? (*/}
        {/*  <TrafficLight YellowOn />*/}
        {/*) : (*/}
        {/*  <TrafficLight GreenOn />*/}
        {/*)}*/}
        {/*</div>*/}
        <div className='left-display'>
          <div className='countdown'>
            <span
              className={`${second <= 15 || second >= 40 ? 'hidden' : ''}`}
              style={second >= 35 ? { color: 'red' } : null}
            >
              {40 - second}
            </span>
            <span
              className={`${39 - second < 0 || second < 4 ? '' : 'hidden'}`}
              style={{ fontFamily: 'system-ui', color: 'blue' }}
            >
              Wait
            </span>
            <span
              className={`${second > 15 || second < 4 ? 'hidden' : ''}`}
              style={{ fontFamily: 'system-ui', color: 'green' }}
            >
              Roll!!
            </span>
            {/*<img*/}
            {/*  className='home__lotto-title-refresh'*/}
            {/*  onClick={() => window.location.reload(false)}*/}
            {/*  src={require('../../assets/refresh.png')}*/}
            {/*  alt='refresh'*/}
            {/*/>*/}
          </div>
          <div className='buy-history'>
            {/* <button
              onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: true, currentModal: 'angalong' }))}
            > */}
            <button>History</button>
          </div>
        </div>
        <div className='random-dice'>
          {/*<div className='dice_container' style={{ transform: `translate3d(0, ${y}px, 0)` }}>*/}
          <div className='dice_container' style={second > 8 ? { display: 'none' } : {}}>
            {angalongDice('cube', 'first')}
            {angalongDice('cube2', 'second')}
            {angalongDice('cube3', 'third')}
          </div>
          {second > 7 ? (
            <div className='dice_container' style={second <= 8 ? { display: 'none' } : {}}>
              {angalongResult('cube', 'first')}
              {angalongResult('cube2', 'second')}
              {angalongResult('cube3', 'third')}
            </div>
          ) : null}

          {/* section 4 for cube to ROLL
          ---------------------- */}
        </div>
        <div className='timer'>
          <div
            onClick={() => {
              // playBubble.muted = false
              playBubble.play()
            }}
          >
            <ModalImage
              className='rule-book'
              small={story_thumbnail}
              large={lang.lang === 'TH' ? story_th : story_mm}
              alt='zodiac-rule'
            />
            <ModalImage
              className='rule-book'
              small={rulebook_img}
              large={lang.lang === 'TH' ? rule_th : rule_mm}
              alt='zodiac-rule'
            />
          </div>
          <div className='credit'>
            <img src={anyChip} alt='anychip'></img>
            <div>
              <span className='px-2'>
                {purchaseAmount > 0 && (second < 10 || second >= 15)
                  ? Math.floor(walletCredit - purchaseAmount)
                  : Math.floor(walletCredit)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*  BOARD 1 */}

      <div className={`board-new ${second <= 15 || second >= 40 ? 'stop' : ''}`}>
        <div className='box1'>
          <div
            className={`box1-inner ${activePair['24'] ? 'price' : ''}`}
            onClick={() => handleBuyAngalong(24, currentChip)}
            alt='angelNaga'
          >
            <img src={angelNaga} />
            <img
              src={activePair['24'] ? anyChip : handleChip('pair', '24')}
              className={`top-side-chip ${buyPair['24'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['24']) || (activePair['24'] && buyPair['24'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['24'] ? buyPair['24'] * 6 : buyPair['24']}
            </span>
          </div>
          <div
            className={`box1-inner ${activePair['13'] ? 'price' : ''}`}
            onClick={() => handleBuyAngalong(13, currentChip)}
            alt='elephantTiger'
          >
            <img src={elephantTiger} />
            <img
              src={activePair['13'] ? anyChip : handleChip('pair', '13')}
              className={`top-side-chip ${buyPair['13'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['13']) || (activePair['13'] && buyPair['13'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['13'] ? buyPair['13'] * 6 : buyPair['13']}
            </span>
          </div>
          <div
            className={`box1-inner ${activePair['15'] ? 'price' : ''}`}
            onClick={() => handleBuyAngalong(15, currentChip)}
            alt='elephantHorse'
          >
            <img src={elephantHorse} />
            <img
              src={activePair['15'] ? anyChip : handleChip('pair', '15')}
              className={`top-side-chip ${buyPair['15'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['15']) || (activePair['15'] && buyPair['15'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['15'] ? buyPair['15'] * 6 : buyPair['15']}
            </span>
          </div>
          <div
            className={`box1-inner ${activePair['16'] ? 'price' : ''}`}
            onClick={() => handleBuyAngalong(16, currentChip)}
            alt='elephantDong'
          >
            <img src={elephantDong} />
            <img
              src={activePair['16'] ? anyChip : handleChip('pair', '16')}
              className={`top-side-chip ${buyPair['16'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['16']) || (activePair['16'] && buyPair['16'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['16'] ? buyPair['16'] * 6 : buyPair['16']}
            </span>
          </div>
        </div>
        <div className='box2-super'>
          <div className='box2'>
            <div
              className={`box2-inner ${activeOne['1'] > 0 ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(1, currentChip)}
              alt='elephant'
            >
              <img src={_.includes(rewardCard, 1) ? elephantCardActive : elephantCard} className='elephant main' />
              <img
                src={activeOne['1'] > 0 ? anyChip : handleChip('one', '1')}
                className={`top-chip ${buyOne['1'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['1']) || activeOne['1'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['1'] * activeOne['1'] > 0 ? buyOne['1'] * activeOne['1'] : buyOne['1'] === 0 ? '' : buyOne['1']}
              </span>
            </div>

            <div
              className={`box2-clicker ${activePair['14'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(14, currentChip)}
              alt='elephantNaga'
            >
              <img
                src={activePair['14'] ? anyChip : handleChip('pair', '14')}
                className={`middle-chip ${buyPair['14'] > 0 ? '' : 'no-chip'}`}
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['14']) || (activePair['14'] && buyPair['14'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['14'] ? buyPair['14'] * 6 : buyPair['14']}
              </span>
            </div>
            <div className={`box2-inner ${activeOne['4'] > 0 ? 'price' : ''}`}>
              <img
                src={_.includes(rewardCard, 4) ? nagaCardActive : nagaCard}
                className='naga main'
                onClick={() => handleBuyAngalong(4, currentChip)}
                alt='naga'
              />
              <img
                src={activeOne['4'] > 0 ? anyChip : handleChip('one', '4')}
                className={`top-chip ${buyOne['4'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['4']) || activeOne['4'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['4'] * activeOne['4'] > 0 ? buyOne['4'] * activeOne['4'] : buyOne['4'] === 0 ? '' : buyOne['4']}
              </span>
            </div>
          </div>
          <div className='box3'>
            <div
              className={`box3-clicker ${activePair['12'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(12, currentChip)}
              alt='Elephant Angel'
            >
              <img
                src={activePair['12'] ? anyChip : handleChip('pair', '12')}
                className={`box3-clicker-chip ${buyPair['12'] > 0 ? '' : 'no-chip'}`}
                alt='box3-clicker-chip'
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['12']) || (activePair['12'] && buyPair['12'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['12'] ? buyPair['12'] * 6 : buyPair['12']}
              </span>
            </div>
            <div className='box3-gap'></div>
            <div
              className={`box3-clicker ${activePair['45'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(45, currentChip)}
              alt='Naga Horse'
            >
              <img
                src={activePair['45'] ? anyChip : handleChip('pair', '45')}
                className={`box3-clicker-chip ${buyPair['45'] > 0 ? '' : 'no-chip'}`}
                alt='box3-clicker-chip'
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['45']) || (activePair['45'] && buyPair['45'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['45'] ? buyPair['45'] * 6 : buyPair['45']}
              </span>
            </div>
          </div>
          <div className='box2'>
            <div className={`box2-inner ${activeOne['2'] > 0 ? 'price' : ''}`}>
              <img
                src={_.includes(rewardCard, 2) ? angelCardActive : angelCard}
                className='angel main'
                onClick={() => handleBuyAngalong(2, currentChip)}
                alt='angel'
              />
              <img
                src={activeOne['2'] > 0 ? anyChip : handleChip('one', '2')}
                className={`top-chip ${buyOne['2'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['2']) || activeOne['2'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['2'] * activeOne['2'] > 0 ? buyOne['2'] * activeOne['2'] : buyOne['2'] === 0 ? '' : buyOne['2']}
              </span>
            </div>
            <div
              className={`box2-clicker ${activePair['25'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(25, currentChip)}
              alt='Angel Horse'
            >
              <img
                src={activePair['25'] ? anyChip : handleChip('pair', '25')}
                className={`middle-chip ${buyPair['25'] > 0 ? '' : 'no-chip'}`}
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['25']) || (activePair['25'] && buyPair['25'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['25'] ? buyPair['25'] * 6 : buyPair['25']}
              </span>
            </div>
            <div className={`box2-inner ${activeOne['5'] > 0 ? 'price' : ''}`}>
              <img
                src={_.includes(rewardCard, 5) ? horseCardActive : horseCard}
                className='horse main'
                onClick={() => handleBuyAngalong(5, currentChip)}
                alt='horse'
              />
              <img
                src={activeOne['5'] > 0 ? anyChip : handleChip('one', '5')}
                className={`top-chip ${buyOne['5'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['5']) || activeOne['5'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['5'] * activeOne['5'] > 0 ? buyOne['5'] * activeOne['5'] : buyOne['5'] === 0 ? '' : buyOne['5']}
              </span>
            </div>
          </div>
          <div className='box3'>
            <div
              className={`box3-clicker ${activePair['23'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(23, currentChip)}
              alt='angel tiger'
            >
              <img
                src={activePair['23'] ? anyChip : handleChip('pair', '23')}
                className={`box3-clicker-chip ${buyPair['23'] > 0 ? '' : 'no-chip'}`}
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['23']) || (activePair['23'] && buyPair['23'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['23'] ? buyPair['23'] * 6 : buyPair['23']}
              </span>
            </div>
            <div className='box3-gap'></div>
            <div
              className={`box3-clicker ${activePair['56'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(56, currentChip)}
              alt='horseDong'
            >
              <img
                src={activePair['56'] ? anyChip : handleChip('pair', '56')}
                className={`box3-clicker-chip ${buyPair['56'] > 0 ? '' : 'no-chip'}`}
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['56']) || (activePair['56'] && buyPair['56'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['56'] ? buyPair['56'] * 6 : buyPair['56']}
              </span>
            </div>
          </div>
          <div className='box2'>
            <div className={`box2-inner ${activeOne['3'] > 0 ? 'price' : ''}`}>
              <img
                src={_.includes(rewardCard, 3) ? tigerCardActive : tigerCard}
                className='tiger main'
                onClick={() => handleBuyAngalong(3, currentChip)}
                alt='tiger'
              />
              <img
                src={activeOne['3'] > 0 ? anyChip : handleChip('one', '3')}
                className={`top-chip ${buyOne['3'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['3']) || activeOne['3'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['3'] * activeOne['3'] > 0 ? buyOne['3'] * activeOne['3'] : buyOne['3'] === 0 ? '' : buyOne['3']}
              </span>
            </div>
            <div
              className={`box2-clicker ${activePair['36'] ? 'price' : ''}`}
              onClick={() => handleBuyAngalong(36, currentChip)}
              alt='Angel Horse'
            >
              <img
                src={activePair['36'] ? anyChip : handleChip('pair', '36')}
                className={`middle-chip ${buyPair['36'] > 0 ? '' : 'no-chip'}`}
              />
              <span
                className={`span-middle-chip ${!_.includes(possibleChip, buyPair['36']) || (activePair['36'] && buyPair['36'] > 0) ? '' : 'no-chip'
                  }`}
              >
                {activePair['36'] ? buyPair['36'] * 6 : buyPair['36']}
              </span>
            </div>
            <div className={`box2-inner ${activeOne['6'] > 0 ? 'price' : ''}`}>
              <img
                src={_.includes(rewardCard, 6) ? dongCardActive : dongCard}
                className='dong main'
                onClick={() => handleBuyAngalong(6, currentChip)}
                alt='dong'
              />
              <img
                src={activeOne['6'] > 0 ? anyChip : handleChip('one', '6')}
                className={`top-chip ${buyOne['6'] > 0 ? '' : 'no-chip'}`}
                alt='top-chip'
              />
              <span
                className={`span-chip ${!_.includes(possibleChip, buyOne['6']) || activeOne['6'] > 0 ? '' : 'no-chip'}`}
              >
                {buyOne['6'] * activeOne['6'] > 0 ? buyOne['6'] * activeOne['6'] : buyOne['6'] === 0 ? '' : buyOne['6']}
              </span>
            </div>
          </div>
        </div>
        <div className='box1'>
          <div className={`box1-inner ${activePair['26'] ? 'price' : ''}`}>
            <img src={angelDong} onClick={() => handleBuyAngalong(26, currentChip)} />
            <img
              src={activePair['26'] ? anyChip : handleChip('pair', '26')}
              className={`top-side-chip ${buyPair['26'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['26']) || (activePair['26'] && buyPair['26'] > 0) ? '' : 'no-chip'
              }`}
            >
              {activePair['26'] ? buyPair['26'] * 6 : buyPair['26']}
            </span>
          </div>
          <div className={`box1-inner ${activePair['43'] ? 'price' : ''}`}>
            <img src={nagaTiger} onClick={() => handleBuyAngalong(43, currentChip)} />
            <img
              src={activePair['43'] ? anyChip : handleChip('pair', '43')}
              className={`top-side-chip ${buyPair['43'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['43']) || (activePair['43'] && buyPair['43'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['43'] ? buyPair['43'] * 6 : buyPair['43']}
            </span>
          </div>
          <div className={`box1-inner ${activePair['46'] ? 'price' : ''}`}>
            <img
              src={nagaDong}
              className='right-first'
              onClick={() => handleBuyAngalong(46, currentChip)}
              alt='right-first'
            />
            <img
              src={activePair['46'] ? anyChip : handleChip('pair', '46')}
              className={`top-side-chip ${buyPair['46'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['46']) || (activePair['46'] && buyPair['46'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['46'] ? buyPair['46'] * 6 : buyPair['46']}
            </span>
          </div>
          <div className={`box1-inner ${activePair['35'] ? 'price' : ''}`}>
            <img
              src={tigerHorse}
              className='right-second'
              onClick={() => handleBuyAngalong(35, currentChip)}
              alt='right-second'
            />
            <img
              src={activePair['35'] ? anyChip : handleChip('pair', '35')}
              className={`top-side-chip ${buyPair['35'] > 0 ? '' : 'no-chip'}`}
              alt='top-side-chip'
            />
            <span
              className={`span-side-chip ${!_.includes(possibleChip, buyPair['35']) || (activePair['35'] && buyPair['35'] > 0) ? '' : 'no-chip'
                }`}
            >
              {activePair['35'] ? buyPair['35'] * 6 : buyPair['35']}
            </span>
          </div>
        </div>
      </div>

      {/* CHIP BOARD */}
      <div className='chip-board'>
        <img
          src={fiveChip}
          className={`five-chip ${currentChip === 5 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(5)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='five-chip'
        />
        <img
          src={tenChip}
          className={`ten-chip ${currentChip === 10 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(10)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='ten-chip'
        />
        <img
          src={twentyChip}
          className={`twenty-chip ${currentChip === 20 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(20)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='twenty-chip'
        />
        <img
          src={fiftyChip}
          className={`fifty-chip ${currentChip === 50 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(50)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='fifty-chip'
        />
        <img
          src={hundredChip}
          className={`hundred-chip ${currentChip === 100 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(100)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='hundred-chip'
        />
        <img
          src={fiveHundredChip}
          className={`five-hundred-chip ${currentChip === 500 ? 'active' : ''}`}
          onClick={() => {
            setCurrentChip(500)
            // playSwitch.muted = false
            playSwitch.play()
          }}
          alt='five-hundred-chip'
        />
      </div>

      {/* CONTRAL PANEL */}
      <div className='control-board'>
        <button className={!isBuy || second <= 15 || second >= 40 ? 'inactive' : ''} onClick={() => resetButton()}>
          Reset
        </button>
        <button
          className={buyHistory.length < 3 || second <= 15 || second >= 40 ? 'inactive' : ''}
          onClick={() => undoButton()}
        >
          Undo
        </button>
        <button className={second <= 15 || second >= 40 ? 'inactive' : ''} onClick={() => luckyButton()}>
          Random
        </button>
        <button className={!isBuy || second <= 15 || second >= 40 ? 'inactive' : ''} onClick={() => multiplyButton()}>
          Double
        </button>
        <button
          className={
            (_.isEmpty(lastBuyOne) && _.isEmpty(lastBuyPair)) || second <= 15 || second >= 40 ? 'inactive' : ''
          }
          onClick={() => followButton()}
        >
          Follow
        </button>
        <button
          className={isAuto ? 'onAuto' : !(_.isEmpty(lastBuyOne) && _.isEmpty(lastBuyPair)) || isBuy ? '' : 'inactive'}
          onClick={() => autoPlayButton()}
        >
          Auto
        </button>
      </div>

      <div className='history'>
        {rewardHistory.map((obj) => (
          <div className='history-col'>
            <span>{obj.lottoName}</span>
            <img
              src={
                obj.reward.slice(0, 1) === '1'
                  ? elephantDice
                  : obj.reward.slice(0, 1) === '2'
                    ? angelDice
                    : obj.reward.slice(0, 1) === '3'
                      ? tigerDice
                      : obj.reward.slice(0, 1) === '4'
                        ? nagaDice
                        : obj.reward.slice(0, 1) === '5'
                          ? horseDice
                          : obj.reward.slice(0, 1) === '6'
                            ? dongDice
                            : anyDice
              }
              alt='rewardHistory'
            />
            <img
              src={
                obj.reward.slice(1, 2) === '1'
                  ? elephantDice
                  : obj.reward.slice(1, 2) === '2'
                    ? angelDice
                    : obj.reward.slice(1, 2) === '3'
                      ? tigerDice
                      : obj.reward.slice(1, 2) === '4'
                        ? nagaDice
                        : obj.reward.slice(1, 2) === '5'
                          ? horseDice
                          : obj.reward.slice(1, 2) === '6'
                            ? dongDice
                            : anyDice
              }
              alt='rewardHistory'
            />
            <img
              src={
                obj.reward.slice(-1) === '1'
                  ? elephantDice
                  : obj.reward.slice(-1) === '2'
                    ? angelDice
                    : obj.reward.slice(-1) === '3'
                      ? tigerDice
                      : obj.reward.slice(-1) === '4'
                        ? nagaDice
                        : obj.reward.slice(-1) === '5'
                          ? horseDice
                          : obj.reward.slice(-1) === '6'
                            ? dongDice
                            : anyDice
              }
              alt='rewardHistory'
            />
          </div>
        ))}
      </div>
      {showModal.currentModal === 'angalong' && showModal.isShow ? (
        <History showModal={showModal} setShowModal={setShowModal} language={lang.lang} allPhoto={allPhoto} />
      ) : null}
    </div>
  )
}

export default Angalong
