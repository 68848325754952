import { SET_LANG } from '../actions/lang'

const INITIAL_STATE = {
  initing: true,
  lang: '',
}

const langReducer = (state = INITIAL_STATE, action) => {
  console.log(action, 'aasdftasdtasdt action')
  switch (action.type) {
    case SET_LANG: {
      const lang = action.payload

      return {
        ...state,
        initing: false,
        lang: lang
      }
    }
    default:
      return state
  }
}

export default langReducer
