import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'
import Modal from 'react-modal'
import Swal from 'sweetalert2'
import _get from 'lodash/get'
import _map from 'lodash/map'
import Header from '../../components/Header/Header'
import { getCognitoProvider } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
import { getLang } from '../../redux/selectors/lang'

import { composePhoneNumber } from '../../utils/phoneNumber'
import Box from '../../components/Box/Box'
import Button from '../../components/Button/Button'
import { mapBankCodeToBankName } from '../../utils/mapBankCodeToBankName'
import { BANK_PAGE } from '../../utils/lottoList'

function Marketing() {
  const history = useHistory()
  const dispatch = useDispatch()
  const cognitoProvider = { provider: getCognitoProvider() }
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: 'register' })
  const [newUser, setNewUser] = useState({ username: '', password: 'a12345678', userConfirmPassword: 'a12345678', newPassword: 'a12345678', accountName: '', accountNumber: '', bankCode: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(false)
  // const [isCollapse, setIsCollapse] = useState(true)
  const auth = useSelector(getAuth)
  const lang = useSelector(getLang)
  const language = lang.lang
  const [isCollapse, setIsCollapse] = useState(true)
  const [isFocus, setIsFocus] = useState(false)

  const handleLogin = async (e, type) => {
    e.preventDefault()
    // console.log(window.location.hash)
    if (type === 'facebook') {
      await Auth.federatedSignIn({ provider: 'Facebook' }).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else if (type === 'line') {
      await Auth.federatedSignIn(cognitoProvider).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
          history.replace('/lottos')
        }
      })
    } else {
      // if (newUser.username.length !== 10 || newUser.password.length < 8) {
      //   return
      // }

      try {
        // const newUsername = newUser.username
        const newUsername = composePhoneNumber('+66', newUser.username)
        await Auth.signIn(newUsername, 'a12345678').then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
            console.log(requiredAttributes)
            const newPassword = 'a12345678'
            Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword // the new password
            )
              .then((res) => {
                // at this time the user is logged in if no MFA required
                console.log(res)
              })
              .catch((e) => {
                console.log(e)
              })
          }
          console.log(user, 'user signIn')
        })
        // console.log(testUser, 'testUserrrrr Home Container')
        history.replace('/callback')
      } catch (e) {
        console.log(e, 'e message')
        if (e.message === 'Incorrect username or password.') {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          })
        } else if (e.message === 'User is not confirmed.') {
          setShowModal({ isShow: true, currentModal: 'verify' })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: e.message || '',
          })
        }
      }
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const status = params.get('status')
    if (status) {
      if (status === 'success') {
        history.replace('/lottos')
      }
      if (status === 'failed') {
        history.replace('/deposit')
        Swal.fire({
          icon: 'error',
          title: 'การฝากเงินไม่สำเร็จ',
        })
      }
    } else {
      const code = params.get('s')
      localStorage.removeItem('marketingCode')
      setShowModal({ isShow: true, currentModal: 'register' })
      setFocus()
      localStorage.setItem('marketingCode', code)
      if (auth.username) {
        history.replace('/')
      } else {
        setShowModal(() => ({ isShow: true, currentModal: 'register' }))
      }
    }
    // Auth.currentAuthenticatedUser()
    //   .then(() => history.replace('/'))
    //   .catch(() =>
    //     Auth.federatedSignIn({
    //       customState: code,
    //     })
    //   )
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '0',
      right: '0',
      bottom: 'auto',
      transform: 'translateY(-50%)',
      maxWidth: '550px',
      margin: '0 auto',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      overflow: 'inherit',
    },
  }
  const handleLoginFacebook = async () => {
    await Auth.federatedSignIn({ provider: 'Facebook' })
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLoginLine = async () => {
    await Auth.federatedSignIn(cognitoProvider)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const validateAll = () => {
    console.log(newUser, 'newUser')
    if (!newUser.username || !newUser.accountName || !newUser.accountNumber || !newUser.bankCode) {
      return false
    }

    // if (newUser.password !== newUser.userConfirmPassword) {
    //   return false
    // }

    return true
  }

  const _onChangeForm = (e, inputName) => {
    const value = e.target.value

    // if (inputName === 'username') {
    //   const newValue = value.replace(/[^0-9]/g, '')
    //   setNewUser({ ...newUser, [inputName]: newValue })
    //   return
    // }

    setNewUser({ ...newUser, [inputName]: value })
  }

  const _handlePhoneNumber = () => {
    if (newUser.username.length !== 10) {
      Swal.fire({
        icon: 'error',
        title: 'Please enter a valid 10-digit mobile phone number.',
      })
      return
    }
    if (newUser.password.length < 8) {
      Swal.fire({
        icon: 'error',
        // title: 'รหัสผ่านของท่านมีความยาวไม่ถึง 8 ตัวอักษรครับ',
        title: 'Password shall be more than 8 characters',
      })
      return
    }
    setConfirmPassword(true)
  }

  const handleSignUp = async () => {
    const isValidForm = validateAll()
    if (!isValidForm) {
      return Swal.fire({
        icon: 'error',
        // title: 'เกิดข้อผิดพลาด',
        // text: 'รหัสผ่านและยืนยันรหัสผ่านของท่านไม่ตรงกันครับ',
        title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
        text: language !== 'TH' ? 'ပါမှန်ရိုက်ပါ' : 'กรุณากรอกข้อมูลให้ครบครับ',
      })
    } else {
      try {
        const newUsername = composePhoneNumber('+66', newUser.username)
        const newPassword = 'a12345678'
        const bankData = { accountName: newUser.accountName, accountNumber: newUser.accountNumber, bankCode: newUser.bankCode }
        // const newUsername = newUser.username.split('@')[0]
        await Auth.signUp({
          username: newUsername,
          password: newPassword,
          attributes: {
            name: newUsername,
            phone_number: newUsername,
          },
        }).then((res) => {
          console.log(res, 'res user signUp')
        })
        setShowModal((prevState) => ({ ...prevState, isShow: false, currentModal: '' }))
        handleLoginAndAddBank(bankData)
      } catch (e) {
        console.error(e)
        if (e.message === 'User already exists') {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            // text: 'บัญชีนี้ซ้ำกับบัญชีที่มีอยู่ในระบบครับ',
            title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
            text: language !== 'TH' ? 'ဤဖုန်းနံပါတ်ကျအသုံးဝင်ပြီ' : 'เบอร์นี้เคยมีผู้สมัครไปแล้วครับผม',
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
            text: e.message || '',
          })
        }
      }
    }
  }

  const handleLoginAndAddBank = async (bankInfo) => {
    // e.preventDefault()
    // console.log(window.location.hash)
    try {
      // const newUsername = newUser.username
      const newUsername = composePhoneNumber('+66', newUser.username)
      const newPassword = 'a12345678'
      await Auth.signIn(newUsername, newPassword).then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
          console.log(requiredAttributes)
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword, // the new password
          )
            .then((res) => {
              // at this time the user is logged in if no MFA required
              console.log(res, 'res in handleLoginAndAddBank')
             
            })
            .catch((e) => {
              console.log(e)
            })
        }
        console.log(user, 'user signIn')
        const username = user.username
        setIsLoading(true)
        const payload = {
          accountName: bankInfo.accountName,
          bankCode: bankInfo.bankCode,
          bankName: mapBankCodeToBankName[bankInfo.bankCode],
          accountNumber: bankInfo.accountNumber,
        }
        API.post('playerBankAccount', `/players/${username}/request`, {
          body: payload,
        })
          .then((res) => {
            const resBankAccount = _get(res, 'detail', null)
            if (resBankAccount) {
              setIsLoading(false)
              return Swal.fire({
                icon: 'success',
                // title: 'เพิ่มบัญชีธนาคารสำเร็จ',
                // text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
                title:
                  language !== 'TH' ? 'သင့်အကောင့်သည်အျင်အားလုပ်ဆောင်ပြီးနောက်တစ်ဆင်ရေ' : 'สร้างบัญชีเรียบร้อยแล้วครับ',
                // text: language !== 'TH' ? 'သင်၏စကားဝှက်ကို a12345678' : 'รหัสผ่านของท่านคือ a12345678',
              })
            } else {
              // throw new Error('ระบบผิดพลาด ไม่สามารถเพิ่มบัญชีธนาคารได้ กรุณาติดต่อเจ้าหน้าที่')
              throw new Error('System error can not add your bank account. Please contact admin.')
            }
          })
          .catch((err) => {
            setIsLoading(false)
            console.log('error add bank account ======')
            console.error(err)
            Swal.fire({
              icon: 'error',
              // title: 'เกิดข้อผิดพลาด',
              title: language !== 'TH' ? 'အမှားအတည်ပညာ' : 'เกิดข้อผิดพลาด',
              text: err.message || '',
            })
          })
          .finally(() => {
            setFocus()
            setIsLoading(false)
            history.replace('/callback')
          })
      })
      // console.log(testUser, 'testUserrrrr Home Container')
    } catch (err) {
      console.log(err, 'e message')
      if (err.message === 'Incorrect username or password.') {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
          // title: 'Error',
          // text: 'Telephone number or password was incorrect',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          // title: 'Error',

          text: err.message || '',
        })
      }
    }
  }

  const setFocus = () => {
    const page = document.getElementsByClassName('main-navigator')[0]
    if (!isFocus && page) {
      setIsFocus(true)
      page.classList.add('disabled')
      document.body.classList.add('block-scroll')
    } else {
      setIsFocus(false)
      page.classList.remove('disabled')
      document.body.classList.remove('block-scroll')
    }
  }

  return (
    <Modal
    isOpen={showModal.currentModal === 'register'}
    onRequestClose={() => {
      setShowModal((prevState) => ({ ...prevState, isShow: false }))
    }}
    style={customStyles}
    contentLabel='Register Modal'
    >
    <Box withShine={false} withFlare={false} containerClass='contactModal__box-wrapper'>
      <div className='contactModal__box-wrapper reg-header'>
        {' '}
        {language !== 'TH' ? 'အကောင့်အသီးစား' : 'สมัครแบบรวดเร็ว'}
      </div>
      <div className='loginModal__field-top loginModal__flex_text mb-4'>
        <div className='loginModal__register-register loginModal__register-color_dong-red'>
          {language !== 'TH' ? 'ဖုန်းနံပါတ်' : 'เบอร์โทร'}
        </div>
        {/*<input className='loginModal__field' id={1} type='text' placeholder={'เบอร์มือถือ เช่น 0812344567'} />*/}
        <input
          className='loginModal__field'
          placeholder={language !== 'TH' ? 'ဥပမာ 0891234567' : 'เช่น 0891234567'}
          onChange={(e) => _onChangeForm(e, 'username')}
          type='text'
          id='current-username'
          value={newUser.username}
        />
        {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
      </div>

      <hr />
      {/* <div style={{ margin: '20px' }}>
        {/* <p>*กรุณากรอกเบอร์โทรศัพท์มือถือจริงของท่านเพื่อใช้ใน การรับ OTP กรณีที่ลืมรหัสผ่าน</p> */}
      {/* <p style={{ textAlign: 'center', fontSize: '12px', color: '#888888' }}>
        {language !== 'TH' ? 'Please input your telephone number.' : 'กรุณาใส่เบอร์โทรที่ท่านใช้'} <br/>
        {language !== 'TH' ? 'The OTP verification will be applied if you want to change password.' : 'สำหรับการขอ OTP หากท่านต้องการเปลี่ยนรหัสผ่าน'}
        </p>
      </div> */}

      <div className='loginModal__field-top loginModal__flex_text'>
        {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
        <div className="bankAndLogo">
          <select
            name='bank'
            id='bank-selection'
            value={newUser.bankCode || ''}
            onChange={(e) => _onChangeForm(e, 'bankCode')}
          >
            {/* <option value=''>กรุณาเลือกธนาคาร</option> */}
            <option
              value=''
            >   {language === 'MM' ? 'ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။' : BANK_PAGE['ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။']}</option>
            {_map(mapBankCodeToBankName, (bankName, bankCode) => {
              return <option value={bankCode}>{language === 'MM' ? BANK_PAGE[bankName] : bankName}</option>
            })}
          </select>
          {newUser.bankCode && newUser.bankCode !== '' ? (
            <img src={require(`../../assets/bank-logo/${newUser.bankCode}.png`)} alt='bank-user-logo' />
          ) : null}
        </div>
        {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
      </div>

      <div className='loginModal__field-top loginModal__flex_text'>
        <div className='loginModal__register-register loginModal__register-color_dong-red'>{language !== 'TH' ? 'အကောင့်နာမည်' : 'ชื่อบัญชี'}</div>
        {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
        <input
          className='loginModal__field'
          onChange={(e) => _onChangeForm(e, 'accountName')}
          // placeholder='รหัสผ่านความยาวอย่างน้อย 8 ตัวอักษร'
          placeholder={language !== 'TH' ? 'account name' : 'ชื่อบัญชีของท่าน'}
          type='text'
          id='account-name'
          // autoComplete='current-password'
          value={newUser.accountName}
        />
        {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
      </div>

      <div className='loginModal__field-top loginModal__flex_text'>
        <div className='loginModal__register-register loginModal__register-color_dong-red'>{language !== 'TH' ? 'အကောင့်နံပါတ်' : 'เลขบัญชี'}</div>
        {/*<input className='loginModal__field' id={2} type='text' placeholder={'รหัสผ่านอย่างน้อย 8 ตัวอักษร'} />*/}
        <input
          className='loginModal__field'
          onChange={(e) => _onChangeForm(e, 'accountNumber')}
          // placeholder='รหัสผ่านความยาวอย่างน้อย 8 ตัวอักษร'
          placeholder={language !== 'TH' ? 'နံပါတ်သားသိမ်းနိုင်ပါ' : 'ระบุเฉพาะตัวเลข'}
          type='number'
          id='account-number'
          // autoComplete='current-password'
          value={newUser.accountNumber}
        />
        {/* <div className='loginModal__register-register loginModal__register-color_blue' onClick={() => { setIsCollapse(true); setRegisterModal(!registerModal) }}>สมัครสมาชิก</div> */}
      </div>


      <div className='loginModal__flex_button'>
        <Button
          onButtonClick={handleSignUp}
          wrapperClassName='loginModal__close-wrapper'
          buttonClassName={`loginModal__login-button ${newUser.username.length < 10 || newUser.bankCode === '' || newUser.accountName === '' || newUser.accountNumber === '' ? 'disabled' : ''}`}
          text={language !== 'TH' ? 'စာရင်းဖွင့်' : 'สมัคร'}
          style={{ color: 'white' }}
        />
      </div>
      <div>
            <hr className='loginModal__divider' data-content='divider' />
          </div>
          {showModal.currentModal !== 'forget' ? (
            <div className='loginModal__text-wrapper mt-6'>
              <div className='loginModal__bottom-wrapper'>
                {/* <div
                  className='loginModal__contact loginModal__contact-facebook '
                  style={{ padding: '5px', marginBottom: '10px' }}
                >
                  <a onClick={handleLoginFacebook} style={{ display: 'flex', gap: '10px' }}>
                    <img style={{ width: '30px', height: '30px' }} src={require('./assets/facebook-icon.png')} />
                    <p style={{ color: '#000000' }}>{language !== 'TH' ? 'Log in with' : 'เข้าสู่ระบบด้วย'}</p>
                    <p style={{ color: '#4267B2' }}>FB</p>
                  </a>
                </div> */}
                <div className='loginModal__contact loginModal__contact-line ' style={{ padding: '5px' }}>
                  <a onClick={handleLoginLine} style={{ display: 'flex', gap: '10px' }}>
                    <img style={{ width: '30px', height: '30px' }} src={require('../../assets/line-icon.png')} />
                    <p style={{ color: '#000000' }}>{language !== 'TH' ? 'လော့ဂ်အင်ဝင်ရန်' : 'เข้าสู่ระบบด้วย'}</p>
                    <p style={{ color: '#00B900' }}>LINE</p>
                  </a>
                </div>
              </div>
            </div>
          ) : null}
    </Box>
  </Modal>
   )
}

export default Marketing
