import thailand from '../assets/flags/thailand.png'
import vietnam from '../assets/flags/vietnam.png'
import laos from '../assets/flags/laos.png'
import malaysia from '../assets/flags/malaysia.png'
import america from '../assets/flags/america.png'
import japan from '../assets/flags/japan.png'
import china from '../assets/flags/china.png'
import hongkong from '../assets/flags/hongkong.png'
import taiwan from '../assets/flags/taiwan.png'
import korea from '../assets/flags/korea.png'
import singapore from '../assets/flags/singapore.png'
import india from '../assets/flags/india.png'
import egypt from '../assets/flags/egypt.png'
import russia from '../assets/flags/russia.png'
import england from '../assets/flags/england.png'
import german from '../assets/flags/german.png'
import gsb from '../assets/flags/gsb.png'
import tks from '../assets/flags/tks.png'

export const LOTTO_LIST = [
  { title: 'ฮานอยเฉพาะกิจ', src: vietnam },
  { title: 'ฮานอย พิเศษ', src: vietnam },
  { title: 'ฮานอย', src: vietnam },
  { title: 'ฮานอย สตาร์', src: vietnam },
  { title: 'เวียดนาม พิเศษ', src: vietnam },
  { title: 'เวียดนาม', src: vietnam },
  { title: 'ฮานอย VIP', src: vietnam },
  { title: 'ฮานอย HD', src: vietnam },
  { title: 'ฮานอย TV', src: vietnam },
  { title: 'ฮานอย กาชาด', src: vietnam },
  { title: 'มาเลเซีย', src: malaysia },
  { title: 'ลาว', src: laos },
  { title: 'ลาว สตาร์', src: laos },
  { title: 'ลาว จำปาศักดิ์', src: laos },
  { title: 'ลาว EXTRA', src: laos },
  { title: 'ลาว HD', src: laos },
  { title: 'ลาว TV', src: laos },
  { title: 'ลาว VIP', src: laos },
  { title: 'ลาว กาชาด', src: laos },
  { title: 'ลาวดิจิตอล', src: laos },
  { title: 'ดาวโจนส์ VIP', src: america },
  { title: 'ดาวโจนส์', src: america },
  { title: 'นิเคอิ รอบเช้า', src: japan },
  { title: 'ไทย รอบเช้า', src: thailand },
  { title: 'ไทย รอบเช้าตลาด', src: thailand },
  { title: 'จีน รอบเช้า', src: china },
  { title: 'ฮั่งเส็ง รอบเช้า', src: hongkong },
  { title: 'ไทยเที่ยง', src: thailand },
  { title: 'ไต้หวัน', src: taiwan },
  { title: 'นิเคอิ รอบบ่าย', src: japan },
  { title: 'เกาหลี', src: korea },
  { title: 'จีน รอบบ่าย', src: china },
  { title: 'ไทย รอบบ่าย', src: thailand },
  { title: 'ฮั่งเส็ง รอบบ่าย', src: hongkong },
  { title: 'ไทย รอบบ่ายตลาด', src: thailand },
  { title: 'สิงคโปร์', src: singapore },
  { title: 'ไทย เย็น', src: thailand },
  { title: 'อินเดีย', src: india },
  { title: 'อียิปต์', src: egypt },
  { title: 'ไทยกลางคืน', src: thailand },
  { title: 'รัสเซีย', src: russia },
  { title: 'อังกฤษ', src: england },
  { title: 'เยอรมัน', src: german },
  { title: 'หวยรัฐบาลไทย', src: thailand },
  { title: 'หวยออมสิน', src: gsb },
  { title: 'หวยธกส.', src: tks },
  { title: 'นิเคอิ(เช้า) VIP', src: japan },
  { title: 'จีน(เช้า) VIP', src: china },
  { title: 'ฮั่งเส็ง(เช้า) VIP', src: hongkong },
  { title: 'ไต้หวัน VIP', src: taiwan },
  { title: 'นิเคอิ(บ่าย) VIP', src: japan },
  { title: 'เกาหลี VIP', src: korea },
  { title: 'จีน(บ่าย) VIP', src: china },
  { title: 'ฮั่งเส็ง(บ่าย) VIP', src: hongkong },
  { title: 'สิงคโปร์ VIP', src: singapore },
  { title: 'รัสเซีย VIP', src: russia },
  { title: 'อังกฤษ VIP', src: england },
  { title: 'เยอรมัน VIP', src: german },
]

export const LOTTO_ENG = {
  ฮานอย: 'Hanoi',
  'ฮานอย เที่ยง': 'Hanoi Noon',
  'เวียดนาม พิเศษ': 'Vietnam Special',
  เวียดนาม: 'Vietnam',
  'เวียดนาม VIP': 'Vietnam VIP',
  'ฮานอย พิเศษ': 'Hanoi Special',
  'ฮานอย VIP': 'Hanoi VIP',
  'ฮานอย HD': 'Hanoi HD',
  'ฮานอย TV': 'Hanoi TV',
  'ฮานอย กาชาด': 'Hanoi Kachart',
  'ฮานอย EXTRA': 'Hanoi EXTRA',
  'ฮานอย รอบดึก': 'Hanoi Night',
  'ฮานอย สตาร์': 'Hanoi Star',
  มาเลเซีย: 'Malaysia',
  'ลาว พัฒนา': 'Laos PATTANA',
  'ลาว สตาร์': 'Laos STAR',
  'ลาวสตาร์ VIP': 'Laos STAR VIP',
  'ลาว จำปาศักดิ์': 'Laos Champasak',
  'ลาว HD': 'Laos HD',
  ลาวดาว: 'Laos DAO',
  'ลาว TV': 'Laos TV',
  'ลาว VIP': 'Laos VIP',
  'ลาว กาชาด': 'Laos Kachart',
  ลาวดิจิตอล: 'Laos Digital',
  'ลาว เวียงจันทร์': 'Laos Vientiane',
  'ลาว สามัคคี': 'Laos SAMAKEE',
  'ดาวโจนส์ VIP': 'Dow Jones VIP',
  ดาวโจนส์: 'Dow Jones',
  'นิเคอิ รอบเช้า': 'Nikkei Morning',
  'ไทย รอบเช้า': 'SET Morning',
  'ไทย รอบเช้าตลาด': 'SET Morning Market',
  'จีน รอบเช้า': 'China Morning',
  'ฮั่งเส็ง รอบเช้า': 'Hang Seng Morning',
  ไทยเที่ยง: 'SET Noon',
  ไต้หวัน: 'Taiwan',
  'นิเคอิ รอบบ่าย': 'Nikkei Afternoon',
  เกาหลี: 'Korea',
  'จีน รอบบ่าย': 'China Afternoon',
  'ไทย รอบบ่าย': 'SET Afternoon',
  'ฮั่งเส็ง รอบบ่าย': 'Hang Seng Afternoon',
  'ไทย รอบบ่ายตลาด': 'SET Afternoon',
  สิงคโปร์: 'Singapore',
  'ไทย เย็น': 'SET Close',
  อินเดีย: 'India',
  อียิปต์: 'Egypt',
  ไทยกลางคืน: 'Thai Night',
  รัสเซีย: 'Russia',
  อังกฤษ: 'England',
  เยอรมัน: 'Germany',
  หวยรัฐบาลไทย: 'Thai Lottery',
  หวยออมสิน: 'GSB Lottery',
  'หวยธกส.': 'BAAC Lottery',
  'นิเคอิ(เช้า) VIP': 'Nikkei Morning VIP',
  'จีน(เช้า) VIP': 'China Morning VIP',
  'ฮั่งเส็ง(เช้า) VIP': 'Hang Seng Morning VIP',
  'ไต้หวัน VIP': 'Taiwan VIP',
  'นิเคอิ(บ่าย) VIP': 'Nikkei Afternoon VIP',
  'เกาหลี VIP': 'Korea VIP',
  'จีน(บ่าย) VIP': 'China Afternoon VIP',
  'ฮั่งเส็ง(บ่าย) VIP': 'Hang Seng Afternoon VIP',
  'สิงคโปร์ VIP': 'Singapore VIP',
  'รัสเซีย VIP': 'Russia VIP',
  'อังกฤษ VIP': 'England VIP',
  'เยอรมัน VIP': 'Germany VIP',
  '2D รอบเช้า': '2D Morning',
  '2D รอบบ่าย': '2D Close',
  '2D รอบเย็น': '2D Close'
}

export const LOTTO_GROUP_ENG = {
  หวยแนะนำ: 'หวยแนะนำ',
  หวยไทย: 'หวยไทย',
  หวยหุ้น: 'หวยหุ้น',
  หวยยี่กี: 'ยี่กี',
  หวยรายวัน: 'หวยรายวัน',
  หวยต่างประเทศ: 'หวยต่างประเทศ',
  หวยวีไอพี: 'หวยวีไอพี',
}

export const LOTTO_GROUP_TH = {
  หวยแนะนำ: 'หวยแนะนำ',
  หวยไทย: 'หวยไทย',
  หวยหุ้น: 'หวยหุ้น',
  หวยยี่กี: 'ยี่กี',
  หวยรายวัน: 'หวยรายวัน',
  หวยต่างประเทศ: 'หวยต่างประเทศ',
  หวยวีไอพี: 'หวยวีไอพี',
}

export const lottoType = {
  '2A': '၂လုံးပေါ်',
  '2B': '၂လုံးအောက်',
  '3A': '၃ လုံးပေါ်',
  '3R': 'တွတ် 3လုံး',
  '3F': 'ရှေ့၃လုံး',
  '3B': '၃လုံးအောက်',
  SA: 'အပေါ်ပြေး',
  SB: 'အောက်ပြေး',
}

export const lottoType_TH = {
  '2A': '2 ตัวบน',
  '2B': '2 ตัวล่าง',
  '3A': '3 ตัวบน',
  '3R': '3 ตัวโต๊ด',
  '3F': '3 ตัวหน้า',
  '3B': '3 ตัวล่าง',
  SA: 'วิ่งบน',
  SB: 'วิ่งล่าง',
}

export const POST_PAGE = {
  'หวยออมสิน': 'GSB',
  'หวยธกส.': 'BAAC',
  'แทงหวย': 'ထီထိုး',
  'หวยหุ้น': 'စတော့ထီ',
  'หวยไทย': 'ထိုင်းထီ',
  'ไทย': 'ထိုင်း',
  'แทง': 'ထိုး',
  'อียิปต์': 'အီဂျစ်',
  'ปิดรับ': 'ပိတ်သည်',
  'กรุณาเข้าสู่ระบบ': 'ကျေးဇူးပြု၍ ဝင်ပါ',
  'รัสเซีย': 'ရုရှား',
  'เยอรมัน': 'ဂျာမန်',
  'อังกฤษ': 'အင်္ဂလိပ်',
  'ดาวโจนส์': 'ဒေါင်းဂျုံး',
  'นิเคอิ รอบเช้า': 'နိခေး မနက်',
  'จีน รอบเช้า': 'တရုတ် မနက်',
  'ฮั่งเส็ง รอบเช้า': 'ဟိုင်ဆိုင်း မနက်',
  'ไต้หวัน': 'ထိုင်ဝမ်',
  'นิเคอิ รอบบ่าย': 'နိခေး နေ့လည်',
  'เกาหลี': 'ကိုးလီးယား',
  'จีน รอบบ่าย': 'တရုတ် နေ့လည်',
  'ฮั่งเส็ง รอบบ่าย': 'ဟိုက်ဆိုင်း နေ့လည်',
  'สิงคโปร์': 'စင်္ကာပူ',
  'ไทย เย็น': 'ထိုင်းယန်း',
  'อินเดีย': 'အိန္ဒိယ',
  'หวยรายวัน': 'ထီနေ့တိုင်း ရလဒ်',
  'ลาว สามัคคี': 'လာအိုညီညွတ်ရေးထီ',
  'ลาว VIP': 'လာအို VIP',
  'ลาวสตาร์ VIP': 'လာအိုစတား VIP',
  'ฮานอย รอบดึก': 'ဟနွိုင်း ည',
  'ฮานอย EXTRA': 'ဟနွိုင်း EXTRA',
  'ลาว เวียงจันทร์': 'လာအိုထီ ဗီယင်ကျန်း',
  'ลาว กาชาด': 'လာအိုကြက်ခြေနီထီ',
  'ลาวดาว': 'လာအို ကြယ်ထီ',
  'ดาวโจนส์ VIP': 'ဒေါင်းဂျုံး VIP',
  'ลาว EXTRA': 'လာအို EXTRA',
  'ลาว TV': 'လာအို TV',
  'ฮานอย HD': 'ဟနွိုင်း HD',
  'ฮานอยสตาร์': 'ဟနွိုင်း စတား',
  'ลาว HD': 'လာအို HD',
  'ฮานอย TV': 'ဟနွိုင်း TV',
  'ลาว จำปาศักดิ์': 'လာအိုထီ ကျန်ပါစပ်',
  'ลาว สตาร์': 'လာအို စတား',
  'ฮานอย กาชาด': 'ဟနွိုင်းကြက်ခြေနီထီ',
  'หวยวีไอพี': 'VIP ထီ',
  'อังกฤษ VIP': 'အင်္ဂလန် VIP',
  'เยอรมัน VIP': 'ဂျာမန် VIP',
  'รัสเซีย VIP': 'ရုရှား VIP',
  'นิเคอิ(เช้า) VIP': 'နိခေး (မနက်)VIP',
  'จีน(เช้า) VIP': 'တရုတ် (မနက်)VIP',
  'ฮั่งเส็ง(เช้า) VIP': 'ဟန်ဆိုင်း(မနက်)VIP',
  'ไต้หวัน VIP': 'ထိုင်ဝမ် VIP',
  'เกาหลี VIP': 'ကိုရီးယား VIP',
  'นิเคอิ(บ่าย) VIP': 'နိခေး (နေ့လည်) VIP',
  'จีน(บ่าย) VIP': 'တရုတ် (နေ့လည်) VIP',
  'ฮั่งเส็ง(บ่าย) VIP': 'ဟန်ဆိုင်း (နေ့လည်) VIP',
  'สิงคโปร์ VIP': 'စင်္ကာပူ VIP',
  'หวยต่างประเทศ': 'နိုင်ငံခြားထီ',
  'ฮานอย': 'ဟနွိုင်း',
  'ฮานอย VIP': 'ဟနွိုင်း VIP',
  'ฮานอย พิเศษ': 'ဟနွိုင်း  အထူး',
  'หวยยี่กี': 'ရီကီထီ',
  'มั่งมียี่กี': 'ချမ်းသာတဲ့ရီကီထီ',
  'ผลการออกรางวัล': 'ထီဆုမဲရလဒ်များ',
  'หวยรัฐบาลไทย': 'ထိုင်းအစိုးရထီ',
  'รางวัลที่ 1': 'ပထမဆု',
  '3 ตัวหน้า': 'ရှေ့၃လုံး',
  '2 ตัวล่าง': 'အောက်၂လုံး',
  '3 ตัวท้าย': 'နောက်၃လုံး',
  'Dong ยี่กี่': 'Dong ရီကီထီ',
  'รอบที่': 'အဝိုင်း',
  '3 ตัวบน': 'အပါ် ၃လုံး',
  '2 ตัวบน': 'အပါ်၂လုံး',
  'เล่นรอบนี้': 'ဒီနေ့စတော့ထီ',
  'หวยหุ้นวันนี้': 'စတော့ထီ',
  'หุ้น': '2Dထီ',
  'หวย 2D': '2Dထီ',
  'หวยหุ้นดาวโจนส์': 'ဒေါင်းဂျုံးစတော့ထီ',
  'หวยหุ้นนิเคอิ รอบเช้า': 'နိခေးစတော့ထီ (မနက်)',
  'หวยหุ้นจีน รอบเช้า': 'တရုတ်စတော့ထီ (မနက်)',
  'หวยหุ้นฮั่งเส็ง รอบเช้า': 'ဟန်ဆိုင်းစတော့ထီ (မနက်)',
  'หวยหุ้นไต้หวัน': 'ထိုင်ဝမ်စတော့ထီ',
  'หวยหุ้นเกาหลี': 'ကိုးရီးယားစတော့ထီ',
  'หวยหุ้นอังกฤษ': 'အင်္ဂလန်စတော့ထီ',
  'หวยหุ้นเยอรมัน': 'ဂျာမန်စတော့ထီ',
  'หวยหุ้นรัสเซีย': 'ရုရှားစတော့ထီ',
  'หวยหุ้นนิเคอิ รอบบ่าย': 'နိခေးစတော့ထီ (နေ့လည်)',
  'หวยหุ้นจีน รอบบ่าย': 'တရုတ်စတော့ထီ (နေ့လည်)',
  'หวยหุ้นฮั่งเส็ง รอบบ่าย': 'ဟန်ဆိုင်းစတော့ထီ (နေ့လည်)',
  'หวยหุ้นสิงคโปร์': 'စင်္ကာပူ စတော့ထီ',
  'หวยหุ้นอินเดีย': 'အိန္ဒိယစတော့ထီ',
  '2D รอบเช้า': '2D Morning',
  '2D รอบเย็น': '2D Close',
  '3D รัฐบาลไทย': '3D Thai'
}

export const LOTTO_PAGE = {
  'ထီထိုး': 'แทงหวย',
  'ထိုင်းထီ': 'หวยไทย',
  'ထိုင်း': 'ไทย',
  'ထိုး': 'แทง',
  'အီဂျစ်': 'อียิปต์',
  'ပိတ်သည်': 'ปิดรับ',
  'ကျေးဇူးပြု၍ ဝင်ပါ': 'กรุณาเข้าสู่ระบบ',
  'ရုရှား': 'รัสเซีย',
  'ဂျာမန်': 'เยอรมัน',
  'အင်္ဂလိပ်': 'อังกฤษ',
  'ဒေါင်းဂျုံး': 'ดาวโจนส์',
  'နိခေး မနက်': 'นิเคอิ รอบเช้า',
  'တရုတ် မနက်': 'จีน รอบเช้า',
  'ဟိုင်ဆိုင်း မနက်': 'ฮั่งเส็ง รอบเช้า',
  'ထိုင်ဝမ်': 'ไต้หวัน',
  'နိခေး နေ့လည်': 'นิเคอิ รอบบ่าย',
  'ကိုးလီးယား': 'เกาหลี',
  'တရုတ် နေ့လည်': 'จีน รอบบ่าย',
  'ဟိုက်ဆိုင်း နေ့လည်': 'ฮั่งเส็ง รอบบ่าย',
  'စင်္ကာပူ': 'สิงคโปร์',
  'ထိုင်းယန်း': 'ไทย เย็น',
  'အိန္ဒိယ': 'อินเดีย',
  'ထီနေ့တိုင်း ရလဒ်': 'หวยรายวัน',
  'လာအိုညီညွတ်ရေးထီ': 'ลาว สามัคคี',
  'လာအို VIP': 'ลาว VIP',
  'လာအိုစတား VIP': 'ลาวสตาร์ VIP',
  'ဟနွိုင်း ည': 'ฮานอย รอบดึก',
  'ဟနွိုင်း EXTRA': 'ฮานอย EXTRA',
  'လာအိုထီ ဗီယင်ကျန်း': 'ลาว เวียงจันทร์',
  'လာအိုကြက်ခြေနီထီ': 'ลาว กาชาด',
  'လာအို ကြယ်ထီ': 'ลาวดาว',
  'ဒေါင်းဂျုံး VIP': 'ดาวโจนส์ VIP',
  'လာအို EXTRA': 'ลาว EXTRA',
  'လာအို TV': 'ลาว TV',
  'ဟနွိုင်း HD': 'ฮานอย HD',
  'ဟနွိုင်း စတား': 'ฮานอยสตาร์',
  'လာအို HD': 'ลาว HD',
  'ဟနွိုင်း TV': 'ฮานอย TV',
  'လာအိုထီ ကျန်ပါစပ်': 'ลาว จำปาศักดิ์',
  'လာအို စတား': 'ลาว สตาร์',
  'ဟနွိုင်းကြက်ခြေနီထီ': 'ฮานอย กาชาด',
  'VIP ထီ': 'หวยวีไอพี',
  'အင်္ဂလန် VIP': 'อังกฤษ VIP',
  'ဂျာမန် VIP': 'เยอรมัน VIP',
  'ရုရှား VIP': 'รัสเซีย VIP',
  'နိခေး (မနက်)VIP': 'นิเคอิ(เช้า) VIP',
  'တရုတ် (မနက်)VIP': 'จีน(เช้า) VIP',
  'ဟန်ဆိုင်း(မနက်)VIP': 'ฮั่งเส็ง(เช้า) VIP',
  'ထိုင်ဝမ် VIP': 'ไต้หวัน VIP',
  'ကိုရီးယား VIP': 'เกาหลี VIP',
  'နိခေး (နေ့လည်) VIP': 'นิเคอิ(บ่าย) VIP',
  'တရုတ် (နေ့လည်) VIP': 'จีน(บ่าย) VIP',
  'ဟန်ဆိုင်း (နေ့လည်) VIP': 'ฮั่งเส็ง(บ่าย) VIP',
  'စင်္ကာပူ VIP': 'สิงคโปร์ VIP',
  'နိုင်ငံခြားထီ': 'หวยต่างประเทศ',
  'ဟနွိုင်း': 'ฮานอย',
  'ဟနွိုင်း VIP': 'ฮานอย VIP',
  'ဟနွိုင်း  အထူး': 'ฮานอย พิเศษ',
  'ရီကီထီ': 'หวยยี่กี',
  'ချမ်းသာတဲ့ရီကီထီ': 'มั่งมียี่กี',
  'ထီဆုမဲရလဒ်များ': 'ผลการออกรางวัล',
  'ထိုင်းအစိုးရထီ': 'หวยรัฐบาลไทย',
  'ပထမဆု': 'รางวัลที่ 1',
  'အောက်၂လုံး': '2 ตัวล่าง',
  'နောက်၃လုံး': '3 ตัวท้าย',
  'Dong ရီကီထီ': 'Dong ยี่กี่',
  'အဝိုင်း': 'รอบที่',
  'အပါ် ၃လုံး': '3 ตัวบน',
  'အပါ်၂လုံး': '2 ตัวบน',
  'ဒီတဝိုင်းကစား': '2 ตัวล่าง',
  'ဒီနေ့စတော့ထီ': 'เล่นรอบนี้',
  'စတော့ထီ': 'หวยหุ้นวันนี้',
  'ဒေါင်းဂျုံးစတော့ထီ': 'หวยหุ้นดาวโจนส์',
  'နိခေးစတော့ထီ (မနက်)': 'หวยหุ้นนิเคอิ รอบเช้า',
  'တရုတ်စတော့ထီ (မနက်)': 'หวยหุ้นจีน รอบเช้า',
  'ဟန်ဆိုင်းစတော့ထီ (မနက်)': 'หวยหุ้นฮั่งเส็ง รอบเช้า',
  'ကိုးရီးယားစတော့ထီ': 'หวยหุ้นเกาหลี',
  'အင်္ဂလန်စတော့ထီ': 'หวยหุ้นอังกฤษ',
  'ဂျာမန်စတော့ထီ': 'หวยหุ้นเยอรมัน',
  'ရုရှားစတော့ထီ': 'หวยหุ้นรัสเซีย',
  'နိခေးစတော့ထီ (နေ့လည်)': 'หวยหุ้นนิเคอิ รอบบ่าย',
  'တရုတ်စတော့ထီ (နေ့လည်)': 'หวยหุ้นจีน รอบบ่าย',
  'ဟန်ဆိုင်းစတော့ထီ (နေ့လည်)': 'หวยหุ้นฮั่งเส็ง รอบบ่าย',
  'စင်္ကာပူ စတော့ထီ': 'หวยหุ้นสิงคโปร์',
  'အိန္ဒိယစတော့ထီ': 'หวยหุ้นอินเดีย',
  'ထိုင်ဝမ်စတော့ထီ': 'หวยหุ้นไต้หวัน',
  'ကစားနည်းများ': 'เงื่อนไขการเล่น',
  'ကြိမ်တ်ုင်းအနိမ့်ဆုံးထိုး ၁ ဘတ်': 'แทงขั้นต่ำต่อครั้ง : 1 บาท',
  'ကြိမ်တိုင်းအမြင့်ဆုံးထိုး ၁၀၀,၀၀၀ ဘတ်': 'แทงสูงสุดต่อครั้ง : 100,000 บาท',
  'ဂဏန်းအမြင့်ဆုံးထိုး ၁၀၀၀-၁၀,၀၀၀ဘတ်': 'แทงสูงสุดต่อเลข : 1,000 - 10,000 บาท',
  'နောက်ရလဒ်ပြန်ကြည့်': 'ดูผลย้อนหลัง',
  'အစဆုံးပယ်ဖျက်': 'ยกเลิกล่าสุด',
  'အပေါ် ၃လုံး': '3 ตัวบน',
  'စျေးကြီးတယ်။': 'จ่ายสูงสุด',
  '၂လုံးပေါ်': '2 ตัวบน',
  '၂လုံးအောက်': '2 ตัวล่าง',
  '၃ လုံးပေါ်': '3 ตัวบน',
  'တွတ် ၃ လုံး': '3 ตัวโต๊ด',
  'ရှေ့၃လုံး': '3 ตัวหน้า',
  '၃လုံးအောက်': '3 ตัวล่าง',
  'အပေါ်ပြေး': 'วิ่งบน',
  'အောက်ပြေး': 'วิ่งล่าง',
  '၁၉ ဂိုး': '19 ประตู',
  'နံပါတ်ပွား': 'เลขเบิ้ล',
  'စာမျက်နှာနံပါတ်': 'รูดหน้า',
  'နောက်ကျောနံပါတ်': 'รูดหลัง',
  '2 နည်း': '2 ตัวต่ำ',
  'အမြင့် ၂': '2 ตัวสูง',
  'အလေးသာမှု ၂ ခု': '2 ตัวคี่',
  '၂ ပင်': '2 ตัวคู่',
  'ထူးဆန်းသော ညီအစ်ကိုများ': 'พี่น้องคี่',
  'အမြွှာညီအစ်ကိုများ': 'พี่น้องคู่',
  'ပေါက်ဖော်နံပါတ်များ': 'เลขพี่น้อง',
  'ဂဏန်း ၃ လုံး': '3 ตัวคี่',
  '၃ ပင်': '3 ตัวคู่',
  'ပြောင်းပြန်နံပါတ်များ': 'กลับตัวเลข',
  'စာရင်းထဲမှ နံပါတ်ကို လုံးဝဖယ်ရှားလိုပါသည်။': 'คุณต้องการลบเลขออกจากรายการทั้งหมด',
  'အရာအားလုံးကို အောင်မြင်စွာ ရှင်းလင်းပြီးပါပြီ။': 'ล้างรายการทั้งหมดสำเร็จ',
  'စာလုံး ၃ လုံး': '3 ตัว',
  'စာလုံး ၂ လုံး': '2 ตัว',
  'အကန့်မှရွေးချယ်': 'เลือกจากแผง',
  'နံပါတ်ကိုယ့်ကိုနှိပ်': 'กดเลขเอง',
  'ဆွဲပါ': 'ดึงโพย',
  'စျေးနှုန်းတင်': 'ใส่ราคา',
  'အားလုံးကို ပယ်ဖျက်ပါ။': 'ยกเลิกทั้งหมด',
  'လောင်းသည့်ငွေ': 'ยอดแทง',
  'အောင်မြင်သည့်ငွေ': 'ยอดถูก',
  'ဂဏန်းရိုက်': 'กดตัวเลข',
  'အနိုင်ရနံပါတ်ကိုဖမ်း': 'จับเลขวิน',
  'ဂဏန်းချ': 'วางตัวเลข',
  'ကျပန်း': 'สุ่ม',
}

export const HOME_PAGE = {
  'လျော်ကြေးပေးဆောင်': 'อัตราการจ่ายผลตอบแทน',
  'အမြင့်ဆုံးပေး': 'จ่ายสูงถึง',
  'အဓိကစာမျက်နှာ': 'หน้าหลัก',
  'ယုံကြည်ပြီး': 'เชื่อในดวง',
  'ယုံကြည်ပြီ': 'ความเชื่อ',
  'တိုက်ရိုက်ထီရလဒ်': 'ผลหวยสด',
  'အဓိကအဆင့်': 'เฟสหลัก',
  'စတော့ရှယ်ယာထီရလဒ်': 'ผลหวยหุ้น',
  'စတော့ရှယ်ယာထီ': 'หุ้น',
  '၃လုံးပေါ်': '3 ตัวบน',
  '၃လုံးအောက်': '3 ตัวล่าง',
  'ဒေါင်ကြိုးထီ': 'ดาวโจนส์',
  'နိခေး၏ထီ မနက်': 'นิเคอิ รอบเช้า',
  'တရုတ်ထီ မနက်': 'จีน รอบเช้า',
  'ဟန်ဆိုင်းထီ မနက်': 'ฮั่งเส็ง รอบเช้า',
  'တိုက်ဝမ်ထီ': 'ไต้หวัน',
  'နိ​ခေး၏ နေ့လည်': 'นิเคอิ รอบบ่าย',
  'ကိုးလီးယားထီ': 'เกาหลี',
  'တရုတ်ထီ နေ့လည်': 'จีน รอบบ่าย',
  'ဟန်ဆိုင်းထီ နေ့လည်': 'ฮั่งเส็ง รอบบ่าย',
  'စင်္ကာပူထီ': 'สิงคโปร์',
  'ယိုးဒယားထီ ညနေ': 'ไทย เย็น',
  'အိန္ဒိယထီ': 'อินเดีย',
  'ရုရှားထီ': 'รัสเซีย',
  'အင်္ဂလိပ်ထီ': 'อังกฤษ',
  'ဂျာမန်နီထီ': 'เยอรมัน',
  'နေ့တိုင်ထီ ရလဒ်': 'ผลหวยรายวัน',
  'ဟနွိုင်းစတား': 'ฮานอย สตาร์',
  'ဟနွိုင်းHD': 'ฮานอย HD',
  'ဟနွိုင်းTV': 'ฮานอย TV',
  'ဟနွိုင်းကြက်ခြေနီ': 'ฮานอย กาชาด',
  'ဟနွိုင်း EXTRA': 'ฮานอย EXTRA',
  'ဟနွိုင်း  ည': 'ฮานอย รอบดึก',
  'ဟနွိုင်း စတား': 'ฮานอย สตาร์',
  'လာအို စတား ': 'ลาว สตาร์',
  'လာအို စတား VIP': 'ลาวสตาร์ VIP',
  'လာအို ကျန်ပါစပ်': 'ลาว จำปาศักดิ์',
  'လာအို HD': 'ลาว HD',
  'လာအို ကြယ်': 'ลาวดาว',
  'လာအို TV': 'ลาว TV',
  'လာအို VIP': 'ลาว VIP',
  'လာအို ကြက်ခြေနီ': 'ลาว กาชาด',
  'လာအို ဗီယင်ကျန်း': 'ลาว เวียงจันทร์',
  'လာအို ညီညွတ်ရေး': 'ลาว สามัคคี',
  'ဒေါင်းကြို VIP': 'ดาวโจนส์ VIP',
  'VIP ထီရလဒ်': 'ผลหวยวีไอพี',
  'နိခေး မနက် VIP': 'นิเคอิ(เช้า) VIP',
  'တရုတ် မနက် VIP': 'จีน(เช้า) VIP',
  'ဟန်ဆိုင်း မနက် VIP': 'ฮั่งเส็ง(เช้า) VIP',
  'တိုက်ဝမ် VIP': 'ไต้หวัน VIP',
  'နိခေး နေ့လည် VIP': 'นิเคอิ(บ่าย) VIP',
  'ကိုးရီးယား VIP': 'เกาหลี VIP',
  'တရုတ် နေ့လည် VIP': 'จีน(บ่าย) VIP',
  'ဟန်ဆိုင်း နေ့လည် VIP': 'ฮั่งเส็ง(บ่าย) VIP',
  'စင်္ကာပူ VIP': 'สิงคโปร์ VIP',
  'ရုရှား VIP': 'รัสเซีย VIP',
  'အင်္ဂလိပ် VIP': 'อังกฤษ VIP',
  'ဂျာမန်နီ VIP': 'เยอรมัน VIP',
  'နိုင်ငံခြားထီ ရလဒ်': 'ผลหวยต่างประเทศ',
  'ဟနွိုင်းအထူး': 'ฮานอย พิเศษ',
  'ဟနွိုင်း': 'ฮานอย',
  'ဟနွိုင်း VIP': 'ฮานอย VIP',
  'လာအို ပတ္တနာ': 'ลาว พัฒนา',
  'ရီကီထီ ရလဒ်': 'ผลหวยยี่กี',
  'အလှည့်ကျ': 'รอบที่',
  '၃ လုံးပေါ်': '3 ตัวบน',
  '၂လုံးပေါ်': '2 ตัวบน',
  '၂လုံးအောက်': '2 ตัวล่าง',
  'ချမ်းသာတယ်။': 'มั่งคั่ง',
  'အကျိုးအမြတ်': 'ร่ำรวย',
  'အကန့်အသတ်မရှိ': 'ไร้ขีดจำกัด',
  'အမျိုးအစား': 'ประเภท',
  'အန်ဂလ': 'อังกะโหล่ง',
  'တွတ် ၃ လုံး': '3 ตัวโต๊ด',
  'ရှေ့၃လုံး': '3 ตัวหน้า',
}

export const LUCKY_BOX_PAGE = {
  'အမြန်ထိုး': 'แทงเร็ว',
  'ထီအားလုံး': 'หวยท้ังหมด',
}

export  const RECEIPT_PAGE = {
  'ထီရလဒ်စစ်ဆေး': 'ตรวจรางวัล โพยหวย',
  'နေ့ကိုရွေးချယ်ရန်': 'กดเพื่อเลือกวัน',
  'လောင်းကစားအကျဉ်းချုပ်': 'สรุปยอดแทง',
  'စုစုပေါင်း': 'ยอดรวม',
  'ထွက်ပြီ': 'ออกผลแล้ว',
  'မထွက်သေး': 'ยังไม่ออกผล',
  'ကစားဖို့အစီအစဉ်အားလုံး': 'รายการเล่นทั้งหมด',
  'ဆုရသည့်စာရင်း': 'รายการที่ถูกรางวัล',
  'မထွက်သေးတဲ့ဆုစာရင်း': 'รายการที่ยังไม่ออกรางวัล',
  'ထွက်ပြီးတဲ့ဆုစာရင်း': 'รายการที่ออกรางวัลแล้ว',
  'မအောင်မြင်တဲ့စာရင်း': 'รายการที่ไม่สำเร็จ',
  'လောင်းသည့်ငွေ': 'ยอดแทง',
  'အောင်မြင်သည့်ငွေ': 'ยอดถูก',
}

export const WALLET_PAGE = {
  'ပိုက်ဆံအိတ်': 'กระเป๋าเงิน',
  'ငွေသွင်း': 'ฝากเงิน',
  'ငွေထုတ်': 'ถอนเงิน',
  'အကောင့်စစ်ဆေး': 'กำลังตรวจสอบบัญชี',
  'အခြေအနေ- အပ်ငွေ': 'สถานะ: ฝากเงิน',
  'ငွေပမာဏ': 'จำนวนเงิน',
  'အနည်းဆုံး ဘတ်ငွေ 100 ကို သတ်မှတ်ပေးပါ': 'กรุณาระบุจำนวนเงินขั้นต่ำ 100 บาท',
  'ငွေလွှဲခြင်းတစ်ခုတည်းအတွက် QR CODE Yes ကိုစကင်န်ဖတ်ခြင်းဖြင့် ငွေလွှဲစနစ်သို့ ငွေလွှဲခြင်း။  ငွေလွှဲရန်အတွက်သာ လျှောက်ထားသော အကောင့်ကို အသုံးပြုရပါမည်။  လက်ကျန်ငွေကို ပြောင်းလဲလိုပါက  QR CODE  အသစ်ကိုပါနှိပ်ရပါမည်။': 'การฝากเงินเข้าระบบผ่านการโอนเงินด้วยการแสกน QR CODE ใช่สำหรับการทำธุรกรรมเพียงครั้งเดียว และต้องใช้บัญชีที่สมัครในการโอนเงินเท่านั้น หารต้องการเปลี่ยนแปลงยอดเงินจะต้องกดสร้าง QR CODE ใหม่เท่านั้น',
  'ငွေသွင်းရန်အတွက် QR CODEကိုဖန်တီးပါ။': 'สร้าง QR CODE สำหรับฝากเงิน',
  'ဘတ် 500 ဖြည့်': 'เติมเงิน <500 บาท',
  'ဘတ် 1500 ဖြည့်': 'เติมเงิน <1500 บาท',
  'ဘတ် 5000 ဖြည့်': 'เติมเงิน <5000 บาท',
  'ဘတ် 10000 ဖြည့်': 'เติมเงิน <10000 บาท',
  'တော်တယ် အကို!': 'แจ่มเลย ลูกพี่ !',
  'အားပေးပါ!!': 'กำลังใจมาเลย !!',
  'ချစ်တယ်!!!': 'เลิฟ เลย !!',
  'သန်းကြွယ်သူဌေးအသစ်!!': 'เศรษฐีคนใหม่ !!',
  'သူ့ကိုယ်သူ လာတယ်၊ Dong!!': 'สุลต่านมาเอง Dong !!',
}

export const PROFILE_PAGE = {
  'အကောင့်တစ်ခုဖွင့်ပါ။': 'ตั้งค่าบัญชีผู้ใช้',
  'Username': 'Username',
  'Password': 'Password',
  'Firstname': 'Firstname',
  'Lastname': 'Lastname',
  'Date of Birth (DD/MM/YY)': 'Date of Birth (DD/MM/YY)',
  'Tel': 'Tel',
  'Gender': 'Gender',
  'မှတ်တမ်း': 'บันทึก',
}

export const BANK_PAGE = {
  'ဘဏ်အကောင့်ထည့်ပါ။': 'เพิ่มบัญชีธนาคาร',
  'အကောင့်အခြေအနေကိုစစ်ဆေးပါ။': 'เช็คสถานะบัญชี',
  'ဘဏ်ကိုရွေးပါ။': 'เลือกธนาคาร',
  'ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။': 'กรุณาเลือกบัญชีธนาคาร',
  'กสิกรไทย': 'Kasikorn Bank',
  'ไทยพาณิชย์': 'Siam Commercial Bank',
  'กรุงไทย': 'Krungthai Bank',
  'กรุงเทพ': 'Bangkok Bank',
  'กรุงศรีอยุธยา': 'Bank of Ayudhya',
  'ทหารไทย': 'TMB Thanachart Bank',
  'ออมสิน': 'Government Savings Bank',
  'ยูโอบี': 'United Overseas Bank',
  'อาคารสงเคราะห์': 'Government Housing Bank',
  'ธกส': 'BAAC',
  'အကောင့်နာမည်': 'ชื่อบัญชี',
  'အမည်၊ မျိုးရိုး၊ အကောင့်စာအုပ် စာမျက်နှာ': 'ชื่อ นามสกุล หน้าสมุดบัญชี',
  'အကောင့်နံပါတ်': 'เลขบัญชี',
  'နံပါတ်များကိုသာ သတ်မှတ်ပါ။': 'ระบุ เฉพาะตัวเลข',
  'အကောင့်နံပါတ်ကိုအတည်ပြုပါ။': 'ยืนยันเลขบัญชี',
  'အကောင့်နံပါတ်ကို ထပ်မံအတည်ပြုပါ။': 'ยืนยันเลขบัญชีอีกครั้ง',
}

export const PAYMENT_HISTORY_PAGE = {
  'အပ်ငွေနှင့် ငွေထုတ်မှတ်တမ်း': 'ประวัติการฝากถอน',
  'အပ်ငွေအခြေအနေ': 'สถานะฝากเงิน',
  'စတင်သည့်ရက်စွဲ': 'วันเริ่มต้น',
  'ကုန်ဆုံးရက်': 'วันสิ้นสุด',
  'အားလုံး': 'ทั้งหมด',
  'သွင်း': 'ฝาก',
  'ထုတ်': 'ถอน',
  'စာရင်း': 'รายการ',
  'ငွေပမာဏ': 'จำนวนเงิน',
  'ရက်စွဲအချိန်': 'วันเวลา',
  'အခြေအနေ': 'สถานะ',
  'စာရင်းမရှိ': 'ไม่มีรายการ',
}

export const RULE_PAGE = {
  'အဖွဲ့ဝင်အားလုံးအား အသိပေးကြေငြာ': 'ประกาศถึงสมาชิกทุกท่าน',
  'လေးစားအပ်ပါသော အဖွဲ့ဝင်များ  ယခုအချိန်တွင် အစွမ်းထက်ဆုံး အွန်လိုင်းနည်းပညာဖြစ်သော Dong Lotto မှ ကြိုဆိုပါသည်။  ထီကစားခြင်းအတွက်  လှပသော ဒေါင်းနတ်ဘုရား၏ ရောင်ခြည်တော်ကဲ့သို့ စီးဆင်းနေသည်။  အဖွဲ့ဝင်များ ဖျော်ဖြေရန်  ရိုးသားမှုအောက်မှာ  အဖွဲ့ဝင်အားလုံးသည် ခြွင်းချက်မရှိဘဲ ဤတစ်ခုတည်းသော စည်းမျဉ်းကို လိုက်နာပါသည်။': 'สมาชิกที่รักทุกท่าน ยินดีต้อนรับสู่ Powerlott เทคโนโลยีออนไลน์อันทรงประสิทธิภาพที่สุดในเวลานี้ สำหรับการเล่นล็อตโต้ เพื่อสมาชิกให้ได้รับความบันเทิงเริงใจ ภายใต้ความสุจริตยุติธรรม สมาชิกทุกท่านจะอยู่ภายใต้กติกาเดียวนี้โดยไม่มีข้อยกเว้น',
  'Dong Lotto ကစားခြင်းအတွက် စည်းမျဉ်းများမှာ အောက်ပါအတိုင်းဖြစ်သည်။': 'กติกาในการเล่น Powerlott มีดังนี้ครับ',
}

//ปัจจุบันใช้ภาษาอังกฤษ
export const LOGIN_PAGE = {
  'စာရင်းသွင်းပါ။': 'สมัครสมาชิก',
  'လော့ဂ်အင်ဝင်ပါ': 'เข้าสู่ระบบ',
  'မိုဘိုင်းဖုန်းနံပါတ်': 'เบอร์โทรศัพท์มือถือ',
  'ဥပမာ 0891234567': 'เช่น 0891234567',
  'ကုဒ်နံပတ်': 'รหัสผ่าน',
  'ကုဒ်နံပတ်အနည်းဆုံး ၈ လုံး': 'รหัสผ่านอย่างน้อย 8 ตัวอักษร',
  '': 'กรุณากรอกเบอร์มือถือของท่าน ระบบจะมีการยืนยัน OTP ครับ',
  'Facebook ဖြင့် ဝင်ရောက်ပါ။': 'เข้าสู่ระบบด้วย Facebook',
  'Line ဖြင့်ဝင်ရောက်ပါ။': 'เข้าสู่ระบบด้วย Line',
  'အစစာမျက်နှာ သို့ ပြန်သွားရန်': 'กลับหน้าแรก',
  'အဖွဲ့ဝင်မဖြစ်သေးပါက စာရင်းပေးသွင်းပါ။': 'หากยังไม่เป็นสมาชิกกรุณา สมัครสมาชิก',
}

export const ANNOUNCE_PAGE = {
  'အဖွဲ့ဝင်အားလုံးအား အသိပေးကြေငြာ': 'ประกาศถึงสมาชิกทุกท่าน',
  'လေးစားအပ်ပါသော အဖွဲ့ဝင်များ  ယခုအချိန်တွင် အစွမ်းထက်ဆုံး အွန်လိုင်းနည်းပညာဖြစ်သော Dong Lotto မှ ကြိုဆိုပါသည်။  ထီကစားခြင်းအတွက်  လှပသော ဒေါင်းနတ်ဘုရား၏ ရောင်ခြည်တော်ကဲ့သို့ စီးဆင်းနေသည်။  အဖွဲ့ဝင်များ ဖျော်ဖြေရန်  ရိုးသားမှုအောက်မှာ  အဖွဲ့ဝင်အားလုံးသည် ခြွင်းချက်မရှိဘဲ ဤတစ်ခုတည်းသော စည်းမျဉ်းကို လိုက်နာပါသည်။': 'สมาชิกที่รักทุกท่าน ยินดีต้อนรับสู่ Powerlott เทคโนโลยีออนไลน์อันทรงประสิทธิภาพที่สุดในเวลานี้ เพื่อการเล่นล็อตโต้ อันลื่นไหลประดุจแพรหางแห่งเทพเจ้านกยูงที่งดงาม เพื่อสมาชิกให้ได้รับความบันเทิงเริงใจ ภายใต้ความสุจริตยุติธรรม สมาชิกทุกคนจะอยู่ภายใต้กติกาเดียวนี้โดยไม่มีข้อยกเว้น',
  '1.Dong Lotto သည် အဖွဲ့ဝင်များအတွက်သာ ရနိုင်ပါသည်။': '1.Powerlott ให้บริการสำหรับผู้ที่เป็นสมาชิกเท่านั้น',
  '2. အဖွဲ့ဝင်များသည် ဝဘ်ဆိုက်ပေါ်တွင် အမျိုးမျိုးသောဂိမ်းများကို ကစားခွင့်ရှိပြီး Dong Lotto မှ သတ်မှတ်သည့်အတိုင်း ဆုလက်ဆောင်များ ရယူပိုင်ခွင့်ရှိသည်။': '2.สมาชิกมีสิทธิในการเล่นเกมส์ต่างๆ บนเว็บไซต์ และได้รับรางวัลตามที่ Powerlott กำหนด',
  '3. ပျက်ပြယ်သောအရာများ  အရောင်းအ၀ယ်ပြုလုပ်သည့်အခါ အတည်မပြုနိုင်သော အကြောင်းအမျိုးမျိုးကြောင့်ဖြစ်သည်။  အရောင်းအဝယ်နောက်ကျခြင်း။  ဖောက်သည်ကွန်ရက်ပြဿနာများ  အကြွေး မလုံလောက်ခြင်း စသည်တို့ ပျက်ပြယ်သွားပါက၊  ကိစ္စတိုင်းတွင် ဆုများအားလုံးကို လက်ခံမည်မဟုတ်ပါ။': '3.รายการที่เป็นโมฆะ อันเนื่องมาจากสาเหตุต่างๆ อาทิ การไม่กดยืนยันเมื่อทำรายการ การทำรายการช้ากว่ากำหนด ปัญหาเครือข่ายอินเตอร์เน็ตของลูกค้า การมีเครดิตไม่พอ และอื่นๆ หากรายการนั้นเป็นโมฆะ จะไม่ได้รับรางวัลทั้งสิ้นทุกกรณี',
  'အားလုံးပဲ ကံကောင်းကြပါစေလို့ Dong Dong မှ ဆုမွန်ကောင်းတောင်းလိုက်ပါတယ်။': 'ขอให้ทุกท่านประสบแต่ความโชคดี Dong Dong',
}

export const AFF_PAGE = {
  'သူငယ်ချင်မိတ်ဆက်စနစ်': 'แนะนำเพื่อน',
  'သင့်မိတ်ဆက်လင့်ခ်': 'ลิงก์ของคุณ',
  'သင့်မိတ်ဆက်လင့်ခ်ကိုသူငယ်ချင်မျှဝေသည်': 'แชร์ให้เพื่อน',
  'ဒီနေ့၏မန်ဘာဝင်များ': 'รายได้แนะนำเพื่อน',
  'ဝင်ငွေထုပ်ယူရန်': 'รับรายได้ !!',
  'မိတ်ဆက်ထားသောမန်ဘာဝင်များ': 'ประวัติรายได้จากเพื่อน',
}
