export const mapBankCodeToBankName = {
  kbank: 'กสิกรไทย',
  scb: 'ไทยพาณิชย์',
  ktb: 'กรุงไทย',
  bbl: 'กรุงเทพ',
  bay: 'กรุงศรีอยุธยา',
  ttb: 'ทหารไทย',
  gsb: 'ออมสิน',
  // CIMBT: 'ซีไอเอ็มบีไทย',
  // TISCO: 'ทิสโก้',
  uob: 'ยูโอบี',
  ghb: 'อาคารสงเคราะห์',
  // KKPA: 'เกียรตินาคิน',
  // TCDA: 'ไทยเครดิตเพื่อรายย่อย',
  // LHFGA: 'แลนด์ แอนด์ เฮาส์',
  // ICBCTA: 'ไอซีบีซี (ไทย)',
  // EXIM: 'ธสน.',
  // ISBTA: 'อิสลามแห่งประเทศไทย',
  baac: 'ธกส',
  // SMEA: 'ธพว.',
  // TBNK: 'ธนชาต',
  // OTHER: 'อื่นๆ',
}

export const mapBankCodeToBankLogoName = {
  // TODO: find all Bank Logo
  kbank: 'kplus',
  scb: 'scb',
  ktb: 'ktb',
  bbl: 'bbl',
  bay: 'bay',
  ttb: 'ttb',
  gsb: 'gsb',
  // CIMBT: 'kplus',
  // TISCO: 'kplus',
  uob: 'uob',
  ghb: 'ghb',
  // KKP: 'kplus',
  // TCD: 'kplus',
  // LHFG: 'kplus',
  // ICBCT: 'kplus',
  // EXIM: 'kplus',
  // ISBT: 'kplus',
  baac: 'baac',
  // SME: 'kplus',
  // TBANK: 'kplus',
  // OTHERS: 'kplus',
}

// // BackUp Updated from P'Ice 2020-08-18
// '4': 'กสิกรไทย',
// '14': 'ไทยพาณิชย์',
// '2': 'กรุงเทพ',
// '6': 'กรุงไทย',
// '25': 'กรุงศรีอยุธยา',
// '11': 'ทหารไทย',
// '30': 'ออมสิน',
// '22': 'ซีไอเอ็มบีไทย',
// '67': 'ทิสโก้',
// '24': 'ยูโอบี',
// '33': 'อาคารสงเคราะห์',
// '69': 'เกียรตินาคิน',
// '71': 'ไทยเครดิตเพื่อรายย่อย',
// '73': 'แลนด์ แอนด์ เฮาส์',
// '70': 'ไอซีบีซี (ไทย)',
// '35': 'สกต.',
// '66': 'อิสลามแห่งประเทศไทย',
// '34': 'ธกส',
// '98': 'ธพว.',
// '65': 'ธนชาต',
// '99': 'อื่นๆ',

/* Backup used before 2020-08-18
  '001': 'กรุงเทพ',
  '002': 'กสิกรไทย',
  '003': 'กรุงไทย',
  '004': 'ไทยพาณิชย์',
  '005': 'กรุงศรีอยุธยา',
  '006': 'ซีไอเอ็มบี',
  '007': 'อิสลามแห่งประเทศไทย',
  '008': 'เกียรตินาคิน',
  '009': 'แลนด์ แอนด์ เฮ้าส์',
  '010': 'สแตนดาร์ดชาร์เตอร์ (ไทย)',
  '011': 'ธนชาติ',
  '012': 'ทิสโก้',
  '013': 'ทหารไทย',
  '014': 'ยูโอบี',
*/
