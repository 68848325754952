import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import { connect, useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Swal from 'sweetalert2'
import Combinatorics from 'js-combinatorics'
import queryString from 'query-string'
import './BuyLotto.scss'
import { LOTTO_PAGE, POST_PAGE } from '../../utils/lottoList'
import { getLang } from '../../redux/selectors/lang'

import useGetPayRate from './components/useGetPayRate'
import KeyPad from './components/KeyPad'
import CartDisplay from './components/CartDisplay'
import NumberInput from './components/NumberInput'
import CheckoutModal from './components/CheckoutModal'
import ConfirmCheckoutModal from './components/ConfirmCheckoutModal'
import StatisticsModal from './components/StatisticsModal'
import Timer from './components/Timer'
import { clearCart, toggleCart } from '../../redux/actions/cart'
// import RulesModal from './components/RulesModal'
import ReceiptModal from './components/ReceiptModal'
import PayRateModal from './components/PayRateModal'
import { lottoType } from '../../utils/lottoList'

function BuyLotto({ match, location }) {
  // console.log('BuyLotto ======')
  const dispatch = useDispatch()
  let history = useHistory()
  const { lottoNumber } = match.params
  const fastbuy = location.search
  const fastbuyQuery = queryString.parse(fastbuy)
  const { fetchPayRate, highestPayRate } = useGetPayRate(lottoNumber)
  // console.log(fastbuyQuery)

  const [lotto, setLotto] = useState({})
  const [lottoName, setLottoName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [currentNumRange, setCurrentNumRange] = useState('000')
  const [isPermutation, setIsPermutation] = useState(false)
  const [currentDigit, setCurrentDigit] = useState('3') // 2 OR 3 DIGITS
  const [currentType, setCurrentType] = useState(['3A']) // (A)BOVE, (B)ELOW, (S)TRAIGHT(A)BOVE, (S)TRAIGHT(B)ELOW
  const [isManualInput, setIsManualInput] = useState(true) // IS MANUAL?
  const [manualInputOption, setManualInputOption] = useState(null)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' }) // IS MODAL SHOWING ? & WHICH MODAL
  const [showYeekeeModal, setShowYeekeeModal] = useState({ isShow: false, currentModal: '' })
  const [filter, setFilter] = useState('') // SEARCH FILTER
  const [isAgentSubmit, setIsAgentSubmit] = useState(false)
  const [customerTable, setCustomerTable] = useState('')
  const lang = useSelector(getLang)
  const language = lang.lang
  // const [watchCurrentDigit, setWatchCurrentDigit] = useState(true)
  // const [isFocus, setIsFocus] = useState(false)
  // const setFocus = () => {
  //   const page = document.getElementsByClassName('buy-lotto')[0]
  //   if (!isFocus && page) {
  //     if (showModal.isShow || showYeekeeModal.isShow || showRulesModal.isShow) {
  //       setIsFocus(true)
  //       page.classList.add('blur-me')
  //       document.body.classList.add('block-scroll')
  //     }
  //   } else {
  //     setIsFocus(false)
  //     page.classList.remove('blur-me')
  //     document.body.classList.remove('block-scroll')
  //   }
  // }
  //
  useEffect(() => {
    if (manualInputOption !== null && currentDigit !== '1') {
      // if (currentDigit === '1' && !_.includes(currentType, 'SA') && !_.includes(currentType, 'SB')) {
      setManualInputOption(null)
    }
    // }
    if (_.includes(currentType, 'SA') || _.includes(currentType, 'SB')) {
      setManualInputOption(null)
    }
  }, [currentDigit])

  // console.log(watchCurrentDigit, 'setWatchCurrentDigit')

  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])
  const cartCounter = cartItems.length || 0

  const cartItemsGroupByType = _.groupBy(cartItems, 'type')

  const lottoType = { '3': ['3A', '3R'], '2': ['2A', '2B'], '1': ['SA', 'SB'] }[currentDigit]
  const lottoThailandType = { '3': ['3A', '3R', '3F', '3B'], '2': ['2A', '2B'], '1': ['SA', 'SB'] }[currentDigit]
  // const lottoOptionTitle = {
  //   // '2A': '2 ตัวบน',
  //   // '2B': '2 ตัวล่าง',
  //   // '3A': '3 ตัวบน',
  //   // '3R': '3 ตัวโต๊ด',
  //   // '3F': '3 ตัวหน้า',
  //   // '3B': '3 ตัวล่าง',
  //   // SA: 'วิ่งบน',
  //   // SB: 'วิ่งล่าง',

  //   '2A': '၂လုံးပေါ်',
  //   '2B': '၂လုံးအောက်',
  //   '3A': '၃ လုံးပေါ်',
  //   '3R': '၃လုံးပြောင်းပြန်',
  //   '3F': 'ရှေ့၃လုံး',
  //   '3B': '၃လုံးအောက်',
  //   SA: 'အပေါ်ပြေး',
  //   SB: 'အောက်ပြေး',
  // }

  const lottoOptionTitle = lottoType

  const handleFilter = (event) => {
    event.preventDefault()
    setFilter(event.target.value)
  }

  const handleType = (type) => {
    let tempTypeArray = [...currentType]
    let incomingType = type

    if (_.includes(tempTypeArray, incomingType)) {
      _.remove(tempTypeArray, function(type) {
        return type === incomingType
      })
    } else {
      if (_.includes(tempTypeArray, '2A') || _.includes(tempTypeArray, '2B')) {
        if (_.includes(type, 'SA') || _.includes(type, 'SB')) {
          _.remove(tempTypeArray, function(type) {
            return _.includes(type, '2A') || _.includes(type, '2B')
          })
          setManualInputOption(null)
        }
      }
      tempTypeArray.push(incomingType)
    }
    setCurrentType(tempTypeArray)
  }

  const ManualInputOptions = ({ typeList, lottoNumber, isPermutation }) => {
    const [activeSubmenu, setActiveSubmenu] = useState(null)

    const selectNumber = (number, singleType) => {
      if (fetchPayRate[singleType]) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 1,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    }

    const autoSelectDoubleLottos = () => {
      const target = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectLowLottos = () => {
      typeList.forEach((type) => {
        for (let index = 0; index < 50; index++) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectHighLottos = () => {
      typeList.forEach((type) => {
        for (let index = 50; index < 100; index++) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectOddLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1; index < 100; index += 2) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectEvenLottos = () => {
      typeList.forEach((type) => {
        for (let index = 0; index < 100; index += 2) {
          selectNumber(index.toString().padStart(2, '0'), type)
        }
      })
    }

    const autoSelectOddThreeLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1001; index < 2000; index += 2) {
          selectNumber(index.toString().substring(1).padStart(2, '0'), type)
        }
      })
    }

    const autoSelectEvenThreeLottos = () => {
      typeList.forEach((type) => {
        for (let index = 1000; index < 2000; index += 2) {
          selectNumber(index.toString().substring(1).padStart(2, '0'), type)
        }
      })
    }

    const autoSelectSibling = () => {
      const target = [
        '01',
        '10',
        '12',
        '21',
        '23',
        '32',
        '34',
        '43',
        '45',
        '54',
        '56',
        '65',
        '67',
        '76',
        '78',
        '87',
        '89',
        '98',
        '90',
        '09',
      ]
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectSiblingOdd = () => {
      const target = ['13', '31', '35', '53', '57', '75', '79', '97', '91', '19']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const autoSelectSiblingEven = () => {
      const target = ['02', '20', '24', '42', '46', '64', '68', '86', '08', '80']
      typeList.forEach((type) => {
        target.forEach((lotto) => {
          selectNumber(lotto, type)
        })
      })
    }

    const handleOption = (digit, option) => {
      if (manualInputOption === null || manualInputOption !== option) {
        setCurrentDigit('1')
        setManualInputOption(option)
      } else if (manualInputOption === option) {
        setCurrentDigit('2')
        setManualInputOption(null)
      } else {
        setCurrentDigit('2')
        setManualInputOption(null)
      }
    }
    // console.log(manualInputOption, 'manualInputOption')
    // console.log(currentDigit, 'currentDigit')
    // console.log(currentType, 'currentType')

    return _.includes(currentType, '2A') || _.includes(currentType, '2B') ? (
      <div className='__btn-group row-btn-group make-gap text-sm'>
        <button
          // className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'nineteen' ? 'active' : ''}`}
          className={manualInputOption === 'nineteen' ? 'button-red' : 'button-green'}
          onClick={() => handleOption('1', 'nineteen')}
        >
          {language !== 'MM' ? <span>19 ประตู</span> :
            <span>၁၉ ဂိုး</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectDoubleLottos()}>
          {language !== 'MM' ? <span>เลขเบิ้ล</span> :
            <span>နံပါတ်ပွား</span>}
        </button>
        <button
          // className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'prefix' ? 'active' : ''}`}
          className={manualInputOption === 'prefix' ? 'button-red' : 'button-green'}
          onClick={() => handleOption('1', 'prefix')}
        >
          {language !== 'MM' ? <span>รูดหน้า</span> :
            <span>စာမျက်နှာနံပါတ်</span>}
        </button>
        <button
          // className={`btn-bevel bg-dark border-on-active ${manualInputOption === 'suffix' ? 'active' : ''}`}
          className={manualInputOption === 'suffix' ? 'button-red' : 'button-green'}
          onClick={() => handleOption('1', 'suffix')}
        >
          {language !== 'MM' ? <span>รูดหลัง</span> :
            <span>နောက်ကျောနံပါတ်</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectLowLottos()}>
          {language !== 'MM' ? <span>2 ตัวต่ำ</span> :
            <span>2 နည်း</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectHighLottos()}>
          {language !== 'MM' ? <span>2 ตัวสูง</span> :
            <span>အမြင့် ၂</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectOddLottos()}>
          {language !== 'MM' ? <span>2 ตัวคี่</span> :
            <span>အလေးသာမှု ၂ ခု</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectEvenLottos()}>
          {language !== 'MM' ? <span>2 ตัวคู่</span> :
            <span>၂ ပင်</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectSiblingOdd()}>
          {language !== 'MM' ? <span>พี่น้องคี่</span> :
            <span>ထူးဆန်းသော ညီအစ်ကိုများ</span>}
        </button>
        <button className={'button-green-focus'} onClick={() => autoSelectSiblingEven()}>
          {language !== 'MM' ? <span>พี่น้องคู่</span> :
            <span>အမြွှာညီအစ်ကိုများ</span>}
        </button>
        {/* className={`btn-bevel bg-dark border-on-active`} */}
        <button className={'button-green-focus'} onClick={() => autoSelectSibling()}>
          {language !== 'MM' ? <span style={{ zIndex: 0 }}>เลขพี่น้อง</span> :
            <span style={{ zIndex: 0 }}>ပေါက်ဖော်နံပါတ်များ</span>}
        </button>
      </div>
    ) : _.includes(currentType, '3A') && !_.includes(currentType, '3R') && !_.includes(lottoName, '2D') ? (
      <div className='__btn-group row-btn-group make-gap text-sm'>
        <button className={'button-green'} onClick={() => autoSelectOddThreeLottos()}>
          {language !== 'MM' ? <span>3 ตัวคี่</span> :
            <span>ဂဏန်း ၃ လုံး</span>}
        </button>
        <button className={'button-green'} onClick={() => autoSelectEvenThreeLottos()}>
          {language !== 'MM' ? <span>3 ตัวคู่</span> :
            <span>၃ ပင်</span>}
        </button>
      </div>
    ) : null
  }
  const lottoNaming = _.get(lotto, 'lottoName', '...')

  const Header = () => {
    return (
      // <section className='__header container mx-auto'>
      <div className='header_name rounded-tl-lg rounded-tr-lg'>
        <div className='middle'>{lottoNaming.slice(0, 5) === 'ดองยี' ? `${language === 'MM' ? 'ရီကီထီ ဒေါင်း ' : 'ยี่กี '}${lottoNaming.split(' ')[1]}` : (language === 'MM' ? POST_PAGE[lottoNaming] : lottoNaming)}</div>
        <div className='box_result'>
          {/* style={{ width: '50px', height: '50px' }} */}
          <div className='text_head'>
            <img style={{ width: '15px', height: '15px' }} src={require('./assets/winner_result.png')}/>
            {_.get(lotto, 'drawTimeStr', '...').slice(0, 5)}
          </div>
          <div className='text_digit'>
            {/* <img style={{ width: '15px', height: '15px' }} src={require('./assets/clock_result.png')} /> */}
            <Timer
              drawDateStr={lotto.drawDateStr}
              drawTimeStr={lotto.drawTimeStr}
              closeMinutesBeforeDraw={lotto.closeMinutesBeforeDraw}
            />
          </div>
        </div>

        {/* <div className='buy-lotto__lotto-number text-xl btn-bevel'>
          <h1>{_.get(lotto, 'lottoName', '...')}</h1>
          <div>ผลออก {_.get(lotto, 'drawTimeStr', '...').slice(0, 5)}</div>
        </div> */}
        {/* <Timer
          drawDateStr={lotto.drawDateStr}
          drawTimeStr={lotto.drawTimeStr}
          closeMinutesBeforeDraw={lotto.closeMinutesBeforeDraw}
        /> */}

        {/*<div className='__btn-group'>*/}
        {/*  <button className='text-xl'>กติกา</button>*/}
        {/*  <button className='btn-bevel text-xl'>*/}
        {/*    <span>ยิงเลข</span>*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    )
  }

  const BuyLottoLeft = () => {
    return (
      <section className='buy-lotto__left'>
        <div
          className={`buy-lotto__box ${isPermutation ? 'permutation' : ''}`}
          // className={isPermutation ? 'button-red' : 'button-green'}
        >
          <div
            // className='text-xl bg-darken btn-bevel'
            className='text-xl btn-bevel rounded-lg my-1 shadow-md'
            style={{ border: '3px solid #CD0100', background: '#FFC046' }}
            onClick={() => setShowModal({ isShow: true, currentModal: 'ดึงโพย' })}
          >
            {/* <span style={{ color: '#000000' }}>ดึงโพย</span> */}
            <span style={{ color: '#000000' }}>{language !== 'MM' ? 'ดึงโพย' : 'History'}</span>
          </div>
          {lottoName === 'หวยรัฐบาลไทย' ? (
            <div
              className='text-xl btn-bevel rounded-lg my-1 shadow-md'
              onClick={() => setShowModal({ isShow: true, currentModal: 'เลขอั้น' })}
            >
              <span style={{ color: '#ffffff' }}>{language !== 'MM' ? 'เลขอั้น' : 'Limited'}</span>
            </div>
          ) : (
            <div
              // className='text-xl bg-darken btn-bevel'
              className='text-xl btn-bevel rounded-lg my-1 shadow-md'
              style={{ border: '3px solid #CD0100', background: '#FFC046' }}
              onClick={() => setShowModal({ isShow: true, currentModal: 'ดูสถิติ' })}
            >
              <span style={{ color: '#000000' }}>Statistics</span>
            </div>
          )}

          {/* <div className='text-xl text-white text-center' style={{ color: 'black' }}>
            ทั้งหมด
          </div> */}
          <div className='buy-lotto__selected'>
            <div className='__cart-counter text-4xl' style={{ color: '#8d7f53' }}>
              Total {cartCounter} items
            </div>
            <CartDisplay/>
          </div>
        </div>
      </section>
    )
  }

  const BuyLottoRight = () => {
    const clearSelection = () => {
      setCurrentType([])
      setCurrentNumRange('000')
    }

    const DigitOptions = () => {
      return (
        <>
          {!lotto.lottoName.includes('2D') ? <button
            // className={`bg-dark btn-bevel border-on-active ${currentDigit === '3' ? 'active' : ''}`}
            className={currentDigit === '3' ? 'button-red' : 'button-green'}
            onClick={() => {
              setCurrentDigit('3')
              clearSelection()
              setCurrentType(['3A'])
            }}
          >
            {/* <span>3 ตัว</span> */}
            <span> {language === 'MM' ? 'စာလုံး ၃ လုံး' : LOTTO_PAGE['စာလုံး ၃ လုံး']}</span>
          </button> : null}

          <button
            // className={`bg-dark btn-bevel border-on-active ${currentDigit === '2' ? 'active' : ''}`}
            className={currentDigit === '2' ? 'button-red' : 'button-green'}
            onClick={() => {
              setCurrentDigit('2')
              clearSelection()
              setCurrentType(['2A', '2B'])
            }}
          >
            {/* <span>2 ตัว</span> */}
            <span>{language === 'MM' ? 'စာလုံး ၂ လုံး' : LOTTO_PAGE['စာလုံး ၂ လုံး']}</span>
          </button>
          <button
            className={
              currentDigit !== '1'
                ? 'button-green'
                : !_.includes(currentType, '2A') && !_.includes(currentType, '2B')
                ? 'button-red'
                : 'button-green'
            }
            // className={`bg-dark btn-bevel border-on-active ${currentDigit !== '1'
            //   ? ''
            //   : !_.includes(currentType, '2A') && !_.includes(currentType, '2B')
            //     ? 'active'
            //     : ''
            //   }`}
            onClick={() => {
              setCurrentDigit('1')
              clearSelection()
              setCurrentType(['SA', 'SB'])
            }}
          >
            <span>Run No.</span>
          </button>
        </>
      )
    }
    // console.log(currentDigit, 'cadfafaesfs')
    const TypeOptions = () => {
      let options = []
      lottoType.forEach((item, index) => {
        options.push(
          <button
            key={index}
            // className={`btn-black btn-bevel ${_.includes(currentType, item) ? 'active' : ''}`}
            className={_.includes(currentType, item) ? 'button-red' : 'button-green'}
            onClick={() => handleType(item)}
          >
            <span className='left'>{lottoOptionTitle[item]}&nbsp;</span>
            <span className='right'>{highestPayRate[item] || 'xx'}</span>
          </button>,
        )
        // }
      })
      return options
    }

    const TypeThailandOptions = () => {
      let options = []
      lottoThailandType.forEach((item, index) => {
        options.push(
          <button
            key={index}
            // className={`btn-black btn-bevel ${_.includes(currentType, item) ? 'active' : ''}`}
            className={_.includes(currentType, item) ? 'button-red' : 'button-green'}
            onClick={() => handleType(item)}
          >
            <span className='left'>{lottoOptionTitle[item]}&nbsp;</span>
            <span className='right'>{highestPayRate[item] || 'xx'}</span>
          </button>,
        )
        // }
      })
      return options
    }

    const TwoDigitSubmenu = () => {
      let nineteen = []
      let sp = []
      let ss = []

      const selectNumber = (number, singleType) => {
        if (fetchPayRate[singleType]) {
          dispatch(
            toggleCart({
              lottoNumber,
              purchaseItem: {
                type: singleType,
                detail: number,
                amount: 1,
                payRate:
                  fetchPayRate[singleType][number] === undefined
                    ? fetchPayRate[singleType].default
                    : fetchPayRate[singleType][number],
                isNumInput: true,
              },
            }),
          )
        }
      }

      const isAlreadyExists = (number, singleType) => {
        return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
      }

      const generate10lotto = (num, model, typeList) => {
        num = num.toString()
        typeList.forEach((type) => {
          switch (model) {
            case 'nineteen': {
              let target = []
              for (let i = 0; i < 10; i++) {
                let front = num.concat(i)
                let back = i.toString().concat(num)
                target.push(front)
                target.push(back)
              }
              _.uniqBy(target).forEach((item) => {
                if (!isAlreadyExists(item, type)) {
                  selectNumber(item, type)
                } else {
                  _.uniqBy(target).forEach((item) => {
                    selectNumber(item, type)
                  })
                }
              })
            }
              break
            case 'prefix':
              for (let i = 0; i < 10; i++) {
                selectNumber(num.concat(i), type)
              }
              break
            case 'suffix':
              for (let i = 0; i < 10; i++) {
                selectNumber(i.toString().concat(num), type)
              }
              break
            default:
              console.log('default model')
          }
        })
      }

      const isStraightActive = (number, currentType, straightType) => {
        let target = []
        const num = number.toString()
        switch (straightType) {
          case 'nineteen':
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              let back = i.toString().concat(num)
              target.push(front)
              target.push(back)
            }
            break
          case 'prefix':
            for (let i = 0; i < 10; i++) {
              let front = num.concat(i)
              target.push(front)
            }
            break
          case 'suffix':
            for (let i = 0; i < 10; i++) {
              let back = i.toString().concat(num)
              target.push(back)
            }
            break
        }
        let included = false
        currentType.forEach((type) => {
          if (_.difference(_.uniqBy(target), _.map(cartItemsGroupByType[type], 'detail')).length === 0) {
            included = true
          }
        })
        return included
      }

      for (let index = 0; index < 10; index++) {
        nineteen.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${
              isStraightActive(index, currentType, 'nineteen') ? 'active ' : ''
            }`}
            // className={isStraightActive(index, currentType, 'nineteen') ? ('__btn-num-straight button-red') : ('__btn-num-straight button-green')}
            onClick={() => generate10lotto(index, 'nineteen', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      for (let index = 0; index < 10; index++) {
        sp.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${
              isStraightActive(index, currentType, 'prefix') ? 'active ' : ''
            }`}
            // className={isStraightActive(index, currentType, 'prefix') ? ('button-red') : ('button-green')}
            onClick={() => generate10lotto(index, 'prefix', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      for (let index = 0; index < 10; index++) {
        ss.push(
          <button
            key={index}
            className={`__btn-num-straight btn-bevel ${
              isStraightActive(index, currentType, 'suffix') ? 'active ' : ''
            }`}
            // className={isStraightActive(index, currentType, 'suffix') ? ('button-red __btn-num-straight') : ('button-green __btn-num-straight')}
            onClick={() => generate10lotto(index, 'suffix', currentType)}
          >
            <span>{index}</span>
          </button>,
        )
      }

      return (
        <>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>{language === 'MM' ? '၁၉ ဂိုး' : '19 ประตู'}</h5>
            <div className='__btn-num-straight-group ml-0'>{nineteen}</div>
          </div>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>{language === 'MM' ? 'စာမျက်နှာနံပါတ်' : 'รูดหน้า'}</h5>
            <div className='__btn-num-straight-group'>{sp}</div>
          </div>
          <div className='__btn-num-straight-group-wrapper'>
            <h5 style={{ color: 'black', fontWeight: 'bold' }}>{language === 'MM' ? 'နောက်ကျောနံပါတ်' : 'รูดหลัง'}</h5>
            <div className='__btn-num-straight-group mr-0'>{ss}</div>
          </div>
        </>
      )
    }


    const NumRangeOpitons = () => {
      let options = []
      for (let index = 0; index < 10; index++) {
        let numRange = index.toString().padEnd(3, '0')
        options.push(
          <button
            key={index}
            // className={`__btn-num-range btn-bevel ${currentNumRange === numRange ? 'active' : ''}`}
            className={currentNumRange === numRange ? 'button-red' : ' button-green'}
            onClick={() => setCurrentNumRange(numRange)}
          >
            <span>{numRange}</span>
          </button>,
        )
      }
      return options
    }

    // const handleClearCart = () => {
    //   if (cartCounter > 0) {
    //     Swal.fire({
    //       icon: 'info',
    //       title: 'แจ้งเตือน',
    //       text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
    //       showCancelButton: true,
    //       confirmButtonColor: '#7dc351',
    //       cancelButtonColor: '#C0C0C0',
    //       confirmButtonText: 'ลบเลข',
    //       cancelButtonText: 'ยกเลิก',
    //     }).then((result) => {
    //       if (result.value) {
    //         dispatch(clearCart())
    //         Swal.fire({
    //           icon: 'success',
    //           title: 'ล้างรายการทั้งหมดสำเร็จ',
    //           showConfirmButton: false,
    //           timer: 1000,
    //         })
    //       }
    //     })
    //   }
    // }

    return (
      <section className='buy-lotto__right'>
        <section className='__form __loto-menu'>
          <div className='__btn-group text-xl'>
            <button
              // className='button_color '
              className={isManualInput ? 'button-red' : 'button-green'}
              style={{ padding: '6px 10px' }}
              onClick={() => setIsManualInput(!isManualInput)}
            >
              {language !== 'MM' ? <span>กดเลขเอง</span> : <span>နံပါတ်ကိုယ့်ကိုနှိပ်</span>}
            </button>
            <button
              className={!isManualInput ? 'button-red' : 'button-green'}
              style={{ padding: '6px 10px' }}
              onClick={() => {
                setIsManualInput(false)
                setCurrentDigit(manualInputOption || _.includes(lottoName, '2D') ? '2' : currentDigit)
              }}
            >
              {language !== 'MM' ? <span>เลือกจากแผง</span> : <span>အကန့်မှရွေးချယ်</span>}
            </button>
          </div>
          {!isManualInput ? (
            <div className='__btn-group make-gap text-xl'>
              <DigitOptions/>
            </div>
          ) : null}
        </section>

        <section className='__form'>
          {isManualInput ? (
            <>
              <div className='__btn-group make-gap text-xl'>
                <button
                  // className={`btn-bevel border-on-active ${isPermutation ? 'bg-grey active' : 'bg-gray'}`}
                  className={isPermutation ? 'button-red' : 'button-green'}
                  onClick={() => {
                    // let tempTypeArray = currentType
                    // if (_.includes(tempTypeArray, '3R')) {
                    //   _.remove(tempTypeArray, function (type) {
                    //     return type === '3R'
                    //   })
                    // }
                    setIsPermutation(!isPermutation)
                  }}
                >
                  {language !== 'MM' ? <span style={{ color: 'white' }}>**** กลับตัวเลข ****</span> :
                    <span style={{ color: 'white' }}>**** ပြောင်းပြန်နံပါတ်များ ****</span>}
                </button>
              </div>
              <div className='__btn-group manual make-gap text-xl'>
                {!_.includes(lottoName, '2D') ? (<button
                  // className={`btn-bevel btn-black ${_.includes(currentType, '3A') ? 'active' : ''}`}
                  className={_.includes(currentType, '3A') ? 'button-red' : 'button-green'}
                  onClick={() => {
                    if (currentDigit !== '3') {
                      setCurrentType(['3A'])
                      setCurrentDigit('3')
                    } else {
                      setCurrentDigit('3')
                      handleType('3A')
                    }
                  }}
                >
                  {language !== 'MM' ? <span className='left'>3 ตัวบน&nbsp;</span> :
                    <span className='left'>၃ လုံးပေါ်&nbsp;</span>}

                  <span className='right'>{highestPayRate['3A'] || 'xx'}</span>
                </button>) : null}
                {!_.includes(lottoName, '2D') ? (
                  <button
                    // className={`btn-bevel btn-black ${_.includes(currentType, '3R') ? 'active' : ''}`}
                    className={_.includes(currentType, '3R') ? 'button-red' : 'button-green'}
                    onClick={() => {
                      if (currentDigit !== '3') {
                        setCurrentType(['3R'])
                        setCurrentDigit('3')
                      } else {
                        setCurrentDigit('3')
                        handleType('3R')
                      }
                      // setIsPermutation(false)
                    }}
                  >

                    {language !== 'MM' ? <span className='left'>3 ตัวโต๊ด&nbsp;</span> :
                      <span className='left'>တွတ် ၃ လုံး&nbsp;</span>}
                    <span className='right'>{highestPayRate['3R'] || 'xx'}</span>
                  </button>) : null}
                {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                  <button
                    // className={`btn-bevel btn-black  ${_.includes(currentType, '3F') ? 'active' : ''}`}
                    className={_.includes(currentType, '3F') ? 'button-red' : 'button-green'}
                    onClick={() => {
                      if (currentDigit !== '3') {
                        setCurrentType(['3F'])
                        setCurrentDigit('3')
                      } else {
                        setCurrentDigit('3')
                        handleType('3F')
                      }
                      // setIsPermutation(!isPermutation)
                    }}
                  >
                    {language !== 'MM' ? <span className='left'>3 ตัวหน้า&nbsp;</span> :
                      <span className='left'>ရှေ့၃လုံး&nbsp;</span>}

                    <span className='right'>{highestPayRate['3F'] || 'xx'}</span>
                  </button>
                ) : null}
                {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                  <button
                    // className={`btn-bevel btn-black  ${_.includes(currentType, '3B') ? 'active' : ''}`}
                    className={_.includes(currentType, '3B') ? 'button-red' : 'button-green'}
                    onClick={() => {
                      if (currentDigit !== '3') {
                        setCurrentType(['3B'])
                        setCurrentDigit('3')
                      } else {
                        setCurrentDigit('3')
                        handleType('3B')
                      }
                      // setIsPermutation(!isPermutation)
                    }}
                  >
                    {language !== 'MM' ? <span className='left'>3 ตัวล่าง&nbsp;</span> :
                      <span className='left'>၃လုံးအောက်&nbsp;</span>}

                    <span className='right'>{highestPayRate['3B'] || 'xx'}</span>
                  </button>
                ) : null}
                {!_.includes(lottoName, '2D') ? (
                  <button
                    // className={`btn-bevel btn-black  ${_.includes(currentType, '2A') ? 'active' : ''}`}
                    className={_.includes(currentType, '2A') ? 'button-red' : 'button-green'}
                    onClick={() => {
                      if (currentDigit !== '2') {
                        setCurrentType(['2A'])
                        setCurrentDigit('2')
                      } else {
                        setCurrentDigit('2')
                        handleType('2A')
                      }
                    }}
                  >
                    {language !== 'MM' ? <span className='left'>2 ตัวบน&nbsp;</span> :
                      <span className='left'>၂လုံးပေါ်&nbsp;</span>}

                    <span className='right'>{highestPayRate['2A'] || 'x'}&nbsp;</span>
                  </button>
                ) : null}
                <button
                  // className={`btn-bevel btn-black  ${_.includes(currentType, '2B') ? 'active' : ''}`}
                  className={_.includes(currentType, '2B') ? 'button-red' : 'button-green'}
                  onClick={() => {
                    // console.log(currentDigit)
                    if (currentDigit !== '2') {
                      setCurrentType(['2B'])
                      setCurrentDigit('2')
                    } else {
                      setCurrentDigit('2')
                      handleType('2B')
                    }
                  }}
                >
                  {language !== 'MM' ? <span className='left'>2 ตัวล่าง&nbsp;</span> :
                    <span className='left'>၂လုံးအောက်&nbsp;</span>}

                  <span className='right'>{highestPayRate['2B'] || 'x'}&nbsp;</span>
                </button>
                {!_.includes(lottoName, '2D') ? (<button
                  // className={`btn-bevel btn-black  ${_.includes(currentType, 'SA') ? 'active' : ''}`}
                  className={_.includes(currentType, 'SA') ? 'button-red' : 'button-green'}
                  onClick={() => {
                    if (currentDigit !== '1') {
                      setCurrentType(['SA'])
                      setCurrentDigit('1')
                    } else {
                      setCurrentDigit('1')
                      handleType('SA')
                    }
                    // setIsPermutation(!isPermutation)
                  }}
                >
                  {language !== 'MM' ? <span className='left'>วิ่งบน&nbsp;</span> :
                    <span className='left'>အပေါ်ပြေး&nbsp;</span>}

                  <span className='right'>{highestPayRate['SA'] || 'x'}&nbsp;</span>
                </button>) : null}


                <button
                  // className={`btn-bevel btn-black ${_.includes(currentType, 'SB') ? 'active' : ''}`}
                  className={_.includes(currentType, 'SB') ? 'button-red' : 'button-green'}
                  onClick={() => {
                    if (currentDigit !== '1') {
                      setCurrentType(['SB'])
                      setCurrentDigit('1')
                    } else {
                      setCurrentDigit('1')
                      handleType('SB')
                    }
                    // setIsPermutation(!isPermutation)
                  }}
                >
                  {language !== 'MM' ? <span className='left'>วิ่งล่าง&nbsp;</span> :
                    <span className='left'>အောက်ပြေး&nbsp;</span>}

                  <span className='right'>{highestPayRate['SB'] || 'x'}&nbsp;</span>
                </button>
              </div>
              <ManualInputOptions typeList={currentType} lottoNumber={lottoNumber} isPermutation={isPermutation}/>
              {!showModal.isShow && !showYeekeeModal.isShow && (
                <KeyPad
                  isManualInput={isManualInput}
                  manualInputOption={manualInputOption}
                  isPermutation={isPermutation}
                  setIsPermutation={setIsPermutation}
                  typeList={currentType}
                  lottoNumber={lottoNumber}
                  lottoName={lottoName}
                  currentDigit={currentDigit}
                  fetchPayRate={fetchPayRate}
                />
              )}
            </>
          ) : (
            <>
              <div className='__btn-group make-gap text-xl'>
                {/*<input*/}
                {/*  className='filter'*/}
                {/*  type='number'*/}
                {/*  placeholder='ค้นหาตัวเลข'*/}
                {/*  value={filter}*/}
                {/*  onChange={handleFilter}*/}
                {/*/>*/}
                <button
                  // className={`btn-bevel border-on-active ${isPermutation ? 'bg-grey active' : 'bg-gray'}`}
                  className={isPermutation ? 'button-red' : 'button-green'}
                  onClick={() => {
                    // let tempTypeArray = currentType
                    // if (_.includes(tempTypeArray, '3R')) {
                    //   _.remove(tempTypeArray, function (type) {
                    //     return type === '3R'
                    //   })
                    // }
                    setIsPermutation(!isPermutation)
                  }}
                >
                  {language !== 'MM' ? <span style={{ color: 'white' }}>**** กลับตัวเลข ****</span> :
                    <span style={{ color: 'white' }}>**** ပြောင်းပြန်နံပါတ်များ ****</span>}
                </button>
              </div>
              {_.get(lotto, 'lottoName') === 'หวยรัฐบาลไทย' ? (
                <div className='__btn-th-group make-gap text-xl'>
                  <TypeThailandOptions/>
                </div>
              ) : (
                <div className='__btn-group make-gap text-xl'>
                  <TypeOptions/>{' '}
                </div>
              )}
              <div className='__overlay make-gap '>
                {!currentType.length > 0 ? <div className='__btn-number-group-overlay'></div> : null}
                {currentDigit === '3' ? (
                  <div className='__btn-num-range-group text-xl'>
                    <NumRangeOpitons/>
                  </div>
                ) : null}
                {_.includes(currentType, '2A') || _.includes(currentType, '2B') ? (
                  <div className='__btn-two-digit-sub-menu-group text-xl'>
                    <TwoDigitSubmenu/>
                  </div>
                ) : null}
                <div className='__btn-number-group text-xl'>
                  <NumberInput
                    filter={filter}
                    currentDigit={currentDigit}
                    currentNumRange={currentNumRange}
                    currentType={currentType}
                    isPermutation={isPermutation}
                    lottoNumber={lottoNumber}
                    fetchPayRate={fetchPayRate}
                    highestPayRate={highestPayRate}
                  />
                </div>
              </div>
            </>
          )}
        </section>

        <section>
          <div className='buy-lotto__direction text-lg'>
            {language !== 'MM' ? <div style={{ fontWeight: 'bold' }}>เงื่อนไขการแทง</div> :
              <div>
                <strong>ကစားနည်းများ</strong>
              </div>}

            {currentType.map((item, index) => (
              <div className='direction_item' key={index}>
                <ul key={lottoOptionTitle[item]}>
                  <li className='danger' key={index} style={{ color: 'black' }}>
                    {/* {lottoOptionTitle[item]} จ่ายสูงสุด : {highestPayRate[item]} */}
                    {lottoOptionTitle[item]}
                    <strong> {language === 'MM' ? 'စျေးကြီးတယ်။ :' : 'จ่ายสูงสุด :'} </strong> {highestPayRate[item]}
                  </li>
                </ul>
                {language !== 'MM' ?
                  <ul>
                    <li>แทงขั้นต่ำต่อครั้ง : 1 บาท</li>
                    <li>แทงสูงสุดต่อครั้ง : 100,000 บาท</li>
                    <li>แทงสูงสุดต่อเลข : 1,000 - 10,000 บาท</li>
                  </ul> :
                  <ul>
                    <li>ကြိမ်တ်ုင်းအနိမ့်ဆုံးထိုး ၁ ဘတ်</li>
                    <li>ကြိမ်တိုင်းအမြင့်ဆုံးထိုး ၁၀၀,၀၀၀ ဘတ်</li>
                    <li>ဂဏန်းအမြင့်ဆုံးထိုး ၁၀၀၀-၁၀,၀၀၀ဘတ်</li>
                  </ul>}
              </div>
            ))}
          </div>
          {/*<div className='s2 text-2xl buy-lotto__btn-group-black-box'>*/}
          {/*  /!*<button className='bg-gray btn-bevel'>ดึงโพย</button>*!/*/}
          {/*  <button className='bg-gray btn-bevel' onClick={() => handleClearCart()}>*/}
          {/*    <span>ยกเลิกทั้งหมด</span>*/}
          {/*  </button>*/}
          {/*  <button*/}
          {/*    className='bg-dark btn-bevel'*/}
          {/*    onClick={() => setShowModal({ isShow: true, currentModal: 'ใส่ราคา' })}*/}
          {/*  >*/}
          {/*    ใส่ราคา*/}
          {/*  </button>*/}
          {/*</div>*/}
        </section>
      </section>
    )
  }

  const SticktyButton = () => {
    const handleClearCart = () => {
      if (cartCounter > 0) {
        Swal.fire({
          icon: 'info',
          // title: 'แจ้งเตือน',
          title: 'Warning',
          // text: `คุณต้องการลบเลขออกจากรายการทั้งหมด`,
          text: `${language === 'MM' ? 'စာရင်းထဲမှ နံပါတ်ကို လုံးဝဖယ်ရှားလိုပါသည်။' : 'คุณต้องการลบเลขออกจากรายการทั้งหมด'}`,

          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: '#C0C0C0',
          // confirmButtonText: 'ลบเลข',
          // cancelButtonText: 'ยกเลิก',
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.value) {
            dispatch(clearCart())
            Swal.fire({
              icon: 'success',
              title: `${language === 'MM' ? 'အရာအားလုံးကို အောင်မြင်စွာ ရှင်းလင်းပြီးပါပြီ။' : 'ล้างรายการทั้งหมดสำเร็จ'}`,
              showConfirmButton: false,
              timer: 1000,
            })
          }
        })
      }
    }

    return (
      <div className='s2 text-2xl buy-lotto__btn-group-black-box'>
        {lottoName === 'หวยรัฐบาลไทย' ? (
          <button className='bg-dark btn-bevel' onClick={() => setShowModal({ isShow: true, currentModal: 'เลขอั้น' })}>
            <span style={{ color: '#ffffff' }}>{language !== 'MM' ? 'เลขอั้น' : 'Limit No.'}</span>
          </button>
        ) : null}
        <button className='bg-gray btn-bevel' onClick={() => handleClearCart()}>
          {/* ยกเลิกทั้งหมด */}
          {language !== 'MM' ? 'ยกเลิกทั้งหมด' : 'Cancel All'}
        </button>
        <button className='bg-dark btn-bevel' onClick={() => setShowModal({ isShow: true, currentModal: 'ใส่ราคา' })}>
          {/* ใส่ราคา */}
          {language !== 'MM' ? 'ต่อไป' : 'Next'}
        </button>
      </div>
    )
  }

  useEffect(() => {
    const selectNumber = (number, singleType) => {
      if (fetchPayRate[singleType]) {
        dispatch(
          toggleCart({
            lottoNumber,
            purchaseItem: {
              type: singleType,
              detail: number,
              amount: 1,
              payRate:
                fetchPayRate[singleType][number] === undefined
                  ? fetchPayRate[singleType].default
                  : fetchPayRate[singleType][number],
            },
          }),
        )
      }
    }
    const removeDups = (array) => {
      let unique = {}
      array.forEach(function(i) {
        if (!unique[i]) {
          unique[i] = true
        }
      })
      return Object.keys(unique)
    }

    const isAlreadyExists = (number, singleType) => {
      return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
    }

    const generatePermutation = (number, singleType) => {
      const result = Combinatorics.permutation(number.split('')).toArray()
      const unique = removeDups(result)
      let allChecked = false
      for (let i = 0; i < unique.length; i++) {
        let newNumber = unique[i].replace(/,/g, '').toString()
        if (!isAlreadyExists(newNumber, singleType)) {
          selectNumber(newNumber, singleType)
        } else {
          allChecked = true
        }
      }
      if (allChecked) {
        for (let i = 0; i < unique.length; i++) {
          let newNumber = unique[i].replace(/,/g, '').toString()
          selectNumber(newNumber, singleType)
        }
      }
    }
    // API.get('lotto', '/lottos?lottoNumber=' + lottoNumber)
    API.get('lotto', '/lottos/' + lottoNumber)
      .then(({ detail }) => {
        // const data = _.get(detail, '0', null)
        const data = detail || null
        // console.log(detail, 'detailasdf')
        if (!data || _.isEmpty(data)) {
          return Swal.fire({
            icon: 'error',
            title: `Lotto not found ID: ${lottoNumber}`,
            text: 'Please return to Lottos menu. Or contact the staff',
            showCancelButton: true,
            confirmButtonColor: '#B6964E',
            cancelButtonColor: '#C0C0C0',
            confirmButtonText: 'To Lottos',
          }).then((result) => {
            if (result.value) {
              history.push('/lottos')
            }
          })
        } else {
          setLotto(data)
          setLottoName(data.lottoName)
        }
      })
      .catch((err) => {
        console.error(err)
        return Swal.fire({
          icon: 'error',
          title: `An error occurred`,
          text: 'Please return to Lottos menu. Or contact the staff',
          confirmButtonColor: '#B6964E',
          confirmButtonText: 'To Lottos',
        }).then((result) => {
          console.log(result)
          history.push('/lottos')
        })
      })
      .then(() => {
        if (fastbuyQuery || isLoading) {
          _.mapKeys(fastbuyQuery, function(value, key) {
            if (!value) {
              return
            }
            var items = value.split(',')
            // console.log(items, 'fastbyuasdfaf')
            items.forEach((item) => {
              switch (key) {
                case 'three':
                  selectNumber(item, '3A')
                  selectNumber(item, '3R')
                  break
                case 'two':
                  generatePermutation(item, '2A')
                  generatePermutation(item, '2B')
                  break
                case 'run':
                  selectNumber(item, 'SA')
                  selectNumber(item, 'SB')
                  break
                case 'straight': {
                  let target = []
                  for (let i = 0; i < 10; i++) {
                    let front = item.concat(i)
                    let back = i.toString().concat(item)
                    target.push(front)
                    target.push(back)
                  }
                  _.uniqBy(target).forEach((item) => {
                    selectNumber(item, '2A')
                  })
                }
                {
                  let target = []
                  for (let i = 0; i < 10; i++) {
                    let front = item.concat(i)
                    let back = i.toString().concat(item)
                    target.push(front)
                    target.push(back)
                  }
                  _.uniqBy(target).forEach((item) => {
                    selectNumber(item, '2B')
                  })
                }
                  break
                default:
                  break
              }
            })
          })
        }
        setIsLoading(false)
      })
    return () => {
    }
  }, [fetchPayRate])

  useEffect(() => {
    if (showModal.isShow) {
      document.body.style.overflow = 'hidden'
    }

    if (!showModal.isShow) {
      document.body.style.overflow = 'unset'
      window.scrollTo(0, -10000)
    }

    return () => {
    }
  }, [showModal])

  useEffect(() => {
    window.scrollTo(0, -10000)
  }, [])

  return (
    <div id='containingDiv' className={`buy-lotto__wrapper page-lotto ${isPermutation ? 'permutation' : ''}`}>
      {showModal.currentModal === 'ใส่ราคา' ? (
        <CheckoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          type={currentType}
          currentDigit={currentDigit}
          highestPayRate={highestPayRate}
          language={language}
        />
      ) : null}
      {/*{lotto.lottoGroup === 'หวยยี่กี' && showYeekeeModal.currentModal === 'ยิงเลข' ? (*/}
      {/*  <YeekeeModal*/}
      {/*    showModal={showYeekeeModal}*/}
      {/*    setShowModal={setShowYeekeeModal}*/}
      {/*    lottoNumber={lottoNumber}*/}
      {/*    lottoData={lotto}*/}
      {/*  />*/}
      {/*) : null}*/}
      {lottoName === 'หวยรัฐบาลไทย' && showModal.currentModal === 'เลขอั้น' ? (
        <PayRateModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          fetchPayRate={fetchPayRate}
          highestPayRate={highestPayRate}
          language={language}
        />
      ) : null}
      {showModal.currentModal === 'confirm' ? (
        <ConfirmCheckoutModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoNumber={lottoNumber}
          lottoData={lotto}
          highestPayRate={highestPayRate}
          language={language}
          isAgentSubmit={isAgentSubmit}
          setIsAgentSubmit={setIsAgentSubmit}
          customerTable={customerTable}
          setCustomerTable={setCustomerTable}
        />
      ) : null}
      {showModal.currentModal === 'ดูสถิติ' ? (
        <StatisticsModal showModal={showModal} setShowModal={setShowModal} lottoData={lotto} language={language}/>
      ) : null}

      {/*<RulesModal showModal={showModal} setShowModal={setShowModal} lottoData={lotto} />*/}
      {showModal.currentModal === 'ดึงโพย' ? (
        <ReceiptModal
          showModal={showModal}
          setShowModal={setShowModal}
          lottoData={lotto}
          lottoNumber={lottoNumber}
          fetchPayRate={fetchPayRate}
          language={language}
        />
      ) : null}
      <Header/>
      <div className='buy-lotto container mx-auto'>
        {BuyLottoLeft()}
        {BuyLottoRight()}
        {SticktyButton()}
      </div>
    </div>
  )
}

export default connect()(BuyLotto)
