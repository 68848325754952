import _ from 'lodash'

import {
  TOGGLE_CART,
  CLEAR_CART,
  UPDATE_AMOUNT,
  UPDATE_RATIO,
  REMOVE_FROM_CART,
  UPDATE_UNIQUE_CART,
  REMOVE_LASTEST,
} from '../actions/cart'

/* Data structure >
==========================
state: {
  lottoNumber: 14,
  items: [
    {
      type: '2A',
      detail: '17',
      amount: 1200,
      payRate: 90
    },
    {
      type: '2A',
      detail: '43',
      amount: 999,
      payRate: 90
    },
    {
      type: '3R',
      detail: '123',
      amount: 50,
      payRate: 150
    },
  ],
  amount: 1000
}
*/

const INITIAL_STATE = {
  lottoNumber: null,
  items: [],
}

// Note: Can only add 1 lottoNumber into the cart.
const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CART: {
      const lottoNumber = _.get(action, 'lottoNumber', '')
      state.lottoNumber = lottoNumber
      const type = _.get(action, 'purchaseItem.type', '')
      const detail = _.get(action, 'purchaseItem.detail', [])
      const isNumInput = _.get(action, 'purchaseItem.isNumInput', false)

      const duplicatedLotto = state.items.find((item) => {
        return item.type === type && item.detail === detail && isNumInput
      })
      if (duplicatedLotto) {
        // found duplicate lotto of type and detail, so remove (toggle) it from state
        const duplicatedLottoIndex = state.items.findIndex((item) => item.type === type && item.detail === detail)

        state.items.splice(duplicatedLottoIndex, 1)
        const newState = { ...state } // important
        return newState
      } else {
        let newState = { ...state }
        const addedItem = {
          type: _.get(action, 'purchaseItem.type', ''),
          detail: _.get(action, 'purchaseItem.detail', ''),
          amount: _.get(action, 'purchaseItem.amount', 0),
          payRate: _.get(action, 'purchaseItem.payRate', 0),
        }

        newState.items.push(addedItem)
        return newState
      }
    }
    case REMOVE_FROM_CART: {
      console.log(action.stamp)
      const findItem = state.items.find(
        (obj) => obj.type === action.key && obj.detail === action.detail && obj.stamp === action.stamp
      )
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i] === findItem) {
          state.items.splice(i, 1)
          break
        }
      }
      const newState = { ...state } // important
      return newState
    }
    case CLEAR_CART: {
      state.items = []
      let newState = { ...state }
      return newState
    }
    case UPDATE_AMOUNT: {
      const newState = { ...state }
      // const targetType = _.get(action, 'purchaseItem.type', '')
      // const targetDetail = _.get(action, 'purchaseItem.detail', [])
      // let targetIndex = _.findIndex(newState.items, {
      //   type: targetType,
      //   detail: targetDetail,
      // })
      // console.log(newState.items[targetIndex])
      return newState
    }
    case UPDATE_RATIO: {
      let newState = { ...state }
      const targetType = _.get(action, 'purchaseItem.type', '')
      const targetDetail = _.get(action, 'purchaseItem.detail', [])
      let filterItem = newState.items.filter((obj) => obj.type === targetType && obj.detail === targetDetail)
      for (let i = 0; i < newState.items.length; i++) {
        if (filterItem.length === 1 && newState.items[i] === filterItem[0]) {
          newState.items[i].amount = action.amount
          break
        }
        if (filterItem.length > 1) {
          if (newState.items[i] === filterItem[0]) {
            filterItem.splice(0, 1)
            newState.items[i].amount = action.amount
          }
        }
      }
      return newState
    }
    case UPDATE_UNIQUE_CART: {
      let newState = { ...state }
      let uniquedCart = []
      let lottoGroupByType = _.groupBy(newState.items, 'type')
      _.forIn(lottoGroupByType, (value) => {
        uniquedCart.push(_.uniqBy(value, 'detail'))
      })
      newState.items = _.flattenDeep(uniquedCart)
      return { ...newState }
    }
    case REMOVE_LASTEST: {
      let newState = { ...state }
      let newItems = newState.items
      if (newItems.length > 0) {
        newItems.splice(newItems.length - 1, 1)
      }
      return newState
    }
    default:
      return state
  }
}

export default cartReducer
