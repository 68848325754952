import React from 'react'
import './SortArrows.scss'

const SortArrows = () => {
  return (
    <button className='sortArrows__wrapper'>
      <span>&and;</span>
      <span>&or;</span>
    </button>
  )
}

export default SortArrows
