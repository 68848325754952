import { getEnvironment, ENVIRONMENT } from './env'

export const BACKEND_URLS = {
  LOCAL: 'https://api.powerlott.com',
  // LOCAL: 'https://3monkeys-dev.api.vava.dev',
  DEVELOPMENT: 'https://api.powerlott.com',
  STAGING: 'https://api.powerlott.com',
  TEST: 'https://api.powerlott.com',
  PRODUCTION: 'https://api.powerlott.com',
}

export const FRONTEND_URLS = {
  LOCAL: 'http://localhost:3001/',
  DEVELOPMENT: 'https://www.powerlott.com',
  STAGING: 'https://www.powerlott.com',
  PRODUCTION: 'https://www.powerlott.com',
}

export const getBackendUrl = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return BACKEND_URLS.LOCAL
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return BACKEND_URLS.DEVELOPMENT
  }

  if (env === ENVIRONMENT.STAGING) {
    return BACKEND_URLS.STAGING
  }

  if (env === ENVIRONMENT.TEST) {
    return BACKEND_URLS.TEST
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return BACKEND_URLS.PRODUCTION
  }

  return BACKEND_URLS.DEVELOPMENT
}

export const getFrontendUrl = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return FRONTEND_URLS.DEVELOPMENT
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return FRONTEND_URLS.PRODUCTION
  }

  return FRONTEND_URLS.DEVELOPMENT
}
