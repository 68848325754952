import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import BoxWhite from '../../components/Box/BoxWhite'
import ruleslogo from '../../assets/rules-logo.svg'
import './Rules.scss'
import { RULE_PAGE } from '../../utils/lottoList'
import { getLang } from '../../redux/selectors/lang'

const Rules = () => {
  const lang = useSelector(getLang)
  const language = lang.lang
  return (
    // <BoxWhite containerClass='rules__wrapper'>
    <div className='rules__wrapper'>
      {/* <div className='rules__title-wrapper'> */}
      {/*  <h2 className='rules__title'>กฎกติกา</h2>*/}
      {/*</div>*/}
      <div className='rules__content mt-20'>
        <div className='rules__header-box1'>
          <img className='img1' src={ruleslogo} alt='ruleslogo'/>
          {/* <h2 className='rules__sub-title'>ประกาศถึงสมาชิกทุกท่าน</h2> */}
          <h2
            className='rules__sub-title'>{language === 'MM' ? 'အဖွဲ့ဝင်အားလုံးအား အသိပေးကြေငြာ' : RULE_PAGE['အဖွဲ့ဝင်အားလုံးအား အသိပေးကြေငြာ']}</h2>

          <img className='img2' src={ruleslogo} alt='ruleslogo'/>
        </div>

        <div className='rules__content-text-wrapper'>
          <p className='rules__content-text-wrapper-paragraph'>
            &nbsp;&nbsp;&nbsp;&nbsp;{language === 'MM' ? 'လေးစားအပ်ပါသော အဖွဲ့ဝင်များ  ယခုအချိန်တွင် အစွမ်းထက်ဆုံး အွန်လိုင်းနည်းပညာဖြစ်သော Dong Lotto မှ ကြိုဆိုပါသည်။  ထီကစားခြင်းအတွက်  လှပသော ဒေါင်းနတ်ဘုရား၏ ရောင်ခြည်တော်ကဲ့သို့ စီးဆင်းနေသည်။  အဖွဲ့ဝင်များ ဖျော်ဖြေရန်  ရိုးသားမှုအောက်မှာ  အဖွဲ့ဝင်အားလုံးသည် ခြွင်းချက်မရှိဘဲ ဤတစ်ခုတည်းသော စည်းမျဉ်းကို လိုက်နာပါသည်။' : RULE_PAGE['လေးစားအပ်ပါသော အဖွဲ့ဝင်များ  ယခုအချိန်တွင် အစွမ်းထက်ဆုံး အွန်လိုင်းနည်းပညာဖြစ်သော Dong Lotto မှ ကြိုဆိုပါသည်။  ထီကစားခြင်းအတွက်  လှပသော ဒေါင်းနတ်ဘုရား၏ ရောင်ခြည်တော်ကဲ့သို့ စီးဆင်းနေသည်။  အဖွဲ့ဝင်များ ဖျော်ဖြေရန်  ရိုးသားမှုအောက်မှာ  အဖွဲ့ဝင်အားလုံးသည် ခြွင်းချက်မရှိဘဲ ဤတစ်ခုတည်းသော စည်းမျဉ်းကို လိုက်နာပါသည်။']}
          </p>

          <p
            className='rules__content-text-wrapper-paragraph'
            style={{ fontSize: '19px', fontWeight: '700', textAlign: 'center' }}
          >
            {language === 'MM' ? 'Dong Lotto ကစားခြင်းအတွက် စည်းမျဉ်းများမှာ အောက်ပါအတိုင်းဖြစ်သည်။' : RULE_PAGE['Dong Lotto ကစားခြင်းအတွက် စည်းမျဉ်းများမှာ အောက်ပါအတိုင်းဖြစ်သည်။']}
          </p>
          {language === 'MM' ? (<ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
            <li>Powerlott သည် အဖွဲ့ဝင်များအတွက်သာ ရနိုင်ပါသည်။</li>
            <li>
              အဖွဲ့ဝင်များသည် ဝဘ်ဆိုက်ပေါ်တွင် အမျိုးမျိုးသောဂိမ်းများကို ကစားခွင့်ရှိပြီး Dong မှ သတ်မှတ်သည့်အတိုင်း
              ဆုလက်ဆောင်များ ရယူပိုင်ခွင့်ရှိသည်။
            </li>
            <li>
              ပျက်ပြယ်စာရင်း အရောင်းအ၀ယ်ပြုလုပ်သည့်အခါ အတည်မပြုနိုင်သော အကြောင်းအမျိုးမျိုးကြောင့်ဖြစ်သည်။
              အရောင်းအဝယ်နောက်ကျခြင်း။
            </li>
            <li>
              လိမ်လည်မှု တစ်စုံတစ်ရာ တွေ့ရှိပါက၊ အဖွဲ့ဝင်ကြောင့်ဖြစ်ရတဲ့ နှစ်မျိုးလုံး သို့မဟုတ် ထီပေါက်သူ ၊ ထီပေါက်သူ ၊
              သော့ခတ်နံပါတ်ရောင်းဝယ်ရေး စာရင်းစစ်ပြုလုပ်ပိုင်ခွင့်ကို ကျွန်ုပ်တို့လက်ဝယ်ရှိသည်။ လိမ်လည်လှည့်ဖြားမှုဟု
              ယူဆသော ထိုအဖွဲ့ဝင်အား နုတ်ထွက်ခြင်းအား ရပ်ဆိုင်းပါ။ မှန်ကန်ကြောင်း သက်သေပြပါက၊ အဖွဲ့ဝင်၏အကောင့်ထဲသို့
              ပြန်အမ်းငွေကို ကျွန်ုပ်တို့ လုပ်ဆောင်ပါမည်။ ဒါပေမယ့် မှားယွင်းကြောင်း သက်သေပြရင် အဲဒီအဖွဲ့ဝင်က
              သူတို့ရဲ့အကောင့်ကို ဆိုင်းငံ့ထားလိမ့်မယ်။ အမြဲတမ်းအဖွဲ့ဝင်ဖြစ်ခွင့် သို့မဟုတ် သင့်လျော်သောပြစ်ဒဏ်များကို
              ရုပ်သိမ်းပါ။
            </li>
            <li>
              လိမ်လည်မှု အမှန်တကယ် ဖြစ်ပွားကြောင်း သက်သေပြနိုင်လျှင် ကစားသမားအကောင့်များကို အပြီးတိုင်ပိတ်ပင်ရန်
              လိုအပ်ပါသည်။ နှင့်အခြားပြစ်ဒဏ်များ အပြုအမူအရ
            </li>
          </ul>) : (<ul style={{ listStyle: 'disc', paddingLeft: '15px' }}>
            <li>Powerlott ให้บริการสำหรับผู้ที่เป็นสมาชิกเท่านั้น</li>
            <li>สมาชิกมีสิทธิในการเล่นเกมส์ต่างๆ บนเว็บไซต์ และได้รับรางวัลตามที่ Powerlott กำหนด</li>
            <li>
              รายการที่เป็นโมฆะ อันเนื่องมาจากสาเหตุต่างๆ อาทิ การไม่กดยืนยันเมื่อทำรายการ การทำรายการช้ากว่ากำหนด
              ปัญหาเครือข่ายอินเตอร์เน็ตของลูกค้า การมีเครดิตไม่พอ และอื่นๆ หากรายการนั้นเป็นโมฆะ
              จะไม่ได้รับรางวัลทั้งสิ้นทุกกรณี{' '}
            </li>
            <li>
              หากพบการทุจริตไม่ว่ากรณีใดก็ตาม ทั้งที่เกิดจากตัวสมาชิก หรือตัวผู้ให้กำเนิดหวย เช่น การล็อคผลรางวัล
              การซื้อขายเลขล็อค ทางเราขอสงวนสิทธิ์ในการดำเนินการตรวจสอบ
              และระงับการถอนเงินของสมาชิกท่านนั้นที่เข้าข่ายมีพฤติกรรมทุจริต ซึ่งหากพิสูจน์ได้ว่าบริสุทธิ์จริง
              ทางเราจะดำเนินการคืนเงินเข้าสู่บัญชีของสมาชิก แต่หากพิสูจน์ได้ว่าผิดจริงสมาชิกรายนั้นจะถูกระงับบัญชี
              และเพิกถอนสิทธิ์การเป็นสมาชิกถาวร หรือ ลงโทษต่างๆ ตามเหมาะสม{' '}
            </li>
            {/* <li>
              หากพิสูจน์ได้ว่ามีการทุจริตเกิดขึ้นจริง ทางเรามีความจำเป็นในการแบนบัญชีผู้เล่นถาวร และบทลงโทษอื่นๆ
              ตามพฤติการณ์ครับ
            </li> */}
          </ul>)}
          <br/>
          {language !== 'MM' ? (
              <p className='rules__content-text-wrapper-paragraph' style={{ paddingTop: '20px' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;Powerlott มีความยินดีอย่างยิ่งที่ได้รับใช้คุณลูกค้า ขอให้ลูกค้าทุกท่านโชคดีตลอดปี
                เล่นแต่พอดี เล่นให้สนุก รู้จักวางแผน รู้จักชนะใจตัวเอง รวยแน่นอนครับ
              </p>) :
            (<p className='rules__content-text-wrapper-paragraph'>
              &nbsp;&nbsp;&nbsp;&nbsp;Powerlott သည် ဖောက်သည်များကို ဝန်ဆောင်မှုပေးရာတွင် အလွန်ပျော်ရွှင်ပါသည်။
              ကျွန်ုပ်တို့၏ဖောက်သည်များအားလုံး တစ်နှစ်ပတ်လုံးကံကောင်းကြပါစေ၊ လုံလုံလောက်လောက်ကစားပါ၊ ပျော်ရွှင်ပါစေ၊
              အစီအစဉ်ဆွဲနည်းသိ၊ သင့်နှလုံးသားကို အနိုင်ယူနည်းကို သိရှိနိုင်ပါစေကြောင်း ဆုမွန်ကောင်းတောင်းအပ်ပါသည်။
              ချမ်းသာမှာ သေချာပါတယ်။
            </p>)}

          <div className='rules__header-box2'>
            {language !== 'MM' ? (
             <h3 className='rules__sub-buttom'>ขอให้ทุกท่านประสบแต่ความโชคดี</h3> ) :
              (<h3 className='rules__sub-buttom'>
              အားလုံးပဲ ကံကောင်းကြပါစေလို့ Dong Dong မှ ဆုမွန်ကောင်းတောင်းလိုက်ပါတယ်။
            </h3>) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Rules
