import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'

import { getAuth } from '../../../redux/selectors/auth'
import { createNewTest } from '../../../graphql/mutations'
// import { listTests } from '../../../graphql/queries'

export default function YeekeeNumPad(props) {
  const [yeekeeInputValue, setYeekeeInputValue] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const lottoRound = props.lottoRound
  const currentDigit = '5'
  const auth = useSelector(getAuth)

  const isYeekeeInput = props.isYeekeeInput
  const setIsYeekeeInput = props.setIsYeekeeInput
  const timeToCheck = props.timeToCheck

  const [timer, setTimer] = React.useState(0)
  const id = useRef(null)
  const clear = () => {
    window.clearInterval(id.current)
  }

  const selectNumber = (number) => {
    const d = new Date()
    d.setHours(24, 0, 0)
    const isOpen = timeToCheck.isSameOrAfter(moment(), 'seconds')
    if (isOpen) {
      if (auth.username && timer === 0) {
        const userEmail = auth.attributes.email.split('@')[0] || auth.attributes.phone_number
        const item = {
          email: userEmail,
          number: number,
          time: moment(new Date()).format('HH:mm:ss'),
          round: lottoRound,
          expire: Math.floor(d / 1000),
        }
        //
        /* create a todo */
        const test = API.graphql(graphqlOperation(createNewTest, { input: item }))
        console.log(test, 'test Query graphql')
        setIsYeekeeInput(!isYeekeeInput)
      } else {

      }
    } else {
      return Swal.fire({
        icon: 'error',
        title: 'หมดเวลายิงเลขแล้วนะคะ',
      })
    }
  }

  const clearInput = () => {
    setIsLoading(true)
    const box = document.getElementById('manual-input-indicator')
    box.classList.add('animate')
    setTimeout(() => {
      box.classList.remove('animate')
      setYeekeeInputValue([])
      setIsLoading(false)
    }, 100)
  }

  const handleSelectNumber = () => {
    if (!isLoading) {
      if (yeekeeInputValue.length > 0) {
        if (currentDigit === '5') {
          if (yeekeeInputValue.length === 5) {
            selectNumber(yeekeeInputValue.join(''))
            clearInput()
            setTimeout(() => {
              setTimer(10)
            }, 100)
          }
        }
      }
    }
  }

  const NumberIndicator = () => {
    if (auth.username) {
      if (timer > 0) {
        return <span style={{ color: 'orange', minHeight: '50px', marginTop: '10px' }}>{timer}</span>
      } else {
        let numberBox = []
        for (let index = 0; index < currentDigit; index++) {
          numberBox.push(<div key={index}>{yeekeeInputValue[index] ? yeekeeInputValue[index] : '-'}</div>)
        }
        return numberBox
      }
    } else {
      return 'กรุณาเข้าสู่ระบบ'
    }
  }

  const handleNumpad = (num, e = null) => {
    const numString = num.toString()
    if (e !== null) {
      e.preventDefault()
    }
    setYeekeeInputValue((state) => [...state, numString])
  }

  const handleRandomNumber = () => {
    const randomDigits = Array.from({ length: 5 }, () => Math.floor(Math.random() * 10))
    setYeekeeInputValue(randomDigits)
  }

  const buyWithKeyboard = (e) => {
    if (auth.username) {
      console.log(e)
      const acceptNumKey = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
      const acceptActionDelete = ['Backspace', 'Delete']
      if (_.includes(acceptNumKey, e.key)) {
        handleNumpad(e.key)
      }
      if (_.includes(acceptActionDelete, e.key)) {
        setYeekeeInputValue((state) => (state.length > 0 ? [...state.slice(0, -1)] : [...state]))
      }
    }
  }

  const handleClearNumber = () => {
    setYeekeeInputValue([])
  }

  useEffect(() => {
    if (timer === 0) {
      if (yeekeeInputValue.length.toString() === currentDigit) {
        handleSelectNumber()
      }
      document.addEventListener('keydown', buyWithKeyboard)
      return () => {
        document.removeEventListener('keydown', buyWithKeyboard)
      }
    }
  }, [yeekeeInputValue, timer])

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1)
    }, 1000)
    return () => clear()
  }, [timer])

  useEffect(() => {
    if (timer === 0) {
      clear()
    }
  }, [timer])

  return (
    <div className='buy-lotto__btn-manual-input' style={{ padding: '10px', margin: '5px 10px' }}>
      <div style={{ paddingBottom: '0px', borderBottom: 'none' }}>
        <div>
          <div></div>
          <div style={{ padding: '0px', border: 'none' }}></div>
        </div>
        <div className='text-4xl'>
          <div className='buy-lotto__manual-input-indicator' id='manual-input-indicator'>
            <NumberIndicator />
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(1, event)}
            >
              1
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(2, event)}
            >
              2
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(3, event)}
            >
              3
            </button>
          </div>
          <div>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(4, event)}
            >
              4
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(5, event)}
            >
              5
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(6, event)}
            >
              6
            </button>
          </div>
          <div>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(7, event)}
            >
              7
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(8, event)}
            >
              8
            </button>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(9, event)}
            >
              9
            </button>
          </div>
          <div>
            <button
              className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
              onClick={(event) => handleNumpad(0, event)}
            >
              0
            </button>
          </div>
        </div>
        <div>
          <button
            className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
            onClick={() => setYeekeeInputValue((state) => (state.length > 0 ? [...state.slice(0, -1)] : [...state]))}
          >
            <svg
              width='24'
              height='24'
              xmlns='http://www.w3.org/2000/svg'
              fill='#ffffff'
              fillRule='evenodd'
              clipRule='evenodd'
            >
              <path d='M7 5h17v16h-17l-7-7.972 7-8.028zm7 6.586l-2.586-2.586-1.414 1.414 2.586 2.586-2.586 2.586 1.414 1.414 2.586-2.586 2.586 2.586 1.414-1.414-2.586-2.586 2.586-2.586-1.414-1.414-2.586 2.586z' />
            </svg>
          </button>
          <button
            className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
            onClick={() => handleClearNumber()}
          >
            ลบทั้งหมด
          </button>
          <button
            className={`${!auth.username || timer > 0 ? 'disabled' : ''} btn-bevel`}
            onClick={() => handleRandomNumber()}
          >
            สุ่มเลข
          </button>
        </div>
      </div>
    </div>
  )
}

YeekeeNumPad.propTypes = {
  lottoRound: PropTypes.string,
  setIsYeekeeInput: PropTypes.func,
  isYeekeeInput: PropTypes.bool,
  timeToCheck: PropTypes.func,
}
