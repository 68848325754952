import React, { useEffect, useState } from 'react'

import HomeContainer from './HomeContainer'
import loadingImg from '../../assets//dong-loading/dong-loading.png'

import './Home.scss'
import './HomePost.scss'

function Home() {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true)
    }
    return () => { }
  }, [isLoaded])

  return (
    <div className='home page'>
      <div className='news container mx-auto'>
        {isLoaded ? (
          <HomeContainer />
        ) : (
          <div className='loader loading-gif'>
            <img className='loading-indicator-gif ' src={loadingImg} alt='loading' />
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
