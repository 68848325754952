import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API, Auth } from 'aws-amplify'
import _get from 'lodash/get'
import _map from 'lodash/map'
import Swal from 'sweetalert2'
import Modal from 'react-modal'
import _mapKeys from 'lodash/mapKeys'
import _omitBy from 'lodash/omitBy'
import _isNil from 'lodash/isNil'
import AliceCarousel from 'react-alice-carousel'
import { composePhoneNumber } from '../../utils/phoneNumber'

import { getAuth } from '../../redux/selectors/auth'
import BoxWhite from '../../components/Box/BoxWhite'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import { mapBankCodeToBankName } from '../../utils/mapBankCodeToBankName'
import Button from '../../components/Button/Button'
import Input from '../../components/Form/Input'
import lineIcon from '../../assets/icon/line-icon.png'
import magnifyingGlass from '../../assets/bank/magnifying-glass.svg'
import bankBannerImg from '../../assets/bank/bank-banner.png'
import { BANK_PAGE } from '../../utils/lottoList'

import 'react-alice-carousel/lib/alice-carousel.css'
import './AddBankAccount.scss'
import { getLang } from '../../redux/selectors/lang'

const AddBankAccount = () => {
  const auth = useSelector(getAuth)
  const username = auth.username
  const [formState, setFormState] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isFormChange, setIsFormChange] = useState(false)
  const [contactModal, setContactModal] = useState(false)
  const [playerBankAccount, setPlayerBankAccount] = useState(null)
  // const [language === 'MM', setIsThai] = useState(true)
  const lang = useSelector(getLang)
  const language = lang.lang

  const [fetchedUserData, setFetchedUserData] = useState({})
  const [formData, setFormData] = useState({
    tel: '',
  })
  const [isPhoneRegister, setIsPhoneRegister] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await Auth.currentAuthenticatedUser()
      setFetchedUserData(user)
      if (user.attributes.phone_number) {
        setIsPhoneRegister(true)
      }
    }
    fetchUserData()
  }, [])


  useEffect(() => {
    setFormData({
      tel: _get(fetchedUserData, 'attributes.phone_number', ''),
    })
  }, [fetchedUserData])

  const mapToCognitoAttributes = {
    tel: 'phone_number',
  }

  const _handleChangeForm = ({ target }) => {
    const profileData = { ...formData }
    profileData[target.name] = target.value
    setFormData(profileData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const payload = _mapKeys(formData, (value, key) => {
        return mapToCognitoAttributes[key] || key
      })
      Object.keys(payload).forEach((key) => {
        if (key === null || key === 'null' || typeof key === 'undefined') {
          delete payload[key]
        }
      })
      payload.phone_number = composePhoneNumber('+66', payload.phone_number)
      if (payload.phone_number.length !== 12) {
        Swal.fire({
          icon: 'error',
          title: 'กรุณากรอกเบอร์มือถือให้ถูกต้อง',
        })
        return
      }
      const finalPayload = _omitBy(payload, _isNil)

      const user = await Auth.currentAuthenticatedUser()

      await Auth.updateUserAttributes(user, finalPayload)

      setIsPhoneRegister(true)
    } catch (error) {
      console.error('error profile handleSubmit>>>', error)
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: error.message || '',
      })
    }
  }

  useEffect(() => {
    fetchActiveBankAccount()
  }, [username])

  const validateAll = () => {
    if (
      !formState.accountName ||
      !formState.accountNumber ||
      !formState.accountNumberConfirm ||
      !formState.bankCode ||
      !formState.bankName
    ) {
      return false
    }

    if (formState.accountNumber !== formState.accountNumberConfirm) {
      return false
    }

    return true
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '0',
      right: '0',
      bottom: 'auto',
      transform: 'translateY(-50%)',
      maxWidth: '550px',
      margin: '0 auto',
      background: 'transparent',
      border: 'none',
      outline: 'none',
      overflow: 'inherit',
    },
  }

  const _onClickSecondButton = async () => {
    if (!username) {
      return Swal.fire({
        icon: 'error',
        title: 'กรุณาเข้าสู่ระบบ',
      })
    }

    const isValidForm = validateAll()
    if (!isValidForm) {
      return Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      })
    }

    const isChangeBankAccount = !!playerBankAccount
    if (isChangeBankAccount) {
      const isConfirmedChangeBankAccount = await Swal.fire({
        icon: 'warning',
        title: 'คำเตือน',
        text: 'หลังจากยืนยันการเปลี่ยนบัญชี ต้องติดต่อแอดมินเพื่อแสดงหลักฐาน ท่านแน่ใจหรือไม่',
        // title: 'Warning',
        // text: 'Changing the bank account is required contacting admin. Do you want to proceed?',
        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'ยืนยัน',
        confirmButtonText: 'Confirm',
        showCancelButton: true,
        cancelButtonColor: '#C0C0C0',
        // cancelButtonText: 'ยกเลิก',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.value) {
          return true
        }
        return false
      })

      if (!isConfirmedChangeBankAccount) {
        return
      }
    }

    setIsLoading(true)

    const payload = {
      accountName: formState.accountName,
      bankCode: formState.bankCode,
      bankName: mapBankCodeToBankName[formState.bankCode],
      accountNumber: formState.accountNumber,
    }
    API.post('playerBankAccount', `/players/${username}/request`, {
      body: payload,
    })
      .then((res) => {
        const resBankAccount = _get(res, 'detail', null)
        if (resBankAccount) {
          if (isChangeBankAccount) {
            setIsLoading(false)
            setPlayerBankAccount(resBankAccount)
            return Swal.fire({
              icon: 'success',
              title: 'ส่งคำสั่งเปลี่ยนบัญชีธนาคารสำเร็จ กรุณาติดต่อแอดมินเพื่อแสดงหลักฐาน',
              text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
              confirmButtonText: 'ติดต่อแอดมิน',

              // title: 'Your request was submitted. Please contact admin to verify.',
              // text: `Name: ${resBankAccount.accountName}, Account Number: ${resBankAccount.accountNumber})`,
              // confirmButtonText: 'Contact admin',
            }).then(function(isConfirm) {
              if (isConfirm) {
                setContactModal(!contactModal)
              }
            })
          }
          setIsLoading(false)
          setPlayerBankAccount(resBankAccount)
          return Swal.fire({
            icon: 'success',
            title: 'เพิ่มบัญชีธนาคารสำเร็จ',
            text: `ชื่อบัญชี: ${resBankAccount.accountName}, เลขบัญชี: ${resBankAccount.accountNumber})`,
            // title: 'Your bank account was successfully added.',
            // text: `Name: ${resBankAccount.accountName}, Account Number: ${resBankAccount.accountNumber})`,
          })
        } else {
          // throw new Error('ระบบผิดพลาด ไม่สามารถเพิ่มบัญชีธนาคารได้ กรุณาติดต่อเจ้าหน้าที่')
          throw new Error('System error can not add your bank account. Please contact admin.')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('error add bank account ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'กรุณาติดต่อเจ้าหน้าที่',
          // title: 'Undefined error occur.',
          // text: 'Please contact admin',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _onClickFirstButton = () => {
    setFormState({})
  }

  const _onChangeForm = (e, inputName) => {
    setIsFormChange(true)
    const value = e.target.value

    if (inputName === 'bankCode') {
      setFormState({ ...formState, [inputName]: value, bankName: mapBankCodeToBankName[value] })
      return
    }

    if (inputName === 'accountNumber' || inputName === 'accountNumberConfirm') {
      const newValue = value.replace(/[^0-9]/g, '')
      setFormState({ ...formState, [inputName]: newValue })
      return
    }

    setFormState({ ...formState, [inputName]: value })
  }

  const fetchActiveBankAccount = async () => {
    setIsLoading(true)
    const result = await API.get('playerBankAccount', `/players/${username}/bank-account`)
      .then((res) => {
        const detail = _get(res, 'detail', null)
        const code = _get(res, 'code', null)
        if (code === 0) {
          setPlayerBankAccount(detail)
          setFormState({ ...detail, accountNumberConfirm: detail.accountNumber })
          setIsLoading(false)

          return detail
        }
      })
      .catch((err) => {
        console.log('error check bank account ======')
        console.error(err)
        setPlayerBankAccount(null)
        setIsLoading(false)
        // Swal.fire({
        //   icon: 'error',
        //   title: 'เกิดข้อผิดพลาด',
        //   text: err.message,
        // })
      })
      .finally(() => {
        setIsLoading(false)
      })

    return result
  }

  const fetchPendingBankAccount = async () => {
    setIsLoading(true)
    const result = await API.get('playerBankAccount', `/players/${username}/request`)
      .then((res) => {
        const detail = _get(res, 'detail', null)
        const code = _get(res, 'code', null)
        if (code === 0) {
          setIsLoading(false)
          Swal.fire({
            icon: 'info',
            title: 'บัญชีธนาคารของคุณกำลังรอการยืนยันจากเจ้าหน้าที่',
            text: `ชื่อบัญชี: ${detail.accountName}, เลขบัญชี: ${detail.accountNumber})`,
            // title: 'Your bank account was under verification.',
            // text: `Name: ${detail.accountName}, Account Number: ${detail.accountNumber})`,
          })
          return true
        } else {
          // throw new Error('ไม่พบบัญชีธนาคารของคุณ กรุณาติดต่อเจ้าหน้าที่')
          throw new Error('Your bank account was not found. Please contact admin.')
        }
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('error check bank account ======')
        console.log(err.message)

        let message = ''
        if (err.message === 'Request failed with status code 400') {
          message = 'ไม่พบบัญชีธนาคารของคุณ'
          // message = 'Your bank account was not found.'
        }

        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: message || 'กรุณาติดต่อเจ้าหน้าที่',
          // title: 'Error',
          // text: message || 'Please contact admin',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })

    return result
  }

  const _onClickCheckBankStatus = async () => {
    if (!username) {
      return Swal.fire({
        icon: 'error',
        title: 'กรุณาเข้าสู่ระบบ',
        // title: 'Please log in',
      })
    }

    // Check "active" bank account
    const activeBankAccount = await fetchActiveBankAccount()
    if (activeBankAccount) {
      // no need to check further more for pending bank account
      Swal.fire({
        icon: 'success',
        title: 'บัญชีธนาคารของคุณได้รับการยืนยันแล้ว',
        text: `ชื่อบัญชี: ${activeBankAccount.accountName}, เลขบัญชี: ${activeBankAccount.accountNumber})`,

        // title: 'Bank Account Confirmed !',
        // text: `Name: ${activeBankAccount.accountName}, Account Number: ${activeBankAccount.accountNumber})`,
      })
      return
    }

    // Check "pending" bank account
    await fetchPendingBankAccount()
  }

  return (
    <section className='newAccount newAccount__container mt-20'>
      <div className='bankbanner'>
        <img src={bankBannerImg}/>
      </div>

      <BoxWhite containerClass='newAccount__add'>
        {/* <h1>เพิ่มบัญชีธนาคาร</h1> */}
        <h1>{language === 'MM' ? 'ဘဏ်အကောင့်ထည့်ပါ။' : BANK_PAGE['ဘဏ်အကောင့်ထည့်ပါ။']}</h1>
        <button className='newAccount__button' onClick={_onClickCheckBankStatus}>
          <img src={magnifyingGlass} alt='icon'/>
          <span>เช็คสถานะบัญชี</span>
          {/* <span>check status</span> */}
        </button>
      </BoxWhite>
      {isPhoneRegister ? (
        <BoxWhite containerClass='newAccount__form'>
          <form className='form__wrapper'>
            <div className='form__setWrapper'>
              <div className='form__itemBank'>
                <label className='form__item_bank' htmlFor='bank-selection'>
                  {/* เลือกธนาคาร */}
                  {language === 'MM' ? 'ဘဏ်ကိုရွေးပါ။' : BANK_PAGE['ဘဏ်ကိုရွေးပါ။']}
                </label>
                <div className="bankAndLogo">
                  <select
                    name='bank'
                    id='bank-selection'
                    value={formState.bankCode || ''}
                    onChange={(e) => _onChangeForm(e, 'bankCode')}
                  >
                    {/* <option value=''>กรุณาเลือกธนาคาร</option> */}
                    <option
                      value=''>   {language === 'MM' ? 'ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။' : BANK_PAGE['ဘဏ်အကောင့်တစ်ခုကို ရွေးပါ။']}</option>
                    {_map(mapBankCodeToBankName, (bankName, bankCode) => {
                      return <option value={bankCode}>{language === 'MM' ? BANK_PAGE[bankName] : bankName}</option>
                    })}
                  </select>
                  {formState.bankCode && formState.bankCode !== '' ?
                    <img src={require(`../../assets/bank-logo/${formState.bankCode}.png`)}
                         alt='bank-user-logo'/> : null}
                </div>
              </div>
              <div className='form__item'>
                <label className='form__item_accountName' htmlFor='account-name'>
                  {/* ชื่อบัญชี */}
                  {language === 'MM' ? 'အကောင့်နာမည်' : BANK_PAGE['အကောင့်နာမည်']}
                </label>
                <input
                  type='text'
                  name='account-name'
                  id='account-name'
                  placeholder='ชื่อ นามสกุล หน้าสมุดบัญชี'
                  // placeholder='Fullname on the bank account'
                  value={formState.accountName || ''}
                  onChange={(e) => _onChangeForm(e, 'accountName')}
                />
              </div>
            </div>
            <div className='form__setWrapper'>
              <div className='form__item'>
                <label className='form__item_accountNumber' htmlFor='account-number'>
                  {/* เลขบัญชี */}
                  {language === 'MM' ? 'အကောင့်နံပါတ်' : BANK_PAGE['အကောင့်နံပါတ်']}
                </label>
                <input
                  type='text'
                  name='account-number'
                  id='account-number'
                  placeholder='ระบุ เฉพาะตัวเลข'
                  // placeholder='number only'
                  value={formState.accountNumber || ''}
                  onChange={(e) => _onChangeForm(e, 'accountNumber')}
                />
              </div>
              <div className='form__item'>
                <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                  {/* ยืนยันเลขบัญชี */}
                  {language === 'MM' ? 'အကောင့်နံပါတ်ကိုအတည်ပြုပါ။' : BANK_PAGE['အကောင့်နံပါတ်ကိုအတည်ပြုပါ။']}
                </label>
                <input
                  type='text'
                  name='account-number-confirm'
                  id='account-number-confirm'
                  placeholder='ยืนยันเลขบัญชีอีกครั้ง'
                  // placeholder='အကောင့်နံပါတ်ကို ထပ်မံအတည်ပြုပါ။'
                  value={formState.accountNumberConfirm || ''}
                  onChange={(e) => _onChangeForm(e, 'accountNumberConfirm')}
                />
              </div>
            </div>
            <hr className='divider'/>
            {isLoading ? (
              <div className='loading-gif'>
                <img
                  className='loading-indicator-gif '
                  src={require('../../assets/dong-loading/dong-loading.png')}
                  alt='loading'
                />
              </div>
            ) : isFormChange ? (
              <ButtonGroup
                onClickFirstButton={_onClickFirstButton}
                onClickSecondButton={_onClickSecondButton}
                withBorder
                firstText='ยกเลิก'
                // firstText='Cancel'
                secondText={playerBankAccount ? 'แก้ไขบัญชี' : 'เพิ่มบัญชี'}
                // secondText={playerBankAccount ? 'Edit' : 'Add'}
              />
            ) : null}

            {playerBankAccount ? (
              <div className='p-2'>
                {language === 'TH' ? <p>กรุณาเพิ่มบัญชีธนาคารของท่านสำหรับการเติมเครดิตในครั้งแรก ซึ่งจะใช้ในการฝากเงิน
                  และถอนเงินออกจากระบบ</p> : <p>Add your bank account for the first time</p>}
                <br/>
                {language === 'TH' ? <p>เมื่อทำการแก้ไขบัญชี ต้องทำการติดต่อแอดมินด้วยปุ่ม "Contact us"</p> :
                  <p>Changing you bank account please contact admin</p>}
              </div>
            ) : null}
          </form>
        </BoxWhite>
      ) : (
        <BoxWhite containerClass='newAccount__form'>
          <form className='form__wrapper'>
            <div className='form__setWrapper'>
              <div className='form__item'>
                <label className='form__item_accountNumber-confirm' htmlFor='account-number-confirm'>
                  {language === 'TH' ? 'กรุณากรอกเบอร์โทรศัพท์มือถือของท่าน' :
                    'Input your phone number'}
                </label>
                <Input onChange={_handleChangeForm} type='tel' id='tel' value={formData.tel}/>
              </div>
            </div>
            <hr className='divider'/>
            {isLoading ? (
              <div className='loading-gif'>
                <img
                  className='loading-indicator-gif '
                  src={require('../../assets//dong-loading/dong-loading.png')}
                  alt='loading'
                />
              </div>
            ) : (
              <Button onButtonClick={handleSubmit} wrapperClassName='profile__submit' text='บันทึก'/>
            )}
          </form>
        </BoxWhite>
      )}
      <Modal
        isOpen={contactModal}
        onRequestClose={() => {
          setContactModal(false)
        }}
        style={customStyles}
        contentLabel='Contact Modal'
      ></Modal>
    </section>
  )
}

export default AddBankAccount
