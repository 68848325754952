import React from 'react'

export default function Flare2() {
  return (
    <div className='flare-2'>
      <div className='source'></div>
      <div className='source-beam'></div>
    </div>
  )
}
