export const ENVIRONMENT = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  TEST: 'test',
  PRODUCTION: 'production',
}

export const getEnvironment = () => {
  /*
    We set the Environment named "REACT_APP_BUILD_ENV" in Amplify Console (e.g. see also: https://ap-southeast-1.console.aws.amazon.com/amplify/home?region=ap-southeast-1#/d2dgbaeoygswk2).
    So we can access the build environment by using `process.env.REACT_APP_BUILD_ENV`
    Now we have as follows: 
    1. development branch: process.env.REACT_APP_BUILD_ENV === 'development'
    2. master branch: process.env.REACT_APP_BUILD_ENV === 'staging'
  */

  return process.env.REACT_APP_BUILD_ENV
}

export const isLocalEnvironment = () => {
  return getEnvironment() === ENVIRONMENT.LOCAL
}

export const isDevelopEnvironment = () => {
  return getEnvironment() === ENVIRONMENT.DEVELOPMENT
}

export const isStagingEnvironment = () => {
  return getEnvironment() === ENVIRONMENT.STAGING
}

export const isTestEnvironment = () => {
  return getEnvironment() === ENVIRONMENT.TEST
}

export const isProductionEnvironment = () => {
  return getEnvironment() === ENVIRONMENT.PRODUCTION
}

export const GA_ID = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.PRODUCTION) {
    return 'UA-192766378-1'
  }

  return 'UA-192766378-2'
}
