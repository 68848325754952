import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { FacebookShareButton, LineShareButton } from 'react-share'
import Swal from 'sweetalert2'
import { API, Auth } from 'aws-amplify'

import coin from '../../assets/svg/coin.svg'
import iconmonstrArrow65 from '../../assets/svg/iconmonstr-arrow-65.svg'
import { useSelector } from 'react-redux'
import giftBox from '../../assets/dong-giftbox/gift-box.png'
import mystery from '../../assets/dong-giftbox/mystery-box.png'
import fullmoon from '../../assets/dong-giftbox/fullmoon-box.png'
import halloween from '../../assets/dong-giftbox/halloween-box.png'
import wizard from '../../assets/dong-giftbox/wizard-box.png'
import { getFrontendUrl } from '../../config/urls'
import './HomeHotpick.scss'
import { getAuth } from '../../redux/selectors/auth'

const FacebookIcon = () => {
  return (
    <svg viewBox='0 0 64 64' width='32' height='32' className='social-icon social-icon--facebook '>
      <g>
        <circle cx='32' cy='32' r='31' fill='#3b5998'></circle>
      </g>
      <g>
        <path
          d='M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z'
          fill='white'
        ></path>
      </g>
    </svg>
  )
}

const LineIcon = () => {
  return (
    <svg viewBox='0 0 64 64' width='32' height='32' className='social-icon social-icon--line '>
      <g>
        <circle cx='32' cy='32' r='31' fill='#00b800'></circle>
      </g>
      <g>
        <path
          d='M52.62 30.138c0 3.693-1.432 7.019-4.42 10.296h.001c-4.326 4.979-14 11.044-16.201 11.972-2.2.927-1.876-.591-1.786-1.112l.294-1.765c.069-.527.142-1.343-.066-1.865-.232-.574-1.146-.872-1.817-1.016-9.909-1.31-17.245-8.238-17.245-16.51 0-9.226 9.251-16.733 20.62-16.733 11.37 0 20.62 7.507 20.62 16.733zM27.81 25.68h-1.446a.402.402 0 0 0-.402.401v8.985c0 .221.18.4.402.4h1.446a.401.401 0 0 0 .402-.4v-8.985a.402.402 0 0 0-.402-.401zm9.956 0H36.32a.402.402 0 0 0-.402.401v5.338L31.8 25.858a.39.39 0 0 0-.031-.04l-.002-.003-.024-.025-.008-.007a.313.313 0 0 0-.032-.026.255.255 0 0 1-.021-.014l-.012-.007-.021-.012-.013-.006-.023-.01-.013-.005-.024-.008-.014-.003-.023-.005-.017-.002-.021-.003-.021-.002h-1.46a.402.402 0 0 0-.402.401v8.985c0 .221.18.4.402.4h1.446a.401.401 0 0 0 .402-.4v-5.337l4.123 5.568c.028.04.063.072.101.099l.004.003a.236.236 0 0 0 .025.015l.012.006.019.01a.154.154 0 0 1 .019.008l.012.004.028.01.005.001a.442.442 0 0 0 .104.013h1.446a.4.4 0 0 0 .401-.4v-8.985a.402.402 0 0 0-.401-.401zm-13.442 7.537h-3.93v-7.136a.401.401 0 0 0-.401-.401h-1.447a.4.4 0 0 0-.401.401v8.984a.392.392 0 0 0 .123.29c.072.068.17.111.278.111h5.778a.4.4 0 0 0 .401-.401v-1.447a.401.401 0 0 0-.401-.401zm21.429-5.287c.222 0 .401-.18.401-.402v-1.446a.401.401 0 0 0-.401-.402h-5.778a.398.398 0 0 0-.279.113l-.005.004-.006.008a.397.397 0 0 0-.111.276v8.984c0 .108.043.206.112.278l.005.006a.401.401 0 0 0 .284.117h5.778a.4.4 0 0 0 .401-.401v-1.447a.401.401 0 0 0-.401-.401h-3.93v-1.519h3.93c.222 0 .401-.18.401-.402V29.85a.401.401 0 0 0-.401-.402h-3.93V27.93h3.93z'
          fill='white'
        ></path>
      </g>
    </svg>
  )
}

const CloseIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='#ffffff' width='24' height='24' viewBox='0 0 24 24'>
      <path
        d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'/>
    </svg>
  )
}

export default function Hotpick({ data = null }) {
  let history = useHistory()
  const [collpased, setCollapsed] = useState(true)
  const auth = useSelector(getAuth)
  const [selected, setSelected] = useState({
    three: [],
    two: [],
    straight: [],
    run: [],
  })
  const [lottoData, setLottoData] = useState({})

  const ShareSheet = ({ url = '' }) => {
    const [isCollapse, setIsCollapse] = useState(true)
    return (
      <div
        className={`py-1 px-4 home-share-sheet-btn ${!isCollapse ? 'active' : ''}`}
        onClick={() => setIsCollapse(!isCollapse)}
      >
        {isCollapse ? (
          <span>Share</span>
        ) : (
          <div className='home-share-sheet'>
            <FacebookShareButton url={getFrontendUrl() + url}>
              <FacebookIcon/>
            </FacebookShareButton>
            <LineShareButton url={getFrontendUrl() + url}>
              <LineIcon/>
            </LineShareButton>
            <button onClick={() => setIsCollapse(!isCollapse)}>
              <CloseIcon/>
            </button>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    API.get('lotto', `/lottos?lottoNumber=${data.lottoNumber}`)
      .then((res) => {
        console.log(res.detail, 'res result')
        if (res.code === 0) {
          const lottoDetail = res.detail[0]
          setLottoData(lottoDetail)
        }
      })
      .catch((err) => console.error(err))
  }, [data])

  ShareSheet.propTypes = {
    url: PropTypes.string,
  }

  const handleSelectedHotpick = (key, item) => {
    let newState = { ...selected }
    if (key === 'threeAll') {
      if (_.includes(newState.three, item)) {
        _.remove(newState.three, (current) => current === item)
      } else {
        newState.three.push(item)
      }
    } else if (key === 'twoAll') {
      if (_.includes(newState.two, item)) {
        _.remove(newState.two, (current) => current === item)
      } else {
        newState.two.push(item)
      }
    } else if (key === 'nineteenStraight') {
      if (_.includes(newState.straight, item)) {
        _.remove(newState.straight, (current) => current === item)
      } else {
        newState.straight.push(item)
      }
    } else if (key === 'runAll') {
      if (_.includes(newState.run, item)) {
        _.remove(newState.run, (current) => current === item)
      } else {
        newState.run.push(item)
      }
    }
    setSelected(newState)
  }

  const switchkey = { threeAll: 'three', twoAll: 'two', nineteenStraight: 'straight', runAll: 'run' }

  let hotpickItems = {
    nineteenStraight: [],
    threeAll: [],
    twoAll: [],
    runAll: [],
  }

  const pushItem = (target, key, item) => {
    target.push(
      <div
        className={`${_.includes(selected[switchkey[key]], item) ? 'active' : ''}`}
        key={key + item}
        onClick={() => handleSelectedHotpick(key, item)}
      >
        {item}
      </div>,
    )
  }
  _.forOwn(data.result, (obj, key) => {
    _.mapValues(obj, (item) => {
      pushItem(hotpickItems[key], key, item)
    })
  })

  const content = {
    three: selected.three,
    two: selected.two,
    straight: selected.straight,
    run: selected.run,
  }

  const isSelected = () => {
    if (
      selected.three.length > 0 ||
      selected.two.length > 0 ||
      selected.straight.length > 0 ||
      selected.run.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const shareUrl = '/hotpick/' + data._id

  const textArray = [giftBox, mystery, fullmoon, wizard, halloween]

  const randomIndex = Math.floor(Math.random() * textArray.length);
  const random = textArray[randomIndex];

  const _onClickLink = (e) => {
    e.preventDefault()
    if (!auth.username || !lottoData.lottoNumber) {
      return Swal.fire({
        icon: 'error',
        title: `ไม่พบข้อมูลหวย`,
        text: 'กรุณากลับเมนูหวยทั้งหมด หรือติดต่อเจ้าหน้าที่',
        showCancelButton: true,
        confirmButtonColor: '#B6964E',
        cancelButtonColor: '#C0C0C0',
        confirmButtonText: 'ไปหน้าหวยทั้งหมด',
      }).then((result) => {
        if (result.value) {
          history.push('/lottos')
        }
      })
    }
    console.log(data, 'hotpick')
    const purchaseNumber = data.result.twoAll
    console.log(purchaseNumber)
    const totalAmount = +data.desc.split(' BAHT')[0]
    let purchaseItems = []
    const perNumber = totalAmount / purchaseNumber.length / 2
    for (let i = 0; i < purchaseNumber.length; i++) {
      purchaseItems.push({
        type: '2A',
        amount: perNumber,
        payRate: 90,
        detail: purchaseNumber[i],
      })
      purchaseItems.push({
        type: '2B',
        amount: perNumber,
        payRate: 90,
        detail: purchaseNumber[i],
      })
    }
    console.log(purchaseItems, 'purchaseItem')
    const info = {
      playerUsername: auth.username,
      playerName: auth.username,
      receiptType: 'lotto',
      refId: lottoData.lottoNumber,
      receiptDetail: {
        lottoGroup: lottoData.lottoGroup,
        lottoName: lottoData.lottoName,
        drawDateStr: lottoData.drawDateStr,
        remark: `Box ${data.desc}`,
      },
      purchaseItems,
      purchaseAmount: totalAmount,
      timeout: 120,
    }
    console.log(info, 'info')
    API.post('purchase', '/receipts', { body: info }).then((res) => {
      if (res.code === 0) {
        console.log(res.detail, 'create Receipt')
        const receiptNumber = res.detail.receiptNumber
        API.post(
          'purchase', `/receipts/${receiptNumber}/confirm`,
        ).then((res) => {
          if (res.code === 0) {
            console.log(res.detail, 'success')
            return Swal.fire({
              icon: 'success',
              title: `สุ่มสำเร็จ`,
              text: 'ขอให้ดองดีน่อ',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              cancelButtonColor: '#C0C0C0',
              confirmButtonText: 'ไปหน้าหวยทั้งหมด',
            }).then((result) => {
              if (result.value) {
                history.push('/lottos')
              }
            })
          } else {
            return Swal.fire({
              icon: 'error',
              title: `การสุ่มล้มเหลว`,
              text: 'กรุณากลับเมนูหวยทั้งหมด หรือติดต่อเจ้าหน้าที่',
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              cancelButtonColor: '#C0C0C0',
              confirmButtonText: 'ไปหน้าหวยทั้งหมด',
            }).then((result) => {
              if (result.value) {
                history.push('/lottos')
              }
            })
          }
        }).catch((err) => {
          console.error(err)
        })
      } else {
        return Swal.fire({
          icon: 'error',
          title: `การสุ่มล้มเหลว`,
          text: 'กรุณากลับเมนูหวยทั้งหมด หรือติดต่อเจ้าหน้าที่',
          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: '#C0C0C0',
          confirmButtonText: 'ไปหน้าหวยทั้งหมด',
        }).then((result) => {
          if (result.value) {
            history.push('/lottos')
          }
        })
      }

    }).catch((err) => {
      console.error(err)
    })
    // const destination =
    //   '/lottos/' + lottoNumber + '/fastbuy?' + queryString.stringify(content, { arrayFormat: 'comma' })
    // console.log(destination, 'destination')
    // history.push(destination)
  }

  return (
    <div className='home-card shadow-2xl'>
      <div className='image'>
        <img
          id={data._id}
          src={random}
          onError={() => (document.getElementById(data._id).style.display = 'none')}
          alt='hotpick'
        />
      </div>
      <div className='hotpick_title'>
        <div>
          <h1 className='text-xl font-bold'>{data.title}</h1>
          <h5>{data.dateStr}</h5>
        </div>
        <div className={`hotpick_toggle ${!collpased && 'active'}`} onClick={() => setCollapsed(!collpased)}>
          <img src={iconmonstrArrow65} alt='V'/>
        </div>
      </div>
      <div className={`detail-wrapper ${!collpased && 'active'}`}>
        <div className='detail'>
          <p>{data.desc}</p>
        </div>
        <div className='hotpick-selector-tip text-sm'>Make a Wish</div>
        {/*<div className='hotpick-selector'>*/}
        {/*  <div>*/}
        {/*    <img className='w-4 h-4 ml-2' src={coin} />*/}
        {/*    <span>รูด</span>*/}
        {/*    {hotpickItems.nineteenStraight}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <img className='w-4 h-4 ml-2' src={coin} />*/}
        {/*    <span>วิ่ง</span>*/}
        {/*    {hotpickItems.runAll}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <img className='w-4 h-4 ml-2' src={coin} />*/}
        {/*    <span>เน้น</span>*/}
        {/*    {hotpickItems.twoAll}*/}
        {/*  </div>*/}
        {/*  <div>*/}
        {/*    <img className='w-4 h-4 ml-2' src={coin} />*/}
        {/*    <span style={{ width: '100%' }}>ตรงโต๊ด</span>*/}
        {/*    {hotpickItems.threeAll}*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className='button-group shadow-lg'>
          <Link onClick={_onClickLink} className={`fastbuy py-1 px-4 btn-bevel active`}>
            <span style={{ color: 'red' }}>LUCKY!!!</span>
          </Link>
          <ShareSheet url={shareUrl}/>
        </div>
      </div>
    </div>
  )
}

Hotpick.propTypes = {
  data: PropTypes.object,
}
