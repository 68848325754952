import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { lottoType } from '../../../utils/lottoList'
import { getLang } from '../../../redux/selectors/lang'

export default function PayRateModal(props) {
  const lang = useSelector(getLang)
  const language = lang.lang

  const showModal = props.showModal

  const setShowModal = props.setShowModal
  const fetchPayRate = props.fetchPayRate
  const highestPayRate = props.highestPayRate

  let cartItems = []
  const payRateList = ['3A', '3R', '2A', '2B', 'SA', 'SB']
  for (let i = 0; i < payRateList.length; i++) {
    for (let payRate in fetchPayRate[payRateList[i]]) {
      if (fetchPayRate[payRateList[i]].hasOwnProperty(payRate)) {
        if (fetchPayRate[payRateList[i]][payRate] < highestPayRate[payRateList[i]]) {
          cartItems.push({ type: payRateList[i], detail: payRate, payRate: fetchPayRate[payRateList[i]][payRate] })
        }
      }
    }
  }

  const sortingScore = {
    '2A': 1,
    '2B': 2,
    '2R': 3,
    '3A': 4,
    '3R': 5,
    '3F': 6,
    '3B': 7,
    SA: 8,
    SB: 9,
    '1P': 10,
    '1S': 11,
  }
  const lottoType_TH = {
    '2A': 'สองตัวบน',
    '2B': 'สองตัวล่าง',
    '3A': 'สามตัวบน',
    '3R': 'สามตัวโต๊ด',
    '3F': 'สามตัวหน้า',
    '3B': 'สามตัวล่าง',
    SA: 'เลขวิ่งบน',
    SB: 'เลขวิ่งล่าง',
  }

  const sortedCartItems = _.sortBy(cartItems, (item) => sortingScore[item.type])
  const groupedCartItems = _.groupBy(sortedCartItems, 'type')
  const cartTable = []
  _.forIn(groupedCartItems, (value, key) =>
    cartTable.push(
      <table key={key} className='table-auto'>
        <thead>
          <tr>
            <th className='px-4 py-2'>#</th>
            <th className='px-4 py-2'> {language === 'MM' ? lottoType[key] : lottoType_TH[key]}</th>
            <th className='px-4 py-2'></th>
            <th className='px-4 py-2'>x Rate</th>
            {/*<th className='px-4 py-2'>เรทชนะ</th>*/}
          </tr>
        </thead>
        <tbody>
          {groupedCartItems[key].map((item, index) => (
            <tr className='buy-lotto__modal-box-cart-counter' key={`${key}-${item.detail}-${index}`}>
              <td className='px-4 py-2'>{index + 1}.</td>
              <td className='px-4 py-2 my-2'>
                <div>{item.detail}</div>
              </td>
              <td className='px-4 py-2'></td>
              <td className='px-4 py-2'>
                {'x' + cartItems.find((price) => price.type === key && price.detail === item.detail).payRate}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  )

  return showModal.isShow && showModal.currentModal === 'เลขอั้น' ? (
    <section className={`buy-lotto__modal ${showModal.isShow && showModal.currentModal === 'เลขอั้น' ? 'active' : ''}`}>
      <div className={`buy-lotto__modal-box ${showModal.currentModal === 'เลขอั้น' ? 'active' : ''}`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          {language !== 'MM' ? 'เลขอั้น' : 'Limited Number'}
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div className='buy-lotto__modal-box-content-3' style={{ fontWeight: 'bold', color: '#7C5925' }}>
          {cartTable}
        </div>
      </div>
    </section>
  ) : null
}

PayRateModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  fetchPayRate: PropTypes.object,
  highestPayRate: PropTypes.object,
}
