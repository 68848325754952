export const colorCode = {
  lottoGreen: '#5fc82e',
  lottoBlue: '#006ad5',
  lottoRed: '#C0534D',
  lottoWhite: '#ffffff', //dong
  lottoBlack: '#000000',
  lottoDarkGreen: '#279c4d',
  lottoGray: '#768087',
  lottoYellow: '#fbc500',
}
