import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import get from 'lodash/get'
import _ from 'lodash'

const useGetPayRate = (lottoNumber) => {
  const [fetchPayRate, setFetchPayRate] = useState({})
  const [highestPayRate, setHighestPayRate] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const requestPayRate = () => {
    const path = `/pay-rate/lotto/${lottoNumber}`

    setIsLoading(true)
    API.get('purchase', path)
      .then((res) => {
        const data = get(res, 'detail', [])
        setFetchPayRate(data)
        const highest = {}
        highest['3A'] = _.max(Object.values(data['3A']), (o) => data['3A'][o])
        highest['3R'] = _.max(Object.values(data['3R']), (o) => data['3R'][o])
        highest['2A'] = _.max(Object.values(data['2A']), (o) => data['2A'][o])
        highest['2B'] = _.max(Object.values(data['2B']), (o) => data['2B'][o])
        highest['SA'] = _.max(Object.values(data['SA']), (o) => data['SA'][o])
        highest['SB'] = _.max(Object.values(data['SB']), (o) => data['SB'][o])
        highest['3F'] = 450
        highest['3B'] = 450
        setHighestPayRate(highest)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    requestPayRate()
  }, [])
  return { fetchPayRate, highestPayRate }
}

export default useGetPayRate
