import { colorCode } from './color'

export const lottoType = {
  '2A': 'สองตัวบน',
  '2B': 'สองตัวล่าง',
  '3A': 'สามตัวบน',
  '3R': 'สามตัวโต๊ด',
  '3F': 'สามตัวหน้า',
  '3B': 'สามตัวล่าง',
  SA: 'เลขวิ่งบน',
  SB: 'เลขวิ่งล่าง',
}

export const getReceiptStatusText = (status = '') => {
  if (status === 'created') {
    return 'ยังไม่ได้ยืนยัน'
    // return 'ORDERING'
  }
  if (status === 'confirm') {
    return 'รอออกผล'
    // return 'RECEIVED'
  }
  if (status === 'cancelled') {
    return 'ยกเลิกแล้ว'
    // return 'CANCEL'
  }
  if (status === 'rewarded') {
    return 'ไม่ถูกรางวัล'
    // return 'YOU LOSE'
  }
  if (status === 'rejected') {
    return 'คืนเครดิตแล้ว'
    // return 'REJECTED'
  }
  if (status === 'error') {
    return 'ล้มเหลว'
    // return 'ERROR'
  }
  if (status === 'timeout') {
    return 'หมดเวลา'
    // return 'TIMEOUT'
  }

  return 'ไม่ทราบสถานะ'
  // return 'UNDEFINE'
}

export const getReceiptTypeText = (type = '') => {
  const typeText = lottoType[type] || 'ไม่พบประเภทหวย'
  return typeText
}

export const getReceiptStatusBackgroundColor = (status = '') => {
  if (status === 'created') {
    return colorCode.lottoGreen
  }
  if (status === 'confirm') {
    return colorCode.lottoDarkGreen
  }
  if (status === 'cancelled') {
    return colorCode.lottoGray
  }
  if (status === 'rewarded') {
    return colorCode.lottoWhite
  }
  if (status === 'rejected') {
    return colorCode.lottoGray
  }
  if (status === 'error') {
    return colorCode.lottoGray
  }
  if (status === 'timeout') {
    return colorCode.lottoGray
  }
}
