import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import _groupBy from 'lodash/groupBy'
import _sortBy from 'lodash/sortBy'
import _keysIn from 'lodash/keysIn'
import { API } from 'aws-amplify'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'

import Flare1 from '../../components/Flare/Flare1'
import Flare2 from '../../components/Flare/Flare2'
import Box from '../../components/Box/Box'
import loadingImg from '../../assets//dong-loading/dong-loading.png'
import dong from '../../assets/lotto-flags/dong.png'

import './ResultHistory.scss'
import _ from 'lodash'

const checkIsLottoSameDate = (lotto = null, startDate) => {
  if (_isEmpty(lotto)) {
    return false
  }

  const newDrawDateStr = lotto.drawDateStr.replace(/\//g, '-')
  const drawDateTime = moment(newDrawDateStr + ' ' + lotto.drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
  const timeToCheck = moment(drawDateTime).subtract(lotto.openMinutesBeforeDraw, 'minutes')
  const isSameDate = timeToCheck.isSame(moment(startDate), 'day')

  return isSameDate
}

const LOTTO_LIST = [
  { title: 'ฮานอย พัฒนา', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สามัคคี', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอยเฉพาะกิจ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย พิเศษ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย เที่ยง', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม พิเศษ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม VIP', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย VIP', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย HD', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย TV', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย กาชาด', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย EXTRA', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย รอบดึก', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'มาเลเซีย', src: require('../../assets/lotto-flags/malaysia-flag.png') },
  { title: 'ลาว พัฒนา', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สตาร์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว EXTRA', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวสตาร์ VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว จำปาศักดิ์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว ประตูชัย', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สามัคคี VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว HD', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดาว', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว TV', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว กาชาด', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดิจิตอล', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว เวียงจันทร์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สามัคคี', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ดาวโจนส์ VIP', src: require('../../assets/lotto-flags/usa-flag.png') },
  { title: 'ดาวโจนส์', src: require('../../assets/lotto-flags/usa-flag.png') },
  { title: 'นิเคอิ รอบเช้า', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'ไทย รอบเช้า', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไทย รอบเช้าตลาด', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'จีน รอบเช้า', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง รอบเช้า', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทยเที่ยง', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไต้หวัน', src: require('../../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ รอบบ่าย', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี', src: require('../../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน รอบบ่าย', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ไทย รอบบ่าย', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ฮั่งเส็ง รอบบ่าย', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทย รอบบ่ายตลาด', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'สิงคโปร์', src: require('../../assets/lotto-flags/singapore-flag.png') },
  { title: 'ไทย เย็น', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'อินเดีย', src: require('../../assets/lotto-flags/india-flag.png') },
  { title: 'อียิปต์', src: require('../../assets/lotto-flags/egypt-flag.png') },
  { title: 'ไทยกลางคืน', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'รัสเซีย', src: require('../../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ', src: require('../../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน', src: require('../../assets/lotto-flags/german-flag.png') },
  { title: 'หวยรัฐบาลไทย', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'หวยออมสิน', src: require('../../assets/lotto-flags/bank-gsb.png') },
  { title: 'หวยธกส.', src: require('../../assets/lotto-flags/tks.png') },
  { title: 'นิเคอิ(เช้า) VIP', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'จีน(เช้า) VIP', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(เช้า) VIP', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไต้หวัน VIP', src: require('../../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ(บ่าย) VIP', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี VIP', src: require('../../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน(บ่าย) VIP', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(บ่าย) VIP', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'สิงคโปร์ VIP', src: require('../../assets/lotto-flags/singapore-flag.png') },
  { title: 'รัสเซีย VIP', src: require('../../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ VIP', src: require('../../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน VIP', src: require('../../assets/lotto-flags/german-flag.png') },
  { title: '2D รอบเช้า', src: require('../../assets/lotto-flags/2d-flag.png') },
  { title: '2D รอบบ่าย', src: require('../../assets/lotto-flags/2d-flag.png') },
]

export default function ResultHistoryContainer() {
  const [isLoading, setIsLoading] = useState(true)
  const [startDate, setStartDate] = useState(moment())
  const [startDateFocus, setStartDateFocus] = useState(false)
  const [lottosItemsGroupByType, setLottosItemsGroupByType] = useState({})
  const [lottosTypes, setLottosTypes] = useState([])

  useEffect(() => {
    setIsLoading(true)

    const queryDateString = moment(startDate || moment())
    fetchData(queryDateString)
    return () => { }
  }, [startDate])

  const fetchData = (date = moment()) => {
    console.log(date, 'dateeee')
    const queryDateString = moment(date).format('DD/MM/YY')
    console.log(queryDateString, 'queryDate')
    API.get('lotto', `/results?drawDateStr=${queryDateString}&limit=2000`)
      .then(({ detail }) => {
        console.log('Rewared Lottos fetched ======')
        // console.log(detail)
        const sortLottos = _sortBy(detail, 'drawTimeStr').reverse()
        const lottosItemsGroupByType = _groupBy(sortLottos, 'lottoGroup')
        const forSort = _.keysIn(lottosItemsGroupByType)
        const sortLotto = ['หวยไทย', 'หวยหุ้น', 'หวยรายวัน', 'หวยวีไอพี', 'หวยต่างประเทศ', 'หวยยี่กี']
        const lottosTypes = sortLotto.filter(function (el) {
          return forSort.indexOf(el) >= 0
        })
        setLottosItemsGroupByType(lottosItemsGroupByType)
        setLottosTypes(lottosTypes)
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='resultHistory mt-24'>
      <div className='resultHistory__date-action '>
        <div className='transactionList__date'>
          <div className='transactionList__date-wrapper'>
            <p>วันที่ต้องการดูผลรางวัล</p>
            <SingleDatePicker
              date={startDate}
              onDateChange={(date) => {
                setStartDate(date)
              }}
              focused={startDateFocus}
              onFocusChange={({ focused }) => setStartDateFocus(focused)}
              id='startDate'
              numberOfMonths={1}
              noBorder={true}
              readOnly
              displayFormat={'DD/MM/YYYY'}
              isOutsideRange={() => false}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='loader loading-gif'>
          <img className='loading-indicator-gif ' src={loadingImg} alt='loading' />
        </div>
      ) : (
        <div>
          {lottosTypes.map((item, index) => {
            return !_isEmpty(lottosItemsGroupByType[item]) ? (
              <div className='lottos__list container' key={`${item}-${index}`} style={{ marginBottom: '40px' }}>
                <div
                  className='lottos__header text-4xl'
                  style={{ flex: '0 0 100%', paddingTop: '0px', transform: 'translateY(-25px)' }}
                >
                  <Box>{item}</Box>
                </div>
                {lottosItemsGroupByType[item].map((item, index) => {
                  const shouldShow = checkIsLottoSameDate(item, startDate)
                  if (!shouldShow) {
                    return null
                  }

                  // const results = _get(item, 'results', null)
                  const twoDigitResult = _get(item, 'results.2B', '') || _get(item, 'results.2DIGITS', '')
                  const threeDigitResult = _get(item, 'results.3A', '') || _get(item, 'results.3DIGITS', '')

                  return (
                    <Link to={'#'} className={`lottos__item`} key={`reward-lotto-${index}`}>
                      <Flare1 />
                      <Flare2 />
                      <div className='lottos__item-header'>
                        <img
                          src={
                            item.lottoGroup === 'หวยยี่กี'
                              ? dong
                              : LOTTO_LIST.find((o) => o.title === item.lottoName)?.src || dong
                          }
                        // alt='lotto icon'
                        />
                        <div>
                          <div>{item.lottoName}</div>
                          <div className='lottos__lotto-wrapper'>
                            <div className='lottos__lotto-result'>
                              <span>{threeDigitResult}</span>
                            </div>
                            <div className='lottos__lotto-result'>
                              <span>{twoDigitResult}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*<div className='lottos__item-timer'></div>*/}
                    </Link>
                  )
                })}
              </div>
            ) : (
              <p style={{ fontSize: '24px', textAlign: 'center', color: 'white' }}>ไม่พบข้อมูล</p>
            )
          })}
        </div>
      )}
    </div>
  )
}
