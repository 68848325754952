import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import Amplify, { Auth } from 'aws-amplify'

import App from './App'
import * as serviceWorker from './serviceWorker'
import { getBackendUrl } from './config/urls'
import {
  getCognitoAppClientId,
  getCognitoPoolId,
  getCognitoOauthDomain,
  getCognitoRegion,
  getGraphEndpoint,
  // getIdentities,
} from './config/cognito'
// import { GA_ID } from '../src/config/env'

import './css/tailwind.build.css'
import './css/globalStyle.scss'

const BACKEND_URL = getBackendUrl()

Amplify.configure({
  aws_project_region: getCognitoRegion(),
  aws_appsync_graphqlEndpoint: getGraphEndpoint(),
  aws_appsync_region: getCognitoRegion(),
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    // identityPoolId: getIdentities(),
    userPoolId: getCognitoPoolId(),
    userPoolWebClientId: getCognitoAppClientId(),
    region: getCognitoRegion(),
    oauth: {
      domain: getCognitoOauthDomain(),
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${window.location.origin}/callback`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'token',
    },
  },
  API: {
    endpoints: [
      // Public Endpoints
      {
        name: 'lotto',
        endpoint: `${BACKEND_URL}/lotto`,
      },
      {
        name: 'post',
        endpoint: `${BACKEND_URL}/post`,
      },
      {
        name: 'hotpick',
        endpoint: `${BACKEND_URL}/hotpick`,
      },
      // Private Endpoints
      {
        name: 'payment',
        endpoint: `${BACKEND_URL}/payment`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'playerBankAccount',
        endpoint: `${BACKEND_URL}/player-bank-account`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'playerWallet',
        endpoint: `${BACKEND_URL}/player-wallet`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'purchase',
        endpoint: `${BACKEND_URL}/purchase`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'thirdParty',
        endpoint: `${BACKEND_URL}/third-party`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'notification',
        endpoint: `${BACKEND_URL}/notification`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'affiliate',
        endpoint: `${BACKEND_URL}/affiliate`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'user',
        endpoint: `${BACKEND_URL}/user`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'marketing',
        endpoint: `${BACKEND_URL}/marketing`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: 'third-party',
        endpoint: `${BACKEND_URL}/third-party`,
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
    ],
  },
  // Analytics: {
  //   // OPTIONAL - disable Analytics if true
  //   disabled: false,
  //   // OPTIONAL - Allow recording session events. Default is true.
  //   autoSessionRecord: true,
  //   AWSPinpoint: {
  //     // OPTIONAL -  Amazon Pinpoint App Client ID
  //     appId: 'c5d7e4d9b5cf4df092b7d42b19352b4b',
  //     // Amazon service region
  //     region: 'ap-southeast-1',
  //     mandatorySignIn: false,
  //     // OPTIONAL -  Customized endpoint
  //     // endpointId: 'XXXXXXXXXXXX',
  //     // // OPTIONAL - Default Endpoint Information
  //     // endpoint: {
  //     //   address: 'xxxxxxx', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
  //     //   attributes: {
  //     //     // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
  //     //     hobbies: ['piano', 'hiking'],
  //     //   },
  //     //   channelType: 'APNS', // The channel type. Valid values: APNS, GCM
  //     //   demographic: {
  //     //     appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
  //     //     locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
  //     //     make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
  //     //     model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
  //     //     modelVersion: 'xxxxxx', // The model version of the endpoint device.
  //     //     platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
  //     //     platformVersion: 'xxxxxx', // The platform version of the endpoint device.
  //     //     timezone: 'xxxxxx', // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
  //     //   },
  //     //   // location: {
  //     //   //   city: 'xxxxxx', // The city where the endpoint is located.
  //     //   //   country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
  //     //   //   latitude: 0, // The latitude of the endpoint location, rounded to one decimal place.
  //     //   //   longitude: 0, // The longitude of the endpoint location, rounded to one decimal place.
  //     //   //   postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
  //     //   //   region: 'xxxxxx', // The region of the endpoint location. For example, in the United States, this corresponds to a state.
  //     //   // },
  //     //   metrics: {
  //     //     // Custom metrics that your app reports to Amazon Pinpoint.
  //     //   },
  //     //   // /** Indicates whether a user has opted out of receiving messages with one of the following values:
  //     //   //  * ALL - User has opted out of all messages.
  //     //   //  * NONE - Users has not opted out and receives all messages.
  //     //   //  */
  //     //   // optOut: 'ALL',
  //     //   // // Customized userId
  //     //   // userId: 'XXXXXXXXXXXX',
  //     //   // // User attributes
  //     //   // userAttributes: {
  //     //   //   interests: ['football', 'basketball', 'AWS'],
  //     //   //   // ...
  //     //   // },
  //     // },
  //
  //     // Buffer settings used for reporting analytics events.
  //     // // OPTIONAL - The buffer size for events in number of items.
  //     // bufferSize: 1000,
  //     //
  //     // // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
  //     // flushInterval: 5000, // 5s
  //     //
  //     // // OPTIONAL - The number of events to be deleted from the buffer when flushed.
  //     // flushSize: 100,
  //     //
  //     // // OPTIONAL - The limit for failed recording retries.
  //     // resendLimit: 5,
  //   },
  // },
})

// //Record an event
// Analytics.record('some-event-name')
//
// //Record a custom event
// Analytics.record({
//   name: 'Album',
//   attributes: { genre: 'Rock', year: '1989' },
// })

// ReactGA.initialize(GA_ID())

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
