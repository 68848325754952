import React, { useEffect } from 'react'
// import ReactGA from 'react-ga'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Auth, Hub } from 'aws-amplify'
import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import Swal from 'sweetalert2'

import rootReducer from './redux/reducers'
import { setAuth, clearAuth } from './redux/actions/auth'
import AuthorizedRoute from './components/AuthorizedRoute/AuthorizedRoute'
import { Header, Navigator } from './components'
import { Home, Lottos, Lotto, AllReceipt, DetailReceipt, HotPick, NotFoundPage, ResultHistory } from './pages'
import AddBankAccount from './pages/AddBankAccount/AddBankAccount'
import Deposit from './pages/Deposit/Deposit'
import Withdrawal from './pages/Withdrawal/Withdrawal'
import Profile from './pages/Profile/Profile'
import D from './pages/D'
import Rules from './pages/Rules/Rules'
import TransactionList from './pages/TransactionList/TransactionList'
import Tutorial from './pages/Tutorial/Tutorial'
import Marketing from './pages/Marketing'
import Callback from './pages/Callback'
import TimeOutPage from './pages/TimeOutPage'
import CreateLotto from './pages/CreateLotto/CreateLotto'
import FastBuy from './pages/HotPick/FastBuy'
import Login from './pages/Login/Login'
import Logout from './pages/Logout/Logout'
// import Gallery from './pages/Gallery/Gallery'
// import Promotion from './pages/Promotion/Promotion'
import InviteFriend from './pages/InviteFriend/InviteFriend'
import Affiliate from './pages/Affiliate/Affiliate'
import Angalong from './pages/Angalong/Angalong'
// import Games from './pages/Games/Games'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn_failure') {
        // Swal.fire({
        //   icon: 'error',
        //   title: 'เกิดข้อผิดพลาด',
        //   text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
        //   confirmButtonText: 'รับทราบ',
        // })
      }
      switch (event) {
        case 'signIn':
          Auth.currentAuthenticatedUser().then((user) => {
            dispatch(setAuth({ user }))
          })
          break
        case 'signOut':
          dispatch(clearAuth())
          break
        default:
      }
    })
  }, [dispatch])

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        dispatch(setAuth({ user: cognitoUser }))
      })
      .catch(() => {
        Auth.signOut()
        dispatch(clearAuth())
      })
  }, [])

  return (
    <BrowserRouter>
      <div className='app'>
        <Header />
        <div className='app-wrapper'>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/angalong' component={Angalong} />
            <Route path='/lottos/:lottoNumber' component={Lotto} />
            {/*<AuthorizedRoute path='/lottos/:lottoNumber' component={Lotto} />*/}
            <Route path='/lottos' component={Lottos} />
            <Route path='/hotpick/:hotpickId' component={HotPick} />
            <Route path='/hotpick' component={FastBuy} />
            <Route path='/tutorial' component={Tutorial} />
            <AuthorizedRoute exact path='/receipts/:receiptNumber' component={DetailReceipt} />
            <Route path='/d' component={D} />
            <Route path='/create-lotto' component={CreateLotto} />
            <Route path='/marketing' component={Marketing} />
            <Route path='/time-out' component={TimeOutPage} />
            <Route path='/callback' component={Callback} />
            <Route path='/result-history' component={ResultHistory} />
            <Route path='/rules' component={Rules} />
            <Route path='/login' component={Login} />
            <Route path='/affiliate' component={Affiliate} />
            <Route path='/invite-friend' component={InviteFriend} />
            {/*<Route path='/story' component={Story} />*/}
            <AuthorizedRoute exact path='/receipts' component={AllReceipt} />
            <AuthorizedRoute path='/add-bank-account' component={AddBankAccount} />
            <AuthorizedRoute path='/deposit' component={Deposit} />
            <AuthorizedRoute path='/withdraw' component={Withdrawal} />
            <AuthorizedRoute path='/transaction-list' component={TransactionList} />
            <AuthorizedRoute path='/profile' component={Profile} />
            {/* <AuthorizedRoute path='/games' component={Games} /> */}
            <Route path='/logout' component={Logout} />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
        <Navigator />
      </div>
    </BrowserRouter>
  )
}

const AppWrapper = () => {
  const persistConfig = {
    key: 'root',
    storage,
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer)
  const persistor = persistStore(store)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

export default AppWrapper

// const withTracker = (WrappedComponent) => {
//   const trackPage = (page) => {
//     ReactGA.set({ page })
//     ReactGA.pageview(page)
//   }
//
//   const HOC = (props) => {
//     const page = props.location.pathname + props.location.search
//     trackPage(page)
//
//     return <WrappedComponent {...props} />
//   }
//
//   return HOC
// }
