import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { getAuth } from '../../../redux/selectors/auth'
import Box from '../../../components/Box/Box'
// import shine from '../../../assets/shine.png'
// import dong from '../../../assets/flags/dong.png'
import { STOCK_LIST } from '../../../utils/stockList'
import { colorCode } from '../../../utils/color'
import { getReceiptStatusBackgroundColor, getReceiptStatusText } from '../../../utils/receiptUtil'
import Button from '../../../components/Button/Button'
// import { toggleCart } from '../../../redux/actions/cart'
import dong from '../../../assets/flags/dong.png'
import {
  fiveChip,
  tenChip,
  twentyChip,
  fiftyChip,
  hundredChip,
  fiveHundredChip,
  anyChip,
} from '../../../utils/angalong_util.js'
// DICE
import {
  angelDice,
  dongDice,
  elephantDice,
  horseDice,
  nagaDice,
  tigerDice,
  anyDice,
  angelDong,
  angelNaga,
  nagaTiger,
  nagaDong,
  elephantTiger,
  elephantHorse,
  elephantDong,
  tigerHorse,
} from '../../../utils/angalong_util.js'
import {
  nagaCard,
  elephantCard,
  horseCard,
  tigerCard,
  angelCard,
  dongCard,
  nagaCardActive,
  elephantCardActive,
  horseCardActive,
  tigerCardActive,
  angelCardActive,
  dongCardActive,
} from '../../../utils/angalong_util.js'

export default function ReceiptModal(props) {
  const showModal = props.showModal
  const setShowModal = props.setShowModal
  // const lottoData = props.lottoData
  // const lottoNumber = props.lottoNumber
  // const fetchPayRate = props.fetchPayRate
  const allPhoto = props.allPhoto
  const auth = useSelector(getAuth)
  const [allReceipts, setAllReceipts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [receiptsPage, setReceiptsPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(true)
  // const dispatch = useDispatch()

  // console.log(allPhoto, 'allPhotoajsdklfjals;dfkj;l')

  // const allPhoto = [
  //   fiveChip, //0
  //   tenChip,
  //   twentyChip,
  //   fiftyChip,
  //   hundredChip,
  //   fiveHundredChip,
  //   anyChip, //6
  //   nagaCard, //7
  //   elephantCard, //8
  //   horseCard, //9
  //   tigerCard, //10
  //   angelCard, //11
  //   dongCard, //12
  //   nagaCardActive, //13
  //   elephantCardActive, //14
  //   horseCardActive, //15
  //   tigerCardActive, //16
  //   angelCardActive, //17
  //   dongCardActive, //18
  //   angelDice,
  //   dongDice,
  //   elephantDice,
  //   horseDice,
  //   nagaDice,
  //   tigerDice,
  //   anyDice,
  //   angelDong, //26
  //   angelNaga, //27
  //   nagaTiger, //28
  //   nagaDong, //29
  //   elephantTiger, //30
  //   elephantHorse, //31
  //   elephantDong, //32
  //   tigerHorse, //33
  // ]

  useEffect(() => {
    if (isBottom && !isLastPage && !isLoading) {
      setReceiptsPage(receiptsPage + 1)
    }
  }, [isBottom])

  useEffect(() => {
    addItems()
  }, [receiptsPage])

  const addItems = () => {
    if (allReceipts.length !== 0) {
      fetchReceiptData()
    }
  }

  const fetchReceiptData = () => {
    setIsLoading(true)
    const path = `/receipts?receiptType=angalong&limit=10&page=${receiptsPage}`
    API.get('purchase', path)
      .then((res) => {
        // let sortedReceipts = []
        const sortedReceipts = _.get(res, 'detail', [])
        console.log(sortedReceipts, 'sortedReceiptslaskdf;jksadf;asdf')
        for (let i = 0; i < sortedReceipts.length; i++) {
          if (sortedReceipts[i].receiptDetail.remark.length > 0) {
            const toJson = JSON.parse(sortedReceipts[i].receiptDetail.remark)
            const purchaseOne = JSON.parse(toJson.one)
            const purchasePair = JSON.parse(toJson.two)
            console.log(purchaseOne, purchasePair, 'asd;fjkasd;lfjalk;sdfj')
            const asArrayOne = Object.entries(purchaseOne)
            const filteredOne = asArrayOne.filter(([value]) => value > 0)
            const asArrayPair = Object.entries(purchasePair)
            const filteredPair = asArrayPair.filter(([value]) => value > 0)
            console.log(filteredOne, filteredPair, 'filteredsadfasdfasdfasdf')
            const filterZero = filteredOne.filter((obj) => obj[1] > 0).concat(filteredPair.filter((obj) => obj[1] > 0))
            sortedReceipts[i].purchaseAngalong = filterZero
            console.log(filterZero, 'filterZero')
          }
        }
        const lastPage = _.get(res, 'currentPage') === _.get(res, 'totalPages')
        setIsLastPage(lastPage)
        if (receiptsPage === 1) {
          setAllReceipts(sortedReceipts)
        }
        if (receiptsPage > 1) {
          setAllReceipts(allReceipts.concat(sortedReceipts))
        }
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
        setIsBottom(false)
      })
  }

  useEffect(() => {
    if (auth.username) {
      fetchReceiptData()
    }
  }, [auth.username])

  // const selectNumber = (number, singleType) => {
  //   if (fetchPayRate[singleType]) {
  //     dispatch(
  //       toggleCart({
  //         lottoNumber,
  //         purchaseItem: {
  //           type: singleType,
  //           detail: number,
  //           amount: 1,
  //           payRate:
  //             fetchPayRate[singleType][number] === undefined
  //               ? fetchPayRate[singleType].default
  //               : fetchPayRate[singleType][number],
  //         },
  //       })
  //     )
  //   }
  // }
  const mapPhoto = {
    1: allPhoto[8],
    2: allPhoto[11],
    3: allPhoto[10],
    4: allPhoto[7],
    5: allPhoto[9],
    6: allPhoto[12],
    12: allPhoto[0], //
    13: allPhoto[30],
    14: allPhoto[0], //
    15: allPhoto[31],
    16: allPhoto[32],
    23: allPhoto[0], //
    24: allPhoto[27],
    25: allPhoto[0], //
    26: allPhoto[26],
    35: allPhoto[33],
    36: allPhoto[0], //
    43: allPhoto[28],
    45: allPhoto[0], //
    46: allPhoto[29],
    56: allPhoto[0], //
  }

  const generatePurchase = (purchase) => {
    // console.log(purchase, 'purdkja;lsdkfja;lsdkjfa;lsdf')
    let arrayPurchase = []
    for (let i = 0; i < purchase.length; i++) {
      console.log(purchase[i], 'purchasepurchase')
      arrayPurchase.push(<div className={`box1-inner`}>
        <img src={mapPhoto[purchase[i][0]]} />
        <img
          src={allPhoto[6]}
          className={`top-side-chip`}
          alt='top-side-chip'
        />
        <span
          className={`span-side-chip`}>
          {purchase[i][1]}
        </span>
      </div>)
    }
    console.log(arrayPurchase, 'arrayPurclkajsdl;fkjal;skdjf')
    return arrayPurchase
  }

  // const handlePickReceipt = (receiptNumber) => {
  //   setIsLoading(true)
  //   API.get('purchase', `/receipts/${receiptNumber}`)
  //     .then((res) => {
  //       const purchaseItems = _.get(res, 'detail.purchaseItems', {})

  //       purchaseItems.forEach((item) => {
  //         selectNumber(item.detail, item.type)
  //       })

  //     })
  //     .catch((err) => {
  //       console.log('error ======', err)
  //       console.error(err)
  //     })
  //     .finally(() => {
  //       setShowModal((prevState) => ({ ...prevState, isShow: false }))
  //       setIsLoading(false)
  //     })
  // }

  return showModal.isShow && showModal.currentModal === 'angalong' ? (
    <section className={`buy-lotto__modal active`}>
      <div className={`buy-lotto__modal-box active`}>
        <div className='buy-lotto__modal-box-header text-xl' style={{ top: '0', position: 'sticky', zIndex: '99' }}>
          <div className='buy-lotto__lotto-number text-xl mr-3 text-white bold strong'>
            {/* <div>ดึงโพย</div> */}
            <div>History</div>
          </div>
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div className='receipts-listing'>
          {allReceipts.map((receipt, index) => (
            <Box
              withShine={false}
              withFlare={false}
              containerClass='allReceipts__selectedModal'
              key={`receipt-${index}`}
            >
              <div className='allReceipts__selectedLotto-firstLine'>
                {/* <img className='allReceipts__selectedLotto-firstLine-shine ' src={shine} alt='shine' /> */}
                <img
                  className='allReceipts__selectedLotto-firstLine-flag '
                  src={
                    receipt.receiptDetail.lottoName.substring(0, 5) === 'ดองยี'
                      ? dong
                      : STOCK_LIST.find((o) => o.title === receipt.receiptDetail.lottoName)?.src || dong
                  }
                  alt='flags'
                />
                <span className='allReceipts__selectedLotto-firstLine-name'>{receipt.receiptDetail.lottoName}</span>
                <div
                  className='allReceipts__selectedLotto-firstLine-status'
                  style={
                    receipt.status === 'rewarded' && receipt.prizeAmount > 0
                      ? {
                        color: colorCode.lottoRed,
                      }
                      : {
                        color: getReceiptStatusBackgroundColor(receipt.status),
                      }
                  }
                >
                  {receipt.status === 'rewarded' && receipt.prizeAmount > receipt.purchaseAmount
                    ? // ? 'ถูกรางวัล'
                    'YOU WIN !!!'
                    : getReceiptStatusText(receipt.status)}
                </div>
                <br />
                {/* <span className='allReceipts__selectedLotto-firstLine-index'>{`โพยเลขที่ #${receipt.receiptNumber}`}</span> */}
                <span className='allReceipts__selectedLotto-firstLine-index'>{`receipt no #${receipt.receiptNumber}`}</span>
              </div>
              <div className='allReceipts__selectedLotto-secondLine allReceipts__selectedLotto-withSeparator'>
                <div className='allReceipts__selectedLotto-secondLine-left'>
                  <div className='allReceipts__selectedLotto-secondLine-left-pay'>
                    {/* <span>ยอดแทง</span> */}
                    <span>လောင်းသည့်ငွေ</span>

                    <span style={{ fontFamily: 'DESG7 Classic', color: colorCode.lottoBlue }} className='font-medium'>
                      {Math.round((receipt.purchaseAmount + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                  {/*<span>หวย{_.get(receipt, 'receiptDetail.lottoName', '')}</span>*/}
                  {/*<span>ประกาศผล {fetchDrawTime(receipt.refId) || 'กำลังโหลด'} </span>*/}
                </div>
                <div className='allReceipts__selectedLotto-secondLine-right'>
                  <div className='allReceipts__selectedLotto-secondLine-right-pay'>
                    {/* <span>ยอดถูก</span> */}
                    <span>အောင်မြင်သည့်ငွေ</span>

                    <span
                      style={{
                        fontFamily: 'DESG7 Classic',
                        color: receipt.prizeAmount > 0 ? colorCode.lottoYellow : '',
                      }}
                      className='font-medium'
                    >
                      {Math.round((_.get(receipt, 'prizeAmount', '') + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                </div>
              </div>
              <div className='allReceipts__selectedLotto-thirdLine'>
                <div className='allReceipts__selectedLotto-thirdLine-left'>
                  <span className='allReceipts__selectedLotto-thirdLine-left-calendar'>
                    {moment(receipt.createdDate).subtract(7, 'hours').format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
                {/*<Button*/}
                {/*  // text='ดึงโพย'*/}
                {/*  text='select'*/}
                {/*  wrapperClassName='allReceipts__selectedLotto-thirdLine-right'*/}
                {/*  buttonClassName='allReceipts__selectedLotto-thirdLine-right-button'*/}
                {/*  buttonBackground={colorCode.lottoYellow}*/}
                {/*  onClick={() => handlePickReceipt(receipt.receiptNumber)}*/}
                {/*/>*/}
              </div>
              {receipt.receiptDetail.remark ? (
                <div className='allReceipts__selectedLotto-thirdLine' style={{ borderTop: '2px solid #efefef' }}>
                  <div className='allReceipts__selectedLotto-thirdLine-left inline-block bold text-xl'>
                    <div>{receipt.purchaseAngalong ? generatePurchase(receipt.purchaseAngalong) : null}</div>
                  </div>
                </div>
              ) : null}
            </Box>
          ))}
          {isLoading && (
            <div className='loading-bottom-gif' style={{ marginBottom: '100px' }}>
              <img
                className='loading-indicator-gif '
                src={require('../../../assets//dong-loading/dong-loading.png')}
                alt='loading'
              />
            </div>
          )}
          {!isLoading && (
            // <Button text='โหลดเพิ่ม' wrapperClassName='LoadMoreButton' onClick={() => setIsBottom(true)} />
            <Button text='more' wrapperClassName='LoadMoreButton' onClick={() => setIsBottom(true)} />
          )}
        </div>
      </div>
    </section>
  ) : null
}

ReceiptModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  allPhoto: PropTypes.array,
}
