import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Auth, API } from 'aws-amplify'
import Swal from 'sweetalert2'
import ReactToPrint from 'react-to-print'
import _ from 'lodash'
import numeral from 'numeral'
// import { parse } from 'postcss'
import _get from 'lodash/get'
// import _reverse from 'lodash/reverse'
// import _sortBy from 'lodash/sortBy'
// import moment from 'moment'
// import _isEqual from 'lodash/isEqual'
// import _mapKeys from 'lodash/mapKeys'
// import _omitBy from 'lodash/omitBy'
// import _isNil from 'lodash/isNil'

import { setReceipt } from '../../../redux/actions/receipts'
import { getAuth } from '../../../redux/selectors/auth'
import { clearCart, updateRatio } from '../../../redux/actions/cart'
// import lotto from '../../lottos_backup/lotto'
import { setWalletCredit } from '../../../redux/actions/wallet'
// import Login from '../../../components/Login/Login'
// import { getStorage } from '../../../config/cognito'
import { lottoType, lottoType_TH,POST_PAGE } from '../../../utils/lottoList'

export default function ConfirmCheckoutModal(props) {
  const { showModal, setShowModal, lottoNumber, lottoData, highestPayRate, isAgentSubmit,
    setIsAgentSubmit,
    customerTable,
    setCustomerTable, } = props

  const dispatch = useDispatch()
  let history = useHistory()
  // const storage = getStorage()

  const [isPurchasing, setIsPurchasing] = useState(false)
  // const [receiptPurchaseAmount, setReceiptPurchaseAmount] = useState(0)
  const [remarkValue, setRemarkValue] = useState('')
  const walletCredit = useSelector((state) => _.get(state, 'wallet.credit', null))
  const auth = useSelector(getAuth)
  const cartStore = useSelector((state) => state).cart

  const cartItems = _.get(cartStore, 'items', [])
  const cartCounter = cartItems.length || 0
  const purchaseAmount = Math.round((cartItems.reduce((sum, x) => sum + x.amount, 0) + Number.EPSILON) * 100) / 100

  // const [priceArray, setPriceArray] = useState(cartItems)
  const [oldPurchaseAmount, setOldPurchaseAmount] = useState(0)
  // const [isAgentSubmit, setIsAgentSubmit] = useState(false)
  const [showLogin, setShowLogin] = useState({ isShow: false, currentModal: '' })
  // const [agentPrice, setAgentPrice] = useState({
  //   '2A': undefined,
  //   '2B': undefined,
  //   '3A': undefined,
  //   '3R': undefined,
  //   SA: undefined,
  //   SB: undefined,
  // })
  // const [agentRate, setAgentRate] = useState({ '2A': 1, '2B': 1, '3A': 1, '3R': 1, SA: 1, SB: 1 })
  // const [isOldAmount, setIsOldAmount] = useState(false)
  const [fetchedUserData, setFetchedUserData] = useState({})
  const [agentUser, setAgentUser] = useState(undefined)
  const language = props.language
  // const [isAgent, setIsAgent] = useState(false)

  class ComponentToPrint extends React.Component {
    render() {
      return <div className='buy-lotto__modal-box-content-2'>{customerTable}</div>
    }
  }

  let componentRef = useRef()

  useEffect(() => {
    if (props.showModal.currentModal === 'confirm') {
      localStorage.setItem('oldPurchaseAmount', purchaseAmount)
      localStorage.setItem('oldAmount', JSON.stringify(cartItems))
      localStorage.setItem('oldForPrint', JSON.stringify(cartItems))
    } else {
      // console.log('clear Storage')
      localStorage.removeItem('oldPurchaseAmount')
      localStorage.removeItem('oldAmount')
      localStorage.removeItem('oldForPrint')
    }
  }, [props.showModal])
  useEffect(() => {
    const fetchUserData = async () => {
      const user = await Auth.currentAuthenticatedUser()
      // console.log(user, 'cognito USER')
      setFetchedUserData(user)
      // if (user.attributes['custom:agent']) {
      //   setIsAgent(true)
      // }
    }
    fetchUserData()
    // console.log(fetchedUserData, 'fetchedUserData')
  }, [])
  useEffect(() => {
    if (fetchedUserData.attributes) {
      if (fetchedUserData.attributes['custom:custom:agent']) {
        setAgentUser(JSON.parse(fetchedUserData.attributes['custom:custom:agent']))
      }
    }
  }, [fetchedUserData])
  const handleAgent = (event, type) => {
    let payAgent =
      type === 'SA' || type === 'SB'
        ? _.defaultTo(parseFloat(event.target.value), 0)
        : _.defaultTo(parseInt(event.target.value), '') // parseInt('') > NaN
    if (payAgent > highestPayRate[type]) {
      payAgent = highestPayRate[type]
    }
    // const agentRatio = payAgent / highestPayRate[type]
    // setAgentRate({ ...agentRate, [type]: agentRatio })
    // setAgentPrice({ ...agentPrice, [type]: payAgent })
    setAgentUser({ ...agentUser, [type]: payAgent })
  }

  const saveAgentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const payload = {
        'custom:custom:agent': JSON.stringify({
          '2A': agentUser['2A'] === undefined ? highestPayRate['2A'] : agentUser['2A'],
          '2B': agentUser['2B'] === undefined ? highestPayRate['2B'] : agentUser['2B'],
          '3A': agentUser['3A'] === undefined ? highestPayRate['3A'] : agentUser['3A'],
          '3R': agentUser['3R'] === undefined ? highestPayRate['3R'] : agentUser['3R'],
          '3F': agentUser['3F'] === undefined ? 450 : agentUser['3F'],
          '3B': agentUser['3B'] === undefined ? 450 : agentUser['3B'],
          SA: agentUser['SA'] === undefined ? highestPayRate['SA'] : agentUser['SA'],
          SB: agentUser['SB'] === undefined ? highestPayRate['SB'] : agentUser['SB'],
        }),
      }
      const result = await Auth.updateUserAttributes(user, payload)
      console.log(result)
    } catch (error) {
      console.error('error profile handleSubmit>>>', error)
      // Swal.fire({
      //   icon: 'error',
      //   title: 'เกิดข้อผิดพลาด',
      //   text: error.message || '',
      // })
    }
  }
  const submitAgent = () => {
    const oldAmount = JSON.parse(localStorage.getItem('oldAmount'))
    const oldPurchaseAmount = localStorage.getItem('oldPurchaseAmount')
    oldAmount.map((obj) => {
      let amount =
        (agentUser[obj.type] !== undefined
          ? agentUser[obj.type] / highestPayRate[obj.type]
          : // : agentPrice[obj.type] !== undefined
            // ? agentPrice[obj.type] / highestPayRate[obj.type]
            1) * obj.amount
      obj.amount = amount
      dispatch(updateRatio({ purchaseItem: obj, amount }))
      // console.log(obj, 'obj that change')
      return obj
    })
    // console.log(newPriceArray, 'newPriceArray')
    setOldPurchaseAmount(oldPurchaseAmount)
    setIsAgentSubmit(true)
    saveAgentUser()

    const oldForPrint = JSON.parse(localStorage.getItem('oldForPrint'))
    // console.log(oldForPrint, 'oldForPrint')
    const sortedCustomerItems = _.sortBy(oldForPrint, (item) => sortingScore[item.type])
    const groupCustomerItems = _.groupBy(sortedCustomerItems, 'type')
    const customerTable = []
    customerTable.push()

    _.forIn(groupCustomerItems, (value, key) =>
      customerTable.push(
        <table key={key} className='home_dong_table'>
          <thead>
          <tr>
            <th className='px-4 py-2'>#</th>
            <th className='px-4 py-2'>{language === 'MM' ? lottoType[key] : lottoType_TH[key]}</th>
            <th className='px-4 py-2'>Bet</th>
            <th className='px-4 py-2'>x Rate</th>
            <th className='px-4 py-2'>Reward</th>
          </tr>
          </thead>
          <tbody>
          {groupCustomerItems[key].map((item, index) => (
            <tr className='buy-lotto__modal-box-cart-counter' key={`${key}-${item.detail}-${index}`}>
              <td className='px-4 py-2'>{index + 1}.</td>
              <td className='px-4 py-2 my-2'>
                <div>{item.detail}</div>
              </td>
              <td className='px-4 py-2'>
                {Math.round(
                  (oldForPrint.find(
                    (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp,
                    ).amount +
                    Number.EPSILON) *
                  100,
                ) / 100}
              </td>
              <td className='px-4 py-2'>
                {/*{fetchPayRate[key][item.detail] === undefined*/}
                {/*  ? 'x' + fetchPayRate[key].default*/}
                {/*  : 'x' + fetchPayRate[key][item.detail]}*/}
                {'x' + Math.round(oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate === highestPayRate[key] ? (oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate /
                  highestPayRate[key]) * agentUser[key] : oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate)}
              </td>
              <td className='px-4 py-2'>
                <div>
                  {Math.round(
                    ((oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate === highestPayRate[key] ? (oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate /
                      highestPayRate[key]) * agentUser[key] : oldForPrint.find((price) => price.type === key && price.detail === item.detail).payRate) *
                      oldForPrint.find(
                        (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp,
                      ).amount +
                      Number.EPSILON) *
                    100,
                  ) / 100}
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>,
      ),
    )
    // console.log(customerTable, 'customerTable')
    setCustomerTable(customerTable)
  }

  const sortingScore = {
    '2A': 1,
    '2B': 2,
    '2R': 3,
    '3A': 4,
    '3R': 5,
    '3F': 6,
    '3B': 7,
    SA: 8,
    SB: 9,
    '1P': 10,
    '1S': 11,
  }
  // const lottoType = {
  //   // '2A': '2 ตัวบน',
  //   // '2B': '2 ตัวล่าง',
  //   // '3A': '3 ตัวบน',
  //   // '3R': '3 ตัวโต๊ด',
  //   // '3F': '3 ตัวหน้า',
  //   // '3B': '3 ตัวล่าง',
  //   // SA: 'วิ่งบน',
  //   // SB: 'วิ่งล่าง',

  //   '2A': '၂လုံးပေါ်',
  //   '2B': '၂လုံးအောက်',
  //   '3A': '၃ လုံးပေါ်',
  //   '3R': '၃လုံးပြောင်းပြန်',
  //   '3F': 'ရှေ့၃လုံး',
  //   '3B': '၃လုံးအောက်',
  //   SA: 'အပေါ်ပြေး',
  //   SB: 'အောက်ပြေး',
  // }

  const sortedCartItems = _.sortBy(cartItems, (item) => sortingScore[item.type])
  const groupedCartItems = _.groupBy(sortedCartItems, 'type')
  const cartTable = []

  function timeoutReceipt(receiptNumber) {
    // console.log('===== ReceiptTimeout ======')
    return API.post('purchase', `/receipts/${receiptNumber}/timeout`)
      .then((res) => {
        console.log('Timeout Status ======>', res.status)
      })
      .catch((err) => console.error(err))
      .finally(() => {
        // console.log('Receipt has Timeout')
      })
  }

  // console.log(groupedCartItems, 'groasdfasfd')

  _.forIn(groupedCartItems, (value, key) =>
    cartTable.push(
      <table key={key} className='home_dong_table'>
        <thead>
          <tr>
            <th className='px-4 py-2'>#</th>
            <th className='px-4 py-2'>{language === 'MM' ? lottoType[key] : lottoType_TH[key]}</th>
            {/* <th className='px-4 py-2'>ราคา</th> */}
            <th className='px-4 py-2'>Price</th>

            {/* <th className='px-4 py-2'>อัตราคูณ</th> */}
            <th className='px-4 py-2'>x Rate</th>

            {/* <th className='px-4 py-2'>เรทชนะ</th> */}
            <th className='px-4 py-2'>Reward</th>
          </tr>
        </thead>
        <tbody>
          {groupedCartItems[key].map((item, index) => (
            <tr
              className='buy-lotto__modal-box-cart-counter'
              style={{ backgroundColor: '#fdeedd' }}
              key={`${key}-${item.detail}-${index}`}
            >
              <td className='px-4 py-2'>{index + 1}.</td>
              <td className='px-4 py-2 my-2'>
                <div>{item.detail}</div>
              </td>
              <td className='px-4 py-2'>
                {Math.round(
                  (cartItems.find(
                    (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp
                  ).amount +
                    Number.EPSILON) *
                    100
                ) / 100}
              </td>
              <td className='px-4 py-2'>
                {/*{fetchPayRate[key][item.detail] === undefined*/}
                {/*  ? 'x' + fetchPayRate[key].default*/}
                {/*  : 'x' + fetchPayRate[key][item.detail]}*/}
                {'x' + cartItems.find((price) => price.type === key && price.detail === item.detail).payRate}
              </td>
              <td className='px-4 py-2'>
                <div>
                  {Math.round(
                    (cartItems.find((price) => price.type === key && price.detail === item.detail).payRate *
                      cartItems.find(
                        (price) => price.type === key && price.detail === item.detail && price.stamp === item.stamp
                      ).amount +
                      Number.EPSILON) *
                      100
                  ) / 100}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  )

  const fetchWallet = async () => {
    return await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
      .then((res) => {
        const credit = _get(res, 'detail.credit', 0)
        dispatch(
          setWalletCredit({
            wallet: {
              credit,
            },
          })
        )
        return credit
      })
      .catch((err) => {
        console.log('error get playerWallet ======', err)
        console.error(err)
      })
  }

  const confirmReceipt = async (detail) => {
    const walletBalance = await fetchWallet()
    const receiptNumber = detail.receiptNumber
    const receiptPurchaseAmount = detail.purchaseAmount
    const lottoName = detail.receiptDetail.lottoName
    const currentPurchaseItems = detail.purchaseItems
    // console.log(currentPurchaseItems, 'currentPurchaseItems')
    const limitedList = currentPurchaseItems.filter((obj) => obj.payRate < highestPayRate[obj.type])
    const getRefId = detail.refId
    // console.log(highestPayRate, 'highestPayRate')
    // console.log(limitedList, 'isLimited')
    if (limitedList.length > 0) {
      const isPayRateZero = limitedList.find((obj) => obj.payRate === 0) !== undefined
      if (isPayRateZero) {
        API.post('purchase', `/receipts/${receiptNumber}/cancel`)
          .then((res) => {
            if (res.code === 0) {
              // console.log(res, 'cancel')
              Swal.fire({
                icon: 'error',
                // title: 'ในรายการมีเลขปิดรับ กรุณาเลือกเลขอื่นนะคะ',
                title: 'There are unavaliable number in your receipt. Please select another number',
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'กลับหน้าแทงหวย',
                confirmButtonText: `${language !== 'MM' ? 'กลับหน้าแทง' : 'Back to Lottos'}`,
              }).then((result) => {
                if (result.value) {
                  setShowModal((prevState) => ({ ...prevState, isShow: false }))
                  setIsPurchasing(false)
                  history.push(`/lottos/${getRefId}`)
                }
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'An error occurred',
                text: res.detail || 'Please contact admin',
              })
            }
          })
          .catch((err) => {
            console.log('error _onClickConfirmPurchaseButton ======', err)
            console.error(err)
          })
      } else {
        Swal.fire({
          icon: 'warning',
          // title: 'ในรายการมีเลขอั้น',
          // text: `ท่านต้องการตรวจสอบอีกครั้งหรือไม่`,
          title: 'There are limited number',
          text: `Do you want to proceed?`,
          showCancelButton: true,
          confirmButtonColor: '#B6964E',
          cancelButtonColor: '#C0C0C0',
          // confirmButtonText: 'ยืนยันการซื้อ',
          // cancelButtonText: 'ตรวจเลขอั้น',
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Back',
        }).then((result) => {
          if (result.isConfirmed) {
            if (walletBalance >= receiptPurchaseAmount) {
              API.post('purchase', `/receipts/${receiptNumber}/confirm`)
                .then((res) => {
                  if (res.code === 0) {
                    // Success
                    // console.log(res, 'resDetail')
                    // const newPurchaseItems = res.detail.purchaseItems
                    // if (newPurchaseItems !== currentPurchaseItems) {
                    //   console.log('มีเลขอั้น')
                    // } else {
                    //   console.log('ไม่มีเลขอั้น')
                    // }
                    Swal.fire({
                      // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/thank-st.png`,
                      // title: 'ซื้อสำเร็จ',
                      title: 'Purchase Complete',

                      // text: `${lottoName} ทั้งหมด ${numeral(receiptPurchaseAmount).format('0,0.00')} บาทครับ`,
                      text: `${lottoName} Total ${numeral(receiptPurchaseAmount).format('0,0.00')} Baht`,

                      showCancelButton: true,
                      confirmButtonColor: '#B6964E',
                      // confirmButtonText: 'กลับหน้าแทงหวย',
                      confirmButtonText: `${language !== 'MM' ? 'กลับหน้าแทง' : 'Back to Lottos'}`,

                      cancelButtonColor: '#C0C0C0',
                      // cancelButtonText: 'ตรวจสอบโพย',
                      cancelButtonText: `${language !== 'MM' ? 'ตรวจสอบโพย' : 'Check your receipt'}`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setShowModal((prevState) => ({ ...prevState, isShow: false }))
                        setIsPurchasing(false)
                        history.push(`/lottos/${getRefId}`)
                      } else {
                        history.push(`/receipts/${receiptNumber}`)
                      }
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'An error occurred',
                      text: res.detail || 'Please contact admin',
                    })
                  }
                })
                .catch((err) => {
                  console.log('error _onClickConfirmPurchaseButton ======', err)
                  console.error(err)
                })
            } else {
              Swal.fire({
                icon: 'error',
                // title: 'ยอดเงินของคุณไม่เพียงพอ',
                title: 'Insufficient fund',

                // confirmButtonText: 'ฝากเงิน',
                confirmButtonText: 'Deposit',

                //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
                //     '0,0.00'
                //   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
                // }).then((result) => {
                //   if (result.value) {
                //     history.push('/deposit')
                //   }

                text: `Credit (${numeral(walletBalance).format('0,0.00')} Baht), 
                required amount ${receiptPurchaseAmount}`,
              }).then((result) => {
                if (result.value) {
                  history.push('/deposit')
                }
              })
            }
          } else {
            history.push(`/receipts/${receiptNumber}`)
            setTimeout(() => {
              console.log(`timeout ${receiptNumber} on process`)
              timeoutReceipt(receiptNumber)
            }, 180000)
          }
        })
      }
    } else {
      if (walletBalance >= receiptPurchaseAmount) {
        API.post('purchase', `/receipts/${receiptNumber}/confirm`)
          .then((res) => {
            if (res.code === 0) {
              // Success
              Swal.fire({
                // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/thank-st.png`,
                // title: 'ซื้อสำเร็จ',
                title: 'Purchase Complete',

                // text: `${lottoName} ทั้งหมด ${numeral(receiptPurchaseAmount).format('0,0.00')} บาทครับ`,
                text: `${lottoName} Total ${numeral(receiptPurchaseAmount).format('0,0.00')} Baht`,

                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'กลับหน้าแทงหวย',
                confirmButtonText: `${language !== 'MM' ? 'กลับหน้าแทง' : 'Back to Lottos'}`,

                cancelButtonColor: '#C0C0C0',
                // cancelButtonText: 'ตรวจสอบโพย',
                cancelButtonText: `${language !== 'MM' ? 'ตรวจสอบโพย' : 'Check your receipt'}`,
              }).then((result) => {
                if (result.isConfirmed) {
                  setShowModal((prevState) => ({ ...prevState, isShow: false }))
                  setIsPurchasing(false)
                  history.push(`/lottos/${getRefId}`)
                } else {
                  history.push(`/receipts/${receiptNumber}`)
                }
                setTimeout(() => {
                  API.get('purchase', `/receipts/${receiptNumber}`).then((res) => {
                    if (res.code === 0) {
                      if (res.detail.status === 'rejected') {
                        Swal.fire({
                          icon: 'error',
                          // title: 'โพยของท่านถูกปฏิเสธ',
                          // text: 'เนื่องจากเกินเวลาที่กำหนดครับ ท่านไม่เสียเครดิตจากการทำรายการนี้ครับ',
                          title: 'Your receipt was rejected',
                          text: 'Timeout, no credit consume',
                        })
                        return
                      }
                      if (res.detail.status === 'error') {
                        Swal.fire({
                          icon: 'error',
                          // title: 'โพยของท่านล้มเหลว',
                          // text: 'กรุณาทำรายการใหม่อีกครั้งครับ',
                          title: 'Your receipt was failed',
                          text: 'Try again',
                        })
                      }
                    }
                  })
                }, 2000)
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'An error occurred',
                text: res.detail || 'Please contact admin',
              })
            }
          })
          .catch((err) => {
            console.log('error _onClickConfirmPurchaseButton ======', err)
            console.error(err)
          })
      } else {
        Swal.fire({
          icon: 'error',
          // title: 'ยอดเงินของคุณไม่เพียงพอ',
          title: 'Insufficient fund',

          // confirmButtonText: 'ฝากเงิน',
          confirmButtonText: 'Deposit',

          //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
          //     '0,0.00'
          //   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
          // }).then((result) => {
          //   if (result.value) {
          //     history.push('/deposit')
          //   }

          text: `Credit (${numeral(walletBalance).format('0,0.00')} Baht), require amount ${receiptPurchaseAmount}`,
        }).then((result) => {
          if (result.value) {
            history.push('/deposit')
          }
        })
      }
    }
  }

  // const checkIfZero = async (receiptNumber) => {
  //   API.get('purchase', `/receipts/${receiptNumber}`).then((res) => {
  //     const limitedList = res.detail.purchaseItems.filter((obj) => obj.payRate < highestPayRate[obj.type])
  //     // console.log(highestPayRate, 'highestPayRate')
  //     // console.log(limitedList, 'isLimited')
  //     if (limitedList.length > 0) {
  //       const isPayRateZero = limitedList.find((obj) => obj.payRate === 0) !== undefined
  //       if (isPayRateZero) {
  //         API.post('purchase', `/receipts/${receiptNumber}/cancel`)
  //           .then((res) => {
  //             if (res.code === 0) {
  //               // Success
  //               Swal.fire({
  //                 icon: 'error',
  //                 title: 'ในรายการมีเลขปิดรับ กรุณาเลือกเลขอื่นนะคะ',
  //                 confirmButtonColor: '#7dc351',
  //                 confirmButtonText: 'กลับหน้าแทงหวย',
  //               }).then((result) => {
  //                 if (result.value) {
  //                   history.push('/lottos')
  //                 }
  //               })
  //             } else {
  //               Swal.fire({
  //                 icon: 'error',
  //                 title: 'เกิดข้อผิดพลาด',
  //                 text: res.detail || 'โปรดติดต่อเจ้าหน้าที่',
  //               })
  //             }
  //           })
  //           .catch((err) => {
  //             console.log('error _onClickConfirmPurchaseButton ======', err)
  //             console.error(err)
  //           })
  //       } else {
  //         Swal.fire({
  //           icon: 'warning',
  //           title: 'ในรายการมีเลขอั้น',
  //           text: `ท่านต้องการตรวจสอบอีกครั้งหรือไม่`,
  //           showCancelButton: true,
  //           confirmButtonColor: '#7dc351',
  //           cancelButtonColor: '#C0C0C0',
  //           confirmButtonText: 'ยืนยันการซื้อ',
  //           cancelButtonText: 'ตรวจเลขอั้น',
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             fetchWallet().then((walletBalance) => {
  //               if (walletBalance >= receiptPurchaseAmount) {
  //                 API.post('purchase', `/receipts/${receiptNumber}/confirm`)
  //                   .then((res) => {
  //                     if (res.code === 0) {
  //                       // Success
  //                       Swal.fire({
  //                         imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/thank-st.png`,
  //                         title: 'ซื้อสำเร็จ',
  //                         confirmButtonColor: '#7dc351',
  //                         confirmButtonText: 'ไปหน้าแทงหวย',
  //                       }).then((result) => {
  //                         if (result.value) {
  //                           history.push('/lottos')
  //                         }
  //                       })
  //                     } else {
  //                       Swal.fire({
  //                         icon: 'error',
  //                         title: 'เกิดข้อผิดพลาด',
  //                         text: res.detail || 'โปรดติดต่อเจ้าหน้าที่',
  //                       })
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     console.log('error _onClickConfirmPurchaseButton ======', err)
  //                     console.error(err)
  //                   })
  //               } else {
  //                 Swal.fire({
  //                   icon: 'error',
  //                   title: 'ยอดเงินของคุณไม่เพียงพอ',
  //                   confirmButtonText: 'ฝากเงิน',
  //                   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
  //                     '0,0.00'
  //                   )} บาท), ยอดเงินที่จะเล่น ${receiptPurchaseAmount}`,
  //                 }).then((result) => {
  //                   if (result.value) {
  //                     history.push('/deposit')
  //                   }
  //                 })
  //               }
  //             })
  //           } else {
  //             history.push(`/receipts/${receiptNumber}`)
  //             setTimeout(() => {
  //               console.log(`timeout ${receiptNumber} on process`)
  //               timeoutReceipt(receiptNumber)
  //             }, 180000)
  //           }
  //         })
  //       }
  //     } else {
  //       console.log('No Zero PayRate')
  //     }
  //   })
  // }

  const _onClickConfirmPurchase = () => {
    if (!auth.username) {
      // Not login, so cannot purchase
      Swal.fire({
        icon: 'error',
        // title: 'คุณยังไม่ได้เข้าสู่ระบบ',
        // text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
        title: 'Error',
        text: 'Please log in to proceed',
        showCancelButton: true,
        confirmButtonColor: '#B6964E',
        cancelButtonColor: '#C0C0C0',
        // confirmButtonText: 'เข้าสู่ระบบ',
        confirmButtonText: 'Log in',
      }).then((result) => {
        if (result.value) {
          setShowLogin({ isShow: true, currentModal: 'login' })
        }
      })
      return
    }
    if (!lottoData) {
      Swal.fire({
        icon: 'error',
        title: 'An error occurred',
        text: 'Please try again',
      })
      return
    }

    setIsPurchasing(true) // To disable the "ส่งโพย" button while processing

    const lottoDetail = {
      refId: lottoNumber,
      lottoGroup: lottoData.lottoGroup,
      lottoName: lottoData.lottoName,
      drawDateStr: lottoData.drawDateStr,
    }

    let purchaseItems = cartItems
    const sumPurchase = cartItems.reduce((sum, x) => sum + x.amount, 0)
    const purchaseAmount = Math.round((sumPurchase + Number.EPSILON) * 100) / 100

    // console.log(sumPurchase, 'sumPurchase')
    // console.log(purchaseAmount, 'purchaseAmount2')
    // console.log(purchaseItems, 'purchaseItems2')

    const data = {
      playerUsername: auth.username, // This is Hash name, not human friendly name
      playerName: auth.attributes.name,
      receiptType: 'lotto',
      refId: lottoDetail.refId, // Earth See here
      // refId: '0', // Earth See here
      receiptDetail: {
        lottoGroup: lottoDetail.lottoGroup,
        lottoName: lottoDetail.lottoName,
        drawDateStr: lottoDetail.drawDateStr,
        remark: remarkValue,
      },
      purchaseItems,
      purchaseAmount,
      timeout: 120,
    }

    if (data.purchaseAmount > 100000) {
      Swal.fire({
        icon: 'error',
        // title: 'กรุณาทำรายการใหม่อีกครั้ง',
        title: 'Please try again',

        // html: 'แทงขั้นสูงสุดต่อครั้ง 100,000.00 </br> แทงขั้นสูงสุดต่อเลข 2,000.00',
        html: 'Max per receipt 100,000.00 </br> Max per number 2,000.00',

        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'ทำรายการใหม่',
        confirmButtonText: 'New receipt',
      }).then(() => {
        setIsPurchasing(false)
      })
    } else if (data.purchaseItems < 1) {
      Swal.fire({
        icon: 'error',
        // title: 'คุณยังไม่ได้เลือกรายการ',
        title: 'No selection',

        // html: 'กรุณาเลือกหวยที่ต้องการอย่างน้อย 1 รายการ',
        html: 'Please select at least 1',

        confirmButtonColor: '#B6964E',
        // confirmButtonText: 'ทำรายการใหม่',
        confirmButtonText: 'New receipt',
      }).then(() => {
        setIsPurchasing(false)
      })
    } else if (purchaseAmount > walletCredit) {
      Swal.fire({
        icon: 'error',
        // title: 'เงินในกระเป๋าไม่พอ',
        title: 'Insufficiant fund',

        // confirmButtonText: 'ฝากเงิน',
        confirmButtonText: 'Deposit',
      }).then((result) => {
        setIsPurchasing(false)
        if (result.value) {
          history.push('/deposit')
        }
      })
    } else {
      // About to buy lotto !!
      API.post('purchase', '/receipts', { body: data })
        .then((res) => {
          const detail = _.get(res, 'detail', {})
          if (!detail.receiptNumber || !detail.receiptDetail.lottoName) {
            Swal.fire({
              icon: 'error',
              title: 'An error occurred',
              text: 'Please try again',
            })
            return
          }
          dispatch(setReceipt({ detail }))
          return confirmReceipt(detail)
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'An error occurred',
            text: err.message,
          })
          console.error(err)
        })
        .finally(() => {
          setShowModal((prevState) => ({ ...prevState, isShow: false }))
          setIsPurchasing(false)
          dispatch(clearCart())
        })
    }
  }

  return (
    <section
      className={`buy-lotto__modal confirmCheckOut ${
        showModal.isShow && showModal.currentModal === 'confirm' ? 'active' : ''
      }`}
    >
      <div className={`buy-lotto__modal-box confirmCheckOut ${showModal.currentModal === 'confirm' ? 'active' : ''}`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          {/* ยืนยันการซื้อ<span>{cartCounter} รายการ</span> */}
          Confirmation<span>{cartCounter} items</span>
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div className='buy-lotto__modal-box-content-2'>{cartTable}</div>
        <div className='agent-wrapper'>
          <div className='agent-header'>
            <div>
              <div className='header__wrapper'>
                { language !== 'MM' ? <span className='header'>สำหรับเอเย่น</span> : <span className='header'>For Dealer</span> }
                { language !== 'MM' ?  <span className='content'>ใส่ตัวเลขที่เราจะจ่ายลูกค้า แล้วกดคำนวณ</span> :  <span className='content'>Adjust number and press "Calculate"</span> }
              </div>
            </div>

            {!isAgentSubmit ? (
              <div>
                <button
                  onClick={() => {
                    submitAgent()
                    // setProfit(
                    //   numeral(Math.round((oldPurchaseAmount - purchaseAmount + Number.EPSILON) * 100) / 100).format(
                    //     '0,0.00',
                    //   ),
                    // )
                  }}
                >
                  <span>สร้างรายได้</span>
                </button>
              </div>
            ) : (
              <div>
                <ReactToPrint
                  documentTitle={`ยอดธง ${remarkValue ? remarkValue : ''}`}
                  trigger={() => (
                    <button style={{ background: 'linear-gradient(to bottom, #B76E79 0%, #D4A3AA 100%)' }}>
                      บันทึก
                    </button>
                  )}
                  content={() => componentRef}
                />

                {/* component to be printed */}
                <div style={{ display: 'none' }}>
                  <ComponentToPrint ref={(el) => (componentRef = el)}/>
                </div>
              </div>
            )}

            {/*<div>*/}
            {/*  <button onClick={submitAgent}>*/}
            {/*    { language !== 'MM' ? <span>คำนวณ</span> :*/}
            {/*    <span>Calculate</span> }*/}
            {/*  </button>*/}
            {/*</div>*/}

          </div>
          <div className='agent-content'>
            <div className='px-1'>
              {/* <div>3 ตัวบน</div> */}
              <div>{language === 'MM' ? lottoType['3A'] : lottoType_TH['3A']}</div>
              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '3A')}
                value={agentUser !== undefined ? agentUser['3A'] : highestPayRate['3A']}
              />
            </div>
            <div className='px-1'>
              {/* <div>3 ตัวโต๊ด</div> */}
              <div>{language === 'MM' ? lottoType['3R'] : lottoType_TH['3R']}</div>
              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '3R')}
                value={agentUser !== undefined ? agentUser['3R'] : highestPayRate['3R']}
              />
            </div>
            <div className='px-1'>
              {/* <div>2 ตัวบน</div> */}
              <div>{language === 'MM' ? lottoType['2A'] : lottoType_TH['2A']}</div>

              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '2A')}
                value={agentUser !== undefined ? agentUser['2A'] : highestPayRate['2A']}
              />
            </div>
            <div className='px-1'>
              {/* <div>2 ตัวล่าง</div> */}
              <div>{language === 'MM' ? lottoType['2B'] : lottoType_TH['2B']}</div>
              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '2B')}
                value={agentUser !== undefined ? agentUser['2B'] : highestPayRate['2B']}
              />
            </div>
            {/*<div className='px-1'>*/}
            {/*  /!* <div>วิ่งบน</div> *!/*/}
            {/*  <div>{language === 'MM' ? lottoType.SA : lottoType_TH.SA}</div>*/}

            {/*  <input*/}
            {/*    className='mx-1 my-1'*/}
            {/*    type='number'*/}
            {/*    onChange={(event) => handleAgent(event, 'SA')}*/}
            {/*    value={agentUser !== undefined ? agentUser['SA'] : highestPayRate['SA']}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className='px-1'>*/}
            {/*  /!* <div>วิ่งล่าง</div> *!/*/}
            {/*  <div>{language === 'MM' ? lottoType.SB : lottoType_TH.SB}</div>*/}

            {/*  <input*/}
            {/*    className='mx-1 my-1'*/}
            {/*    type='number'*/}
            {/*    onChange={(event) => handleAgent(event, 'SB')}*/}
            {/*    value={agentUser !== undefined ? agentUser['SB'] : highestPayRate['SB']}*/}
            {/*  />*/}
            {/*</div>*/}
            {lottoData.lottoName !== 'หวยรัฐบาลไทย' ? (<div className='px-1 text-green-600'>
              <div>{language === 'MM' ? lottoType.SA : lottoType_TH.SA}</div>
              <input
                className='mx-1 my-1'
                type='number'
                onChange={(event) => handleAgent(event, 'SA')}
                value={agentUser !== undefined ? agentUser['SA'] : highestPayRate['SA']}
              />
            </div>) : null}
            {lottoData.lottoName !== 'หวยรัฐบาลไทย' ? (<div className='px-1 text-green-600'>
              <div>{language === 'MM' ? lottoType.SB : lottoType_TH.SB}</div>
              <input
                className='mx-1 my-1'
                type='number'
                onChange={(event) => handleAgent(event, 'SB')}
                value={agentUser !== undefined ? agentUser['SB'] : highestPayRate['SB']}
              />
            </div>) : null}
          </div>
          <div className='agent-content'>
            {lottoData.lottoName === 'หวยรัฐบาลไทย' ? (<div className='px-1 text-yellow-900'>
              <div>{language === 'MM' ? lottoType['3F'] : lottoType_TH['3F']}</div>
              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '3F')}
                value={agentUser !== undefined ? agentUser['3F'] : 450}
              />
            </div>) : null}

            {lottoData.lottoName === 'หวยรัฐบาลไทย' ? (<div className='px-1 text-yellow-900'>
              <div>{language === 'MM' ? lottoType['3B'] : lottoType_TH['3B']}</div>
              <input
                className='mx-1 my-1'
                type='text'
                pattern='[0-9]*'
                min='1'
                onChange={(event) => handleAgent(event, '3B')}
                value={agentUser !== undefined ? agentUser['3B'] : 450}
              />
            </div>) : null}
            {lottoData.lottoName === 'หวยรัฐบาลไทย' ? (<div className='px-1 text-green-600'>
              <div>{language === 'MM' ? lottoType.SA : lottoType_TH.SA}</div>
              <input
                className='mx-1 my-1'
                type='number'
                onChange={(event) => handleAgent(event, 'SA')}
                value={agentUser !== undefined ? agentUser['SA'] : highestPayRate['SA']}
              />
            </div>) : null}
            {lottoData.lottoName === 'หวยรัฐบาลไทย' ? (<div className='px-1 text-green-600'>
              <div>{language === 'MM' ? lottoType.SB : lottoType_TH.SB}</div>
              <input
                className='mx-1 my-1'
                type='number'
                onChange={(event) => handleAgent(event, 'SB')}
                value={agentUser !== undefined ? agentUser['SB'] : highestPayRate['SB']}
              />
            </div>) : null}
          </div>
          <div className='confirmCheckout purchaseAmount rounded-lg'>
            <div className={`px-4 py-2 rounded-lg ${isAgentSubmit ? 'active' : ''}`}>
              {language !== 'MM' ? <div>ยอดเครดิตคงเหลือ</div> : <div>Remaining credit</div> }


              <div className='text-xl'>
                {!auth.username ? 'Please login' : numeral(walletCredit).format('0,0.00')}
              </div>
            </div>
            <div className={`px-4 py-2 rounded-lg${isAgentSubmit ? 'active' : ''}`}>
              {/* <div>รวมยอดแทง</div> */}
              <div>Total amount</div>

              <div className='text-xl'>
                {numeral(Math.round((purchaseAmount + Number.EPSILON) * 100) / 100).format('0,0.00')}
              </div>
            </div>
            {oldPurchaseAmount > 0 ? (
              <div className={`px-4 py-2 ${isAgentSubmit ? 'active' : ''}`}>

                {language !== 'MM' ? <div>รายได้</div> : <div>Revenue</div> }

                <div className='text-xl'>
                  {numeral(Math.round((oldPurchaseAmount - purchaseAmount + Number.EPSILON) * 100) / 100).format(
                    '0,0.00'
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className='buy-lotto__modal__remark-box'>
            {/* <p className='buy-lotto__modal__remark-label'>บันทึกช่วยจำ</p> */}
            <p className='buy-lotto__modal__remark-label'>Note</p>

            <textarea
              className='buy-lotto__modal__remark-input'
              type='text'
              rows='3'
              value={remarkValue}
              style={{ backgrounColor: '#ECECEC' }}
              onChange={(e) => setRemarkValue(e.target.value)}
            />
          </div>
        </div>
        {isPurchasing ? (
          <div className='buy-lotto__check-duplicate-btn text-xl'>
            <button className='btn-bevel'>{language === 'MM' ? 'Submitting ...' : 'กำลังส่ง ...' }{lottoData.lottoName}...</button>
          </div>
        ) : (
          <div className='buy-lotto__check-duplicate-btn text-xl'>
            <button className='btn-bevel' onClick={() => setShowModal({ isShow: true, currentModal: 'ใส่ราคา' })}>
              {language !== 'MM' ? <span>ย้อนกลับ</span> : <span>Back</span>}
            </button>
            <button
              className={`${isPurchasing || lottoData.status !== 'open' ? 'disabled' : ''} btn-bevel`}
              onClick={() => _onClickConfirmPurchase()}
            >
              {language !== 'MM' ? <span>ส่งโพย</span> : <span>Submit</span>}
            </button>
          </div>
        )}
      </div>
      {/*{!auth.username ? <Login showModal={showLogin} setShowModal={setShowLogin} /> : null}*/}
    </section>
  )
}

ConfirmCheckoutModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  lottoNumber: PropTypes.string,
  lottoData: PropTypes.object,
  highestPayRate: PropTypes.object,
  isAgentSubmit: PropTypes.bool,
  setIsAgentSubmit: PropTypes.func,
  customerTable: PropTypes.any,
  setCustomerTable: PropTypes.func,
}
