import React, { useEffect, useState, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { API, Auth } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import AliceCarousel from 'react-alice-carousel'
import _get from 'lodash/get'
import { faColumns } from '@fortawesome/free-solid-svg-icons'
import { render } from 'enzyme'

import { getCognitoProvider } from '../../config/cognito'
import { getAuth } from '../../redux/selectors/auth'
import { setAuth } from '../../redux/actions/auth'
import { getLang } from '../../redux/selectors/lang'
import Post from './Post'
import PostTable from './PostTable'
import PostThaiWinning from './PostThaiWinning'
import Box from '../../components/Box/Box'
import BoxWhite from '../../components/Box/BoxWhite'
import Login from '../../components/Login/Login'
import SignUp from '../../components/SignUp/SignUp'
import { STOCK_LIST } from '../../utils/stockList'
import thailand from '../../assets/flags/thailand.png'
import 'react-alice-carousel/lib/alice-carousel.css'
// import believeRich from '../../assets/dong-mascot/believe.jpg'
// import facebook from '../../assets/dong-mascot/facebook.jpg'
// import hotNumber from '../../assets/dong-mascot/number.png'
// import angalong_logo from '../../assets/dong-mascot/angalong.jpg'
// import fishingIcon from '../../assets/games-icon/fishing-big.jpg'
// import line from '../../assets/dong-mascot/line.jpg'
import menu1 from '../../assets/powerlott-logo/1.jpg'
import menu2 from '../../assets/powerlott-logo/2.jpg'
import menu3 from '../../assets/powerlott-logo/3.jpg'
import menu4 from '../../assets/powerlott-logo/4.jpg'
import { HOME_PAGE } from '../../utils/lottoList'

const YEEKEE_ROUND = _.range(287).map((num) => num + 1)

// SLIDE SHOW
let listOfMobileImages = []

class SlideshowMobile extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfMobileImages = this.importAll(
      require.context('../../assets/dong-banner/mobile/', false, /\.(png|jpe?g|svg)$/)
    )
  }

  render() {
    return (
      <div className='home mobile' style={{ marginBottom: '10px' }}>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
          {listOfMobileImages.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

let listOfPcImages = []

class SlideshowPc extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfPcImages = this.importAll(require.context('../../assets/dong-banner/pc/', false, /\.(png|jpe?g|svg)$/))
  }

  render() {
    return (
      <div className='home desktop' style={{ marginBottom: '10px', margin: 'auto' }}>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
          {listOfPcImages.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

// SLIDE SHOW TH
let listOfMobileImagesThai = []

class SlideshowMobileThai extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfMobileImagesThai = this.importAll(
      require.context('../../assets/dong-banner/mobile/TH/', false, /\.(png|jpe?g|svg)$/)
    )
  }

  render() {
    return (
      <div className='home mobile' style={{ marginBottom: '10px' }}>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={2000} mouseTracking>
          {listOfMobileImagesThai.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

let listOfPcImagesThai = []

class SlideshowPcThai extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfPcImagesThai = this.importAll(require.context('../../assets/dong-banner/pc/TH/', false, /\.(png|jpe?g|svg)$/))
  }

  render() {
    return (
      <div className='home desktop' style={{ marginBottom: '10px', margin: 'auto' }}>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={2000} mouseTracking>
          {listOfPcImagesThai.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

export default function HomeContainer() {
  const dispatch = useDispatch()
  const auth = useSelector(getAuth)
  const [countryLottos, setCountryLottos] = useState([])
  const [countryRewarded, setCountryRewarded] = useState([])
  const [stockLottos, setStockLottos] = useState([])
  const [stockRewarded, setStockRewarded] = useState([])
  const [dailyLottos, setDailyLottos] = useState([])
  const [dailyRewarded, setDailyRewarded] = useState([])
  const [vipLottos, setVipLottos] = useState([])
  const [vipRewarded, setVipRewarded] = useState([])
  const [posts, setPosts] = useState([])
  const [thaiPost, setThaiPost] = useState([])
  const [threeD, setThreeD] = useState()
  const [yeekeePosts, setYeekeePosts] = useState([])
  const [mobileCountry, setMobileCountry] = useState({})
  const [mobileStock, setMobileStock] = useState({})
  const [mobileDaily, setMobileDaily] = useState({})
  const [mobileVip, setMobileVip] = useState({})
  // const time = moment(new Date()).format('HH mm')
  const [currentYeekee, setCurrentYeekee] = useState(1)
  // const currentYeekee = Math.floor((parseInt(time.split(' ')[0]) * 60 + parseInt(time.split(' ')[1]) - 1) / 5)
  const [mobileYeekeeNumber, setMobileYeekeeNumber] = useState(1)
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  const [confirmPassword, setConfirmPassword] = useState(false)
  const history = useHistory()
  const cognitoProvider = { provider: getCognitoProvider() }
  // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn())
  // const FadeUp = batch(Fade(), Move(), Sticky())
  // console.log(currentYeekee, 'currentYeekee')
  // const [show, setShow] = useState(false)
  const [goldNumber, setGoldNumber] = useState({ one: false, two: false, three: false, four: false, five: false })
  // const [stopPoint, setStopPoint] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [rewardDigit, setRewardDigit] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [counter, setCounter] = useState({
    one: ~~(Math.random() * 10),
    two: ~~(Math.random() * 10),
    three: ~~(Math.random() * 10),
    four: ~~(Math.random() * 10),
    five: ~~(Math.random() * 10),
    six: -10,
  })
  const [counter2, setCounter2] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [timing, setTiming] = useState({ one: false, two: false, three: false, four: false, five: false })
  const [trigger, setTrigger] = useState(false)
  const [countdown, setCountdown] = useState(0)
  const [fadeProp, setFadeProp] = useState({ fade: 'fade-in' })
  const [fadePropTwo, setFadePropTwo] = useState({ fade: 'fade-in-2' })
  const [fadePropThree, setFadePropThree] = useState({ fade: 'fade-in-3' })
  const [fadePropFour, setFadePropFour] = useState({ fade: 'fade-in-4' })
  const [fadePropFive, setFadePropFive] = useState({ fade: 'fade-in-5' })
  const [showTwoD, setShowTwoD] = useState({ isShow: false, morning: 'XX', close: 'XX' })
  const lang = useSelector(getLang)
  const language = lang.lang

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, one: prevCount.one + 1 })) // <-- Change this line!
    }, 523)
    const timer2 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, two: prevCount.two + 1 })) // <-- Change this line!
    }, 727)
    const timer3 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, three: prevCount.three + 1 })) // <-- Change this line!
    }, 419)
    const timer4 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, four: prevCount.four + 1 })) // <-- Change this line!
    }, 953)
    const timer5 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, five: prevCount.five + 1 })) // <-- Change this line!
    }, 307)
    const timer6 = setInterval(() => {
      setCounter((prevCount) => ({ ...prevCount, six: prevCount.six + 1 })) // <-- Change this line!
    }, 1000)
    return () => {
      clearInterval(timer)
      clearInterval(timer2)
      clearInterval(timer3)
      clearInterval(timer4)
      clearInterval(timer5)
      clearInterval(timer6)
    }
  }, []) // Pass in empty array to run effect only once!

  useEffect(() => {
    if (!goldNumber.one) {
      if (fadeProp.fade === 'fade-in') {
        setFadeProp({
          fade: 'fade-out',
        })
      } else {
        setFadeProp({
          fade: 'fade-in',
        })
      }
    } else {
      setFadeProp({ fade: '' })
    }
  }, [counter2.one])

  useEffect(() => {
    if (!goldNumber.two) {
      if (fadePropTwo.fade === 'fade-in-2') {
        setFadePropTwo({
          fade: 'fade-out-2',
        })
      } else {
        setFadePropTwo({
          fade: 'fade-in-2',
        })
      }
    } else {
      setFadePropTwo({ fade: '' })
    }
  }, [counter2.two])

  useEffect(() => {
    if (!goldNumber.three) {
      if (fadePropThree.fade === 'fade-in-3') {
        setFadePropThree({
          fade: 'fade-out-3',
        })
      } else {
        setFadePropThree({
          fade: 'fade-in-3',
        })
      }
    } else {
      setFadePropThree({ fade: '' })
    }
  }, [counter2.three])
  //
  useEffect(() => {
    if (!goldNumber.four) {
      if (fadePropFour.fade === 'fade-in-4') {
        setFadePropFour({
          fade: 'fade-out-4',
        })
      } else {
        setFadePropFour({
          fade: 'fade-in-4',
        })
      }
    } else {
      setFadePropFour({ fade: '' })
    }
  }, [counter2.four])

  useEffect(() => {
    if (!goldNumber.five) {
      if (fadePropFive.fade === 'fade-in-5') {
        setFadePropFive({
          fade: 'fade-out-5',
        })
      } else {
        setFadePropFive({
          fade: 'fade-in-5',
        })
      }
    } else {
      setFadePropFive({ fade: '' })
    }
  }, [counter2.five])

  useEffect(
    () => {
      const date = moment(new Date()).format('DD/MM/YY')
      const time = moment(new Date()).format('HH mm ss')
      // console.log(time, 'time')
      const current = Math.floor((parseInt(time.split(' ')[0]) * 60 + parseInt(time.split(' ')[1]) - 1) / 5)
      // console.log(current, 'current')
      const next = current + 1
      const name = 'ดองยี่กี ' + next
      setCurrentYeekee(current <= 0 ? 1 : current)
      setMobileYeekeeNumber(current <= 0 ? 1 : current)

      const nextYeekee = (current + 1) * 5
      // const nextHours = Math.floor(nextYeekee / 60)
      // const nextMinutes = nextYeekee - nextHours * 60
      // console.log(nextYeekee, 'nextYeekee')
      // console.log(nextHours, 'nextHours')
      // console.log(nextMinutes, 'nextMinutes')
      const currentSecond =
        parseInt(time.split(' ')[0]) * 3600 + parseInt(time.split(' ')[1]) * 60 + parseInt(time.split(' ')[2])
      const nextSecond = nextYeekee * 60 + 20
      // console.log(currentSecond, 'currentSecond')
      // console.log(nextSecond, 'nextSecond')
      const countdownSecond = nextSecond - currentSecond
      setCountdown(countdownSecond)
      if (countdownSecond <= 0) {
        API.get('lotto', `/results?lottoName=${name}&drawDateStr=${date}`)
          .then((res) => {
            // console.log(res.detail, 'res result')
            if (res.code === 0) {
              const resultLotto = res.detail[0].results
              const firstVal = parseInt(resultLotto['3A'].split('')[0])
              const secondVal = parseInt(resultLotto['2A'].split('')[0])
              const thirdVal = parseInt(resultLotto['2A'].split('')[1])
              const fourthVal = parseInt(resultLotto['2B'].split('')[0])
              const fifthVal = parseInt(resultLotto['2B'].split('')[1])
              setRewardDigit((prevState) => ({
                ...prevState,
                one: firstVal,
                two: secondVal,
                three: thirdVal,
                four: fourthVal,
                five: fifthVal,
              }))
            }
          })
          .catch((err) => console.error(err))
        setGoldNumber((prevState) => ({ ...prevState, one: true, two: true, three: true, four: true, five: true }))
        setCountdown(0)
        setCounter((prevState) => ({ ...prevState, six: 0 }))
        return
      }
      // // console.log(countdownSecond, 'countdownSecound')
      // // console.log(countdown, 'countdown')
      // // console.log(name, 'lotto Name')
      const timer1 = setTimeout(() => {
        API.get('lotto', `/results?lottoName=${name}&drawDateStr=${date}`)
          .then((res) => {
            // console.log(res.detail, 'res result')
            if (res.code === 0) {
              const resultLotto = res.detail[0].results
              const firstVal = parseInt(resultLotto['3A'].split('')[0])
              const secondVal = parseInt(resultLotto['2A'].split('')[0])
              const thirdVal = parseInt(resultLotto['2A'].split('')[1])
              const fourthVal = parseInt(resultLotto['2B'].split('')[0])
              const fifthVal = parseInt(resultLotto['2B'].split('')[1])
              setRewardDigit((prevState) => ({
                ...prevState,
                one: firstVal,
                two: secondVal,
                three: thirdVal,
                four: fourthVal,
                five: fifthVal,
              }))
            }
          })
          .catch((err) => console.error(err))
      }, countdownSecond * 1000)
      // console.log(time, 'new time')
      return () => {
        clearTimeout(timer1)
      }
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  )

  useEffect(() => {
    if (typeof rewardDigit.one == 'number' && typeof counter.one == 'number') {
      // console.log(counter, 'counter user')
      setCounter2((prevCount) => ({
        ...prevCount,
        one: counter.one,
        two: counter.two,
        three: counter.three,
        four: counter.four,
        five: counter.five,
      }))
      setTrigger(true)
      const timer7 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, one: true }))
      }, 10000)
      const timer8 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, two: true }))
      }, 11000)
      const timer9 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, three: true }))
      }, 12000)
      const timer10 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, four: true }))
      }, 13000)
      const timer11 = setTimeout(() => {
        setGoldNumber((prevState) => ({ ...prevState, five: true }))
      }, 14000)
      return () => {
        clearTimeout(timer7)
        clearTimeout(timer8)
        clearTimeout(timer9)
        clearTimeout(timer10)
        clearTimeout(timer11)
      }
    }
  }, [rewardDigit])

  useEffect(() => {
    if (trigger) {
      const timing1 = rewardDigit.one - (counter2.one % 10) + 20
      const timing2 = rewardDigit.two - (counter2.two % 10) + 20
      const timing3 = rewardDigit.three - (counter2.three % 10) + 20
      const timing4 = rewardDigit.four - (counter2.four % 10) + 20
      const timing5 = rewardDigit.five - (counter2.five % 10) + 20
      setTiming({
        one: 10000 / timing1,
        two: 11000 / timing2,
        three: 12000 / timing3,
        four: 13000 / timing4,
        five: 14000 / timing5,
      })
      const timer12 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, one: prevCount.one + 1 })) // <-- Change this line!
      }, 10000 / timing1)
      const timer13 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, two: prevCount.two + 1 })) // <-- Change this line!
      }, 11000 / timing2)
      const timer14 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, three: prevCount.three + 1 })) // <-- Change this line!
      }, 12000 / timing3)
      const timer15 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, four: prevCount.four + 1 })) // <-- Change this line!
      }, 13000 / timing4)
      const timer16 = setInterval(() => {
        setCounter2((prevCount) => ({ ...prevCount, five: prevCount.five + 1 })) // <-- Change this line!
      }, 14000 / timing5)
      return () => {
        clearTimeout(timer12)
        clearTimeout(timer13)
        clearTimeout(timer14)
        clearTimeout(timer15)
        clearTimeout(timer16)
      }
    }
  }, [trigger])
  // date time 'th-TH' - > 'en-UK'
  const dateNow = new Date().toLocaleDateString('en-UK', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const dateToday = (
    <span
      style={{
        float: 'right',
        color: '#A98E70',
        background: '#231F20',
        fontSize: '20px',
        marginTop: '3px',
        borderRadius: '10px',
      }}
    >
      &nbsp;&nbsp;{dateNow}&nbsp;&nbsp;
    </span>
  )

  const dongDateToday = (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFFFFF',
        background: '#C0534D',
      }}
    >
      &nbsp;&nbsp;{dateNow}&nbsp;&nbsp;
    </span>
  )

  const handleClick = () => {
    history.replace('/lottos')
  }

  useEffect(() => {
    const date = moment(new Date()).format('DD/MM/YY')
    // const date = '16/08/22' //dong
    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยหุ้น`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        setStockLottos(data)
      })
      .catch((err) => console.error(err))

    // รอปิด หวยรายวัน และหวยวีไอพี

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยรายวัน`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        // console.log(data, 'data')
        setDailyLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยวีไอพี`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        setVipLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/lottos?drawDateStr=${date}&limit=50&lottoGroup=หวยต่างประเทศ`)
      .then((res) => {
        const data = res.detail.map((obj) => obj.lottoName)
        // console.log(res, 'detail')
        setCountryLottos(data)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?limit=1&lottoName=หวยรัฐบาลไทย`)
      .then((res) => {
        let array = []
        const data = res.detail
        const lotto = data[0]
        const results = lotto.results
        const newObj = {}
        // console.log(data, 'asdf asd')
        // console.log(results, 'asdfasdf')
        newObj.title = lotto.lottoName
        newObj.createdAt = lotto.drawDateStr
        newObj.result = {}
        // newObj.result.lotto6 = results['6S']
        newObj.result.lotto3f = `${results['3F1']} ${results['3F2']}`
        newObj.result.lotto3b = `${results['3B1']} ${results['3B2']}`
        newObj.result.lotto3a = results['3A']
        newObj.result.lotto2a = results['2A']
        newObj.result.lotto2b = results['2B']
        array.push(newObj)
        setThreeD(results['3A'])
        setThaiPost(array)
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยยี่กี&limit=300`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          setYeekeePosts(data.reverse())
        }
      })
      .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยหุ้น&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const stockReward = data.map((obj) => obj.lottoName)
          setStockRewarded(stockReward)
        }
      })
      .catch((err) => console.error(err))


    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยรายวัน&limit=50`)
    .then((res) => {
      const data = res.detail
      if (data.length > 0) {
        const newPost = data.map((lotto) => {
          const newLotto = {}
          newLotto.title = lotto.lottoName
          newLotto.createAt = lotto.drawDateStr
          newLotto.result = {}
          newLotto.result.lotto3a = lotto.results['3A']
          newLotto.result.lotto2a = lotto.results['2A']
          newLotto.result.lotto2b = lotto.results['2B']
          // }
          newLotto.createdAt = lotto.drawDateStr
          return newLotto
        })
        // console.log(newPost, 'newPost')
        setPosts([...posts, ...newPost])
        const dailyReward = data.map((obj) => obj.lottoName)
        setDailyRewarded(dailyReward)
      }
    })
    .catch((err) => console.error(err))

    API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยวีไอพี&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const vipReward = data.map((obj) => obj.lottoName)
          setVipRewarded(vipReward)
        }
      })
      .catch((err) => console.error(err))

      API.get('lotto', `/results?drawDateStr=${date}&lottoGroup=หวยต่างประเทศ&limit=50`)
      .then((res) => {
        const data = res.detail
        if (data.length > 0) {
          const newPost = data.map((lotto) => {
            const newLotto = {}
            newLotto.title = lotto.lottoName
            newLotto.createAt = lotto.drawDateStr
            newLotto.result = {}
            newLotto.result.lotto3a = lotto.results['3A']
            newLotto.result.lotto2a = lotto.results['2A']
            newLotto.result.lotto2b = lotto.results['2B']
            // }
            newLotto.createdAt = lotto.drawDateStr
            return newLotto
          })
          // console.log(newPost, 'newPost')
          setPosts([...posts, ...newPost])
          const countryReward = data.map((obj) => obj.lottoName)
          setCountryRewarded(countryReward)
        }
      })
      .catch((err) => console.error(err))

    // API.get('lotto', `/results?drawDateStr=${date}&limit=2000`)
    //   .then((res) => {
    //     const data = res.detail
    //     const newPost = data.map((lotto) => {
    //       // console.log(lotto.lottoName, 'asdfasdfasdf')
    //       const newLotto = {}
    //       newLotto.title = lotto.lottoName
    //       newLotto.createAt = lotto.drawDateStr
    //       newLotto.result = {}
    //
    //       // if (lotto.lottoName !== 'หวยรัฐบาลไทย' && lotto.lottoName !== 'หวยออมสิน' && lotto.lottoName !== 'หวยธกส.') {
    //       if (lotto.lottoName === 'หวยรัฐบาลไทย') {
    //         newLotto.result.lotto6 = lotto.results['6S']
    //         newLotto.result.lotto3f = `${lotto.results['3F1']} ${lotto.results['3F2']}`
    //         newLotto.result.lotto3b = `${lotto.results['3B1']} ${lotto.results['3B2']}`
    //       }
    //       if (lotto.lottoName === 'หวยธกส.') {
    //         newLotto.result.lotto6 = lotto.results['6S']
    //       }
    //       if (lotto.lottoName === 'หวยออมสิน') {
    //         newLotto.result.lotto6 = `${lotto.results['6S1']} ${lotto.results['6S2']}`
    //       }
    //       // console.log(lotto.results, lotto.results['3A'])
    //       newLotto.result.lotto3a = lotto.results['3A']
    //       newLotto.result.lotto2a = lotto.results['2A']
    //       newLotto.result.lotto2b = lotto.results['2B']
    //       // }
    //       newLotto.createdAt = lotto.drawDateStr
    //       return newLotto
    //     })
    //
    //     // console.log(newPost, 'newPost')
    //     setPosts(newPost)
    //     const yeekeeReward = data.filter((obj) => obj.lottoGroup === 'หวยยี่กี')
    //     // console.log(yeekeeReward, 'yeekeeReward')
    //     setYeekeePosts(yeekeeReward.reverse())
    //     const stockReward = data.filter((obj) => obj.lottoGroup === 'หวยหุ้น').map((obj) => obj.lottoName)
    //     setStockRewarded(stockReward)
    //     // const dailyReward = data.filter((obj) => obj.lottoGroup === 'หวยรายวัน').map((obj) => obj.lottoName)
    //     // setDailyRewarded(dailyReward)
    //     const vipReward = data.filter((obj) => obj.lottoGroup === 'หวยวีไอพี').map((obj) => obj.lottoName)
    //     setVipRewarded(vipReward)
    //     // const countryReward = data.filter((obj) => obj.lottoGroup === 'หวยต่างประเทศ').map((obj) => obj.lottoName)
    //     // setCountryRewarded(countryReward)
    //   })
    //   .catch((err) => console.error(err))
  }, [])

  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    userConfirmPassword: '',
  })

  const handleLoginFacebook = async () => {
    await Auth.federatedSignIn({ provider: 'Facebook' })
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLoginLine = async () => {
    await Auth.federatedSignIn(cognitoProvider)
      .then(() => {
        return Auth.currentAuthenticatedUser()
      })
      .then((user) => {
        dispatch(setAuth({ user }))
      })
  }

  const handleLogin = async (e, type) => {
    e.preventDefault()
    // console.log(window.location.hash)
    if (type === 'facebook') {
      await Auth.federatedSignIn({ provider: 'Facebook' }).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
        }
      })
    } else if (type === 'line') {
      await Auth.federatedSignIn(cognitoProvider).then((user) => {
        if (user) {
          dispatch(setAuth({ user }))
        }
      })
    } else {
      // if (newUser.username.length !== 10 || newUser.password.length < 8) {
      //   return
      // }

      try {
        const newUsername = newUser.username
        // const newUsername = composePhoneNumber('+66', newUser.username)
        await Auth.signIn(newUsername, newUser.password).then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
            console.log(requiredAttributes)
            const newPassword = newUser.password
            Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword // the new password
            )
              .then((res) => {
                // at this time the user is logged in if no MFA required
                console.log(res)
              })
              .catch((e) => {
                console.log(e)
              })
          }
          console.log(user, 'user signIn')
          dispatch(setAuth({ user }))
        })
        // console.log(testUser, 'testUserrrrr Home Container')
        history.replace('/callback')
      } catch (e) {
        console.log(e, 'e message')
        if (e.message === 'Incorrect username or password.') {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            // text: 'ท่านกรอกเบอร์โทรศัพท์ที่ลงทะเบียน หรือรหัสผ่านไม่ถูกต้องครับ',
            title: 'Error',
            text: 'Your telephone number or password are incorrect',
          })
        } else if (e.message === 'User is not confirmed.') {
          setShowModal({ isShow: true, currentModal: 'verify' })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            title: 'Error',
            text: e.message || '',
          })
        }
      }
    }
  }

  return (
    <>
      {_.isNull(auth.username) ? (
        // <div className='home__banking-action mt-20'>
        //   <div className='rounded-full'>
        //     <button
        //       className='btn-bevel text-xl font-bold text-white rounded-full __register'
        //       onClick={() => {
        //         setShowModal({ isShow: true, currentModal: 'register' })
        //         setConfirmPassword(false)
        //       }}
        //     >
        //       <img src={register} alt='register' />
        //       <span>&nbsp;สมัครสมาชิก</span>
        //     </button>
        //     <button
        //       onClick={() => setShowModal({ isShow: true, currentModal: 'login' })}
        //       className='btn-bevel text-xl font-bold text-white rounded-full __login'
        //     >
        //       <img src={login} alt='register' />
        //       <span>&nbsp;เข้าสู่ระบบ</span>
        //     </button>
        //   </div>
        // </div>
        <div className='login btn-bevel text-xl font-bold mt-20 mb-3 sticky'>
          {/* <div className='facebook'>
            <button
              onClick={handleLoginFacebook}
              className='btn-bevel text-xl py-1 px-5 font-bold'
              style={{ height: '150%' }}
            >
              <FacebookIcon />
            </button>
          </div> */}
          {/* <div className='line'>
            <button
              onClick={handleLoginLine}
              className='btn-bevel text-xl py-1 px-5 font-bold'
              style={{ height: '150%' }}
            >
              <LineIcon />
              <span>เข้าสู่ระบบด้วยไลน์</span>
            </button>
          </div> */}
        </div>
      ) : (
        <div className='mt-20'></div>
      )}
      {language !== 'TH' ? <SlideshowMobile /> : <SlideshowMobileThai />}
      {language !== 'TH' ? <SlideshowPc /> : <SlideshowPcThai />}

      <div className='home__lucky-icon'>
        <div className='home__lucky-icon-wrapper disabled'>
          <a
            href='#'
            rel='noopener noreferrer'
            onClick={() => {
              history.push(`/angalong`)
            }}
          >
            <img className='home__lucky-icon-wrapper-image' src={menu1} alt='menu1' />
            <div className='home__lucky-icon-wrapper-label'>
              {/* <span>เลขเด็ด</span> */}
              <span>เลขเด็ด</span>
            </div>
          </a>
        </div>
        <div className='home__lucky-icon-wrapper'>
          <a
            href='#'
            rel='noopener noreferrer'
            onClick={() => {
              history.push(`/lottos`)
            }}
          >
            <img className='home__lucky-icon-wrapper-image' src={menu4} alt='menu2' />
            <div className='home__lucky-icon-wrapper-label'>
              {/* <span>ศรัทธาพารวย</span> */}
              {/* <span>{language === 'MM' ? 'ယုံကြည်ပြီး' : HOME_PAGE['ယုံကြည်ပြီး']}</span> */}
              {/* <span>{language === 'MM' ? 'မာစတာဘောလုံး' : 'คลับเซียนบอล'}</span> */}
              <span>แนวทาง</span>
            </div>
          </a>
        </div>
        <div className='home__lucky-icon-wrapper'>
          <a href='#' target='_blank' rel='noopener noreferrer'>
            <img className='home__lucky-icon-wrapper-image' src={menu3} alt='menu3' />
            <div className='home__lucky-icon-wrapper-label'>
              {/* <span>ผลหวยสด</span> */}
              {/* <span>{language === 'MM' ? 'တိုက်ရိုက်ထီရလဒ်' : HOME_PAGE['တိုက်ရိုက်ထီရလဒ်']}</span> */}
              <span>ผลหวยสด</span>
            </div>
          </a>
        </div>
        <div className='home__lucky-icon-wrapper'>
          <a href='#' target='_blank' rel='noopener noreferrer'>
            <img className='home__lucky-icon-wrapper-image' src={menu2} alt='menu4' />
            <div className='home__lucky-icon-wrapper-label'>
              <span>เพจ</span>
            </div>
          </a>
        </div>
      </div>
      {/*<div className='munkmee'>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value === 10 ? 0 : value}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 1 === -1 ? 9 : value - 1}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 2 === -2 ? 8 : value - 2 === -1 ? 9 : value - 2}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>{value - 3 === -3 ? 7 : value - 3 === -2 ? 8 : value - 3 === -1 ? 9 : value - 3}</div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className='counter'>*/}
      {/*    <div className='numbers'>*/}
      {/*      <div>*/}
      {/*        {value - 4 === -4 ? 6 : value - 4 === -3 ? 7 : value - 4 === -2 ? 8 : value - 4 === -1 ? 9 : value - 4}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {currentYeekee + 1 >= 288 ? null : (
        <div></div>
        // <div className='munkmee'>
        //   <div className='counter'>
        //     <div className={`numbers ${goldNumber.one ? 'gold' : 'white'}`}>
        //       {/*<div className={`${goldNumber.one ? 'gold' : trigger && !goldNumber.one ? 'red' : ''}`}>*/}
        //       <div
        //         className={!goldNumber.one && trigger ? fadeProp.fade : ''}
        //         style={
        //           !goldNumber.one && trigger
        //             ? { animation: `${fadeProp.fade === 'fade-in' ? 'move-in' : 'move-out'} ${timing.one}ms forwards` }
        //             : goldNumber.one
        //             ? {
        //                 animation: `reward-in ${timing.one * 1.1}ms forwards`,
        //               }
        //             : null
        //         }
        //       >
        //         {/*<div className={`${trigger && !goldNumber.one ? 'red' : ''}`}>*/}
        //         {goldNumber.one ? rewardDigit.one : trigger ? counter2.one % 10 : counter.one % 10}
        //       </div>
        //     </div>
        //   </div>
        //   <div className='counter'>
        //     <div className={`numbers ${goldNumber.two ? 'gold' : 'white'}`}>
        //       <div
        //         className={!goldNumber.two && trigger ? fadePropTwo.fade : ''}
        //         style={
        //           !goldNumber.two && trigger
        //             ? {
        //                 animation: `${fadePropTwo.fade === 'fade-in-2' ? 'move-in' : 'move-out'} ${
        //                   timing.two
        //                 }ms forwards`,
        //               }
        //             : goldNumber.two
        //             ? {
        //                 animation: `reward-in ${timing.two * 1.2}ms forwards`,
        //               }
        //             : null
        //         }
        //       >
        //         {/*<div className={`${trigger && !goldNumber.two ? 'red' : ''}`}>*/}
        //         {goldNumber.two ? rewardDigit.two : trigger ? counter2.two % 10 : counter.two % 10}
        //       </div>
        //     </div>
        //   </div>
        //   <div className='counter'>
        //     <div className={`numbers ${goldNumber.three ? 'gold' : 'white'}`}>
        //       <div
        //         className={!goldNumber.three && trigger ? fadePropThree.fade : ''}
        //         style={
        //           !goldNumber.three && trigger
        //             ? {
        //                 animation: `${fadePropThree.fade === 'fade-in-3' ? 'move-in' : 'move-out'} ${
        //                   timing.three
        //                 }ms forwards`,
        //               }
        //             : goldNumber.three
        //             ? {
        //                 animation: `reward-in ${timing.three * 1.3}ms forwards`,
        //               }
        //             : null
        //         }
        //       >
        //         {/*<div className={`${trigger && !goldNumber.three ? 'red' : ''}`}>*/}
        //         {goldNumber.three ? rewardDigit.three : trigger ? counter2.three % 10 : counter.three % 10}
        //       </div>
        //     </div>
        //   </div>
        //   <div className='counter'>
        //     <div className={`numbers ${goldNumber.four ? 'blue' : 'white'}`}>
        //       <div
        //         className={!goldNumber.four && trigger ? fadePropFour.fade : ''}
        //         style={
        //           !goldNumber.four && trigger
        //             ? {
        //                 animation: `${fadePropFour.fade === 'fade-in-4' ? 'move-in' : 'move-out'} ${
        //                   timing.four
        //                 }ms forwards`,
        //               }
        //             : goldNumber.four
        //             ? {
        //                 animation: `reward-in ${timing.four * 1.4}ms forwards`,
        //               }
        //             : null
        //         }
        //       >
        //         {/*<div className={`${trigger && !goldNumber.four ? 'red' : ''}`}>*/}
        //         {goldNumber.four ? rewardDigit.four : trigger ? counter2.four % 10 : counter.four % 10}
        //       </div>
        //     </div>
        //   </div>
        //   <div className='counter'>
        //     <div className={`numbers ${goldNumber.five ? 'blue' : 'white'}`}>
        //       <div
        //         className={!goldNumber.five && trigger ? fadePropFive.fade : ''}
        //         style={
        //           !goldNumber.five && trigger
        //             ? {
        //                 animation: `${fadePropFive.fade === 'fade-in-5' ? 'move-in' : 'move-out'} ${
        //                   timing.five
        //                 }ms forwards`,
        //               }
        //             : goldNumber.five
        //             ? {
        //                 animation: `reward-in ${timing.five * 1.5}ms forwards`,
        //               }
        //             : null
        //         }
        //       >
        //         {/*<div className={`${trigger && !goldNumber.five ? 'red' : ''}`}>*/}
        //         {goldNumber.five ? rewardDigit.five : trigger ? counter2.five % 10 : counter.five % 10}
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
      {currentYeekee + 1 >= 288 ? null : (
        <div></div>
        // <div className='home__lotto-suggestion mt-3'>
        //   <div className={`home__lotto`}>
        //     <div className='home__lotto-title'>
        //       {/*<img className='home__lotto-title-shine ' src={shine} alt='shine' />*/}
        //       <img
        //         className='home__lotto-title-flag '
        //         src={require('../../assets/lotto-flags/munkmee.png')}
        //         alt='flags'
        //       />

        //       <div>มั่งมียี่กี {currentYeekee + 1}</div>
        //       {/*<div>{dateNow(createdAt)}</div>*/}
        //       <span
        //         className={`home__lotto-title-timer ${countdown <= 0 || counter.six > countdown ? 'hidden' : ''}`}
        //         style={countdown - counter.six <= 30 ? { color: 'red' } : null}
        //       >
        //         0{Math.floor((countdown - counter.six) / 60) <= 0 ? 0 : Math.floor((countdown - counter.six) / 60)}:
        //         {countdown - counter.six - Math.floor((countdown - counter.six) / 60) * 60 < 10 ? 0 : null}
        //         {countdown - counter.six - Math.floor((countdown - counter.six) / 60) * 60 <= 0
        //           ? 0
        //           : countdown - counter.six - Math.floor((countdown - counter.six) / 60) * 60}
        //       </span>
        //       <img
        //         className='home__lotto-title-refresh'
        //         onClick={() => window.location.reload(false)}
        //         src={require('../../assets/refresh.png')}
        //         alt='refresh'
        //       />
        //     </div>
        //     <div className='home__lotto-box-wrapper'>
        //       <div className='two-column'>
        //         <div className='home__lotto-box '>
        //           <div className='text-xl'>3 ตัวบน</div>
        //           <div>
        //             {' '}
        //             {goldNumber.one ? rewardDigit.one : trigger ? counter2.one % 10 : counter.one % 10}{' '}
        //             {goldNumber.two ? rewardDigit.two : trigger ? counter2.two % 10 : counter.two % 10}{' '}
        //             {goldNumber.three ? rewardDigit.three : trigger ? counter2.three % 10 : counter.three % 10}
        //           </div>
        //         </div>
        //         <div className='home__lotto-box '>
        //           <div className='text-xl'>2 ตัวล่าง</div>
        //           <div>
        //             {goldNumber.four ? rewardDigit.four : trigger ? counter2.four % 10 : counter.four % 10}{' '}
        //             {goldNumber.five ? rewardDigit.five : trigger ? counter2.five % 10 : counter.five % 10}
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
      {/* SHARED DESKTOP MOBILE */}
      {/*{showTwoD.isShow ? (*/}
      {/*) : null}*/}

      <div className='home__lotto-suggestion mt-3' onClick={handleClick}>
        {[
          { ...thaiPost.find((item) => item.title === 'หวยรัฐบาลไทย'), src: thailand },
          // { ...posts.find((item) => item.title === 'หวยออมสิน'), src: gsb },
          // { ...posts.find((item) => item.title === 'หวยธกส.'), src: tks },
        ].map((item, index) => (
          <PostThaiWinning {...item} key={index} />
          // <Post {...item} key={index} />
        ))}
      </div>

      {/* MOBILE STOCK*/}
      {stockRewarded.length > 0 ? (
        <div className='md:hidden mt-3' onClick={handleClick}>
          <div>
            <div className='home__dong__lotto__result__header'>
              {language === 'MM' ? 'စတော့ရှယ်ယာထီရလဒ်' : HOME_PAGE['စတော့ရှယ်ယာထီရလဒ်']}
            </div>
            <div>{dongDateToday}</div>
            <table className='home_dong_table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lotto</th>
                  <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                  <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
                </tr>
              </thead>
            </table>
            {STOCK_LIST.filter(function (el) {
              return stockLottos.concat(stockRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              if (item.title) {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index} />
              }
            })}
          </div>

          {/* </BoxWhite> */}
        </div>
      ) : null}

      {/* REMOVE หวยรายวัน GIANTD*/}
      {/* MOBILE DAILY*/}
      {dailyRewarded.length > 0 ? (
        <div className='md:hidden mt-3' onClick={handleClick}>
          <div>
            <div className='home__dong__lotto__result__header'>
             ผลหวยรายวัน
            </div>
            <div>{dongDateToday}</div>
            <table className='home_dong_table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lotto</th>
                  <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                  <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
                </tr>
              </thead>
            </table>
            {STOCK_LIST.filter(function (el) {
              return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              if (item.title) {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index} />
              }
            })}
          </div>

          {/* </BoxWhite> */}
        </div>
      ) : null}

      {/* REMOVE หวย VIP GIANTD*/}
      {/* MOBILE VIP*/}
      {vipRewarded.length > 0 ? (
        <div className='md:hidden mt-3' onClick={handleClick}>
          <div>
            <div className='home__dong__lotto__result__header'>
            ผลหวยวีไอพี
            </div>
            <div>{dongDateToday}</div>
            <table className='home_dong_table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lotto</th>
                  <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                  <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
                </tr>
              </thead>
            </table>
            {STOCK_LIST.filter(function (el) {
              return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              if (item.title) {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index} />
              }
            })}
          </div>

          {/* </BoxWhite> */}
        </div>
      ) : null}

      {/* MOBILE FOREIGN COUNTRY*/}
      {countryRewarded.length > 0 ? (
        <div className='md:hidden mt-3' onClick={handleClick}>
          <div>
            <div className='home__dong__lotto__result__header'>
            ผลหวยต่างประเทศ
            </div>
            <div>{dongDateToday}</div>
            <table className='home_dong_table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lotto</th>
                  <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                  <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
                </tr>
              </thead>
            </table>
            {STOCK_LIST.filter(function (el) {
              return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              if (item.title) {
                const lookupItem = posts.find((post) => post.title === item.title) || item
                lookupItem['src'] = item.src
                return <PostTable {...lookupItem} key={index} />
              }
            })}
          </div>

          {/* </BoxWhite> */}
        </div>
      ) : null}

      {/* MOBILE YEEKEE*/}
      <div className='md:hidden mt-3' onClick={handleClick}>
        <div>
          <div className='home__dong__lotto__result__header'>
            {language === 'MM' ? 'ရီကီထီ ရလဒ်' : HOME_PAGE['ရီကီထီ ရလဒ်']}
          </div>
          <div>{dongDateToday}</div>
          <table className='home_dong_table'>
            <thead>
              <tr>
                <th>Round</th>
                <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                <th>{language === 'MM' ? '၂လုံးပေါ်' : HOME_PAGE['၂လုံးပေါ်']} (2 ตัวบน)</th>
                <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
              </tr>
            </thead>
            <tbody>
              {yeekeePosts.slice(0, 10).map((data) => (
                <tr>
                  <td>{data.lottoName.split(' ')[1]}</td>
                  <td>{data.results['3A']}</td>
                  <td>{data.results['2A']}</td>
                  <td>{data.results['2B']}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/*{YEEKEE_ROUND.map((num) => {*/}
          {/*  const buttonClass =*/}
          {/*    num === mobileYeekeeNumber ? 'normalButton' : num <= currentYeekee ? 'rewardedButton' : 'whiteGrey'*/}
          {/*  return (*/}
          {/*    <button*/}
          {/*      key={num}*/}
          {/*      onClick={() => {*/}
          {/*        setMobileYeekeeNumber(num)*/}
          {/*      }}*/}
          {/*      className={`home__lotto__mobile-yeekee-item-button rounded-lg ${buttonClass}`}*/}
          {/*      style={{*/}
          {/*        fontSize: '14px',*/}
          {/*        display: 'flex',*/}
          {/*        alignItems: 'center',*/}
          {/*        justifyContent: 'center',*/}
          {/*        width: '10%',*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      {num}*/}
          {/*    </button>*/}
          {/*  )*/}
          {/*})}*/}
          {/* {yeekeePosts.map((lotto, index) => {
              return (
                <div
                  className={`home__detailsBox-purchaseDetails`}
                  key={index}
                  style={{
                    borderBottom: '2px solid #dddddd',
                  }}
                >
                  <div className='home__detailsBox-purchaseDetails-detail'>
                    <span className='home__detailsBox-purchaseDetails-amount-title' style={{ fontSize: '15px' }}>
                      {lotto.lottoName.split(' ')[1]}
                    </span>
                  </div>
                  <div className='home__detailsBox-purchaseDetails-amount'>
                    <span className='home__detailsBox-purchaseDetails-amount-title'>{lotto.results['3A']}</span>
                  </div>
                  <div className='home__detailsBox-purchaseDetails-amount'>
                    <span className='home__detailsBox-purchaseDetails-amount-title'>{lotto.results['2A']}</span>
                  </div>
                  <div className='home__detailsBox-purchaseDetails-amount'>
                    <span className='home__detailsBox-purchaseDetails-amount-title'>{lotto.results['2B']}</span>
                  </div>
                </div>
              )
            })} */}
        </div>
      </div>

      {/* DESKTOP STOCK*/}
      {stockRewarded.length > 0 ? (
        <div className='hidden md:flex home__lotto-suggestion' onClick={handleClick}>
          <div>
            <div className='home__dong__lotto__result__header'>
              {language === 'MM' ? 'စတော့ရှယ်ယာထီရလဒ်' : HOME_PAGE['စတော့ရှယ်ယာထီရလဒ်']}
            </div>
            <div>{dongDateToday}</div>
            <table className='home_dong_table'>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Lotto</th>
                  <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                  <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
                </tr>
              </thead>
            </table>

            {STOCK_LIST.filter(function (el) {
              return stockLottos.concat(stockRewarded).indexOf(el.title) >= 0
            }).map((item, index) => {
              const lookupItem = posts.find((post) => post.title === item.title) || item
              lookupItem['src'] = item.src
              return <PostTable {...lookupItem} key={index} />
            })}
          </div>
        </div>
      ) : null}

      {/* REMOVE หวยรายวัน GIANTD */}
      {/* DESKTOP DAILY*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        <div>
          <div className='home__dong__lotto__result__header'>หวยรายวัน</div>
          <div>{dongDateToday}</div>
          <table className='home_dong_table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Lotto</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
            </thead>
          </table>
          {STOCK_LIST.filter(function (el) {
            return dailyLottos.concat(dailyRewarded).indexOf(el.title) >= 0
          }).map((item, index) => {
            const lookupItem = posts.find((post) => post.title === item.title) || item
            lookupItem['src'] = item.src
            return <PostTable {...lookupItem} key={index} />
          })}
        </div>
      </div>

      {/* REMOVE หวย VIP GIANTD */}
      {/* DESKTOP VIP*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        <div>
          <div className='home__dong__lotto__result__header'>หวยวีไอพี</div>
          <div>{dongDateToday}</div>
          <table className='home_dong_table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Lotto</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
            </thead>
          </table>
          {STOCK_LIST.filter(function (el) {
            return vipLottos.concat(vipRewarded).indexOf(el.title) >= 0
          }).map((item, index) => {
            const lookupItem = posts.find((post) => post.title === item.title) || item
            lookupItem['src'] = item.src
            return <PostTable {...lookupItem} key={index} />
          })}
        </div>
      </div>

      {/* DESKTOP COUNTRY*/}
      <div className='hidden md:flex home__lotto-suggestion'>
        <div>
          <div className='home__dong__lotto__result__header'>หวยต่างประเทศ</div>
          <div>{dongDateToday}</div>
          <table className='home_dong_table'>
            <thead>
              <tr>
                <th>#</th>
                <th>Lotto</th>
                <th>3 ตัวบน</th>
                <th>2 ตัวล่าง</th>
              </tr>
            </thead>
          </table>
          {STOCK_LIST.filter(function (el) {
            return countryLottos.concat(countryRewarded).indexOf(el.title) >= 0
          }).map((item, index) => {
            const lookupItem = posts.find((post) => post.title === item.title) || item
            lookupItem['src'] = item.src
            return <PostTable {...lookupItem} key={index} />
          })}
        </div>
      </div>

      {/* DESKTOP YEEKEE*/}
      <div className='hidden md:flex home__lotto-suggestion' onClick={handleClick}>
        <div>
          <div className='home__dong__lotto__result__header'>
            {language === 'MM' ? 'ရီကီထီ ရလဒ်' : HOME_PAGE['ရီကီထီ ရလဒ်']}
          </div>
          <div>{dongDateToday}</div>
          <table className='home_dong_table'>
            <thead>
              <tr>
                <th>Round</th>
                <th>{language === 'MM' ? '၃ လုံးပေါ်' : HOME_PAGE['၃ လုံးပေါ်']} (3 ตัวบน)</th>
                <th>{language === 'MM' ? '၂လုံးပေါ်' : HOME_PAGE['၂လုံးပေါ်']} (2 ตัวบน)</th>
                <th>{language === 'MM' ? '၂လုံးအောက်' : HOME_PAGE['၂လုံးအောက်']} (2 ตัวล่าง)</th>
              </tr>
            </thead>
            <tbody>
              {yeekeePosts.slice(0, 10).map((data) => (
                <tr>
                  <td>{data.lottoName.split(' ')[1]}</td>
                  <td>{data.results['3A']}</td>
                  <td>{data.results['2A']}</td>
                  <td>{data.results['2B']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {!auth.username || showModal.currentModal === 'verify' ? (
        <>
          <Login
            showModal={showModal}
            setShowModal={setShowModal}
            handleLogin={handleLogin}
            setNewUser={setNewUser}
            newUser={newUser}
          />
          <SignUp
            showModal={showModal}
            setShowModal={setShowModal}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            handleLogin={handleLogin}
            setNewUser={setNewUser}
            newUser={newUser}
          />
        </>
      ) : null}
    </>
  )
}
