import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify'
import get from 'lodash/get'
// import sumBy from 'lodash/sumBy'
import _sortBy from 'lodash/sortBy'
import _reverse from 'lodash/reverse'
import moment from 'moment'
import numeral from 'numeral'
import { useSelector } from 'react-redux'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
import { getLang } from '../../redux/selectors/lang'
import { PAYMENT_HISTORY_PAGE } from '../../utils/lottoList'

import './TransactionList.scss'
import Box from '../../components/Box/Box'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import SortArrows from '../../components/SortArrows'
import BoxWhite from '../../components/Box/BoxWhite'

const TransactionList = () => {
  const [allTransactions, setAllTransactions] = useState([])
  // const [totalDeposit, setTotalDeposit] = useState(0)
  // const [totalWithdraw, setTotalWithdraw] = useState(0)
  const [startDate, setStartDate] = useState(moment().add(-7, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [startDateFocus, setStartDateFocus] = useState(false)
  const [endDateFocus, setEndDateFocus] = useState(false)
  const [transactionType, setTransactionType] = useState('')
  const [sortAsc, setSortAsc] = useState(false)
  const [tableColumn] = useState([
    // { name: 'transactionType', label: 'รายการ' },
    // { name: 'amount', label: 'จำนวนเงิน' },
    // { name: 'createDate', label: 'วันเวลา' },
    // { name: 'status', label: 'สถานะ' },

    { name: 'transactionType', label: 'Description' },
    { name: 'amount', label: 'Amount' },
    { name: 'createDate', label: 'Date' },
    { name: 'status', label: 'Status' },
  ])
  const [isLoading, setIsLoading] = useState(false)
  const lang = useSelector(getLang)
  const language = lang.lang


  const fetchTransactionListsData = () => {
    const path = `/transactions/?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate
      .clone()
      .add(1, 'days')
      .format('YYYY-MM-DD')}${transactionType ? `&transactionType=${transactionType}` : ''}&limit=1000`

    setIsLoading(true)
    API.get('payment', path)
      .then((res) => {
        const allTxn = get(res, 'detail', [])
        setAllTransactions(allTxn)

        // const sumDeposit = sumBy(
        //   allTxn,
        //   (txn) => txn.transactionType === 'deposit' && txn.status === 'approved' && txn.amount
        // )
        // const sumWithdraw = sumBy(
        //   allTxn,
        //   (txn) => txn.transactionType === 'withdraw' && txn.status === 'approved' && txn.amount
        // )
        // setTotalDeposit(sumDeposit)
        // setTotalWithdraw(sumWithdraw)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchTransactionListsData()
  }, [startDate, endDate, transactionType])

  const sortColumn = (columnName) => {
    let sorted = []

    if (columnName === 'createDate') {
      sorted = allTransactions.sort((a, b) => {
        return moment(a.createDate).diff(moment(b.createDate))
      })
    } else {
      sorted = _sortBy(allTransactions, columnName)
    }

    if (!sortAsc) {
      sorted = _reverse(sorted)
    }
    setSortAsc(!sortAsc)
    setAllTransactions(sorted)
  }

  const _onClickFirstButton = (e) => {
    e.preventDefault()
    setTransactionType('')
  }
  const _onClickSecondButton = (e) => {
    e.preventDefault()
    setTransactionType('deposit')
  }
  const _onClickThirdButton = (e) => {
    e.preventDefault()
    setTransactionType('withdraw')
  }

  return (
    <section className='transactionList mt-20'>
      {/* <Box containerClass='transactionList__pageTitle'>สถานะฝากเงิน</Box> */}
      <Box containerClass='transactionList__pageTitle'>{language === 'MM' ? 'အပ်ငွေအခြေအနေ' : PAYMENT_HISTORY_PAGE['အပ်ငွေအခြေအနေ'] }</Box>

      <div className='transactionList__date' style={{ marginTop: '30px' }}>
        <div className='transactionList__date-wrapper'>
          <p>{language === 'MM' ? 'စတင်သည့်ရက်စွဲ' : PAYMENT_HISTORY_PAGE['စတင်သည့်ရက်စွဲ']}</p>
          <SingleDatePicker
            date={startDate}
            onDateChange={(date) => {
              setStartDate(date)
            }}
            focused={startDateFocus}
            onFocusChange={({ focused }) => setStartDateFocus(focused)}
            id='startDate'
            numberOfMonths={1}
            noBorder={true}
            readOnly
            displayFormat={'DD/MM/YYYY'}
            isOutsideRange={() => false}
          />
        </div>
        <div className='transactionList__date-wrapper'>
          <p>{language === 'MM' ? 'ကုန်ဆုံးရက်' : PAYMENT_HISTORY_PAGE['ကုန်ဆုံးရက်']}</p>
          <SingleDatePicker
            date={endDate}
            onDateChange={(date) => {
              setEndDate(date)
            }}
            focused={endDateFocus}
            onFocusChange={({ focused }) => setEndDateFocus(focused)}
            id='endDate'
            numberOfMonths={1}
            noBorder={true}
            readOnly
            displayFormat={'DD/MM/YYYY'}
            isOutsideRange={() => false}
          />
        </div>
      </div>
      <ButtonGroup
        withBorder
        containerClass='transactionList__buttonGroup'
        // firstText='ทั้งหมด'
        // secondText='ฝาก'
        // thirdText='ถอน'
        firstText={`All (${language === 'MM' ? 'အားလုံး': PAYMENT_HISTORY_PAGE['အားလုံး'] })`}
        secondText={`Deposit (${language === 'MM' ? 'သွင်း': PAYMENT_HISTORY_PAGE['သွင်း'] })`}
        thirdText={`Withdraw (${language === 'MM' ? 'ထုတ်': PAYMENT_HISTORY_PAGE['ထုတ်'] })`}
        onClickFirstButton={_onClickFirstButton}
        onClickSecondButton={_onClickSecondButton}
        onClickThirdButton={_onClickThirdButton}
        activeType={transactionType}
      />
      <div className='transactionList__boxContainer'>
        <table className='transactionList__table'>
          <thead>
            <tr>
              {tableColumn.map((column, i) => (
                <th onClick={() => sortColumn(column.name)} key={`${column.name}-${i}`}>
                  {column.label}
                  <SortArrows />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isLoading && allTransactions.length > 0 ? (
              allTransactions.map(({ transactionType, amount, createDate, status }, index) => {
                const formattedDate = moment(createDate).subtract(7, 'hours').format('DD/MM/YYYY')
                const formattedTime = moment(createDate).subtract(7, 'hours').format('HH:mm')
                return (
                  <tr key={`transaction-${index}`}>
                    <td>{transactionType === 'deposit' ? 'deposit' : 'withdraw'}</td>
                    <td>{numeral(amount).format('0,0.00')}</td>
                    <td>
                      {formattedDate} {formattedTime}
                    </td>
                    <td className='transactionList__table-status'>
                      {status === 'approved'
                        ? 'approved'
                        : status === 'pending'
                        ? 'pending'
                        : status === 'rejected'
                        ? 'rejected'
                        : 'error'}
                    </td>
                  </tr>
                )
              })
            ) : isLoading ? (
              <div className='loading-gif'>
                <img
                  className='loading-indicator-gif '
                  src={require('../../assets//dong-loading/dong-loading.png')}
                  alt='loading'
                />
              </div>
            ) : (
              <tr>
                <td colSpan='7' className='transactionList__title' style={{ color: 'gray' }}>
                  no transaction
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/*<div className='transactionList__boxContainer-money'>*/}
        {/*  <span>สรุปสถานะการเงิน</span>*/}
        {/*  <div className='transactionList__boxContainer-money-detail'>*/}
        {/*    {transactionType === 'deposit' || transactionType === '' ? (*/}
        {/*      <span className='transactionList__boxContainer-money-detail_deposit'>*/}
        {/*        ฝาก: {numeral(totalDeposit).format('0,0.00')} บาท*/}
        {/*      </span>*/}
        {/*    ) : null}*/}

        {/*    {transactionType === 'withdraw' || transactionType === '' ? (*/}
        {/*      <span className='transactionList__boxContainer-money-detail_withdraw'>*/}
        {/*        ถอน: {numeral(totalWithdraw).format('0,0.00')} บาท*/}
        {/*      </span>*/}
        {/*    ) : null}*/}

        {/*    {transactionType === '' ? (*/}
        {/*      <span>*/}
        {/*        รวม:{' '}*/}
        {/*        <span*/}
        {/*          className={`transactionList__boxContainer-money-detail_total${*/}
        {/*            totalDeposit - totalWithdraw < 0 ? ' negativeValue' : ''*/}
        {/*          }`}*/}
        {/*        >*/}
        {/*          {numeral(totalDeposit - totalWithdraw).format('0,0.00')}{' '}*/}
        {/*        </span>*/}
        {/*        บาท*/}
        {/*      </span>*/}
        {/*    ) : null}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </section>
  )
}

export default TransactionList
