import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import queryString from 'query-string'
import moment from 'moment'
import _ from 'lodash'
import { SingleDatePicker } from 'react-dates'

import { getAuth } from '../../redux/selectors/auth'
import Button from '../../components/Button/Button'
import Box from '../../components/Box/Box'
import { getReceiptStatusText, getReceiptStatusBackgroundColor } from '../../utils/receiptUtil'
import { colorCode } from '../../utils/color'
import { STOCK_LIST } from '../../utils/stockList'
import dong from '../../assets/flags/dong.png'
// import shine from '../../assets/shine.png'
import { getLang } from '../../redux/selectors/lang'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './AllReceipt.scss'
import { POST_PAGE, RECEIPT_PAGE } from '../../utils/lottoList'

const checkFinishReceipt = (receipt) => {
  if (
    receipt.status === 'created' ||
    receipt.status === 'cancelled' ||
    receipt.status === 'error' ||
    receipt.status === 'rejected' ||
    receipt.status === 'timeout'
  ) {
    return
  }
  return receipt.status === 'rewarded'
}

const checkPrizedReceipt = (receipt) => {
  if (
    receipt.status === 'created' ||
    receipt.status === 'cancelled' ||
    receipt.status === 'error' ||
    receipt.status === 'rejected' ||
    receipt.status === 'timeout'
  ) {
    return
  }
  return receipt.status === 'rewarded' && receipt.prizeAmount > 0
}

function AllReceipt({ queryObject = {}, match, location, history }) {
  const auth = useSelector(getAuth)
  // const { receipts } = useSelector(getReceipts)
  const [allReceipts, setAllReceipts] = useState([])
  const [filteredReceipts, setFilteredReceipts] = useState([])
  const [allPurchasesAmount, setAllPurchasesAmount] = useState(0)
  // const [startDate, setStartDate] = useState(moment().subtract(7, 'days'))
  const [date, setDate] = useState(moment())
  // const [startDateFocus, setStartDateFocus] = useState(false)
  const [dateFocus, setDateFocus] = useState(false)
  const [isDate, setIsDate] = useState(false)
  const [receiptType, setReceiptType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isBottom, setIsBottom] = useState(false)
  const [receiptsPage, setReceiptsPage] = useState(1)
  const [isLastPage, setIsLastPage] = useState(true)
  const [selectLotto, setSelectLotto] = useState('')
  const [lottoOptions, setLottoOptions] = useState([])
  const lang = useSelector(getLang)
  const language = lang.lang


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true)
    }
  }

  useEffect(() => {
    if (isBottom && !isLastPage && !isLoading) {
      setReceiptsPage(receiptsPage + 1)
    }
  }, [isBottom])

  useEffect(() => {
    addItems()
  }, [receiptsPage])

  const addItems = () => {
    if (allReceipts.length !== 0) {
      fetchReceiptData()
    }
  }

  const fetchLottoOptions = () => {
    console.log(date, 'date')
    const drawDate = moment(new Date(date)).format('DD/MM/YY')
    console.log(drawDate, 'drawDate')
    /////need fix API
    API.get('lotto', `/results?drawDateStr=${drawDate}&limit=600`)
      .then((res) => {
        // const data = res.detail
        const data = res.detail.filter((obj) => obj.lottoGroup !== 'หวยยี่กี' && obj.lottoGroup !== 'Angalong')
        const lottoList = data.map((obj) => {
          const list = {}
          list.lottoName = obj.lottoName
          list.lottoNumber = obj.lottoNumber
          return list
        })
        console.log(lottoList, 'lottoList')
        let allLottos = []
        if (language === 'MM') {
          allLottos = [
            // { lottoName: 'หวยทั้งหมด', lottoNumber: '' },
            { lottoName: 'ထီအားလုံး', lottoNumber: '' },
            { lottoName: 'ရီကီထီ ဒေါင်း', lottoNumber: '99999999' },
          ]
        } else {
          allLottos = [
            { lottoName: 'หวยทั้งหมด', lottoNumber: '' },
            { lottoName: 'ดองยี่กี', lottoNumber: '99999999' },
          ]
        }
        const option = allLottos.concat(lottoList)
        console.log(option, 'lottoOptions')
        setLottoOptions(option)
      })
      .catch((err) => console.error(err))
  }

  const fetchReceiptData = () => {
    let path = ''
    if (isDate) {
      const dateFormatted = moment(date).format('YYYY-MM-DD')
      const endDateFormatted = moment(date).add(1, 'days').format('YYYY-MM-DD')
      if (selectLotto === '99999999') {
        path = `/receipts?receiptType=lotto&${queryString.stringify({
          startDate: dateFormatted,
          endDate: endDateFormatted,
        })}&limit=20&page=${receiptsPage}`
      } else {
        path = `/receipts?receiptType=lotto&${queryString.stringify({
          startDate: dateFormatted,
          endDate: endDateFormatted,
        })}&limit=20&refId=${selectLotto}&page=${receiptsPage}`
      }
    } else {
      if (selectLotto === '99999999') {
        path = `/receipts?receiptType=lotto&limit=20&page=${receiptsPage}`
      } else {
        path = `/receipts?receiptType=lotto&limit=20&refId=${selectLotto}&page=${receiptsPage}`
      }
    }
    setIsLoading(true)
    if (selectLotto === '') {
      fetchLottoOptions()
    }
    API.get('purchase', path)
      .then((res) => {
        // let sortedReceipts = []
        let sortedReceipts = _.get(res, 'detail', []).filter(obj => obj.receiptDetail.lottoGroup !== 'Angalong')
        console.log(sortedReceipts, 'sortReceipt')
        if (selectLotto === '99999999') {
          sortedReceipts = sortedReceipts.filter((obj) => obj.receiptDetail.lottoName.includes('ดองยี่กี'))
        }
        const lastPage = _.get(res, 'currentPage') === _.get(res, 'totalPages')
        setIsLastPage(lastPage)
        if (receiptsPage === 1) {
          setAllReceipts(sortedReceipts)
        }
        if (receiptsPage > 1) {
          setAllReceipts(allReceipts.concat(sortedReceipts))
        }
        // const purchaseAmount = _.sumBy(sortedReceipts, function (receipt) {
        //   return receipt.purchaseAmount
        // })
        // setAllPurchasesAmount(purchaseAmount)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
        setIsBottom(false)
      })
  }

  // useEffect(() => {
  //   if (auth.username) {
  //     fetchReceiptData()
  //   }
  // }, [auth.username])

  useEffect(() => {
    if (auth.username) {
      setReceiptsPage(1)
      setReceiptType('')
      if (receiptsPage === 1 && receiptType === '') {
        fetchReceiptData()
      }
    }
  }, [date, auth.username, selectLotto])

  useEffect(() => {
    let filteredReceipts = []
    if (receiptType === '') {
      filteredReceipts = allReceipts
    } else if (receiptType === 'notFinished') {
      filteredReceipts = allReceipts.filter((receipt) => receipt.status === 'confirm')
    } else if (receiptType === 'isFinished') {
      filteredReceipts = allReceipts.filter((receipt) => checkFinishReceipt(receipt))
    } else if (receiptType === 'prized') {
      filteredReceipts = allReceipts.filter((receipt) => checkPrizedReceipt(receipt))
    } else if (receiptType === 'cancelled') {
      filteredReceipts = allReceipts.filter(
        (receipt) =>
          receipt.status === 'cancelled' ||
          receipt.status === 'error' ||
          receipt.status === 'rejected' ||
          receipt.status === 'timeout',
      )
    }
    setFilteredReceipts(filteredReceipts)
    // if (selectLotto !== '' && selectLotto !== 'หวยทั้งหมด') {
    //   console.log('select')
    //   const filterLottoName = filteredReceipts.filter((obj) => obj.receiptDetail.lottoName === selectLotto)
    //   setFilteredReceipts(filterLottoName)
    // } else {
    //   console.log('selectAll')
    //   setFilteredReceipts(filteredReceipts)
    // }
  }, [allReceipts, receiptType])

  const _onClickFilterButton = (e, status) => {
    e.preventDefault()
    if (receiptType === status) {
      setReceiptType('')
    } else {
      setReceiptType(status)
    }
  }

  const _onSelectLotto = (e) => {
    const value = e.target.value
    console.log(value, 'value')
    setSelectLotto(value)
  }

  return (
    <div className='allReceipts page-top p-4 flex flex-col items-center mx-auto'>
      {/* SECTION 1: HEADER */}
      {/* <Box containerClass='allReceipts__buttonBox mt-5'>โพยหวย</Box> */}
      <div className='allReceipts__buttonBox shadow-lgallReceipts__selectedLotto-firstLine-flag  mt-5'>
        {/* ตรวจรางวัล โพยหวย */}
        <strong>  {language === 'MM' ? 'ထီရလဒ်စစ်ဆေး' : RECEIPT_PAGE['ထီရလဒ်စစ်ဆေး']}</strong>
      </div>

      <div className='transactionList__date' style={{ marginTop: '10px' }}>
        {isDate ? (
          <div className='transactionList__date-wrapper'>
            <p className='mt-10' style={{ color: '#000000' }}>
              select date
            </p>

            <SingleDatePicker
              date={date}
              onDateChange={(date) => {
                setDate(moment(date))
                setSelectLotto('')
              }}
              focused={dateFocus}
              onFocusChange={({ focused }) => setDateFocus(focused)}
              id='date'
              numberOfMonths={1}
              noBorder={true}
              readOnly
              displayFormat={'DD/MM/YYYY'}
              isOutsideRange={() => false}
            />
          </div>
        ) : (
          <div className='transactionList__date-wrapper mt-8 text-2xl btn-bevel`'>
            <Button
              // text='กดเพื่อเลือกวัน'
              text='select date'
              wrapperClassName='loginModal__close-wrapper'
              buttonClassName='loginModal__close-button'
              style={{ color: 'black' }}
              onClick={() => {
                setIsDate(true)
              }}
            />
          </div>
        )}
      </div>

      {/* กล่องวันที่และ รายชื่อหวย */}
      <div className='transactionList__date'>
        {isDate ? (
          <div className='transactionList__select' style={{ margin: '5px' }}>
            <select value={selectLotto || 0} onChange={(e) => _onSelectLotto(e)}>
              {lottoOptions?.map((item) => (
                <option key={item.lottoNumber} value={item.lottoNumber}>
                  {language === 'MM' ? POST_PAGE[item.lottoName] : item.lottoName}
                </option>
              ))}
            </select>
          </div>
        ) : null}
      </div>

      {/* SECTION 2: FIRST BOX */}

      {/* <div className='btn_box_header'>Summary</div> */}
      <div
        className='btn_box_header'>{language === 'MM' ? 'လောင်းကစားအကျဉ်းချုပ်' : RECEIPT_PAGE['လောင်းကစားအကျဉ်းချုပ်']}</div>

      <div className='allReceipts__box'>
        <section className='allReceipts__todayResultBox'>
          <div className='allReceipts__todayResultBox-paid w-1/2 h-20 text-center ml-1'>
            {/* <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยอดรวม</div>  */}
            <div
              className='allReceipts__todayResultBox-title h-6 mx-auto'>{language === 'MM' ? 'စုစုပေါင်း' : RECEIPT_PAGE['စုစုပေါင်း']}</div>

            <div className='text-2xl sm:text-4xl' style={{ color: '#000000' }}>
              {Math.round((allPurchasesAmount + Number.EPSILON) * 100) / 100}
            </div>
          </div>
          <div className='allReceipts__todayResultBox-paid2 w-1/2 h-20 text-center'>
            {/* <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ออกผลแล้ว</div> */}
            <div
              className='allReceipts__todayResultBox-title h-6 mx-auto'>{language === 'MM' ? 'ထွက်ပြီ' : RECEIPT_PAGE['ထွက်ပြီ']}</div>

            <div className='text-2xl sm:text-4xl' style={{ color: '#000000' }}>
              {_.countBy(allReceipts, (receipt) => checkFinishReceipt(receipt)).true || 0}
            </div>
          </div>
          <div className='allReceipts__todayResultBox-paid3 w-1/2 h-20 text-center mr-1'>
            {/* <div className='allReceipts__todayResultBox-title h-6 mx-auto'>ยังไม่ออกผล</div> */}
            <div
              className='allReceipts__todayResultBox-title h-6 mx-auto'>{language === 'MM' ? 'မထွက်သေး' : RECEIPT_PAGE['မထွက်သေး']}</div>

            <div className='text-2xl sm:text-4xl' style={{ color: '#000000' }}>
              {_.countBy(allReceipts, (receipt) => checkFinishReceipt(receipt)).false || 0}
            </div>
          </div>
        </section>
      </div>

      {/* SECTION 3: RECEIPTS*/}

      {/* <div className='btn_box_header'>รายการเล่นทั้งหมด</div> */}
      <div
        className='btn_box_header'>{language === 'MM' ? 'ကစားဖို့အစီအစဉ်အားလုံး' : RECEIPT_PAGE['ကစားဖို့အစီအစဉ်အားလုံး']}</div>

      <Box containerClass='allReceipts__box'>
        {/* <Box containerClass='allReceipts__buttonTopic'>รายการเล่นทั้งหมด</Box> */}

        {/* SECTION 3.1: Filter Groups */}
        <div className='allReceipts__filterGroup flex p-1 filter-groups' style={{ marginTop: '10px' }}>
          <div className='allReceipts__filterGroup-button mb-2'>
            {[
              {
                text: (
                  <strong>
                    รายการที่<span>ถูกรางวัล</span>
                    {/* {language === 'MM' ? 'ဆုရသည့်စာရင်း' : RECEIPT_PAGE['ဆုရသည့်စာရင်း']} */}
                  </strong>
                ),
                backgroundColor: '#C0534D',
                status: 'prized',
              },
              {
                text: (
                  <strong>
                    รายการที่ยัง<span>ไม่ออกรางวัล</span>
                    {/* {language === 'MM' ? 'မထွက်သေးတဲ့ဆုစာရင်း' : RECEIPT_PAGE['မထွက်သေးတဲ့ဆုစာရင်း']} */}
                  </strong>
                ),
                backgroundColor: '#C0534D',
                status: 'notFinished',
              },
              {
                text: (
                  <strong>
                    รายการที่ออก<span>รางวัลแล้ว</span>
                    {/* {language === 'MM' ? 'ထွက်ပြီးတဲ့ဆုစာရင်း' : RECEIPT_PAGE['ထွက်ပြီးတဲ့ဆုစာရင်း']} */}
                  </strong>
                ),
                backgroundColor: '#C0534D',
                status: 'isFinished',
              },
              {
                text: (
                  <strong>
                    รายการที่<span>ไม่สำเร็จ</span>
                    {/* {language === 'MM' ? 'မအောင်မြင်တဲ့စာရင်း' : RECEIPT_PAGE['မအောင်မြင်တဲ့စာရင်း']} */}
                  </strong>
                ),
                backgroundColor: '#C0534D',
                status: 'cancelled',
              },
            ].map(({ text, backgroundColor, status }, index) => (
              // Split 4 when > tablet, Split 2 when mobile
              <Button
                key={`receipt-${index}`}
                text={text}
                wrapperClassName='allReceipts__receiptsButtonWrapper'
                buttonClassName={`allReceipts__receiptsButtonWrapper-element allReceipts__receiptsButtonWrapper-element-${index} `}
                buttonBackground={backgroundColor}
                onClick={(e) => _onClickFilterButton(e, status)}
                isActive={receiptType === status}
              />
            ))}
          </div>
        </div>
        {/* SECTION 3.2: Receipts listing */}

        <div className='receipts-listing'>
          {filteredReceipts.map((receipt, index) => (
            <Box
              withShine={false}
              withFlare={false}
              containerClass='allReceipts__selectedLotto'
              key={`receipt-${index}`}
            >
              <div className='allReceipts__selectedLotto-firstLine'>
                {/* <img className='allReceipts__selectedLotto-firstLine-shine ' src={dong} alt='shine' /> */}
                <img
                  className='allReceipts__selectedLotto-firstLine-flag '
                  src={
                    receipt.receiptDetail.lottoName.substring(0, 5) === 'ดองยี'
                      ? dong
                      : STOCK_LIST.find((o) => o.title === receipt.receiptDetail.lottoName)?.src || dong
                  }
                  alt='flags'
                />
                <span className='allReceipts__selectedLotto-firstLine-name'>{receipt.receiptDetail.lottoName.slice(0, 5) === 'ดองยี' ? `${language === 'MM' ? 'ရီကီထီ ဒေါင်း ' : 'ยี่กี '}${receipt.receiptDetail.lottoName.split(' ')[1]}` : (language === 'MM' ? POST_PAGE[receipt.receiptDetail.lottoName] : receipt.receiptDetail.lottoName)}</span>
                <div
                  className='allReceipts__selectedLotto-firstLine-status'
                  style={
                    receipt.status === 'rewarded' && receipt.prizeAmount > 0
                      ? {
                        color: colorCode.lottoRed,
                        border: `1px solid ${colorCode.lottoRed}`,
                      }
                      : {
                        color: getReceiptStatusBackgroundColor(receipt.status),
                        border: `1px solid ${getReceiptStatusBackgroundColor(receipt.status)}`,
                      }
                  }
                >
                  {receipt.status === 'rewarded' && receipt.prizeAmount > 0
                    ? 'ถูกรางวัล!' // ? 'ถูกรางวัล'
                    : getReceiptStatusText(receipt.status)}
                </div>
                <br/>
                <span className='allReceipts__selectedLotto-firstLine-index'>{`รายการเลขที่ #${receipt.receiptNumber}`}</span>{' '}
                {/* <span
                  className='allReceipts__selectedLotto-firstLine-index'>{`receipt no #${receipt.receiptNumber}`}</span>{' '} */}
              </div>
              <div className='allReceipts__selectedLotto-secondLine allReceipts__selectedLotto-withSeparator'>
                <div className='allReceipts__selectedLotto-secondLine-left'>
                  <div className='allReceipts__selectedLotto-secondLine-left-pay'>
                    <span>ยอดแทง</span>
                    {/* <span> {language === 'MM' ? 'လောင်းသည့်ငွေ' : RECEIPT_PAGE['လောင်းသည့်ငွေ']}</span> */}
                    <span style={{ color: colorCode.lottoBlack }} className='font-medium'>
                      {Math.round((receipt.purchaseAmount + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                  {/*<span>หวย{_.get(receipt, 'receiptDetail.lottoName', '')}</span>*/}
                  {/*<span>ประกาศผล {fetchDrawTime(receipt.refId) || 'กำลังโหลด'} </span>*/}
                </div>
                <div className='allReceipts__selectedLotto-secondLine-right'>
                  <div className='allReceipts__selectedLotto-secondLine-right-pay'>
                    <span>ยอดถูก</span>
                    {/* <span> {language === 'MM' ? 'အောင်မြင်သည့်ငွေ' : RECEIPT_PAGE['အောင်မြင်သည့်ငွေ']}</span> */}
                    <span
                      style={{
                        color: receipt.prizeAmount > 0 ? colorCode.lottoBlack : '',
                      }}
                      className='font-medium'
                    >
                      {Math.round((_.get(receipt, 'prizeAmount', '') + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                </div>
              </div>
              <div className='allReceipts__selectedLotto-thirdLine'>
                <div className='allReceipts__selectedLotto-thirdLine-left'>
                  <span className='allReceipts__selectedLotto-thirdLine-left-calendar'>
                    {moment(receipt.createdDate).subtract(7, 'hours').format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
                <Button
                  text='รายละเอียด'
                  // text='more detail'
                  wrapperClassName='allReceipts__selectedLotto-thirdLine-right'
                  buttonClassName='allReceipts__selectedLotto-thirdLine-right-button'
                  buttonBackground='#F8B365'
                  onClick={() => {
                    history.push(`/receipts/${receipt.receiptNumber}`)
                  }}
                />
              </div>
              {receipt.receiptDetail.remark ? (
                <div className='allReceipts__selectedLotto-thirdLine' style={{ borderTop: '2px solid #efefef' }}>
                  <div className='allReceipts__selectedLotto-thirdLine-left bold text-xl'>
                    บันทึก: {receipt.receiptDetail.remark}
                  </div>
                </div>
              ) : null}
            </Box>
          ))}
          {isLoading && (
            <div className='loading-bottom-gif' style={{ marginTop: '80px' }}>
              <img
                className='loading-indicator-gif '
                src={require('../../assets/dong-loading/dong-loading.png')}
                alt='loading'
              />
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}

export default React.memo(AllReceipt)
