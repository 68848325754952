import { combineReducers } from 'redux'

import lottos from './lottos'
import auth from './auth'
import cart from './cart'
import receipts from './receipts'
import wallet from './wallet'
import lang from './lang'

export default combineReducers({
  lottos,
  auth,
  cart,
  receipts,
  wallet,
  lang,
})
