import { getEnvironment, ENVIRONMENT } from './env'

export const COGNITO_CONFIGS = {
  LOCAL: {
    POOL_ID: 'ap-southeast-1_bnJ9gfCve',
    APP_CLIENT_ID: 'po27i3jkc5380i25lbq1rjn9j',
    OAUTH_DOMAIN: 'powerlott-player.auth.ap-southeast-1.amazoncognito.com',
    REGION: 'ap-southeast-1',
    PROVIDER: 'LINE',
    GRAPH: 'https://test-powerlott.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    S3_STORAGE: 'powerlott',
    WS_ENDPOINT: 'wss://ws.powerlott.com/notification',
    // IDENTITIES: '',
  },
  // LOCAL: {
  //   POOL_ID: 'ap-southeast-1_uLYoHB7eG',
  //   APP_CLIENT_ID: '6udbjae4s8o5feknm0v9rb1bda',
  //   OAUTH_DOMAIN: 'user-lotto.auth.ap-southeast-1.amazoncognito.com',
  //   REGION: 'ap-southeast-1',
  //   PROVIDER: 'GodHand',
  //   GRAPH: 'https://ktkdsuanb5b2zjmzlungh3g2lu.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  //   S3_STORAGE: 'hotpick-images',
  //   // IDENTITIES: '',
  // },
  DEVELOPMENT: {
    POOL_ID: 'ap-southeast-1_bnJ9gfCve',
    APP_CLIENT_ID: 'po27i3jkc5380i25lbq1rjn9j',
    OAUTH_DOMAIN: 'powerlott-player.auth.ap-southeast-1.amazoncognito.com',
    REGION: 'ap-southeast-1',
    PROVIDER: 'LINE',
    GRAPH: 'https://test-powerlott.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    S3_STORAGE: 'powerlott',
    WS_ENDPOINT: 'wss://ws.powerlott.com/notification',
    // IDENTITIES: '',
  },
  STAGING: {
    POOL_ID: 'ap-southeast-1_bnJ9gfCve',
    APP_CLIENT_ID: 'po27i3jkc5380i25lbq1rjn9j',
    OAUTH_DOMAIN: 'powerlott-player.auth.ap-southeast-1.amazoncognito.com',
    REGION: 'ap-southeast-1',
    PROVIDER: 'LINE',
    GRAPH: 'https://test-powerlott.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    S3_STORAGE: 'powerlott',
    WS_ENDPOINT: 'wss://ws.powerlott.com/notification',
    // IDENTITIES: '',
  },
  // TODO:
  TEST: {
    POOL_ID: 'ap-southeast-1_bnJ9gfCve',
    APP_CLIENT_ID: 'po27i3jkc5380i25lbq1rjn9j',
    OAUTH_DOMAIN: 'powerlott-player.auth.ap-southeast-1.amazoncognito.com',
    REGION: 'ap-southeast-1',
    PROVIDER: 'LINE',
    GRAPH: 'https://test-powerlott.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    S3_STORAGE: 'powerlott',
    WS_ENDPOINT: 'wss://ws.powerlott.com/notification',
    // IDENTITIES: '',
  },
  PRODUCTION: {
    POOL_ID: 'ap-southeast-1_bnJ9gfCve',
    APP_CLIENT_ID: 'po27i3jkc5380i25lbq1rjn9j',
    OAUTH_DOMAIN: 'powerlott-player.auth.ap-southeast-1.amazoncognito.com',
    REGION: 'ap-southeast-1',
    PROVIDER: 'LINE',
    GRAPH: 'https://test-powerlott.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    S3_STORAGE: 'powerlott',
    WS_ENDPOINT: 'wss://ws.powerlott.com/notification',
    // IDENTITIES: '',
  },
}

export const getCognitoAppClientId = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.APP_CLIENT_ID
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.APP_CLIENT_ID
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.APP_CLIENT_ID
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.APP_CLIENT_ID
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.APP_CLIENT_ID
  }

  return COGNITO_CONFIGS.DEVELOPMENT.APP_CLIENT_ID
}

export const getCognitoPoolId = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.POOL_ID
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.POOL_ID
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.POOL_ID
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.POOL_ID
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.POOL_ID
  }

  return COGNITO_CONFIGS.DEVELOPMENT.POOL_ID
}

export const getCognitoOauthDomain = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.OAUTH_DOMAIN
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.OAUTH_DOMAIN
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.OAUTH_DOMAIN
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.OAUTH_DOMAIN
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.OAUTH_DOMAIN
  }

  return COGNITO_CONFIGS.DEVELOPMENT.OAUTH_DOMAIN
}

export const getCognitoRegion = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.REGION
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.REGION
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.REGION
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.REGION
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.REGION
  }

  return COGNITO_CONFIGS.DEVELOPMENT.REGION
}

export const getCognitoProvider = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.PROVIDER
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.PROVIDER
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.PROVIDER
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.PROVIDER
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.PROVIDER
  }

  return COGNITO_CONFIGS.DEVELOPMENT.PROVIDER
}

export const getGraphEndpoint = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.GRAPH
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.GRAPH
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.GRAPH
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.GRAPH
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.GRAPH
  }

  return COGNITO_CONFIGS.DEVELOPMENT.GRAPH
}

export const getStorage = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.S3_STORAGE
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.S3_STORAGE
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.S3_STORAGE
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.S3_STORAGE
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.S3_STORAGE
  }

  return COGNITO_CONFIGS.DEVELOPMENT.S3_STORAGE
}

export const getWSEndpoint = () => {
  const env = getEnvironment()

  if (env === ENVIRONMENT.LOCAL) {
    return COGNITO_CONFIGS.LOCAL.WS_ENDPOINT
  }

  if (env === ENVIRONMENT.DEVELOPMENT) {
    return COGNITO_CONFIGS.DEVELOPMENT.WS_ENDPOINT
  }

  if (env === ENVIRONMENT.STAGING) {
    return COGNITO_CONFIGS.STAGING.WS_ENDPOINT
  }

  if (env === ENVIRONMENT.TEST) {
    return COGNITO_CONFIGS.TEST.WS_ENDPOINT
  }

  if (env === ENVIRONMENT.PRODUCTION) {
    return COGNITO_CONFIGS.PRODUCTION.WS_ENDPOINT
  }

  return COGNITO_CONFIGS.DEVELOPMENT.WS_ENDPOINT
}

// export const getIdentities = () => {
//   const env = getEnvironment()
//
//   if (env === ENVIRONMENT.LOCAL) {
//     return COGNITO_CONFIGS.LOCAL.IDENTITIES
//   }
//
//   if (env === ENVIRONMENT.DEVELOPMENT) {
//     return COGNITO_CONFIGS.DEVELOPMENT.IDENTITIES
//   }
//
//   if (env === ENVIRONMENT.STAGING) {
//     return COGNITO_CONFIGS.STAGING.IDENTITIES
//   }
//
//   if (env === ENVIRONMENT.TEST) {
//     return COGNITO_CONFIGS.TEST.IDENTITIES
//   }
//
//   if (env === ENVIRONMENT.PRODUCTION) {
//     return COGNITO_CONFIGS.PRODUCTION.IDENTITIES
//   }
//
//   return COGNITO_CONFIGS.DEVELOPMENT.IDENTITIES
// }
