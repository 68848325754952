import React from 'react'
import TransactionList from './components/TransactionList'
import './style.scss'

import * as data from './data'

function Transactions() {
  const player = data.player
  const transactions = data.transactions
  return (
    <div className='transactions page text-white'>
      <div className='container mx-auto'>
        <div id='balance' className='card'>
          <h3 className='text-xl font-bold'>เงินในระบบของท่าน</h3>
          <p>{player.playerWallet} บาท</p>
        </div>

        <div id='bank-account' className='card'>
          <h3 className='text-xl font-bold'>บัญชีธนาคารของท่าน</h3>
          {player.playerAccount.id ? (
            <div>
              รหัสธนาคาร {player.playerAccount.bankCode}
              <br />
              ธนาคาร {player.playerAccount.bankName}
              <br />
              ชื่อบัญชี {player.playerAccount.ownerName}
              <br />
              เลขบัญชี {player.playerAccount.accountNumber}
              <br />
            </div>
          ) : (
            <p>ไม่พบบัญชีธนาคารของท่าน กรุณาคลิกเพื่อตั้งค่าบัญชี</p>
          )}
          <div className='btn-group'>
            {player.playerAccount.id ? <button className='btn py-1 px-4'>ฝากเงิน</button> : null}
            <button className='btn py-1 px-4'>ตั้งค่าบัญชี</button>
          </div>
        </div>

        <div id='transactions' className='card transaction__table-card'>
          <h3 className='text-xl font-bold'>ประวัติการทำรายการ</h3>
          <TransactionList data={transactions} />
        </div>
      </div>
    </div>
  )
}

export default Transactions
