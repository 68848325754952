import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import { getLang } from '../../../redux/selectors/lang'

export default function Timer({ drawDateStr = '09/09/2030', drawTimeStr = '23:59:59', closeMinutesBeforeDraw = 0 }) {
  const [diffTime, setDiffTime] = useState('Loading...')
  const newDrawDateStr = drawDateStr.replace(/\//g, '-')
  const drawDateTime = moment(newDrawDateStr + ' ' + drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
  const realDrawTimeMoment = moment(drawDateTime).subtract(closeMinutesBeforeDraw, 'minutes')
  const [isBlock, setIsBlock] = useState(false)
  const lang = useSelector(getLang)
  const language = lang.lang
  const textDay = language === 'MM' ? 'နေ့' : 'วัน'

  useEffect(() => {
    const differ = realDrawTimeMoment.diff(moment())
    let myInterval
    const createInverval = (drawTimeMoment) => {
      const updateTime = (drawTimeMoment) => {
        const differ = drawTimeMoment.diff(moment())
        let withOutDate = moment.utc(differ).format('HH:mm:ss')
        let withDate = moment.utc(differ).subtract(1, 'days').format(`DD ${textDay} HH:mm:ss`)

        if (differ < 86400000) {
          setDiffTime(withOutDate)
        } else {
          setDiffTime(withDate)
        }

        if (differ < 0 || differ === 0) {
          clearInterval(myInterval)
        }
      }

      myInterval = setInterval(() => {
        updateTime(drawTimeMoment)
      }, 1000)
    }
    if (parseInt(differ) < 0) {
      setIsBlock(true)
    } else {
      createInverval(realDrawTimeMoment)
    }
    return () => {
      clearInterval(myInterval)
    }
  }, [])
  // return <div className='__timer text-2xl'>{isBlock ? 'ปิดรับแทง' : diffTime}</div>
  return <div >{isBlock ? `${language === 'MM' ? 'ပိတ်ချိန်' : 'ปิดรับแทง'}` : diffTime}</div>
}
