import PropTypes from 'prop-types'
import React from 'react'

import './Input.scss'

const Input = ({ label, id, value, placeholder, onChange, containerClassName, type }) => {
  return (
    <div className={`${containerClassName} input__wrapper`}>
      <label htmlFor={id}>{label}</label>
      <input type={type} id={id} name={id} placeholder={placeholder} value={value} onChange={onChange} />
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
}

Input.defaultProps = {
  placeholder: '',
  containerClassName: '',
  type: 'text',
}

export default Input
