import { SET_RECEIPT } from '../actions/receipts'

const INITIAL_STATE = {
  receipts: {},
}

const receiptsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RECEIPT: {
      return {
        ...state,
        receipts: action.receipts,
      }
    }
    default: {
      return state
    }
  }
}

export default receiptsReducer
