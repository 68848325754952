import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import qrcode from 'qrcode'
import { API, Auth } from 'aws-amplify'

import ShareButton from '../../components/ShareButton/ShareButton'
import { copyToClipboard } from '../../utils/copy'
import './InviteFriend.scss'
import Box from '../../components/Box/Box'
import { useSelector } from 'react-redux'
import { getAuth } from '../../redux/selectors/auth'
import { getLang } from '../../redux/selectors/lang'
import { AFF_PAGE } from '../../utils/lottoList'

const InviteFriend = () => {
  const [loading, setLoading] = useState(true)
  const [refUrl, setRefUrl] = useState()
  const [refQR, setRefQR] = useState()
  const [filename, setFilename] = useState()

  const auth = useSelector(getAuth)

  const lang = useSelector(getLang)
  const language = lang.lang

  const _onClickToCopy = (domId) => (e) => {
    e.preventDefault()
    const value = document.getElementById(domId).value
    if (value) {
      copyToClipboard(value)
      Swal.fire({
        icon: 'success',
        title: 'คัดลอกสำเร็จ',
        text: value,
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ไม่พบข้อความสำหรับคัดลอก',
      })
    }
  }

  console.log(auth)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        API.post('affiliate', '/ref/generate', {
          body: { username: user.username },
        })
          .then(({ detail = {} }) => {
            console.log(detail, 'detail')
            const code = detail.refCode
            const url = `${window.location.origin}/d?s=${code}`

            setRefUrl(url)
            setFilename(`dong-${code}.png`)
            qrcode.toDataURL(url, { width: 256 }).then((data) => setRefQR(data))
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false))
      })
      .catch(() => setLoading(false))
  }, [])

  return (
    <section className='inviteFriend page-top p-4 flex flex-col items-center mx-auto'>
      <Box containerClass='inviteFriend__pageTitle'>{language === 'MM' ? 'သူငယ်ချင်မိတ်ဆက်စနစ်' : AFF_PAGE['သူငယ်ချင်မိတ်ဆက်စနစ်']}</Box>
      {/*<div className='w-4/5 m-auto text-center mb-10'>*/}
      {/*  <img src={require('./images/invite-friend-banner.png')} className='w-3/5 m-auto' alt='invite-friend' />*/}
      {/*</div>*/}

      <div className='inviteFriend__box inviteFriend__form' id='referral-details'>
        <form className='form__wrapper'>
          <div className='form__setWrapper'>
            <label htmlFor='invite-link' className='text-2xl text-center block text-white mb-6'>
              {language === 'MM' ? 'သင့်မိတ်ဆက်လင့်ခ်' : AFF_PAGE['သင့်မိတ်ဆက်လင့်ခ်']}
            </label>
            {!auth.username ? (
              <div className='text-center'>
                <button
                  className='font-bold text-2xl styleButton py-4 px-20'
                  // onClick={(e) => {
                  //   if (!loading) {
                  //     e.preventDefault()
                  //     Auth.federatedSignIn()
                  //   }
                  // }}
                >
                  {loading ? 'Loading...' : 'กรุณาเข้าสู่ระบบ'}
                </button>
              </div>
            ) : (
              <>
                <div className='form__item row form__buttonGroup w-full justify-center'>
                  <input
                    type='text'
                    name='invite-link'
                    value={refUrl}
                    id='invite-link'
                    className='w-3/5'
                    disabled={!refUrl || refUrl === 'Loading...'}
                  />
                  <button className='styleButton w-1/5 p-1' onClick={_onClickToCopy('invite-link')}>
                    Copy
                  </button>
                </div>
                <div className='inviteFriend__share-button'>
                  <ShareButton url={refUrl} />
                </div>
              </>
            )}
          </div>

          {refQR && (
            <div className='form__setWrapper'>
              <div className='form__item'>
                <img
                  src={refQR}
                  className='w-2/5 max-w-screen-md m-auto inviteFriend__qrCode-rendered'
                  alt='invite-friend'
                />
                <a
                  href={refQR}
                  download={filename}
                  className='inviteFriend__button styleButton w-1/5 bgWhite text-xl my-3 m-auto'
                >
                  <span>Download</span>
                </a>
              </div>
            </div>
          )}
        </form>
      </div>

      {/* {!loading && (
        <div>
          <h3 className='text-2xl text-center text-white font-bold my-6'>เพื่อนทั้งหมด: {0} คน</h3>

          <div className='inviteFriend__box inviteFriend__form'>
            <img
              src={require('./images/invite-friend-group.png')}
              className='m-auto'
              style={{ maxWidth: '100px' }}
              alt='invite-friend-group'
            />
            <p className='text-2xl text-white text-center mt-10'>ไม่มีรายการเพื่อน</p>
            <p className='text-sm text-white text-center'>xxxxxxx</p>
          </div>
        </div>
      )} */}
    </section>
  )
}

export default InviteFriend
