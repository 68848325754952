import PropTypes from 'prop-types'
import React from 'react'

import './Box.scss'
import noop from 'lodash/noop'

import Flare1 from '../Flare/Flare1'
import Flare2 from '../Flare/Flare2'

const Box = ({ children, containerClass, withFlare, withShine, onClick }) => {
  return (
    <div className={`boxComponent__container ${containerClass}`} onClick={onClick}>
      {withShine && (
        <div className='boxComponent__shineBox'>
          <div className='boxComponent__shineElement' />
        </div>
      )}
      {children}
      {withFlare && <Flare1 />}
      {withFlare && <Flare2 />}
    </div>
  )
}

// test

Box.defaultProps = {
  withFlare: true,
  withShine: true,
  onClick: noop,
}

Box.propTypes = {
  children: PropTypes.node,
  containerClass: PropTypes.string,
  withFlare: PropTypes.bool,
  withShine: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Box
