import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'
import { LUCKY_BOX_PAGE } from '../../utils/lottoList'
import { useSelector } from 'react-redux'
import { getLang } from '../../redux/selectors/lang'

import Hotpick from '../Home/Hotpick'
import Box from '../../components/Box/Box'
import loadingImg from '../../assets//dong-loading/dong-loading.png'

import './FastBuy.scss'

export default function FastBuyList() {
  const [hotpicks, setHotpicks] = useState([])
  const [filterHotpicks, setFilterHotpicks] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [hotpickOption, setHotpickOptions] = useState([])
  const [selectLotto, setSelectLotto] = useState(0)

  const lang = useSelector(getLang)
  const language = lang.lang

  useEffect(() => {
    setIsLoaded(false)
    API.get('lotto', `/lottos?status=open&limit=600`).then((res) => {
      const data = res.detail
      const lottoList = data.map((obj) => {
        const lotto = {}
        lotto.lottoNumber = obj.lottoNumber
        lotto.lottoName = obj.lottoName
        return lotto
      })
      API.get('hotpick', `/hotpicks?limit=300`)
        // Old endpoint to fetch all
        // API.get('hotpick', '/hotpicks')
        .then((res) => {
          // console.log('get hotpicks >> ', res)
          const getHotpick = _.get(res, 'detail', [])
          const now = moment()
          // console.log('get hotpicks today >> ', res)
          const todayHotpicks = getHotpick
            .filter((item) => {
              const dateDateTime = moment(item.dateStr, 'DD/MM/YYYY')
              const isSameDate = dateDateTime.isSameOrAfter(now, 'day')
              return isSameDate
            })
            .map((row) => {
              const hotpick = {}
              hotpick._id = row._id
              hotpick.lottoNumber = row.lottoNumber
              hotpick.title = row.title
              hotpick.desc = row.desc
              hotpick.dateStr = row.dateStr
              hotpick.result = {}
              hotpick.result.threeAll = row.result.threeAll
              hotpick.result.twoAll = row.result.twoAll
              hotpick.result.nineteenStraight = row.result.nineteenStraight
              hotpick.result.runAll = row.result.nineteenStraight
              hotpick.hotpickImageURL = row.hotpickImageURL
              return hotpick
            })
          const hotpickNow = todayHotpicks.map((obj) => obj.lottoNumber)
          // console.log(todayHotpicks, 'hotpicksRes in use Effect')
          const hotpickNowList = [...new Set(hotpickNow)]
          const hotpickList = []
          hotpickList.push({
            // lottoName: 'หวยทั้งหมด',
            lottoName: `${ language === 'MM' ? 'ထီအားလုံး' : LUCKY_BOX_PAGE['ထီအားလုံး'] }`,
            lottoNumber: 0,
          })
          for (let i = 0; i < hotpickNowList.length; i++) {
            const hotpickName = lottoList.find((obj) => obj.lottoNumber === hotpickNowList[i])
            hotpickList.push(hotpickName)
          }
          // console.log(hotpickList, 'hotpick List in use Effect')
          const filterUndefined = hotpickList.filter((obj) => obj !== undefined)
          // console.log(filterUndefined, 'hotpick List in use Effect')
          setHotpickOptions(filterUndefined)
          setHotpicks(todayHotpicks)
          // console.log(selectLotto, 'selectLotto in use Effect')
          if (selectLotto !== 0) {
            // console.log(hotpicks, 'hotpicks in use Effect')
            const filterHotpick = hotpicks.filter((obj) => obj.lottoNumber === selectLotto)
            // console.log('filterHotpick >>>', filterHotpick)
            setFilterHotpicks(filterHotpick)
          } else {
            setFilterHotpicks(todayHotpicks)
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoaded(true))
    })
    // .catch((err) => console.error(err))
    // .finally(() => setIsLoaded(true))
    return () => { }
  }, [selectLotto])

  const _onSelectLotto = (e) => {
    const value = e.target.value
    setSelectLotto(+value)
  }

  return (
    <div className='home page-top'>
      <div className='news container mx-auto'>
        {isLoaded ? (
          <>
            <div>
              <div className='flex flex-wrap'>
                <div className='allReceipts__buttonBox home__lotto__result__header'>เลขเด็ด</div>
                <div className='transactionList__select' style={{ transform: 'translateY(-15px)' }}>
                  <select value={selectLotto || 0} onChange={(e) => _onSelectLotto(e)}>
                    {hotpickOption?.map((item) => (
                      <option key={item.lottoNumber} value={item.lottoNumber}>
                        {item.lottoName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='home__posts'>
                  {filterHotpicks?.map((item, index) => (
                    <Hotpick key={index} data={item} />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='loading-gif'>
            <img className='loading-indicator-gif ' src={loadingImg} alt='loading' />
          </div>
        )}
      </div>
    </div>
  )
}
