export const SET_AUTH = 'AUTH/SET_AUTH'
export const CLEAR_AUTH = 'AUTH/CLEAR_AUTH'

export function setAuth({ user }) {
  return {
    type: SET_AUTH,
    payload: { user },
  }
}

export function clearAuth() {
  return {
    type: CLEAR_AUTH,
  }
}
