import { SET_AUTH, CLEAR_AUTH } from '../actions/auth'

const INITIAL_STATE = {
  initing: true,
  username: null,
  attributes: {},
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTH: {
      const { user = {} } = action.payload
      const { username } = user

      return {
        ...state,
        initing: false,
        username,
        attributes: action.payload.user.attributes || {},
      }
    }
    case CLEAR_AUTH: {
      return {
        ...state,
        username: null,
        attributes: {},
      }
    }
    default:
      return state
  }
}

export default userReducer
