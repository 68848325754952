import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { Link, NavLink } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'
import moment from 'moment'
import AliceCarousel from 'react-alice-carousel'

import { POST_PAGE } from '../../utils/lottoList'
import { getLang } from '../../redux/selectors/lang'
import { getAuth } from '../../redux/selectors/auth'
import Flare1 from '../../components/Flare/Flare1'
import Flare2 from '../../components/Flare/Flare2'
import { fetchLottos } from '../../redux/actions/lottos'
import './Lottos.scss'
import loadingImg from '../../assets//dong-loading/dong-loading.png'
import YeekeeModal from './components/YeekeeModal'
import 'react-alice-carousel/lib/alice-carousel.css'
import { LOTTO_ENG, LOTTO_GROUP_ENG, LOTTO_GROUP_TH } from '../../utils/lottoList'

let listOfMobileImages = []

class SlideshowMobile extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfMobileImages = this.importAll(
      require.context('../../assets/dong-banner/mobile/', false, /\.(png|jpe?g|svg)$/)
    )
  }

  render() {
    return (
      <div className='home mobile' style={{ marginBottom: '10px' }}>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
          {listOfMobileImages.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

let listOfPcImages = []

class SlideshowPc extends React.Component {
  importAll(r) {
    return r.keys().map(r)
  }

  componentWillMount() {
    listOfPcImages = this.importAll(require.context('../../assets/dong-banner/pc/', false, /\.(png|jpe?g|svg)$/))
  }

  render() {
    return (
      <div className='home desktop container mx-auto mb-5'>
        <AliceCarousel buttonsDisabled={true} dotsDisabled={true} autoPlay autoPlayInterval={5000} mouseTracking>
          {listOfPcImages.map((each, index) => (
            <img key={index} className='home__sliderimg' src={each} alt='slide' />
          ))}
        </AliceCarousel>
      </div>
    )
  }
}

const LOTTO_LIST = [
  { title: 'ฮานอย สามัคคี', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย พัฒนา', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย พิเศษ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอยเฉพาะกิจ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย เที่ยง', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม พิเศษ', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'เวียดนาม VIP', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย VIP', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย HD', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย TV', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย กาชาด', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย EXTRA', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย รอบดึก', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'ฮานอย สตาร์', src: require('../../assets/lotto-flags/vietnam-flag.png') },
  { title: 'มาเลเซีย', src: require('../../assets/lotto-flags/malaysia-flag.png') },
  { title: 'ลาว EXTRA', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว พัฒนา', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สตาร์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวสตาร์ VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว จำปาศักดิ์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว ประตูชัย', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สามัคคี VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว HD', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดาว', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว TV', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว VIP', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว กาชาด', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาวดิจิตอล', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว เวียงจันทร์', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ลาว สามัคคี', src: require('../../assets/lotto-flags/laos-flag.png') },
  { title: 'ดาวโจนส์ VIP', src: require('../../assets/lotto-flags/usa-flag.png') },
  { title: 'ดาวโจนส์', src: require('../../assets/lotto-flags/usa-flag.png') },
  { title: 'นิเคอิ รอบเช้า', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'ไทย รอบเช้า', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไทย รอบเช้าตลาด', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'จีน รอบเช้า', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง รอบเช้า', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทยเที่ยง', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ไต้หวัน', src: require('../../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ รอบบ่าย', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี', src: require('../../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน รอบบ่าย', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ไทย รอบบ่าย', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'ฮั่งเส็ง รอบบ่าย', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไทย รอบบ่ายตลาด', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'สิงคโปร์', src: require('../../assets/lotto-flags/singapore-flag.png') },
  { title: 'ไทย เย็น', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'อินเดีย', src: require('../../assets/lotto-flags/india-flag.png') },
  { title: 'อียิปต์', src: require('../../assets/lotto-flags/egypt-flag.png'), title_en: 'Egypt' },
  { title: 'ไทยกลางคืน', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'รัสเซีย', src: require('../../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ', src: require('../../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน', src: require('../../assets/lotto-flags/german-flag.png') },
  { title: 'หวยรัฐบาลไทย', src: require('../../assets/lotto-flags/thailand-flag.png') },
  { title: 'หวยออมสิน', src: require('../../assets/lotto-flags/bank-gsb.png') },
  { title: 'หวยธกส.', src: require('../../assets/lotto-flags/tks.png') },
  { title: 'นิเคอิ(เช้า) VIP', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'จีน(เช้า) VIP', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(เช้า) VIP', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'ไต้หวัน VIP', src: require('../../assets/lotto-flags/taiwan-flag.png') },
  { title: 'นิเคอิ(บ่าย) VIP', src: require('../../assets/lotto-flags/japan-flag.png') },
  { title: 'เกาหลี VIP', src: require('../../assets/lotto-flags/korean-flag.png') },
  { title: 'จีน(บ่าย) VIP', src: require('../../assets/lotto-flags/china-flag.png') },
  { title: 'ฮั่งเส็ง(บ่าย) VIP', src: require('../../assets/lotto-flags/hongkong-flag.png') },
  { title: 'สิงคโปร์ VIP', src: require('../../assets/lotto-flags/singapore-flag.png') },
  { title: 'รัสเซีย VIP', src: require('../../assets/lotto-flags/russia-flag.png') },
  { title: 'อังกฤษ VIP', src: require('../../assets/lotto-flags/uk-flag.png') },
  { title: 'เยอรมัน VIP', src: require('../../assets/lotto-flags/german-flag.png') },
  { title: '2D รอบเช้า', src: require('../../assets/lotto-flags/2d-flag.png') },
  { title: '2D รอบบ่าย', src: require('../../assets/lotto-flags/2d-flag.png') },
  { title: '2D รอบเย็น', src: require('../../assets/lotto-flags/2d-flag.png') },
]

const checkIsLottoSameDate = (lottos = null) => {
  if (_.isEmpty(lottos)) {
    return false
  }
  const shouldShow = _.some(lottos, (item) => {
    const newDrawDateStr = item.drawDateStr.replace(/\//g, '-')
    const drawDateTime = moment(newDrawDateStr + ' ' + item.drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
    const timeToCheck = moment(drawDateTime).subtract(item.openMinutesBeforeDraw - 1, 'minutes')
    const isSameDate =
      item.lottoGroup === 'หวยไทย'
        ? timeToCheck.isSameOrBefore(moment(), 'day')
        : item.lottoName === 'ดาวโจนส์' || item.lottoName === 'ดาวโจนส์ VIP'
        ? timeToCheck.isSame(moment().subtract(10, 'hours'), 'day')
        : timeToCheck.isSame(moment(), 'day')
    return isSameDate
  })
  return shouldShow
}

export default function Lottos({ location }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch()

  const { twoAbove, twoBelow, threeStraight } = queryString.parse(location.search)
  const auth = useSelector(getAuth)
  const lang = useSelector(getLang)
  const language = lang.lang

  const lottos = useSelector((state) => state).lottos.lottos
  const lottosItemsGroupByType = _.groupBy(lottos, 'lottoGroup')
  const forSort = _.keysIn(lottosItemsGroupByType)
  const sortLotto = ['หวยแนะนำ', 'หวยไทย', 'หวยหุ้น', 'หวยต่างประเทศ', 'หวยวีไอพี', 'หวยรายวัน', 'หวยยี่กี']
  const lottosTypes = sortLotto.filter(function (el) {
    return forSort.indexOf(el) >= 0
  })
  const [showYeekeeModal, setShowYeekeeModal] = useState({ isShow: false, data: {} })
  // const [currentYeekee, setCurrentYeekee] = useState({})
  // const [isJustBlock, setIsJustBlock] = useState(false)

  useEffect(() => {
    setIsLoaded(false)
    API.get('lotto', '/lottos?limit=600')
      .then(({ detail }) => {
        const todayLottos = detail
        let recommend = []
        // dispatch(fetchLottos(detail))
        // console.log(todayLottos, 'todayLottos')
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยยี่กี&limit=1').then(({ detail }) => {
          if (detail.length > 0) {
            const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 1 }])
            if (newGroup[0][0].status === 'open') {
              recommend.push(newGroup[0][0])
            }
          }
        })
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยไทย&limit=1').then(({ detail }) => {
          if (detail.length > 0) {
            const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 2 }])
            if (newGroup[0][0].status === 'open') {
              recommend.push(newGroup[0][0])
            }
          }
        })
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยหุ้น&limit=1').then(({ detail }) => {
          if (detail.length > 0) {
            const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 3 }])
            if (newGroup[0][0].status === 'open') {
              recommend.push(newGroup[0][0])
            }
          }
        })
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยต่างประเทศ&limit=1').then(({ detail }) => {
          if (detail.length > 0) {
            const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 4 }])
            if (newGroup[0][0].status === 'open') {
              recommend.push(newGroup[0][0])
            }
          }
        })
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยวีไอพี&limit=1').then(({ detail }) => {
          if (detail.length > 0) {
            const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 5 }])
            if (newGroup[0][0].status === 'open') {
              recommend.push(newGroup[0][0])
            }
          }
        })
        API.get('lotto', '/lottos?status=open&lottoGroup=หวยรายวัน&limit=1')
          .then(({ detail }) => {
            if (detail.length > 0) {
              const newGroup = detail.map((el) => [{ ...el, lottoGroup: 'หวยแนะนำ', index: 6 }])
              if (newGroup[0][0].status === 'open') {
                recommend.push(newGroup[0][0])
              }
            }
          })
          .then(() => {
            const sortData = recommend.sort((a, b) => (a.index > b.index ? 1 : b.index > a.index ? -1 : 0))
            dispatch(fetchLottos(todayLottos.concat(sortData)))
          })
        // console.log('Lottos fetched ======')
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoaded(true))

    return () => {
      clearInterval() // FOR?
    }
  }, [twoAbove, twoBelow, threeStraight, dispatch])

  if (!lottos) return <p>loading...</p>

  const Lotto = (props) => {
    const [diffTime, setDiffTime] = useState('loading...')
    const [isBlock, setIsBlock] = useState(false)
    const realDrawTimeMoment = moment(props.closeDateTimeRealMoment, 'DD-MM-YYYY HH:mm:ss')
    // const fastBuyImage = getStorage()
    const [imageUrl, setImageUrl] = useState(require('../../assets/lotto-flags/dong.png'))

    const [errorImageUrl, setErrorImageUrl] = useState(false)

    const textDay = language === 'MM' ? 'နေ့' : 'วัน'

    useEffect(() => {
      const differ = realDrawTimeMoment.diff(moment())
      let myInterval
      const createInverval = (drawTimeMoment) => {
        const updateTime = (drawTimeMoment) => {
          const differ = drawTimeMoment.diff(moment())
          let withOutDate = moment.utc(differ).format('HH:mm:ss')
          let withDate = moment.utc(differ).subtract(1, 'days').format(`DD ${textDay} HH:mm:ss`)
          if (differ < 86400000) {
            setDiffTime(withOutDate)
          } else {
            setDiffTime(withDate)
          }

          if (differ < 0 || differ === 0) {
            clearInterval(myInterval)
            // setIsJustBlock(!isJustBlock)
          }
        }

        myInterval = setInterval(() => {
          updateTime(drawTimeMoment)
        }, 1000)
      }
      if (parseInt(differ) < 0) {
        setIsBlock(true)
      } else {
        createInverval(realDrawTimeMoment)
      }
      return () => {
        clearInterval(myInterval)
      }
    }, [])

    const _onImageUrlError = () => {
      if (!errorImageUrl) {
        // Fallback to default lotto image
        setImageUrl(require('../../assets/lotto-flags/dong.png'))
        setErrorImageUrl(true)
      }
    }

    return (
      <Link
        to={'/lottos/' + props.lottoNumber}
        className={`lottos__item ${isBlock ? 'blocked' : auth.username ? '' : 'notAuthenticated'}`}
      >
        <Flare1 />
        <Flare2 />
        <div className='lottos__item-header'>
          <img
            src={
              props.lottoName.slice(0, 5) === 'ยี่กี'
                ? require('../../assets/lotto-flags/dong.png')
                : LOTTO_LIST.find((o) => o.title === props.lottoName)?.src
            }
            onError={_onImageUrlError}
            alt='lotto icon'
          />
          <div>
            <div>
              {props.lottoName.slice(0, 5) === 'ยี่กี'
                ? `${language === 'MM' ? 'ရီကီထီ ဒေါင်း ' : 'ยี่กี '}${props.lottoName.split(' ')[1]}`
                : language === 'MM'
                ? POST_PAGE[props.lottoName]
                : props.lottoName}
            </div>

            <div>ปิด {moment(props.closeDateTimeRealMoment).format('HH:mm')}</div>
          </div>
        </div>
        <div className='lottos__item-timer'>
          <img src={require('./assets/clock.svg')} alt='clock' />
          <img className='lottos__item-block-icon' src={require('./assets/blocked.png')} alt='lotto icon' />
          {auth.username ? (
            <div>{isBlock ? 'ปิดรับ' : diffTime}</div>
          ) : (
            <div style={{ color: 'red', fontSize: '12px' }}>กรุณาเข้าสู่ระบบ</div>
            // <div style={{ color: 'red', fontSize: '12px' }}>Please log in to proceed</div>
          )}
        </div>
      </Link>
    )
  }
  Lotto.propTypes = {
    lottoName: PropTypes.string,
    lottoNumber: PropTypes.number,
    closeDateTimeRealMoment: PropTypes.object,
    lottoGroup: PropTypes.string,
    status: PropTypes.string,
  }

  const RenderLotto = (props) => {
    const lottos = props.lottos
    const activeLottos = []
    // const inactiveLottos = []
    const inactiveLottosWithSameDate = []

    if (!_.isEmpty(lottos)) {
      lottos.forEach((item) => {
        const newDrawDateStr = item.drawDateStr.replace(/\//g, '-')
        const drawDateTime = moment(newDrawDateStr + ' ' + item.drawTimeStr, 'DD-MM-YYYY HH:mm:ss')
        const differ = drawDateTime.diff(moment())

        const timeToCheck = moment(drawDateTime).subtract(item.openMinutesBeforeDraw - 1, 'minutes')
        const isSameDate =
          item.lottoGroup === 'หวยไทย'
            ? timeToCheck.isSameOrBefore(moment(), 'day')
            : item.lottoName === 'ดาวโจนส์' || item.lottoName === 'ดาวโจนส์ VIP'
            ? timeToCheck.isSame(moment().subtract(10, 'hours'), 'day')
            : timeToCheck.isSame(moment(), 'day')
        const isPassed = parseInt(differ) < 0
        const closeDateTimeRealMoment = moment(drawDateTime).subtract(item.closeMinutesBeforeDraw, 'minutes')

        if (isSameDate && !isPassed) {
          activeLottos.push(
            <Lotto
              key={item.lottoNumber + item.lottoName}
              lottoNumber={item.lottoNumber}
              lottoName={item.lottoName}
              drawDateStr={item.drawDateStr}
              drawTimeStr={item.drawTimeStr}
              imageUrl={item.imageUrl}
              closeMinutesBeforeDraw={item.closeMinutesBeforeDraw}
              closeDateTimeRealMoment={closeDateTimeRealMoment}
            />
          )
        } else if (isSameDate && isPassed) {
          inactiveLottosWithSameDate.push(
            <Lotto
              key={item.lottoNumber + item.lottoName}
              lottoNumber={item.lottoNumber}
              lottoName={item.lottoName}
              drawDateStr={item.drawDateStr}
              drawTimeStr={item.drawTimeStr}
              imageUrl={item.imageUrl}
              closeMinutesBeforeDraw={item.closeMinutesBeforeDraw}
              closeDateTimeRealMoment={closeDateTimeRealMoment}
              lottoGroup={item.lottoGroup}
              status={item.status}
            />
          )
        }
      })
    }

    return [...activeLottos, ...inactiveLottosWithSameDate]
  }

  RenderLotto.propTypes = {
    lottos: PropTypes.array,
  }

  return (
    <div className='lottos page mt-20'>
      <SlideshowMobile />
      <SlideshowPc />
      {!isLoaded && <div className='loading-gif'></div>}
      {lottosTypes.map((item, index) => {
        const shouldShow = checkIsLottoSameDate(lottosItemsGroupByType[item] || null)
        if (!shouldShow) {
          return null
        }

        return (
          <section key={index}>
            <div className='dong_lottos__header container text-4xl mt-10'>
              <h1>{LOTTO_GROUP_TH[item]}</h1>
            </div>
            <div className='lottos__list container'>
              {isLoaded ? (
                !_.isEmpty(lottosItemsGroupByType[item]) ? (
                  <RenderLotto lottos={lottosItemsGroupByType[item]} />
                ) : null
              ) : (
                <div style={{ color: 'red' }}>กำลังโหลด...</div>
              )}
            </div>
          </section>
        )
      })}
      {showYeekeeModal.isShow ? (
        <YeekeeModal
          showModal={showYeekeeModal}
          setShowModal={setShowYeekeeModal}
          lottoNumber={showYeekeeModal.data.lottoNumber}
          lottoData={showYeekeeModal.data}
        />
      ) : null}
    </div>
  )
}

Lottos.propTypes = {
  location: PropTypes.object,
}
