import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { getLang } from '../../../redux/selectors/lang'
import { POST_PAGE } from '../../../utils/lottoList'

export default function StatisticsModal(props) {
  const showModal = props.showModal

  const setShowModal = props.setShowModal
  const lottoName = props.lottoData.lottoName
  const lottoGroup = props.lottoData.lottoGroup
  const drawDateStr = props.lottoData.drawDateStr

  const [lottoData, setLottoData] = useState([])
  const [hotNumber, setHotNumber] = useState([])
  const [coldNumber, setColdNumber] = useState([])
  const [doubleNumber, setDoubleNumber] = useState(0)
  const [isLastest, setIsLastest] = useState(false)
  const [storeData, setStoreData] = useState('empty')
  const lang = useSelector(getLang)
  const language = lang.lang

  const getHotAndCold = (data) => {
    let newArray = []
    let double = 0
    for (let i = 0; i < data.length; i++) {
      newArray.push(data[i].results['2A'].split('')[0])
      newArray.push(data[i].results['2A'].split('')[1])
      if (data[i].results['2A'].split('')[0] === data[i].results['2A'].split('')[1]) {
        ++double
      }
      newArray.push(data[i].results['2B'].split('')[0])
      newArray.push(data[i].results['2B'].split('')[1])
      if (data[i].results['2B'].split('')[0] === data[i].results['2B'].split('')[1]) {
        ++double
      }
    }
    // console.log(newArray, 'newArray')

    const counts = {}
    newArray.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1
    })
    // console.log(counts, 'counts')
    let max = Object.entries(counts).reduce((max, entry) => (entry[1] >= max[1] ? entry : max), [0, -Infinity])
    let min = Object.entries(counts).reduce((min, entry) => (entry[1] <= min[1] ? entry : min), [0, +Infinity])
    // console.log(max, 'max')
    // console.log(min, 'min')
    setDoubleNumber(double)
    setHotNumber(max)
    setColdNumber(min)
  }

  useEffect(() => {
    if (lottoName === '' || lottoName === undefined) {
      return
    }
    if (lottoGroup !== 'หวยยี่กี') {
      if (isLastest === false) {
        if (storeData === 'empty') {
          // console.log('Get data from api')
          API.get('lotto', `/results?limit=30&status=rewarded&lottoName=${lottoName}`)
            .then((res) => {
              const lottoData = res.detail
              // console.log(lottoData, 'lottoData')
              setLottoData(lottoData)
              getHotAndCold(lottoData)
            })
            .catch((err) => console.error(err))
        } else {
          const dataStore = storeData
          setLottoData(dataStore)
          getHotAndCold(dataStore)
        }
      } else {
        // console.log('Get data from filter')
        setStoreData(lottoData)
        const lastestLotto = lottoData.slice(0, 10)
        setLottoData(lastestLotto)
        getHotAndCold(lastestLotto)
      }
    } else {
      if (isLastest === false) {
        if (storeData === 'empty') {
          // console.log('Get data from api')
          API.get('lotto', `/results?limit=300&status=rewarded&lottoGroup=${lottoGroup}&drawDateStr=${drawDateStr}`)
            .then((res) => {
              const lottoData = res.detail.reverse()
              // console.log(lottoData, 'lottoData')
              setLottoData(lottoData)
              getHotAndCold(lottoData)
            })
            .catch((err) => console.error(err))
        } else {
          const dataStore = storeData
          setLottoData(dataStore)
          getHotAndCold(dataStore)
        }
      } else {
        // console.log('Get data from filter')
        setStoreData(lottoData)
        const lastestLotto = lottoData.slice(0, 10)
        setLottoData(lastestLotto)
        getHotAndCold(lastestLotto)
      }
    }
    return () => {
      // console.log('return in useEffect')
    }
  }, [lottoName, isLastest])

  const dateNow = (date) => {
    // console.log(date, 'date')
    if (date !== undefined) {
      const dateString = date.substr(3, 2) + '/' + date.substr(0, 2) + '/' + date.substr(6, 4)
      // console.log(dateString)
      return new Date(dateString).toLocaleDateString('th-TH', {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
        weekday: 'long',
      })
    }
    return new Date().toLocaleDateString('th-TH', {
      year: '2-digit',
      month: 'short',
      day: 'numeric',
      weekday: 'long',
    })
  }

  // const lottoRound =
  //   lottoData.lottoGroup === 'หวยยี่กี'
  //     ? 'Y' + lottoData.lottoName.split(' ')[1]
  //     : lottoData.lottoGroup === 'หวยป้าตุ๊ก'
  //     ? 'P' + lottoData.lottoName.split(' ')[1]
  //     : lottoData.lottoName.slice(-2)

  return showModal.isShow && showModal.currentModal === 'ดูสถิติ' ? (
    <section className={`buy-lotto__modal active`}>
      <div className={`buy-lotto__modal-box active`}>
        <div className='buy-lotto__modal-box-header text-xl'>
          <div className='buy-lotto__lotto-number text-xl mr-3 text-white bold strong'>
            {lottoGroup !== 'หวยยี่กี' ? (
              <div style={{ fontWeight: 'bold' }}>
                {language === 'MM' ? POST_PAGE[lottoName] : lottoName} {lottoData.length} Lastest Rounds
              </div>
            ) : (
              <div style={{ fontWeight: 'bold' }}>
                Dong Yeekee {lottoData.length} Rounds{isLastest ? ' Lastest' : ' Today'}
              </div>
            )}
          </div>
          <button className='danger' onClick={() => setShowModal((prevState) => ({ ...prevState, isShow: false }))}>
            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
              <path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
            </svg>
          </button>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={() => {
              setIsLastest(!isLastest)
            }}
            style={{
              color: 'white',
              background: 'linear-gradient(to right, #C4A85A 0%, #794D1F 50%, #887936 100%)',
              fontWeight: 'bold',
              padding: '0 10px',
              borderRadius: '10px',
              border: '2px ridge #c8b785',
            }}
          >
            {lottoGroup === 'หวยยี่กี' && isLastest
              ? 'View All'
              : lottoGroup === 'หวยยี่กี'
                ? 'View last 10 rounds'
                : isLastest
                  ? 'View last 30 rounds'
                  : 'View last 10 rounds'}
          </button>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-hotNumber'>
            <span className='home__detailsBox-purchaseDetails-amount-title'>Hot Number</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}>
              {hotNumber[0] || 0}
            </span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'red' }}>
              {hotNumber[1] || 0} Times
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-coldNumber'>
            <span className='home__detailsBox-purchaseDetails-amount-title'>Cold Number</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'blue' }}>
              {coldNumber[0] || 0}
            </span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'blue' }}>
              {coldNumber[1] || 0} Times
            </span>
          </div>
        </div>
        <div
          className='home__detailsBox-purchaseDetails'
          style={{
            borderBottom: '2px solid #dddddd',
          }}
        >
          <div className='home__detailsBox-purchaseDetails-double'>
            <span className='home__detailsBox-purchaseDetails-amount-title'>Double Number</span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'green' }}></span>
          </div>
          <div className='home__detailsBox-purchaseDetails-amount'>
            <span className='home__detailsBox-purchaseDetails-amount-title' style={{ color: 'green' }}>
              {doubleNumber} Times
            </span>
          </div>
        </div>
        {lottoData.map((lotto, index) => {
          return (
            <div
              className='home__detailsBox-purchaseDetails'
              key={index}
              style={{
                borderBottom: '2px solid #dddddd',
              }}
            >
              <div className='home__detailsBox-purchaseDetails-detail'>
                <span className='home__detailsBox-purchaseDetails-amount-title' style={{ fontSize: '15px' }}>
                  {lottoGroup !== 'หวยยี่กี' ? dateNow(lotto.drawDateStr) : lotto.lottoName}
                  {/*{new Date(`20${lotto.drawDateStr.split('/').reverse().join(',')}`).toLocaleDateString('th-TH', {*/}
                  {/*  year: '2-digit',*/}
                  {/*  month: 'short',*/}
                  {/*  day: 'numeric',*/}
                  {/*  weekday: 'long',*/}
                  {/*}) || ''}*/}
                </span>
              </div>
              <div className='home__detailsBox-purchaseDetails-amount'>
                <span>{lotto.results['3A'].split('')[0]}</span>
                <span
                  style={
                    lotto.results['3A'].split('')[1] === lotto.results['3A'].split('')[2]
                      ? { color: 'green' }
                      : lotto.results['3A'].split('')[1] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['3A'].split('')[1] === coldNumber[0]
                          ? { color: 'blue' }
                          : { color: '' }
                  }
                >
                  {lotto.results['3A'].split('')[1]}
                </span>
                <span
                  style={
                    lotto.results['3A'].split('')[1] === lotto.results['3A'].split('')[2]
                      ? { color: 'green' }
                      : lotto.results['3A'].split('')[2] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['3A'].split('')[2] === coldNumber[0]
                          ? { color: 'blue' }
                          : { color: '' }
                  }
                >
                  {lotto.results['3A'].split('')[2]}
                </span>
              </div>
              <div className='home__detailsBox-purchaseDetails-amount'>
                <span
                  style={
                    lotto.results['2B'].split('')[0] === lotto.results['2B'].split('')[1]
                      ? { color: 'green' }
                      : lotto.results['2B'].split('')[0] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['2B'].split('')[0] === coldNumber[0]
                          ? { color: 'blue' }
                          : { color: '' }
                  }
                >
                  {lotto.results['2B'].split('')[0]}
                </span>
                <span
                  style={
                    lotto.results['2B'].split('')[0] === lotto.results['2B'].split('')[1]
                      ? { color: 'green' }
                      : lotto.results['2B'].split('')[1] === hotNumber[0]
                        ? { color: 'red' }
                        : lotto.results['2B'].split('')[1] === coldNumber[0]
                          ? { color: 'blue' }
                          : { color: '' }
                  }
                >
                  {lotto.results['2B'].split('')[1]}
                </span>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  ) : null
}

StatisticsModal.propTypes = {
  showModal: PropTypes.object,
  setShowModal: PropTypes.func,
  lottoData: PropTypes.object,
}
