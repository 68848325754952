import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// import { Auth } from 'aws-amplify'

function Marketing() {
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('p')
    localStorage.setItem('marketingCode', code)
    history.replace('/')

    // Auth.currentAuthenticatedUser()
    //   .then(() => history.replace('/'))
    //   .catch(() =>
    //     Auth.federatedSignIn({
    //       customState: code,
    //     })
    //   )
  }, [])

  return <div />
}

export default Marketing
