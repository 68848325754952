import { SET_WALLET_CREDIT } from '../actions/wallet'

const INITIAL_STATE = {
  credit: 0,
}

const walletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WALLET_CREDIT: {
      return { ...state, ...action.wallet }
    }
    default:
      return state
  }
}

export default walletReducer
