import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import Swal from 'sweetalert2'
import Iframe from 'react-iframe'
import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { ppthb888Deposit } from '../../third-party/ppthb888'
import { getAuth } from '../../redux/selectors/auth'
import './DepositModalV2.scss'
import loadingImg from '../../assets//dong-loading/dong-loading.png'
import Box from '../../components/Box/Box'
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup'
import { copyToClipboard } from '../../utils/copy'
import CreditCard from '../../components/CreditCard/CreditCard'
import Login from '../Login/Login'

let myInterval = null
let timeoutId = null
let intervalCheckExpiredDepositId = null

function DepositModal({ isShow, onExit }) {
  const history = useHistory()
  const auth = useSelector(getAuth)
  const username = auth.username
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [depositUrl, setDepositUrl] = useState('')
  const [depositAmount, setDepositAmount] = useState('')
  const [isShowState, setIsShowState] = useState(isShow)
  const [isPaySuccess, setIsPaySuccess] = useState(false)
  const [playerAccountId, setPlayerAccountId] = useState('')
  const [availableBank, setAvailableBank] = useState([])
  const [selectedAvailableBankAccountNumber, setSelectedAvailableBankAccountNumber] = useState('')
  const [playerBankAccount, setPlayerBankAccount] = useState('')
  const [paymentUrl, setPaymentUrl] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [isLoadingSystemBank, setIsLoadingSystemBank] = useState(false)
  const [successDepositData, setSuccessDepositData] = useState(null)
  const [depositDate, setDepositDate] = useState(moment())
  const [depositDateFocus, setDepositDateFocus] = useState(false)
  const [depositTime, setDepositTime] = useState(moment().format('HH:mm'))
  const [showModal, setShowModal] = useState({ isShow: false, currentModal: '' })
  const [depositType, setDepositType] = useState('bookBank')
  const [imageQRCode, setImageQRCode] = useState(null)
  const [isLoadingQRCode, setLoadingQRCode] = useState(null)
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    setIsShowState(isShow)
    if (isShow) {
      setIsLoadingSystemBank(true)
      setImageQRCode(null)
      setLoadingQRCode(null)
      setAmount(0)
      setDepositType('bookBank')
      if (!username) {
        // Not login yet
        timeoutId = setTimeout(() => {
          Swal.fire({
            icon: 'error',
            title: 'กรุณาเข้าสู่ระบบ',
            text: 'กรุณาเข้าสู่ระบบก่อนดำเนินการต่อไป',
            showCancelButton: true,
            confirmButtonColor: '#B6964E',
            cancelButtonColor: '#C0C0C0',
            confirmButtonText: 'เข้าสู่ระบบ',
          }).then((result) => {
            if (result.value) {
              setShowModal({ isShow: true, currentModal: 'login' })
            }
          })
        }, 1500)
        setIsLoadingSystemBank(false)
      } else {
        clearTimeout(timeoutId)
        API.get('payment', `/bank-accounts`)
          .then((res) => {
            const allBank = _get(res, 'detail', null)
            const forSortBank = allBank.filter(
              (obj) =>
                obj.accountNumber !== '0684000309' &&
                obj.accountNumber !== '2152617967' &&
                obj.accountNumber !== '1702965691'
            )
            if (forSortBank && !_isEmpty(forSortBank)) {
              const availableBank = shuffleArray(forSortBank)
              console.log('availableBank >>>', availableBank)
              setAvailableBank(availableBank)

              const bankAccountNumber = _get(availableBank, '0.accountNumber', '')
              setSelectedAvailableBankAccountNumber(bankAccountNumber)
            }
          })
          .catch((err) => {
            console.log('error get available bank account ======')
            console.error(err)
          })
          .finally(() => {
            setIsLoadingSystemBank(false)
          })

        API.get('playerBankAccount', `/players/${username}/bank-account`)
          .then((res) => {
            const playerBankAccount = _get(res, 'detail', null)
            if (playerBankAccount && !_isEmpty(playerBankAccount)) {
              console.log('playerBankAccount >>>', playerBankAccount)
              setPlayerBankAccount(playerBankAccount)
            } else {
              alertGoToAddBankAccount('ไม่พบบัญชีธนาคารของคุณ กรุณาเพิ่มบัญชีธนาคารก่อน \nหรือกรุณาติดต่อเจ้าหน้าที่')
            }
          })
          .catch((err) => {
            console.log('error get bank account ======')
            console.error(err)
            alertGoToAddBankAccount(_get(err, 'message', ''))
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }, [isShow])

  useEffect(() => {
    if (isShowState) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      setIsSubmitting(false)
      setDepositAmount('')
      setDepositUrl('')
    }
  }, [isShowState])

  useEffect(() => {
    if (isPaySuccess) {
      // Close modal because this means the iframe redirects to our website
      clearInterval(myInterval)
      setIsSubmitting(false)
      setIsLoading(false)

      setTimeout(() => {
        setIsShowState(false)
        history.push('/transaction-list')
      }, 500)
    }
  }, [isPaySuccess])

  function shuffleArray(array) {
    let i = array.length
    while (i--) {
      const ri = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[ri]] = [array[ri], array[i]]
    }
    return array
  }

  const alertGoToAddBankAccount = (message = '') => {
    const isNotFoundBankAccount = message === 'Request failed with status code 400'
    Swal.fire({
      icon: 'error',
      title: isNotFoundBankAccount ? 'กรุณาเพิ่มบัญชีธนาคารครับ' : 'เกิดข้อผิดพลาด',
      text: isNotFoundBankAccount ? 'ไม่พบบัญชีธนาคารของคุณ' : message,
      confirmButtonColor: '#B6964E',
      confirmButtonText: 'ไปหน้าเพิ่มบัญชีธนาคาร',
      showCancelButton: true,
      cancelButtonColor: '#C0C0C0',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        history.push('/add-bank-account')
        handleExit()
      }
    })
  }

  const _onClickToCopy = (text = '') => {
    // const value = _get(
    //   _find(availableBank, (bank) => bank.accountNumber === selectedAvailableBankAccountNumber),
    //   'accountNumber'
    // )
    if (text) {
      copyToClipboard(text)
      Swal.fire({
        icon: 'success',
        title: 'คัดลอกสำเร็จ',
        text: text,
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ไม่พบข้อความสำหรับคัดลอก',
      })
    }
  }

  const _onAutoDepositAmountChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+$/g.test(value)) {
      setDepositAmount(value)
    }
  }

  const _onClickAutoDeposit = (e) => {
    e.preventDefault()
    const amountNumber = Number(depositAmount)
    // Validate
    if (!amountNumber || amountNumber < 1) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณาระบุจำนวนเงินด้วยตัวเลขขั้นต่ำ 1 บาท',
      })
      return
    }

    setIsLoading(true)

    API.post('thirdParty', `/auto-deposit/reserve`, {
      body: {
        amount: amountNumber,
      },
    })
      .then((res) => {
        const detail = _get(res, 'detail', null)
        if (!_isEmpty(detail)) {
          const { reserveAmount, id, expiredDate } = detail
          const expiredCheckTime = moment(expiredDate)
          let expiredText = moment(expiredDate).format('[ก่อนวันที่] DD/MM/YYYY [เวลา] HH:mm:ss')

          intervalCheckExpiredDepositId = setInterval(() => {
            const now = moment()
            console.log('-> now', now.format())
            console.log('expiredDate ======', expiredCheckTime)
            const isExpired = now.isAfter(expiredCheckTime)
            if (isExpired) {
              clearInterval(intervalCheckExpiredDepositId)
              intervalCheckExpiredDepositId = null
              setSuccessDepositData(null)
              setDepositAmount('')
            }
          }, 2000)

          setSuccessDepositData({ ...detail, expiredText })

          return Swal.fire({
            icon: 'success',
            title: 'ส่งคำสั่งฝากเงินสำเร็จ',
            text: `กรุณาโอนเงินจำนวน ${reserveAmount} บาท ภายใน ${expiredText}\nหมายเลขธุรกรรม: ${id})`,
          })
        } else {
          throw new Error('ระบบผิดพลาด ไม่พบหมายเลขธุรกรรม Transaction number')
        }
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message,
        })
      })
      .finally(() => {
        setIsLoading(false)
        setDepositAmount('')
      })
  }

  const _onClickAutoCancel = (e) => {
    e.preventDefault()

    setIsLoading(true)
    API.del('thirdParty', `/auto-deposit/cancel-reserve`, {})
      .then((res) => {
        console.log('cancel >>>', res)

        const code = _get(res, 'code', null)
        const detail = _get(res, 'detail', null)
        if (code === 0) {
          // const message = _get(detail, 'message', '')
          setSuccessDepositData(null)

          return Swal.fire({
            icon: 'success',
            title: 'ยกเลิกคำสั่งที่ค้างทั้งหมดสำเร็จ',
          })
        } else {
          throw new Error('ระบบผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
        }
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message + 'กรุณาติดต่อเจ้าหน้าที่',
        })
      })
      .finally(() => {
        setIsLoading(false)
        setDepositAmount('')
      })
  }

  const handleExit = () => {
    document.body.style.overflow = 'unset'
    onExit()
  }

  const _onClickConfirmDeposit = async () => {
    if (!Number(depositAmount) || Number(depositAmount) < 100) {
      Swal.fire({
        icon: 'error',
        title: 'กรุณาทำรายการอีกครั้ง',
        text: 'กรุณาระบุจำนวนเงินด้วยตัวเลขขั้นต่ำ 100 บาท',
      })
      return
    }

    if (!playerAccountId) {
      return Swal.fire({
        icon: 'error',
        title: 'ไม่พบบัญชีธนาคารของคุณ',
      })
    }

    // Create transaction and generate QR Code
    setIsLoading(true)

    API.post('payment', '/transactions', {
      body: {
        playerUsername: username,
        playerAccountId: playerAccountId,
        collectionAccountId: username,
        transactionType: 'deposit',
        amount: depositAmount,
      },
    })
      .then((res) => {
        const transactionNumber = _get(res, 'detail', null)
        if (transactionNumber) {
          return transactionNumber
        } else {
          throw new Error('Not found transaction number')
        }
      })
      .then(async (transactionNumber) => {
        try {
          const url = await ppthb888Deposit({
            playerUsername: username,
            amount: depositAmount,
            transactionNumber,
            remark: '',
          })
          setDepositUrl(url)
        } catch (err) {
          console.log(err)
          return Swal.fire({
            icon: 'error',
            title: 'ระบบไม่สามารถใช้งานได้ในขณะนี้',
            text: 'ขออภัยครับ ไม่สามารถฝากเงินด้วย QR ได้ในขณะนี้ กรุณาติดต่อเจ้าหน้าที่ครับ',
          })
        }

        // myTimeout = setTimeout(() => {
        //   document.getElementById('deposit-iframe').src = 'https://google.com'
        // }, 1000)
      })
      .then(() => {
        myInterval = setInterval(() => {
          try {
            const iframe = document.getElementById('deposit-iframe')
            const href = iframe.contentWindow.location.href
            const myOrigin = window.location.origin // e.g. "http://localhost:3000" or "https://dev.vava.dev"
            if (href.includes(myOrigin)) {
              // Payment is successful, so the iframe redirect to our website (within iframe), so we will close the modal and manually redirect in our website, not in the iframe/modal
              setIsPaySuccess(true)
            }

            console.log('>>>> href', iframe.contentWindow.location.href)
          } catch (error) {
            console.log('error iframe get href >>>', error)
          }
        }, 1500)
      })
      .catch((err) => {
        console.log('error deposit ======')
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.message,
          onClose: () => {
            setTimeout(() => {
              setIsShowState(false)
            }, 500)
          },
        })
      })
      .finally(() => {
        setIsSubmitting(true)
        setIsLoading(false)
      })
  }

  const _handleDepositAmountChange = (e) => {
    const value = e.target.value
    if (value === '' || /^\d+(\.\d{0,2})?$/g.test(value)) {
      setDepositAmount(value)
    }
  }

  const handleChangeDepositType = (e) => {
    const value = e.target.value
    setDepositType(value)
    setLoadingQRCode(false)
    setImageQRCode(null)
  }

  const handleAmount = (e) => {
    const value = e.target.value
    setAmount(value)
    setImageQRCode(null)
  }

  const handleGenerateQRCode = async () => {
    if (amount < 200) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกเงินขั้นต่ำที่ 200 บาท',
        confirmButtonColor: '#B6964E',
        cancelButtonColor: '#C0C0C0',
        confirmButtonText: 'ปิด',
      })
      return
    }
    setLoadingQRCode(true)
    setImageQRCode(null)
    API.get('playerBankAccount', `/players/${username}/bank-account`)
      .then((res) => {
        const playerBankAccount = _get(res, 'detail', null)
        if (playerBankAccount && !_isEmpty(playerBankAccount)) {
          console.log('playerBankAccount >>>', playerBankAccount)
          setPlayerBankAccount(playerBankAccount)
        } else {
          alertGoToAddBankAccount('ไม่พบบัญชีธนาคารของคุณ กรุณาเพิ่มบัญชีธนาคารก่อน \nหรือกรุณาติดต่อเจ้าหน้าที่')
        }

        const split = playerBankAccount.accountName.split(' ')
        let firstName = ''
        let lastName = ''
        if (split.length > 0) {
          firstName = split[0]
          lastName = split[1] ? split[1] : ''
        }

        API.post('thirdParty', '/amb/generateQR', {
          body: {
            player_username: username,
            amount: amount,
            payer_account_no: playerBankAccount.accountNumber,
            payer_account_first_name: firstName,
            payer_account_last_name: lastName,
            bank_name: playerBankAccount.bankCode,
          },
        })
          .then((res) => {
            try {
              setLoadingQRCode(false)
              setImageQRCode(res.detail.value)
            } catch (error) {
              console.log('error => ', error)
            }
          })
          .catch((err) => {
            setLoadingQRCode(false)
            console.log('error => ', err)
            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถสร้าง QR Code ได้',
              text: 'กรุณาสร้าง QR Code ใหม่อีกครั้ง',
              confirmButtonColor: '#B6964E',
              cancelButtonColor: '#C0C0C0',
              confirmButtonText: 'ปิด',
            })
          })
      })
      .catch((err) => {
        console.log('error get bank account ======')
        console.error(err)
        alertGoToAddBankAccount(_get(err, 'message', ''))
        setLoadingQRCode(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return isShowState ? (
    <div className='deposit_modal cm_modal_wrapper'>
      <div
        className='cm_modal_header cm_modal_header_v2 text-xl'
        // style={{ marginTop: `${_get(playerBankAccount, 'bankCode', null) === 'SCB' ? '' : '40px'}` }}
      >
        <Box containerClass='deposit_modal__buttonBox'>ฝากเงิน</Box>
        <button className='cm_modal_exit cm_modal_exit_v2' onClick={() => handleExit()}>
          <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
            <path
              d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z'
              fill='#ffffff'
            />
          </svg>
        </button>
      </div>

      {!isLoadingSystemBank && playerBankAccount ? <CreditCard /> : null}

      <div className='cm_modal_content cm_modal_content_v2'>
        {_get(playerBankAccount, 'bankCode', null) === 'SCB' && !successDepositData ? (
          <Box containerClass='deposit__stepBox'>
            <span className='deposit__step'>ขั้นตอนที่ 1</span>
            <form>
              <p>ระบุจำนวนเงินที่ต้องการโอน</p>
              <input
                type='text'
                name='deposit-value'
                id='deposit-value'
                className='deposit_amount_input'
                placeholder='ขั้นต่ำ 1 บาท'
                value={depositAmount}
                onChange={_onAutoDepositAmountChange}
              />
            </form>
          </Box>
        ) : _get(playerBankAccount, 'bankCode', null) === 'SCB' ? (
          <Box containerClass='deposit__stepBox'>
            <span className='deposit__step'>ขั้นตอนที่ 1</span>
            <p>
              กรุณาโอนเงิน{' '}
              <strong style={{ color: 'red', fontSize: '30px' }}>{successDepositData.reserveAmount}</strong> บาท{' '}
              <button onClick={() => _onClickToCopy(successDepositData.reserveAmount)}>
                <span>คัดลอก</span>
              </button>
              <br />
              ภายใน 10 นาที ({successDepositData.expiredText})
              <br />
              <span style={{ color: 'red' }}> **เมื่อโอนเศษทศนิยมถูกต้อง เครดิตจะเข้าทันทีครับ**</span>
            </p>
          </Box>
        ) : (
          <Box containerClass='deposit__stepBox'>
            <span className='deposit__step'>ขั้นตอนที่ 1</span>
            {!isLoadingSystemBank && playerBankAccount ? (
              <>
                <p>เลือก 1 บัญชีเพื่อทำการโอนเงิน</p>

                {availableBank.map((bankData, i) => {
                  return (
                    <div key={bankData.bankCode + i}>
                      <div className='deposit__bankDetail'>
                        {bankData.bankCode === 'SCB' ? (
                          <img src={require('../../pages/Deposit/images/scb-logo.png')} alt='scb-logo' />
                        ) : (
                          <img src={require('../../pages/Deposit/images/scb-logo.png')} alt='bank-logo' />
                        )}
                        <div className='deposit__bankDetail_user'>
                          <p className='deposit__bankDetail_accountNumber'>
                            {/* {bank.bankCode} {bank.bankName} - {bank.accountNumber} */}
                            {!isLoadingSystemBank ? bankData.accountNumber : 'กำลังโหลด...'}
                          </p>
                          <div className='deposit__bankDetail_userInfo'>
                            <p>{!isLoadingSystemBank ? bankData.accountName : 'กำลังโหลด...'}</p>
                            <button onClick={() => _onClickToCopy(bankData.accountNumber)}>
                              <span>คัดลอก</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : !isLoadingSystemBank && !playerBankAccount ? (
              <p>
                กรุณา
                <Link to='/add-bank-account' style={{ color: '#5fc22f' }}>
                  เพิ่มบัญชีธนาคาร
                </Link>
                ของท่าน ก่อนดำเนินการต่อไปครับ
              </p>
            ) : isLoadingSystemBank ? (
              <span>กำลังโหลด...</span>
            ) : null}
          </Box>
        )}

        {successDepositData ? (
          <Box containerClass='deposit__stepBox'>
            <span className='deposit__step'>ขั้นตอนที่ 2</span>
            <p>เลือก 1 บัญชีและทำการโอนเงิน</p>
            {!isLoadingSystemBank && playerBankAccount ? (
              availableBank.map((bankData, i) => {
                return (
                  <div key={bankData.bankCode + i}>
                    <div className='deposit__bankDetail'>
                      {bankData.bankCode === 'SCB' ? (
                        <img src={require('../../pages/Deposit/images/scb-logo.png')} alt='scb-logo' />
                      ) : (
                        <img src={require('../../pages/Deposit/images/scb-logo.png')} alt='bank-logo' />
                      )}
                      <div className='deposit__bankDetail_user'>
                        <p className='deposit__bankDetail_accountNumber'>
                          {/* {bank.bankCode} {bank.bankName} - {bank.accountNumber} */}
                          {!isLoadingSystemBank ? bankData.accountNumber : 'กำลังโหลด...'}
                        </p>
                        <div className='deposit__bankDetail_userInfo'>
                          <p>{!isLoadingSystemBank ? bankData.accountName : 'กำลังโหลด...'}</p>
                          <button onClick={() => _onClickToCopy(bankData.accountNumber)}>
                            <span>คัดลอก</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : !isLoadingSystemBank && !playerBankAccount ? (
              <p>
                กรุณา
                <Link to='/add-bank-account' style={{ color: '#5fc22f' }}>
                  เพิ่มบัญชีธนาคาร
                </Link>
                ของท่าน ก่อนดำเนินการต่อไปครับ
              </p>
            ) : isLoadingSystemBank ? (
              <span>กำลังโหลด...</span>
            ) : null}
          </Box>
        ) : _get(playerBankAccount, 'bankCode', null) === 'SCB' ? null : (
          <Box containerClass='deposit__stepBox'>
            <span className='deposit__step'>ขั้นตอนที่ 2</span>
            <p style={{ marginBottom: '5px' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;กรุณาโอนเงินจากบัญชีที่ท่านได้ลงทะเบียนไว้เท่านั้น สู่บัญชีใดบัญชีหนึ่งข้างต้น
              ระบบจะเติมเครดิตให้ท่านโดยอัตโนมัติ
            </p>
            <p style={{ color: 'grey', fontSize: '13px', textAlign: 'center' }}>
              การฝากเงินไม่มีขั้นต่ำ ท่านสามารถทดสอบระบบด้วยการโอน 1 บาท หากท่านต้องการ
            </p>
          </Box>
        )}

        {_get(playerBankAccount, 'bankCode', null) === 'SCB' && !successDepositData ? (
          <ButtonGroup
            onClickFirstButton={_onClickAutoCancel}
            onClickSecondButton={_onClickAutoDeposit}
            withBorder
            firstText='ยกเลิก'
            firstTextStyle={{ fontSize: '16px' }}
            secondText='ฝากเงิน'
            containerClass='buttonGroupDeposit'
            disabled={isLoading}
            disabledSecond={isLoading || successDepositData}
          />
        ) : null}
      </div>
      {!auth.username ? <Login showModal={showModal} setShowModal={setShowModal} /> : null}
    </div>
  ) : null
}

DepositModal.propTypes = {
  isShow: PropTypes.bool,
  onExit: PropTypes.func,
}

DepositModal.defaultProps = {
  isShow: false,
  onExit: () => {},
}

export default DepositModal
