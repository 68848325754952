import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import { clearAuth } from '../../redux/actions/auth'

export default function Logout() {
  const dispatch = useDispatch()

  useEffect(() => {
    Auth.signOut()
    dispatch(clearAuth())
  }, [])

  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/logout' },
      }}
    />
  )
}
