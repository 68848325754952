import React from 'react'

import Box from '../../components/Box/Box'
import BoxWhite from '../../components/Box/BoxWhite'
import Button from '../../components/Button/Button'

import './Tutorial.scss'

const Tutorial = () => {
  const data = [
    {
      title: 'วิธีแทงหวย และ ยิงเลข ยี่กี',
      content: 'https://www.youtube.com/embed/Sb7GSSQEBm0',
    },
    {
      title: 'วิธีการแทงหวย และ ดึงโพย',
      content: null,
    },
    {
      title: 'วิธีจัดเลขชุด แทงหวยออนไลน์',
      content: null,
    },
    {
      title: 'วิธีติดต่อ เว็บแทงหวยออนไลน์',
      content: null,
    },
    {
      title: 'วิธีแจ้งถอนเงินเข้าบัญชี ภายใน 5 นาที',
      content: null,
    },
    {
      title: 'แนะนำเพื่อนรับส่วนแบ่ง 8% จากทุกยอดแทง',
      content: null,
    },
    {
      title: 'ฝากเงินเข้าเล่นหวยออนไลน์',
      content: null,
    },
  ]

  return (
    <section className='tutorial'>
      <Box containerClass='tutorial__title'>วิธีใช้งาน</Box>
      {data.map(({ title, content }) => {
        return (
          <BoxWhite key={title} containerClass='tutorial__boxContainer'>
            <Button
              wrapperClassName='tutorial__buttonWrapper'
              buttonClassName='tutorial__buttonTopic'
              text={title}
              shouldNotShowCursor
            />
            {content && (
              <iframe
                src={content}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            )}
          </BoxWhite>
        )
      })}
    </section>
  )
}

export default Tutorial
