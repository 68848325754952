import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { API } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'
import _get from 'lodash/get'
import numeral from 'numeral'

import { getReceipts } from '../../redux/selectors/receipts'
import { getAuth } from '../../redux/selectors/auth'
import Button from '../../components/Button/Button'
import BoxWhite from '../../components/Box/BoxWhite'
import { getReceiptStatusBackgroundColor, getReceiptStatusText } from '../../utils/receiptUtil'
import { setWalletCredit } from '../../redux/actions/wallet'
import { getStorage } from '../../config/cognito'
import iconmonstrArrow65 from '../../assets/svg/iconmonstr-arrow-65.svg'
import { STOCK_LIST } from '../../utils/stockList'
import shine from '../../assets/shine.png'
import './DetailReceipt.scss'
import dong from '../../assets/flags/dong.png'
import { lottoType } from '../../utils/lottoList'
import { lottoType_TH } from '../../utils/lottoList'
import { getLang } from '../../redux/selectors/lang'

const colorCode = {
  lottoGreen: '#1FAC4D',
  lottoGold: '#CFBF91',
  lottoBlue: '#757ebe',
  lottoRed: '#C0534D',
  lottoDarkGreen: '#9dc14a',
  lottoGray: '#768087',
  lottoYellow: '#d4af37',
  lottoBlack: '#000000',
}

function DetailReceipt({ props, match, location, history }) {
  const { receiptNumber } = match.params
  const auth = useSelector(getAuth)
  const dispatch = useDispatch()
  const walletCredit = useSelector((state) => _get(state, 'wallet.credit', null))
  console.log('>>> -> walletCredit', walletCredit)
  const { receipts } = useSelector(getReceipts)

  const [fetchedReceipt, setFetchedReceipt] = useState({})
  const [receiptDrawTime, setReceiptDrawTime] = useState('')
  const [resultRef, setResultRef] = useState({})
  const [groupPurchaseItems, setGroupPurchaseItems] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isCreatedReceipt, setIsCreatedReceipt] = useState(false)
  const [isConfirmedReceipt, setIsConfirmedReceipt] = useState(false)
  const storage = getStorage()
  const lang = useSelector(getLang)
  const language = lang.lang
  const [isCollapse, setIsCollapse] = useState({
    '2A': false,
    '2B': false,
    '2R': false,
    '3A': false,
    '3R': false,
    '3F': false,
    '3B': false,
    SA: false,
    SB: false,
  })

  const sortingScore = {
    '2A': 1,
    '2B': 2,
    '2R': 3,
    '3A': 4,
    '3R': 5,
    '3F': 6,
    '3B': 7,
    SA: 8,
    SB: 9,
    '1P': 10,
    '1S': 11,
  }
  // const lottoType = {
  //   // '2A': 'สองตัวบน',
  //   // '2B': 'สองตัวล่าง',
  //   // '3A': 'สามตัวบน',
  //   // '3R': 'สามตัวโต๊ด',
  //   // '3F': 'สามตัวหน้า',
  //   // '3B': 'สามตัวล่าง',
  //   // SA: 'เลขวิ่งบน',
  //   // SB: 'เลขวิ่งล่าง',

  //   '2A': '၂လုံးပေါ်',
  //   '2B': '၂လုံးအောက်',
  //   '3A': '၃ လုံးပေါ်',
  //   '3R': '၃လုံးပြောင်းပြန်',
  //   '3F': 'ရှေ့၃လုံး',
  //   '3B': '၃လုံးအောက်',
  //   SA: 'အပေါ်ပြေး',
  //   SB: 'အောက်ပြေး',
  // }

  const fetchWallet = async () => {
    return await API.get('playerWallet', `/players/${auth.username}/wallets/0`)
      .then((res) => {
        const credit = _get(res, 'detail.credit', 0)
        dispatch(
          setWalletCredit({
            wallet: {
              credit,
            },
          })
        )
        return credit
      })
      .catch((err) => {
        console.log('error get playerWallet from noti ======', err)
        console.error(err)
      })
  }

  useEffect(() => {
    fetchWallet()

    const isCreatedReceipt = fetchedReceipt.status === 'created'

    setIsCreatedReceipt(isCreatedReceipt)
  }, [fetchedReceipt])

  useEffect(() => {
    fetchWallet()

    const isConfirmedReceipt = fetchedReceipt.status === 'confirm'

    setIsConfirmedReceipt(isConfirmedReceipt)
  }, [fetchedReceipt])

  useEffect(() => {
    setIsLoading(true)

    API.get('purchase', `/receipts/${receiptNumber}`)
      .then((res) => {
        const receipt = _.get(res, 'detail', {})
        API.get('lotto', `/results?lottoNumber=${receipt.refId}`)
          .then((res) => {
            const result = _.get(res, 'detail', {})
            if (result.length !== 0) {
              setResultRef(result[0].results)
            }
          })
          .catch((err) => {
            console.log('error ======', err)
            console.error(err)
          })
        API.get('lotto', `/lottos/${receipt.refId}`).then((res) => {
          const drawDateTime = res.detail.drawTimeStr
          const getDrawTime = drawDateTime.slice(0, 5)
          const isOpen = res.detail.status === 'open'
          setIsOpen(isOpen)
          setReceiptDrawTime(getDrawTime)
        })
        setFetchedReceipt(receipt)
        const sortPurchaseItems = _.sortBy(receipt.purchaseItems, (item) => sortingScore[item.type])
        const purchaseItemsGroupByType = _.groupBy(sortPurchaseItems, 'type')
        // console.log(purchaseItemsGroupByType, 'purchase')
        setGroupPurchaseItems(purchaseItemsGroupByType || {})
        // setTwoA(receipt.purchaseItems.filter((obj) => obj.type === '2A') || [])
        // setTwoB(receipt.purchaseItems.filter((obj) => obj.type === '2B') || [])
        // setThreeA(receipt.purchaseItems.filter((obj) => obj.type === '3A') || [])
        // setThreeR(receipt.purchaseItems.filter((obj) => obj.type === '3R') || [])
        // if (receipt.receiptDetails.lottoName === 'หวยรัฐบาลไทย') {
        //   setThreeF(receipt.purchaseItems.filter((obj) => obj.type === '3F') || [])
        //   setThreeB(receipt.purchaseItems.filter((obj) => obj.type === '3B') || [])
        // }
        // setRunA(receipt.purchaseItems.filter((obj) => obj.type === 'SA') || [])
        // setRunB(receipt.purchaseItems.filter((obj) => obj.type === 'SB') || [])
        console.log('fetchedReceipt >>>', res)
      })
      .catch((err) => {
        console.log('error ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })

    return () => {}
  }, [])

  // const getLottoName = (lottoId = '') => {
  //   let lottoName = 'loading...'
  //   API.get('lotto', `/lottos/${lottoId}`)
  //     .then(({ detail }) => {
  //       console.log(detail)
  //     })
  //     .catch((err) => console.error(err))
  //   return lottoName
  // }

  const getTotalReceiptAmount = () => {
    let total = 0
    const purchaseItems = fetchedReceipt.purchaseItems
    if (purchaseItems) {
      purchaseItems.forEach((element) => {
        total += element.amount
      })
    }
    return total
  }

  const _onClickConfirmPurchaseButton = async () => {
    const walletBalance = await fetchWallet()
    setIsLoading(true)

    const totalReceiptAmount = Math.round((getTotalReceiptAmount() + Number.EPSILON) * 100) / 100

    if (walletBalance >= totalReceiptAmount) {
      API.post('purchase', `/receipts/${receiptNumber}/confirm`)
        .then((res) => {
          if (res.code === 0) {
            Swal.fire({
              // imageUrl: `https://${storage}.s3-ap-southeast-1.amazonaws.com/thank-st.png`,
              title: 'ซื้อสำเร็จ',
              text: `${fetchedReceipt.receiptDetail.lottoName} ทั้งหมด ${numeral(totalReceiptAmount).format(
                '0,0.00'
              )} บาทครับ`,
              showCancelButton: true,
              confirmButtonColor: '#B6964E',
              confirmButtonText: 'ไปหน้าแทงหวย',
              cancelButtonColor: '#C0C0C0',
              cancelButtonText: 'ตรวจสอบโพย',
            }).then((result) => {
              if (result.isConfirmed) {
                history.push('/lottos')
              } else {
                history.push(`/receipts/${receiptNumber}`)
              }
              setTimeout(() => {
                API.get('purchase', `/receipts/${receiptNumber}`).then((res) => {
                  if (res.code === 0) {
                    if (res.detail.status === 'rejected') {
                      Swal.fire({
                        icon: 'error',
                        // title: 'โพยของท่านถูกปฏิเสธ',
                        // text: 'ท่านยืนยันการซื้อเกินเวลาที่กำหนดครับ ท่านไม่เสียเครดิตจากการทำรายการนี้ครับ',
                        title: 'Your receipt was rejected',
                        text: 'Time out. You will not lose credit',
                      })
                      return
                    }
                    if (res.detail.status === 'error') {
                      Swal.fire({
                        icon: 'error',
                        // title: 'โพยของท่านล้มเหลว',
                        // text: 'กรุณาทำรายการใหม่อีกครั้งครับ',
                        title: 'Your order failed',
                        text: 'Please try again',
                      })
                    }
                  }
                })
              }, 2001)
            })
          } else {
            Swal.fire({
              icon: 'error',
              // title: 'เกิดข้อผิดพลาด',
              // text: res.detail || 'โปรดติดต่อเจ้าหน้าที่',
              title: 'Error',
              text: res.detail || 'Please contact admin',
            })
          }
        })
        .catch((err) => {
          console.log('error _onClickConfirmPurchaseButton ======', err)
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      Swal.fire({
        icon: 'error',
        // title: 'ยอดเงินของคุณไม่เพียงพอ',
        title: 'Insufficient fund',

        // confirmButtonText: 'ฝากเงิน',
        confirmButtonText: 'Deposit',

        //   text: `ยอดเงินที่คงเหลือได้ (${numeral(walletBalance).format(
        //     '0,0.00'
        //   )} บาท), ยอดเงินที่จะเล่น ${totalReceiptAmount}`,
        // }).then((result) => {
        //   if (result.value) {
        //     history.push('/deposit')
        //   }
        // })
        text: `Your remaining credit (${numeral(walletBalance).format('0,0.00')} Baht), amount ${totalReceiptAmount}`,
      }).then((result) => {
        if (result.value) {
          history.push('/deposit')
        }
      })
    }
  }
  const _onClickCancelPurchaseButton = () => {
    setIsLoading(true)

    API.post('purchase', `/receipts/${receiptNumber}/cancel`)
      .then((res) => {
        if (res.code === 0) {
          // Success
          Swal.fire({
            icon: 'success',
            // title: 'ยกเลิกคำสั่งสำเร็จ',
            title: 'Cancel Success',

            showCancelButton: true,
            confirmButtonColor: '#B6964E',
            // confirmButtonText: 'ไปหน้าแทงหวย',
            confirmButtonText: `${language !== 'MM' ? 'กลับหน้าแทง' : 'Back to Lottos'}`,

            cancelButtonColor: '#C0C0C0',
            // cancelButtonText: 'ตรวจโพยอื่นๆ',
            cancelButtonText: `${language !== 'MM' ? 'ตรวจสอบโพย' : 'Check your receipt'}`,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push('/lottos')
            } else {
              history.push(`/receipts`)
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'เกิดข้อผิดพลาด',
            // text: res.detail || 'โปรดติดต่อเจ้าหน้าที่',
            title: 'Error',
            text: res.detail || 'Please contact admin',
          })
        }
      })
      .catch((err) => {
        console.log('error _onClickCancelPurchaseButton ======', err)
        console.error(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const _onClickRefundPurchaseButton = (refId) => {
    setIsLoading(true)
    API.get('lotto', `/lottos/${refId}`).then((res) => {
      const isLottoOpen = res.detail.status === 'open'
      if (!isLottoOpen) {
        Swal.fire({
          icon: 'error',
          // title: 'ท่านไม่ได้ยกเลิกโพยในระยะเวลาที่กำหนดครับ',
          title: 'Time out',
        })
      } else {
        API.post('purchase', `/receipts/${receiptNumber}/reject`)
          .then((res) => {
            if (res.code === 0) {
              // Success
              Swal.fire({
                icon: 'success',
                // title: 'ยกเลิกโพยสำเร็จแล้วครับ',
                title: 'Cancel Success',

                showCancelButton: true,
                confirmButtonColor: '#B6964E',
                // confirmButtonText: 'ไปหน้าแทงหวย',
                confirmButtonText: `${language !== 'MM' ? 'กลับหน้าแทง' : 'Back to Lottos'}`,

                cancelButtonColor: '#C0C0C0',
                // cancelButtonText: 'ตรวจโพยอื่นๆ',
                cancelButtonText: `${language !== 'MM' ? 'ตรวจสอบโพย' : 'Check your receipt'}`,
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push('/lottos')
                } else {
                  history.push(`/receipts`)
                }
              })
            } else {
              Swal.fire({
                icon: 'error',
                // title: 'เกิดข้อผิดพลาด',
                // text: 'โปรดติดต่อเจ้าหน้าที่',
                title: 'Error',
                text: res.detail || 'Please contact admin',
              })
            }
          })
          .catch((err) => {
            console.log('error _onClickRefundPurchaseButton ======', err)
            console.error(err)
            Swal.fire({
              icon: 'error',
              // title: 'เกิดข้อผิดพลาด',
              // text: 'โปรดติดต่อเจ้าหน้าที่',
              title: 'Error',
              text: res.detail || 'Please contact admin',
            })
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    })
  }

  const getItemStatusText = (receipt) => {
    const status = fetchedReceipt.status
    const prizeItems = fetchedReceipt.prizeItems
    const foundPrizeItem = prizeItems.find((prize) => prize.detail === receipt.detail && prize.type === receipt.type)
    if (status === 'created') {
      return 'กรุณายืนยัน'
    }
    if (status === 'confirm') {
      return 'รอออกผล'
    }
    if (status === 'cancelled') {
      return 'ยกเลิกแล้ว'
    }
    if (status === 'rewarded') {
      if (foundPrizeItem) {
        return 'ถูกรางวัล'
      }
      return 'ไม่ถูกรางวัล'
    }
    if (status === 'rejected') {
      return 'ถูกปฏิเสธ'
    }
    if (status === 'error') {
      return 'ล้มเหลว'
    }
    if (status === 'timeout') {
      return 'หมดเวลา'
    }

    return 'ไม่ทราบสถานะ'
  }

  const groupTypePurchase = []

  const getItemProfitAmount = (receipt) => {
    const prizeItems = fetchedReceipt.prizeItems
    if (_.isEmpty(prizeItems)) {
      return '0'
    }

    const foundPrizeItem = prizeItems.find((prize) => prize.detail === receipt.detail && prize.type === receipt.type)

    if (foundPrizeItem) {
      return foundPrizeItem.amount
    }

    return '0'
  }

  _.forIn(groupPurchaseItems, (value, type) =>
    groupTypePurchase.push(
      <BoxWhite containerClass='detailReceipts__detailsBox' key={type}>
        <div className='detailReceipts__detailsBox-detail-wrapper'>
          <div className='allReceipts__detailsLotto-firstLine'>
            <img
              className='allReceipts__detailsLotto-firstLine-flag '
              src={
                _.get(fetchedReceipt, 'receiptDetail.lottoGroup', '') === 'หวยยี่กี'
                  ? dong
                  : STOCK_LIST.find((o) => o.title === _.get(fetchedReceipt, 'receiptDetail.lottoName', ''))?.src ||
                    dong
              }
              alt='flags'
            />
            <span className='allReceipts__detailsLotto-firstLine-name'>{language === 'MM' ? lottoType[type] : lottoType_TH[type] }</span>
            <span className='allReceipts__detailsLotto-firstLine-result'>
              {resultRef
                ? type === '3A' || type === '3R' || type === 'SA'
                  ? resultRef['3A']
                  : type === '2A'
                  ? resultRef['2A']
                  : type === '2B' || type === 'SB'
                  ? resultRef['2B']
                  : type === '3F' && resultRef['3F1']
                  ? `${resultRef['3F1']}, ${resultRef['3F2']}`
                  : type === '3B' && resultRef['3B1']
                  ? `${resultRef['3B1']}, ${resultRef['3B2']}`
                  : 'XX'
                : 'XX'}
            </span>
            <div
              className={`allReceipts__detailsLotto-firstLine-toggle ${isCollapse[type] && 'active'}`}
              onClick={() => setIsCollapse({ ...isCollapse, [type]: !isCollapse[type] })}
            >
              <img src={iconmonstrArrow65} alt='V' />
            </div>
          </div>
          {groupPurchaseItems[type]
            .map((receipt, index) => {
              const detailStatus = getItemStatusText(receipt)
              const colorStatus =
                detailStatus === 'รอออกผล'
                  ? colorCode.lottoGreen
                  : detailStatus === 'ไม่ถูกรางวัล'
                  ? colorCode.lottoRed
                  : detailStatus === 'ถูกรางวัล'
                  ? colorCode.lottoYellow
                  : colorCode.lottoGray
              const isHide = isCollapse[type] && detailStatus !== 'ถูกรางวัล'

              const engStatus =
                detailStatus === 'รอออกผล'
                  ? 'Received'
                  : detailStatus === 'ไม่ถูกรางวัล'
                  ? 'Lose'
                  : detailStatus === 'ถูกรางวัล'
                  ? 'Win'
                  : detailStatus === 'ถูกปฏิเสธ'
                  ? 'Rejected'
                  : detailStatus === 'ล้มเหลว'
                  ? 'Failure'
                  : detailStatus === 'หมดเวลา'
                  ? 'Time out'
                  : detailStatus

              return (
                <div
                  className={`detailReceipts__detailsBox-purchaseDetails ${isHide ? 'hide' : ''}`}
                  key={index}
                  style={{
                    // borderBottom: isHide ? '' : '2px solid #dddddd',
                    fontWeight: detailStatus === 'ถูกรางวัล' ? 'bold' : '',
                  }}
                >
                  <div
                    className='detailReceipts__detailsBox-purchaseDetails-detail'
                    style={{
                      color: colorStatus,
                      borderRadius: '10px',
                      border:
                        detailStatus === 'ถูกรางวัล'
                          ? `2px solid ${colorCode.lottoYellow}`
                          : `1px solid ${colorStatus}`,
                    }}
                  >
                    <span className='detailReceipts__detailsBox-purchaseDetails-detail-status'>{engStatus}</span>
                    <span className='detailReceipts__detailsBox-purchaseDetails-detail-number'>{receipt.detail}</span>
                  </div>
                  <div
                    className='detailReceipts__detailsBox-purchaseDetails-amount'
                    style={{
                      border: detailStatus === 'ถูกรางวัล' ? `2px solid ${colorCode.lottoYellow}` : '',
                    }}
                  >
                    <span
                      className='detailReceipts__detailsBox-purchaseDetails-amount-title'
                      style={{
                        color: detailStatus === 'ถูกรางวัล' ? 'black' : '',
                      }}
                    >
                      {/* ราคาแทง */}
                      {language !== 'MM' ? 'ราคาแทง' : 'Amount'}
                    </span>
                    <span
                      className='detailReceipts__detailsBox-purchaseDetails-amount-detail'
                      style={{
                        color: detailStatus === 'ถูกรางวัล' ? 'black' : '',
                      }}
                    >
                      {Math.round((receipt.amount + Number.EPSILON) * 100) / 100}
                    </span>
                  </div>
                  <div className='detailReceipts__detailsBox-purchaseDetails-reward'>
                    <div
                      className='detailReceipts__detailsBox-purchaseDetails-reward-pay'
                      style={{
                        border: detailStatus === 'ถูกรางวัล' ? `2px solid ${colorCode.lottoYellow}` : '',
                      }}
                    >
                      <span
                        className='detailReceipts__detailsBox-purchaseDetails-reward-pay-title'
                        style={{
                          color: detailStatus === 'ถูกรางวัล' ? 'black' : '',
                        }}
                      >
                        {/* ราคาจ่าย */}
                        {language !== 'MM' ? 'ราคาจ่าย' : 'Reward'}
                      </span>
                      <span
                        className='detailReceipts__detailsBox-purchaseDetails-reward-pay-detail'
                        style={{
                          color: detailStatus === 'ถูกรางวัล' ? colorCode.lottoYellow : '',
                        }}
                      >
                        {Math.round((receipt.amount * receipt.payRate + Number.EPSILON) * 100) / 100}
                      </span>
                    </div>
                    <div
                      className='detailReceipts__detailsBox-purchaseDetails-reward-prized'
                      style={{
                        border: detailStatus === 'ถูกรางวัล' ? `2px solid ${colorCode.lottoYellow}` : '',
                      }}
                    >
                      <span
                        className='detailReceipts__detailsBox-purchaseDetails-reward-prized-title'
                        style={{
                          color: detailStatus === 'ถูกรางวัล' ? 'black' : '',
                        }}
                      >
                        {/* ยอดถูก */}
                        {language !== 'MM' ? 'ยอดถูก' : 'Your win'}
                      </span>
                      <span
                        className='detailReceipts__detailsBox-purchaseDetails-reward-prized-detail'
                        style={{
                          color: detailStatus === 'ถูกรางวัล' ? colorCode.lottoYellow : colorCode.lottoRed,
                        }}
                      >
                        {Math.round((getItemProfitAmount(receipt) + Number.EPSILON) * 100) / 100}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })
            .sort(function (a, b) {
              return (
                // ['ถูกรางวัล', 'ไม่ถูกรางวัล'].indexOf(a.props.children[0].props.children[0].props.children) -
                // ['ถูกรางวัล', 'ไม่ถูกรางวัล'].indexOf(b.props.children[0].props.children[0].props.children)

                ['Win', 'Lose'].indexOf(a.props.children[0].props.children[0].props.children) -
                ['Win', 'Lose'].indexOf(b.props.children[0].props.children[0].props.children)
              )
            })}
        </div>
      </BoxWhite>
    )
  )

  return (
    <div className='detailReceipts single-receipt p-4 flex flex-col items-center mx-auto mt-20'>
      {/* SECTION 1: PAGE HEADER */}
      <section className='detailReceipts__headingBlock'>
        <Button
          wrapperClassName='detailReceipts__headingBlock-title'
          buttonClassName='detailReceipts__headingBlock-button'
          shouldNotShowCursor
          // text='รายละเอียด'
          text='This Receipt'
          buttonBackground={colorCode.lottoRed}
        />
        {
          isCreatedReceipt ? (
            <div className='receipts-confirmation-sections text-center mt-10'>
              <button
                className={`confirm_button __green px-10 py-2 btn-bevel rounded-full font-bold ${
                  isLoading ? 'disabled' : ''
                }`}
                onClick={_onClickConfirmPurchaseButton}
              >
                {/* ยืนยันการซื้อ */}
                {language !== 'MM' ? 'ยืนยัน' : 'Confirm'}
              </button>
              <button
                className={`confirm_button __red px-10 py-2 btn-bevel rounded-full font-bold ${
                  isLoading ? 'disabled' : ''
                }`}
                onClick={_onClickCancelPurchaseButton}
              >
                {/* ยกเลิกการซื้อ */}
                {language !== 'MM' ? 'ยกเลิก' : 'Cancel'}
              </button>
            </div>
          ) : isOpen && isConfirmedReceipt && !_.includes(_.get(fetchedReceipt, 'receiptDetail.remark', ''), 'Box') ? (
            <div className='receipts-confirmation-sections text-center mt-10'>
              <button
                className={`confirm_button __red px-10 py-2 btn-bevel rounded-full font-bold ${
                  isLoading ? 'disabled' : ''
                }`}
                onClick={() => _onClickRefundPurchaseButton(fetchedReceipt.refId)}
              >
                {/* ยกเลิกโพย */}
                {language !== 'MM' ? 'ยกเลิกโพย' : 'Cancel receipt'}
              </button>
            </div>
          ) : null
          // <Button
          //   wrapperClassName='detailReceipts__headingBlock-returnButton'
          //   buttonClassName='detailReceipts__headingBlock-button'
          //   text='คืนโพย'
          //   buttonBackground={colorCode.lottoRed}
          // />
        }
      </section>

      {/* SECTION 2: RECEIPT HEADER */}
      {/* <BoxWhite containerClass='detailReceipts__resultBox'> */}
      <div className='detailReceipts__resultBox'>
        <div className='detailReceipts__resultBox-detail-wrapper'>
          <div className='allReceipts__selectedLotto-firstLine'>
            {/*<img className='allReceipts__selectedLotto-firstLine-shine ' src={shine} alt='shine' />*/}
            <img
              className='allReceipts__selectedLotto-firstLine-flag '
              src={
                _.get(fetchedReceipt, 'receiptDetail.lottoGroup', '') === 'หวยยี่กี'
                  ? dong
                  : STOCK_LIST.find((o) => o.title === _.get(fetchedReceipt, 'receiptDetail.lottoName', ''))?.src ||
                    dong
              }
              alt='flags'
            />
            <span className='allReceipts__selectedLotto-firstLine-name'>
              {_.get(fetchedReceipt, 'receiptDetail.lottoName', '')}
            </span>
            <div
              className='allReceipts__selectedLotto-firstLine-status'
              style={
                _.get(fetchedReceipt, 'status', '') === 'rewarded' && _.get(fetchedReceipt, 'prizeAmount', '') > 0
                  ? {
                      color: colorCode.lottoYellow,
                      border: `2px solid ${colorCode.lottoYellow}`,
                    }
                  : {
                      color: getReceiptStatusBackgroundColor(_.get(fetchedReceipt, 'status', '')),
                      border: `2px solid ${getReceiptStatusBackgroundColor(_.get(fetchedReceipt, 'status', ''))}`,
                    }
              }
            >
              {_.get(fetchedReceipt, 'status', '') === 'rewarded' && _.get(fetchedReceipt, 'prizeAmount', '') > 0
                ? 'ถูกรางวัล'
                : getReceiptStatusText(_.get(fetchedReceipt, 'status', ''))}
            </div>
            <br />
            <span className='allReceipts__selectedLotto-firstLine-index'>{`โพยเลขที่ #${_.get(
              fetchedReceipt,
              'receiptNumber',
              ''
            )}`}</span>
          </div>
          <div className='detailReceipts__resultBox-detail detailReceipts__resultBox-firstLine'>
            <div className='allReceipts__selectedLotto-thirdLine-left' style={{ marginLeft: '20px' }}>
              <span className='allReceipts__selectedLotto-thirdLine-left-calendar' style={{ color: 'black' }}>
                {moment(fetchedReceipt.createdDate).subtract(7, 'hours').format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            <span className='px-4 font-extrabold' style={{ fontSize: '18px' }}>
              {/* ผลออก {receiptDrawTime || ''} น. */}
              {language !== 'MM' ? 'ผลออก' : 'Reward at'} {receiptDrawTime || ''}
            </span>
          </div>
        </div>
        <div>
          {_.get(fetchedReceipt, 'receiptDetail.remark') ? (
            <div
              className='detailReceipts__time-wrapper overflow-auto text-left text-xl'
              style={{
                display: 'block',
                borderBottom: '2px solid #cdcdcd',
                margin: 'auto',
                width: '95%',
                padding: '5px 0',
              }}
            >
              <span
                className='detailReceipts__time-date'
                style={{
                  fontSize: '18px',
                }}
              >
                {language !== 'MM' ? 'Note' : 'บันทึก'}: {fetchedReceipt.receiptDetail.remark}
              </span>
            </div>
          ) : null}
          <div className='detailReceipts__mainBox-wrapper'>
            <div className='detailReceipts__mainBox-left'>
              {/* <span>ยอดแทง</span> */}
              <span>{language !== 'MM' ? 'ยอดแทง' : 'Total Amount'}</span>
              <span className='detailReceipts__resultBox-digit' style={{ color: colorCode.lottoBlack }}>
                {Math.round((fetchedReceipt.purchaseAmount + Number.EPSILON) * 100) / 100}
              </span>
            </div>
            <hr className='detailReceipts__mainBox-divider' />
            <div className='detailReceipts__mainBox-right'>
              {/* <span>ยอดถูก</span> */}
              <span>{language !== 'MM' ? 'ยอดถูก' : 'Total Win'}</span>

              <span
                className='detailReceipts__resultBox-digit'
                style={{
                  color: fetchedReceipt.prizeAmount > 0 ? colorCode.lottoYellow : '',
                }}
              >
                {Math.round((_.get(fetchedReceipt, 'prizeAmount', '') + Number.EPSILON) * 100) / 100}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* SECTION 3: RECEIPT BODY */}
      <div className='receipts-listing w-full pb-20'>
        {isLoading ? (
          <div className='loader loading-gif'>
            <img
              className='loading-indicator-gif '
              src={require('../../assets//dong-loading/dong-loading.png')}
              alt='loading'
            />
          </div>
        ) : (
          groupTypePurchase
        )}
      </div>
    </div>
  )
}

export default React.memo(DetailReceipt)
