import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { getAuth } from '../../redux/selectors/auth'

export default function AuthorizedRoute({ component: Component, ...rest }) {
  const auth = useSelector(getAuth)

  return (
    <Route
      {...rest}
      render={(props) => (auth && auth.username ? <Component {...props} /> : <Redirect to='/login' />)}
    />
  )
}
