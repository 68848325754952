import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Combinatorics from 'js-combinatorics'
import _ from 'lodash'

import { toggleCart } from '../../../redux/actions/cart'

export default function NumberInput({
  filter,
  currentDigit,
  currentNumRange,
  currentType,
  lottoNumber,
  isPermutation,
  fetchPayRate,
  highestPayRate,
}) {
  const dispatch = useDispatch()
  const cartStore = useSelector((state) => state).cart
  const cartItems = _.get(cartStore, 'items', [])

  const cartItemsGroupByType = _.groupBy(cartItems, 'type')

  const limiter = { '1': 10 }[currentDigit] || 100

  let numbers = []

  const removeDups = (array) => {
    let unique = {}
    array.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true
      }
    })
    return Object.keys(unique)
  }

  const isAlreadyExists = (number, singleType) => {
    return _.some(cartItemsGroupByType[singleType], (item) => item.detail === number)
  }

  const generatePermutation = (number, singleType) => {
    const arr = []
    if (singleType === '3R') {
      arr.push(Array.from(number))
    }
    const result = singleType === '3R' ? arr : Combinatorics.permutation(number.split('')).toArray()
    const unique = removeDups(result)
    let allChecked = false
    for (let i = 0; i < unique.length; i++) {
      let newNumber = unique[i].replace(/,/g, '').toString()
      if (!isAlreadyExists(newNumber, singleType)) {
        selectNumber(newNumber, singleType)
      } else {
        allChecked = true
      }
    }
    if (allChecked) {
      for (let i = 0; i < unique.length; i++) {
        let newNumber = unique[i].replace(/,/g, '').toString()
        selectNumber(newNumber, singleType)
      }
    }
  }

  const selectNumber = (number, singleType) => {
    if (fetchPayRate[singleType]) {
      dispatch(
        toggleCart({
          lottoNumber,
          purchaseItem: {
            type: singleType,
            detail: number === undefined ? '' : number,
            amount: 1,
            payRate:
              fetchPayRate[singleType][number] === undefined
                ? fetchPayRate[singleType].default
                : fetchPayRate[singleType][number],
            isNumInput: true,
          },
        })
      )
    }
  }

  const handleSelectNumber = (number, typeList) => {
    if (!isPermutation) {
      typeList.forEach((type) => {
        selectNumber(number, type)
      })
    } else {
      typeList.forEach((type) => {
        generatePermutation(number, type)
      })
    }
  }

  // !_.some(cartItemsGroupByType[type], (item) => item.detail === number)

  const generateFunction = (number, typeList) => {
    switch (isPermutation) {
      case true:
        handleSelectNumber(generateNumber(number), typeList)
        break
      default:
        handleSelectNumber(generateNumber(number), typeList)
    }
  }

  const generateNumber = (number) => {
    return number.toString().padStart(parseInt(currentDigit), '0')
  }

  const isSelected = (number) => {
    let result = false
    const lottoType = { '3': ['3A', '3R', '3F', '3B'], '2': ['2A', '2B'], '1': ['SA', 'SB'] }[currentDigit]
    lottoType.forEach((type) => {
      if (_.some(cartItemsGroupByType[type], (item) => item.detail === number)) {
        result = true
      }
    })
    return result
  }

  const isLimited = (number) => {
    if (_.includes(currentType, '2A') && _.includes(currentType, '2B')) {
      return fetchPayRate['2A']
        ? fetchPayRate['2A'][number] < highestPayRate['2A'] &&
            fetchPayRate['2A'][number] > 0 &&
            fetchPayRate['2B'][number] < highestPayRate['2B'] &&
            fetchPayRate['2B'][number] > 0
        : false
    } else if (_.includes(currentType, '3A') && _.includes(currentType, '3R')) {
      return fetchPayRate['3A']
        ? fetchPayRate['3A'][number] < highestPayRate['3A'] &&
            fetchPayRate['3A'][number] > 0 &&
            fetchPayRate['3R'][number] < highestPayRate['3R'] &&
            fetchPayRate['3R'][number] > 0
        : false
    } else {
      return fetchPayRate[currentType]
        ? fetchPayRate[currentType][number] < highestPayRate[currentType] && fetchPayRate[currentType][number] > 0
        : false
    }
  }

  const isClosed = (number) => {
    const result = fetchPayRate[currentType] ? fetchPayRate[currentType][number] === 0 : false
    return result
  }

  const range = parseInt(currentNumRange)

  for (let index = range; index < range + limiter; index++) {
    if (_.includes(generateNumber(index), filter)) {
      numbers.push(
        <button
          className={`btn-bevel ${isSelected(generateNumber(index)) ? 'active' : ''} ${
            isLimited(generateNumber(index)) ? 'limited' : isClosed(generateNumber(index)) ? 'closed disabled' : ''
          }`}
          key={index}
          onClick={() => generateFunction(index, currentType)}
        >
          {generateNumber(index)}
        </button>
      )
    }
  }

  return numbers
}
