import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import './Navigator.scss'
import * as Icons from './icons'
import homeIcon from './assets/dong-home.png'
import homeIconActive from './assets/home-active.png'
import lottoIcon from './assets/dong-lotto.png'
import lottoIconActive from './assets/lotto-active.png'
import hotIcon from './assets/dong-hot.png'
import hotIconActive from './assets/hot-active.png'
import resultIcon from './assets/dong-result.png'
import resultIconActive from './assets/result-active.png'
import walletIcon from './assets/dong-wallet.png'
import walletIconActive from './assets/wallet-active.png'
import fishingIcon from './assets/fishing.jpeg'
import { getLang } from '../../redux/selectors/lang'

function Navigator() {
  const [isCollpase, setIsCollapse] = useState(true)

  const location = useLocation()
  const currentLocation = location.pathname.split('/')[1]
  const lang = useSelector(getLang)
  const language = lang.lang

  return (
    <div className='navigator container-fluid'>
      <div className='wrapper'>
        <ul className='main-navigator'>
          <li>
            <NavLink activeClassName='active' exact to='/'>
              <img
                src={currentLocation === '' ? homeIconActive : homeIcon}
                style={{ width: '20px', marginBottom: '10px' }}
                alt='home'
              />
              <div style={currentLocation === '' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}>
                {language === 'MM' ? 'ပင်မစာမျက်နှာ' : 'หน้าแรก'}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/hotpick'>
              <img
                src={currentLocation === 'hotpick' ? hotIconActive : hotIcon}
                style={{ width: '20px', marginBottom: '10px' }}
                alt='lotto'
              />
              <div
                style={currentLocation === 'hotpick' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}
              >
                {language === 'MM' ? 'သေတ္တာ' : 'เลขเด็ด'}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/lottos'>
              <img
                src={currentLocation === 'lottos' ? lottoIconActive : lottoIcon}
                style={{ width: '25px', marginBottom: '10px' }}
                alt='lotto'
              />
              <div
                style={currentLocation === 'lottos' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}
              >
                {language === 'MM' ? 'ထီထိုးရန်' : 'แทงหวย'}
              </div>
              {/* style={{ width: '50px' }} */}
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/receipts'>
              <img
                src={currentLocation === 'receipts' ? resultIconActive : resultIcon}
                style={{ width: '20px', marginBottom: '10px' }}
                alt='check result'
              />
              <div
                style={currentLocation === 'receipts' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}
              >
                {language === 'MM' ? 'ထီမှတ်တမ်း' : 'ตรวจผล'}
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName='active' to='/deposit'>
              <img
                src={currentLocation === 'deposit' ? walletIconActive : walletIcon}
                style={{ width: '20px', marginBottom: '10px' }}
                alt='check result'
              />
              <div
                style={currentLocation === 'deposit' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}
              >
                {language === 'MM' ? 'ပိုက်ဆံအိပ်' : 'กระเป๋าเงิน'}
              </div>
            </NavLink>
          </li>
          {/* <li>
            <NavLink activeClassName='active' to='/games'>
              <img
                src={currentLocation === 'games' ? fishingIcon : fishingIcon}
                style={{ width: '40px' }}
                alt='check result'
              />
              <div
                style={currentLocation === 'games' ? { color: '#EFAC48', fontWeight: 'bold' } : { color: '#000000' }}
              >
                {language === 'MM' ? 'Fishing' : 'ยิงปลา'}
              </div>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Navigator
